import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Button from '../ui/button/Button'
import Typography from '../ui/typography/Typography'
import DirectionLineRight from '../ui/icons/DirectionLineRight'
import { useNavigate } from 'react-router-dom'

const DashboardTextCard = ({
  image,
  title,
  text,
  rawText,
  button,
}) => {
  const navigate = useNavigate()

  return (
    <div className="card dashboard-card d-lg-block">
      <div className="overflow-auto">
        <img src={image} alt="" className="float-lg-start me-lg-6 mb-4 mb-lg-2 dashboard-card__image" />
        <div className="dashboard-card__text">
          {title && <Typography textType="heading4" font="Moranga" className="mb-4" text={title} block />}
          {text &&<Typography textType="body1" className="d-lg-inline" text={text} />}
          {rawText && documentToReactComponents(rawText)}
        </div>
      </div>

      {button && (
        <div className="px-6 px-lg-0">
          <Button
            label={button}
            type="secondary"
            className="mt-6"
            rightIcon={<DirectionLineRight />}
            fullWidth
            onClick={() => { navigate('/knowledge-library', { state: { studiesExpanded: true }})}}
          />
        </div>
      )}
    </div>
  )
}

export default DashboardTextCard
