function ChevronLeftIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="32"
      fill="none"
      viewBox="0 0 31 32"
    >
      <path
        stroke="#444"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.667"
        d="M19.57 24l-7.617-8 7.618-8"
      ></path>
    </svg>
  );
}

export default ChevronLeftIcon;
