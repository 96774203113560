import Link from '../../components/ui/link/Link';

const ResetPasswordSuccess = () => {
  return (
    <div className="login-box">
      <div className="login-header">
        <h3>Forgot Your Password?</h3>
      </div>
      <div className="login-body mb-8 text-center">
        <p>We&apos;ve just sent you an email to reset your password.</p>
        <Link to={'/auth/sign-in'}>Go back to login</Link>
      </div>
    </div>
  )
}

export default ResetPasswordSuccess;
