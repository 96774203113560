import { Col, Container, ProgressBar, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ArrowLeft from '../ui/icons/ArrowLeft';
import Typography from '../ui/typography/Typography';
import CloseIcon from '../ui/icons/CloseIcon';

const QuizHeader = ({
  step,
  maxStep,
  onBackClick,
  titlePrefix,
  onCloseUrl,
  hideCloseButton,
}) => {
  const navigate = useNavigate();

  return (
    <Container className="questions-header-wrap">
      <Row>
        <Col>
          <div className="questions-header">
            <ProgressBar now={step + 1} max={maxStep} />
            <div className="questions-title">
              {step == 0 && <button className="back-btn" onClick={() => navigate(onCloseUrl ? onCloseUrl : "/")}><ArrowLeft /></button>}
              {step !== 0 && <button className="back-btn" onClick={() => onBackClick(step - 1)}><ArrowLeft /></button>}
              <Typography textType="heading4" text={`${titlePrefix}${step + 1}`} className="text-center" block />
              {!hideCloseButton && (
                <button className="close-btn" onClick={() => navigate(onCloseUrl ? onCloseUrl : "/")}>
                  <CloseIcon />
                </button>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default QuizHeader;
