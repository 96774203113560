import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormProvider, useForm } from 'react-hook-form'
import { unwrapResult } from '@reduxjs/toolkit'
import { Col, Row } from 'react-bootstrap'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import Typography from '../../ui/typography/Typography'
import PencilIcon from '../../ui/icons/PencilIcon'
import AccountAddressForm from './AccountAddressForm'
import Button from '../../ui/button/Button'
import { createAddress, getUserInfo, updateAccount, updateAddress } from '../../../store/slices/auth/authSlice'
import { customToast } from '../../ui/notification/customToast'

const BillingAddressForm = () => {
  const formMethods = useForm()
  const {
    handleSubmit,
    reset,
  } = formMethods
  const transitionRef = useRef(null)
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.auth);
  const { shipping_address, billing_address: address } = user;
  const [isEditing, setIsEditing] = useState(false)
  const [pending, setPending] = useState(false)

  const resetForm = () => {
    reset({
      firstname: address.firstname,
      lastname: address.lastname,
      address1: address.address1,
      city: address.city,
      country_iso: address.country_iso,
      state_name: address.state_code,
      zipcode: address.zipcode,
      phone: address.phone,
    })
  }

  const setAddressId = async (id) => {
    try {
      await dispatch(updateAccount({
        user: {
          email: user.email,
          bill_address_id: id,
        }
      })).then(unwrapResult);
      setPending(false);
      setIsEditing(false);
      dispatch(getUserInfo());
      customToast({
        title: 'Changes saved',
        message: 'Your changes have been successfully saved.',
        type: 'success'
      });
    } catch (error) {
      console.log(error);
      setPending(false);
    }
  }
  
  const updateExistingAddress = async (formData) => {
    try {
      await dispatch(updateAddress({ id: address.id, address: formData }));
      setPending(false);
      setIsEditing(false);
      dispatch(getUserInfo());
      customToast({
        title: 'Changes saved',
        message: 'Your changes have been successfully saved.',
        type: 'success'
      });
    } catch (error) {
      console.log(error);
      setPending(false);
    }
  }
  
  const createAndSetAddress = async (formData) => {
    try {
      const newAddress = await dispatch(createAddress(formData)).then(unwrapResult);
      await setAddressId(newAddress.data.id);
      setPending(false);
      setIsEditing(false);
      customToast({
        title: 'Changes saved',
        message: 'Your changes have been successfully saved.',
        type: 'success'
      });
    } catch (error) {
      console.log(error);
      setPending(false);
    }
  }
  
  const onSubmit = async ({ useShippingAddress, ...formData }) => {
    setPending(true);
  
    if (useShippingAddress) {
      setAddressId(shipping_address.id);
    } else if (address.id && address.id !== shipping_address.id) {
      updateExistingAddress(formData);
    } else {
      createAndSetAddress(formData);
    }
  }

  const onCancelEditing = () => {
    resetForm();
    setIsEditing(false);
  }

  const toggleEditing = (event) => {
    event.preventDefault();
    if (isEditing) {
      onCancelEditing()
    } else {
      setIsEditing(true);
    }
  };

  const form = (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AccountAddressForm
          data={address}
          savedData={shipping_address}
        />
        <Row>
          <Col className="mb-3 mb-lg-0">
            <Button
              label="Save Changes"
              onClick={handleSubmit(onSubmit)}
              disabled={pending}
              fullWidth
            />
          </Col>
          <Col>
            <Button
              label="Cancel"
              onClick={toggleEditing}
              type="secondary"
              fullWidth
            />
          </Col>
        </Row>
      </form>
    </FormProvider>
  )

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <Typography textType="heading5" text="Billing Address" />
        <button onClick={toggleEditing} className={`edit-button ${isEditing ? 'editing' : ''}`}>
          <span className="edit-button-text">{isEditing ? 'Editing...' : 'Edit'}</span>
          <PencilIcon />
        </button>
      </div>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={isEditing ? 'form' : 'default'}
          timeout={300}
          classNames="form-part"
          nodeRef={transitionRef}
        >
          {isEditing ? (
            <div ref={transitionRef}>
              {form}
            </div>
          ) : (
            <div ref={transitionRef}>
              {address.id ? (
                <div>
                  <Typography textType="body1" text={`${address.firstname} ${address.lastname}`} block />
                  <Typography textType="body1" text={`${address.address1}, ${address.city}, ${address.state_code}, ${address.zipcode}`} block />
                  <Typography textType="body1" text={`${address.country_name}`} block />
                  <Typography textType="body1" text={`${address.phone}`} block />
                </div>
              ) : (
                <Typography textType="body1" text="Address not set" block />
              )}
            </div>
          )}
        </CSSTransition>
      </SwitchTransition>
    </>
  )
}

export default BillingAddressForm
