import { useNavigate } from 'react-router-dom'
import Typography from '../ui/typography/Typography'
import ChevronLeftIcon from '../ui/icons/ChevronLeftIcon'

const BackButton = ({
  text,
  to,
}) => {
  const navigate = useNavigate()

  return (
    <button onClick={() => navigate(to)} className="mb-6 d-flex align-items-center">
      <ChevronLeftIcon />
      <Typography textType="heading5" text={text} />
    </button>
  )
}

export default BackButton
