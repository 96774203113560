import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import RadialSeparators from './RadialSeparators';

const ComparisonCircularProgressBar = ({
  percentage,
  pathColor,
}) => {
  let averagePercentage = 66;

  return (
    <div className="outer-progress-bar">
      <CircularProgressbarWithChildren
        value={percentage}
        strokeWidth={10}
        styles={buildStyles({
          strokeLinecap: "butt",
          pathColor,
          rotation: 1 / 2,
          trailColor: '#F6F6F6',
        })}
      >
      <RadialSeparators
            count={3}
            style={{
              background: "#fff",
              width: "4px",
              // This needs to be equal to props.strokeWidth
              height: `${10}%`,
            }}
          />
        <div className="inner-progress-bar">
          <CircularProgressbarWithChildren
            className="inner-circle"
            value={averagePercentage}
            strokeWidth={13}
            styles={buildStyles({
              strokeLinecap: "butt",
              pathColor: '#8CE2FF',
              rotation: 1 / 2,
              trailColor: '#F6F6F6',
            })}
          >
            <RadialSeparators
              count={3}
              style={{
                background: "#fff",
                width: "4px",
                // This needs to be equal to props.strokeWidth
                height: `${13}%`,
              }}
            /></CircularProgressbarWithChildren>
        </div>
      </CircularProgressbarWithChildren>
    </div>
  );
}

export default ComparisonCircularProgressBar
