import { useEffect, useState } from "react";
import { useDateSelect } from "react-ymd-date-select";
import dayjs from 'dayjs';
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import Dropdown from '../ui/forms/Dropdown';

dayjs.extend(isSameOrBefore);

const DateQuestion = ({
  onChange,
  setError,
  defaultValue,
}) => {
  const [date, setDate] = useState(defaultValue);
  const dateSelect = useDateSelect(date, setDate);
  const shortMonths = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  useEffect(() => {
    if (defaultValue) {
      setDate(defaultValue)
    }
  }, [defaultValue])

  useEffect(() => {
    if (dayjs(date).isValid() && dayjs(date).isSameOrBefore(new Date())) {
      setError();
      onChange([dayjs(date, 'YYYY-MM-DD').format('MM/DD/YYYY')])
    } else if (date) {
      setError('Please, enter valid date.');
    }
  }, [onChange, date, setError])

  const yearOptions = dateSelect.yearOptions.map((yearOption) => ({
    value: yearOption.value,
    label: yearOption.label,
  }))

  const monthOptions = dateSelect.monthOptions.map((monthOption) => ({
    value: monthOption.value,
    label: shortMonths[monthOption.label - 1]
  }))

  const dayOptions = dateSelect.dayOptions.map((dayOption) => ({
    value: dayOption.value,
    label: dayOption.label,
  }))
  return (
    <div className="question-answer question-date">
      <Dropdown placeholder="Month" value={dateSelect.monthValue ? shortMonths[dateSelect.monthValue - 1] : ''} options={monthOptions} onChange={dateSelect.onMonthChange} />
      <Dropdown placeholder="Day" value={dateSelect.dayValue} options={dayOptions} onChange={dateSelect.onDayChange} />
      <Dropdown placeholder="Year" value={dateSelect.yearValue} options={yearOptions.reverse().slice(0, 5)} onChange={dateSelect.onYearChange} />
    </div>
  )
}

export default DateQuestion
