import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { useEffect, useState } from 'react'
import { Container, Row, Col, Accordion } from 'react-bootstrap'
import Button from '../components/ui/button/Button';
import DirectionLineRight from '../components/ui/icons/DirectionLineRight';
import Typography from '../components/ui/typography/Typography'
import { fetchFaqPage } from '../services/apiService';
import { fetchContentfulPage } from '../services/apiService';
import { FAQ_SLUGS } from '../constants/pageSlugs';
import ScrollspyNav from 'react-scrollspy-nav'

const FAQ = () => {
  const [contentfulData, setContentfulData] = useState(null)
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const convertTitle = (name) => {
    return name.toLowerCase().replace(/\s/g, "-");
  }

  useEffect(() => {
    fetchContentfulPage(FAQ_SLUGS)
    .then((res) => {
      setContentfulData(res.data);
      setError(false);
    }).catch(() => {
      setError(true);
    })
    fetchFaqPage()
    .then((res) => {
      setData(res.data)
    }).catch(ex => {
      setError(ex.message);
    })
  }, [])

  const faqData = data.map(({ category }) => {
    category.modified_name = convertTitle(category.name)
    return category;
  }).reverse()
  if (!faqData || !contentfulData) return (
    <>Loading...</>
  )

  const {
    frequently_asked_questions_title,
    menu_category
  } = contentfulData;
    
  return (
    <>
      <div className='faq'>
        <div className="header-mobile d-lg-none">
          <div className="bg-white px-3 my-4">
            <Typography textType="heading2" className="font-Moranga" text={documentToPlainTextString(frequently_asked_questions_title?.content)} />
          </div>
          <div className="filters">
            <Typography textType="heading4" className="m-3" text={documentToPlainTextString(menu_category?.content)} />
            <ScrollspyNav
                        scrollTargetIds={ faqData.map((category)=>{return category.modified_name }) }
                        offset={70}
                        activeNavClass="is-active"
                        scrollDuration="250"
                        headerBackground="true"
                      >
              <ul className="d-flex flex-row overflow-scroll py-3">
                {faqData.map((category, index) => (
                  <li className="nav nav-pills mx-1" key={index}>
                    <a className="button button--secondary rounded-pill nav-link py-2" href={`#${category.modified_name}`}>{category.name}</a>
                  </li>
                ))}
              </ul>
            </ScrollspyNav>
          </div>
        </div>        
        <div className='container'>
          <div className='row'>
            <div className="col-3 sidebar">
              <div className="sidebar-content">
                <Typography textType="heading3" className="mb-8 font-Moranga" text={documentToPlainTextString(frequently_asked_questions_title?.content)} block />

                <div className="categories">
                  <Typography textType="heading4" className="mb-6" text={documentToPlainTextString(menu_category?.content)} />
                  <div className="list">
                    <ScrollspyNav
                      scrollTargetIds={ faqData.map((category)=>{return category.modified_name }) }
                      offset={70}
                      activeNavClass="is-active"
                      scrollDuration="250"
                      headerBackground="true"
                    >
                      <ul>
                        {faqData.map((category, index) => (
                          <li className="nav nav-pills flex-column" key={index}>
                            <a className="nav-link py-5" href={`#${category.modified_name}`}>{category.name}</a>
                          </li>
                        ))}
                      </ul>
                    </ScrollspyNav>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-lg-9 main-content gap-2">
              {faqData.map((category, index) => (
                <div className="faq-category" key={index} id={`${category.modified_name}`}>
                  <Typography textType="heading4" text={category.name} className="mb-3 mt-4 mt-md-0 fw-700" />
                  <Accordion className="faq-accordion">
                    {category.data.map((question, questionIndex) => (
                      <Accordion.Item eventKey={questionIndex} key={questionIndex}>
                        <Accordion.Header>
                          {question.question}
                        </Accordion.Header>
                        <Accordion.Body>
                          {documentToPlainTextString(question.answer)}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>      
    </>
  )
}

export default FAQ
