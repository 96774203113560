function TwitterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      fill="none"
      viewBox="0 0 35 35"
    >
      <path
        fill="#44C8F5"
        fillRule="evenodd"
        d="M0 17.5C0 7.835 7.835 0 17.5 0S35 7.835 35 17.5 27.165 35 17.5 35 0 27.165 0 17.5z"
        clipRule="evenodd"
        opacity="0.1"
      ></path>
      <path
        fill="#44C8F5"
        fillRule="evenodd"
        d="M16.976 14.224l.037.606-.612-.074c-2.228-.285-4.175-1.248-5.827-2.867l-.808-.804-.208.593c-.44 1.323-.16 2.72.759 3.658.49.52.38.594-.465.285-.294-.1-.551-.173-.576-.136-.085.086.208 1.21.441 1.656.318.618.967 1.223 1.677 1.582l.6.284-.71.012c-.686 0-.71.013-.637.272.245.803 1.212 1.656 2.29 2.027l.758.26-.66.395c-.98.568-2.13.89-3.281.914-.551.012-1.004.062-1.004.099 0 .124 1.493.816 2.363 1.087 2.607.804 5.704.458 8.03-.914 1.652-.976 3.305-2.916 4.076-4.795.416-1 .832-2.83.832-3.707 0-.569.037-.643.722-1.323.404-.395.784-.827.857-.951.123-.235.11-.235-.514-.025-1.04.37-1.187.322-.673-.235.38-.395.832-1.112.832-1.322 0-.037-.183.025-.391.136-.22.124-.71.309-1.078.42l-.66.21-.6-.408c-.331-.222-.796-.47-1.041-.543-.624-.173-1.58-.149-2.142.05-1.53.555-2.497 1.989-2.387 3.558z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default TwitterIcon;
