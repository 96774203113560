import { useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  applyCouponCode,
  cartSelector,
  removeCouponCode,
} from '../../store/slices/cart/cartSlice';
import CartItem from './CartItem';
import { storeState } from '../../constants/storeState';
import Link from '../ui/link/Link';
import Button from '../ui/button/Button';
import Input from '../ui/forms/Input';
import Typography from '../../components/ui/typography/Typography';
import ErrorIcon from '../ui/icons/Error';
import DirectionLineRight from '../ui/icons/DirectionLineRight';
import { useAuth0 } from '@auth0/auth0-react';

const CartView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, status, error } = useSelector(cartSelector);
  const isLoading = status === storeState.idle || status === storeState.loading;
  const [couponCode, setCouponCode] = useState('');

  const {
    isAuthLoading,
    isAuthenticated,
  } = useAuth0()

  const authenticated = !isAuthLoading && isAuthenticated;

  const onBuyNowClick = () => {
    navigate('/kits/baby-microbiome-gut-health-test-kit');
  }

  if (isLoading) {
    return (
      <div className="checkout-layout">
        <Container>
          <Row>
            <Col className="py-5 text-center">
              Loading...
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  if (!data || data?.lineItems.length === 0 || data.state === 'complete') {
    return (
      <div className="checkout-layout">
        <Container id="cart-view" className='empty-cart'>
          <Row>
            <Col className="pt-10 pt-30 pb-30 py-md-30 mb-md-30 text-center">
              <Typography textType="heading2" className="font-Moranga cart-title mt-0 mt-md-30 mb-2 mb-md-4 title-text" text="Your Cart" />
              <p>Oops! Looks like your cart is empty.<br />Use the button below to order a new testing kit!</p>
              <Button
                label="Add to Cart"
                onClick={onBuyNowClick}
                className="w-md-auto mx-auto"
              />
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  const { lineItems } = data

  const onApplyCouponCode = () => {
    dispatch(applyCouponCode({ coupon_code: couponCode }))

    setCouponCode('')
  }

  const onRemoveCouponCode = (code) => {
    dispatch(removeCouponCode({ code }))
    setCouponCode('')
  }
  
  const promoCodeSection = () => {
    if (lineItems?.length < 1) return null;

    if (data.promotions?.length > 0) { 
      return (
        <div className="promo-codes-list">
          {data.promotions.map((promotion, index) => (
            <div key={index}>
              <Button
                onClick={() => onRemoveCouponCode(promotion.code)}
                label={promotion.name}
                type="outline-primary"
                rightIcon={<ErrorIcon color='#44C8F5' />}
              />
            </div>
          ))}
        </div>
      )
    }
  }

  return (
    <div className="checkout-layout">
      <Container id="cart-view" >
        <Row>
          <Col md={12} className="d-flex justify-content-start">
            <h3 className="cart-title font-Moranga">Your Cart</h3>
          </Col>
        </Row>
        <Card body className="cart-card p-0 p-md-6" border="light">
          <Row className="d-flex gx-md-5 justify-content-between">
            <Col md={7} sm={12}>
              {lineItems.map(lineItem => <CartItem key={lineItem.id} lineItem={lineItem} />)}

            </Col>
            <Col id='invoice-section' md={5} sm={12}>
              <Col id='invoice-area' md={12} className="p-sm-0">
                <div className="d-flex flex-column">
              <div className="d-flex my-2">
                <Input
                  placeholder="Promo Code"
                  className="promo-code-input"
                  type="text"
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                />
                <div className="d-grid ms-3 flex-grow-1">
                  <Button
                    onClick={onApplyCouponCode}
                    label="Apply"
                  />
                </div>
              </div>
              {error && <p className='body2 p-1 mb-0 fw-600'><ErrorIcon color='red' className='mx-1' />{error}</p>}
              {promoCodeSection()}
            </div>
            <hr />
            <div className="invoice-line invoice-line--bold">
                  <span>Subtotal:</span>
                  {data.displayItemTotal}
                </div>
                <div className="invoice-line">
                  <span>Sales Tax</span>
                  <b>-</b>
                </div>
                <div className="invoice-line">
                  <span>Shipping</span>
                  <b>Free</b>
                </div>
                {data.promotions?.map(promo => (
                  <div className="invoice-line" key={promo.id}>
                    <span>{`Promo ${promo.name}`}</span>
                    <span className="promo-value">{promo.displayAmount}</span>
                  </div>
                ))}
                <hr />
                <div className="invoice-line invoice-line--bold">
                  <span>Total</span>
                  {data.displayTotalPrice}
                </div>
                <div  className="mt-6">
                  {authenticated ? 
                    (<Link to="/checkout?step=address">
                      <div className="d-grid gap-2 col-12 mx-auto">
                        <Button 
                          className="btn btn-light-blue btn-fh" 
                          label="Checkout" 
                          rightIcon={<DirectionLineRight />}
                          />
                      </div>
                    </Link>) 
                  :
                    (<Link to={`/auth/sign-in?redirect_url=${window.location.pathname}`}>
                      <div className="d-grid gap-2 col-12 mx-auto">
                          <Button
                            className="btn btn-light-blue btn-fh"
                            label="Sign In to Checkout"
                            rightIcon={<DirectionLineRight />}
                            />
                      </div>
                    </Link>) 
                  }
                </div>
              </Col>
            </Col>
          </Row>
        </Card>
      </Container>
    </div>
  )
}

export default CartView
