import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { createPortal } from 'react-dom'
import { useParams, useSearchParams } from 'react-router-dom'
import BabyGeneralInfo from '../../components/account/babyProfile/BabyGeneralInfo'
import Button from '../../components/ui/button/Button'
import Link from '../../components/ui/link/Link'
import Typography from '../../components/ui/typography/Typography'
import { useContentful } from '../../hooks/useContentful'
import { makeRequest } from '../../services/requestHandler'
import { useGetAccountByIdQuery } from './accountSlice'
import RemoveBabyProfile from './RemoveBabyProfile'

const pageSlugs = [
  { id: '3qJ2c4jFANK3kI0qciKWDh', slug: 'baby_name' },
  { id: '39cNOTnJMrSH5pgHQoqrZs', slug: 'birthday' },
  { id: '4pszKLgkjOU8KbHrzeQoWI', slug: 'highlight_pen_icon_black' },
  { id: '4yjL11bwn7z4CUUwvMIZy5', slug: 'button_view_baby_dashboard' },
  { id: '4DtsFwesse0u5mQNlhrG1x', slug: 'icon_arrow_right_white' },
  { id: 'ihf3cPPvUpPfoPMw2fPJk', slug: 'baby_information' },
  { id: '6R4Tm624rYi4ja0M2goaLt', slug: 'term_completed' },
  { id: '3lxGjnXyGUzWTH4ShYxEQp', slug: 'weight' },
  { id: '1JmZBs4N8dbEnNdMtiEjRt', slug: 'height' },
  { id: '3g6uz8e494fUTqegWGFwiL', slug: 'delivery_method' },
  { id: '5AP6nRBiSmvNGzqU8YmNy0', slug: 'length_of_breastfeeding' },
  { id: 'J6aGVwHpusUKkGVNLMwgC', slug: 'feeding_your_baby' },
  { id: '5oAuJGZGpD1k3bbNrv6h4I', slug: 'length_of_formula_feeding' },
  { id: '3XBsLSPaibF4x07auZjWHj', slug: 'course_of_antibiotics' },
  { id: '4hfmvbT2kUh8hBclDSsEwz', slug: 'types_of_antibiotics' },
  { id: '5ao8mxrNzVOMrRaveJQsQU', slug: 'course_of_probiotics' },
  { id: '1S27tLS7Ero73chbqrvyuC', slug: 'types_of_probiotics' },
  { id: '7Gclr3ty6IDJ5Ik5bgm9k0', slug: 'button_view_all' },
  { id: '7rPBDdbESYYFBkFDg2er8i', slug: 'icon_straight_arrow_black' },
]

const BabyProfile = () => {
  const { data: contentfulData } = useContentful({
    'sys.id[in]': pageSlugs.map(item => item.id).join(','),
    convertToObject: true,
  })
  const { childId } = useParams();
  const { data: accountData } = useGetAccountByIdQuery(childId)
  const [answers, setAnswers] = useState([]);
  const [registrationQuestions, setRegistrationQuestions] = useState([]);
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [viewAll, setViewAll] = useState(false);
  const [searchParams] = useSearchParams();
  const viewAllParam = searchParams.get('view_all');

  if(viewAllParam && viewAll !== viewAllParam) {
    setViewAll(viewAllParam);
  }

  useEffect(() => {
    const fetchAnswers = async () => {
      try {
        const response = await makeRequest({
          url: `/api/v1/answers?account_id=${childId}`,
          authType: 'auth0'
        })

        setAnswers(response.data)
      } catch (error) {
        console.error(error.message)
      }
    }

    fetchAnswers()
  }, [childId])

  useEffect(() => {
    const fetchQuestions = async (id) => {
      const response = await makeRequest({
        url: `/api/v1/question_variants?category_id=${id}`,
        authType: 'auth0'
      });
      return response.data;
    };
  
    const fetchQuestionCategories = async () => {
      const response = await makeRequest({
        url: '/api/v1/question_categories',
        isPublic: true
      });
  
      const registrationQuestions = response.data?.find(item => item.title === 'registration');
      const quizQuestions = response.data?.find(item => item.title === 'baby_profile');
  
      const [registration, quiz] = await Promise.all([
        fetchQuestions(registrationQuestions.id),
        fetchQuestions(quizQuestions.id)
      ]);
  
      setRegistrationQuestions(registration);
      setQuizQuestions(quiz);
    };
  
    fetchQuestionCategories();
  }, []);

  const getAnswerBySlug = (slug) => {
    const question = getQuestionBySlug(slug)
    if (!question) return null;
    const answer = answers.find(item => item.question_id === question.question_id);
    if (!answer) return null;

    return answer.value?.join(', ')
  }

  const getQuestionBySlug = (slug) => {
    return quizQuestions
      .filter(variant => variant.question.question_slug !== null)
      .find(variant => variant.question.question_slug.title === slug);
  }

  const getBabyName = () => {
    return accountData ? accountData.title : 'Baby'
  }

  const listArray = [{
    text: contentfulData?.term_completed.fields.content,
    slug: 'term_completed'
  }, {
    text: contentfulData?.weight.fields.content,
    slug: 'weight_of_baby'
  }, {
    text: contentfulData?.height.fields.content,
    slug: 'length_of_baby'
  }, {
    text: contentfulData?.delivery_method.fields.content,
    slug: 'delivery_method'
  }, {
    text: contentfulData?.length_of_breastfeeding.fields.content,
    slug: 'breastfeeding'
  }, {
    text: contentfulData?.feeding_your_baby.fields.content,
    slug: 'feeding_your_baby'
  }, {
    text: contentfulData?.course_of_antibiotics.fields.content,
    slug: 'course_of_antibiotics',
  }, {
    text: contentfulData?.types_of_antibiotics.fields.content,
    slug: 'date_of_antibiotics',
  }, {
    text: contentfulData?.course_of_probiotics.fields.content,
    slug: 'probiotics',
  }, {
    text: contentfulData?.types_of_probiotics.fields.content,
    slug: 'types_of_probiotics',
  }]

  const portalContainer = document.getElementById('mobile-main-content-head') || document.body;
  const mobileHeader = createPortal(
    <div className="d-lg-none mobile-content-header">
      <Typography textType="heading2" text={getBabyName()} className="font-Moranga mb-2" />
      <div className="d-flex gap-4 d-none">
        {/* // TODO: add contentful data */}
        <Typography textType="body1B" text="Registration Date: 1/01/2022" />
        <Typography textType="body1B" text="Last Updated: 02/01/2022" className="text-darkGrass" />
      </div>
    </div>,
    portalContainer
  );

  return (
    <>
      {mobileHeader}
      <BabyGeneralInfo
        questions={registrationQuestions}
        answers={answers}
        contentfulData={contentfulData}
        getAnswerBySlug={getAnswerBySlug}
      />

      <div>
        <Link to={`/account/my-babies/${childId}/dashboard`}>
          <Button
            label={documentToPlainTextString(contentfulData?.button_view_baby_dashboard.fields.content).replace('<<baby name>>', getBabyName())}
            rightIcon={<img src={contentfulData?.icon_arrow_right_white?.fields.image.fields.file.url} alt="" />}
            fullWidth
          />
        </Link>
        <hr className="my-6" />
        <Card className='p-6 pb-2'>
          <div className="mb-4">
            <Typography textType="heading4" text={`${getBabyName()}’s Information`} />
          </div>
          <div className="baby-information">
            {listArray.slice(0, viewAll ? undefined : 5).map(item => (
              <div key={item.id} className="baby-information-item">
                <Typography textType="body1B" text={documentToPlainTextString(item.text)} />
                <div>
                  <Typography textType="body1" text={getAnswerBySlug(item.slug)} />
                </div>
                <Link to={item.slug}>
                  <img src={contentfulData?.highlight_pen_icon_black?.fields.image.fields.file.url} alt="" />
                </Link>
              </div>
            ))}
          </div>
          {!viewAll && (
            <Button
              type="tertiary"
              label={documentToPlainTextString(contentfulData?.button_view_all.fields.content)}
              onClick={() => setViewAll(true)}
              fullWidth
            />
          )}
        </Card>
      </div>

      {/* TODO: Add slug */}
      <RemoveBabyProfile name={getBabyName()} />
    </>
  )
}

export default BabyProfile
