import * as yup from 'yup';
import validateCard from 'card-validator';

const addressSchemaObject = {
  firstname: yup.string().required(),
  lastname: yup.string().required(),
  address1: yup.string().required(),
  city: yup.string().required(),
  country_iso: yup.string().required(),
  state_name: yup.string().required(),
  zipcode: yup.string().required(),
  phone: yup.string().required(),
}

const paymentSchemaObject = {
  cardHolder: yup.string().required('Cardholder name is required'),
  cardNumber: yup.string().test('card', 'Card number is not valid', (value) => validateCard.number(value).isValid).required('Card number is required'),
  expDate: yup.string().test('expiration-date', 'Expiration date is not valid', (value) => validateCard.expirationDate(value).isValid).required('Expiration date is required'),
  cardCode: yup.string().required('Security code is required'),
}

export const addressSchema = yup.object(addressSchemaObject)

export const paymentSchema = yup.object(paymentSchemaObject);

export const billingStepSchema = yup.object(addressSchemaObject).shape(paymentSchemaObject)
