import Link from '../../../components/ui/link/Link';

const RecipeCard = ({ data }) => {

  return (
    <Link to={`/knowledge-library/recipe/${data.url}`} className="recipe-card">
      <img src={data.recipePicture?.file.url} alt="" />
      <p className="title">{data.title}</p>
      <span className="label">{data.foodTypeValue}</span>
      <p className="text">{data.wellnessArea.name}</p>
    </Link>
  )
}

export default RecipeCard;
