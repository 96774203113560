import { useState } from 'react'
import Button from '../../components/ui/button/Button'
import { Modal } from 'react-bootstrap'
import Typography from '../../components/ui/typography/Typography';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteAccountMutation } from './accountSlice';
import { customToast } from './../../components/ui/notification/customToast';

const RemoveBabyProfile = ({
  name = 'baby',
}) => {
  const { childId } = useParams();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [deleteAccount] = useDeleteAccountMutation();

  const onClick = async () => {
    const res = await deleteAccount(childId)

    if (res.error) {
      customToast({
        title: 'Something went wrong',
        message: 'Please, try again later.',
        type: 'error'
      });
    } else {
      navigate('/account')
      customToast({
        title: 'Changes saved',
        message: 'Baby profile was deleted',
        type: 'success'
      });
    }
  }

  return (
    <div>
      <Button
        type="tertiary"
        label="Remove baby profile"
        fullWidth
        className="text-black"
        onClick={() => setShowModal(true)}
      />

      <Modal show={showModal} contentClassName="question-modal" onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title className="gap-4 d-flex">
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Typography textType="heading3" font="Moranga" text={`Are you sure you want to remove ${name}'s profile?`} />

        </Modal.Body>
        <Modal.Footer className="flex-nowrap justify-content-center">
          <Button
            type="primary"
            label="Cancel"
            onClick={() => setShowModal(false)}
            fullWidth
          />
          <Button
            type="secondary"
            label="Confirm"
            onClick={onClick}
            fullWidth
          />
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default RemoveBabyProfile
