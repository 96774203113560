import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import auth0 from 'auth0-js';

const Auth0Context = createContext();
const DATABASE_CONNECTION = 'auth0';

const Auth0Provider = (props) => {
  const [redirectUrlState, setRedirectUrlState] = useState('/');

  const setRedirectUrl = useCallback((newRedirectUrl) => {
    setRedirectUrlState(newRedirectUrl);
  },[]);

  const [authConfig, setAuthConfig] = useState(null)

  useEffect(() => {
    fetch('/api/v1/configs')
      .then(res => res.json())
      .then(res => setAuthConfig(res.data))
  }, [])

  const webAuth = useMemo(() => new auth0.WebAuth({
    domain: process.env.AUTH0_DOMAIN,
    clientID: process.env.AUTH0_CLIENT_ID,
    responseType: 'token',
    redirectUri: window.location.origin,
    scope: 'openid read:permissions profile email',
    audience: authConfig?.api_identifier || ''
  }), [authConfig]);

  const loginWithUsernamePassword = useCallback((username, password) => {
    const urlParams = new URLSearchParams(window.location.search);
    const stateParam = urlParams.get('state') || '';
    sessionStorage.setItem('redirectUrl', redirectUrlState);
    return new Promise((resolve, reject) => {
      webAuth.login({
        username: username,
        password: password,
        realm: DATABASE_CONNECTION,
        state: stateParam,
        redirectUri: window.location.origin
      }, (error, result) => {
        if (error) {
          reject(error);
          return;
        }
        resolve(result);
      })
    })
  }, [redirectUrlState, webAuth]);

  const loginWithGoogle = useCallback(() => {
    sessionStorage.setItem('redirectUrl', redirectUrlState);
    webAuth.authorize({ connection: 'google-oauth2' }); 
  }, [redirectUrlState, webAuth]);

  const loginWithMicrosoft = useCallback(() => {
    sessionStorage.setItem('redirectUrl', redirectUrlState);
    webAuth.authorize({ connection: 'windowslive' })
  }, [redirectUrlState, webAuth])

  const signUp = useCallback((data) => {
    const { email, password } = data;
    sessionStorage.setItem('redirectUrl', redirectUrlState);
    return new Promise((resolve, reject) => {
      webAuth.signup({
        connection: DATABASE_CONNECTION,
        ...data,
      }, async (err, result) => {
        if (err) {
          reject(err);
          return;
        }
        loginWithUsernamePassword(email, password);
        resolve(result)
      });
    })
  }, [redirectUrlState, webAuth, loginWithUsernamePassword])

  const forgotPassword = useCallback((email) => {
    return new Promise((resolve, reject) => {
      webAuth.changePassword({
        email,
        connection: DATABASE_CONNECTION,
      }, function (err, response) {
        if(err){
          console.log(err.message);
          reject(err.message);
        } else {
          resolve(response);
        }
      })
    }) 
  }, [webAuth])

  return <Auth0Context.Provider value={{
    webAuth,
    loginWithGoogle,
    loginWithMicrosoft,
    loginWithUsernamePassword,
    signUp,
    forgotPassword,
    setRedirectUrl,
  }} {...props} />
}

const useCustomAuth0 = (options = {}) => {
  const context = useContext(Auth0Context);

  useEffect(() => {
    if (options.redirectUrl) {
      context.setRedirectUrl(options.redirectUrl);
    }
  }, [context, options.redirectUrl]);

  return context;
};

export { Auth0Provider, useCustomAuth0 }
