import { useSelector } from 'react-redux';
import clsx from 'clsx';
import Link from '../ui/link/Link';
import Bag from '../ui/icons/Bag';
import { cartItemsCountSelector } from '../../store/slices/cart/cartSlice';

const CartLink = ({
  className
}) => {
  const count = useSelector(cartItemsCountSelector);

  return (
    <Link to="cart" className={clsx('cart-link', { [className]: className })}>
      <Bag />
      <span className="cart-link-counter">{count}</span>
    </Link>
  )
}

export default CartLink;
