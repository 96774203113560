import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { selectAuthorizationPending, selectAuthorizationStatus } from '../../store/slices/auth/authSlice';

const withoutAuthorization = (Component) => {
  return AuthorizationNotRequired

  function AuthorizationNotRequired() {
    const location = useLocation()
    const loading = useSelector(selectAuthorizationPending)
    const { authorized } = useSelector(selectAuthorizationStatus)


    if (authorized && !loading && !location.state) {
      return <Navigate to="/" replace />
    }

    return !authorized
      ? <Component/>
      : <div>Loading...</div>
  }
}

export default withoutAuthorization
