import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { useState, useEffect } from 'react';
import { REGISTER_BABY_SLUGS } from '../../constants/pageSlugs';
import { fetchContentfulPage } from '../../services/apiService';
import { makeRequest } from '../../services/requestHandler';
import QuestionItem from './QuestionItem';
import QuizHeader from './QuizHeader';

const RegisterBabyQuiz = ({
  onFinishQuiz,
  hideCloseButton,
}) => {
  const [variants, setVariants] = useState([]);
  const [accountType, setAccountType] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const [childId, setChildId] = useState();
  const [contentfulData, setContentfulData] = useState(null);

  useEffect(() => {
    Promise.all([
      fetchContentfulPage(REGISTER_BABY_SLUGS),
      makeRequest({ url: '/api/v1/question_categories', isPublic: true }),
      makeRequest({ url: '/api/v1/account_types?title=Children', isPublic: true }),
    ]).then(([contentfulPageRes, questionCategoriesRes, accountTypesRes]) => {
        setContentfulData(contentfulPageRes.data);

        const category = questionCategoriesRes.data?.find(
          (item) => item.title === 'registration'
        );

        makeRequest({
          url: `/api/v1/question_variants?category_id=${category.id}`,
          isPublic: true,
        }).then((response) => {
          let nameQuestion;
          let questions = response.data.filter((q) => {
            if (q.question.question_slug?.title === 'babys_name') {
              nameQuestion = q;
              return false;
            } else {
              return true;
            }
          }).sort((a, b) => a.question.position - b.question.position);
          if (nameQuestion) {
            questions.unshift(nameQuestion);
          }

          setVariants(questions);
        });

        setAccountType(accountTypesRes.data[0]);
      })
      .catch((error) => console.error(error));
  }, []);

  if (!variants || !accountType || !contentfulData) {
    return <>Loading</>
  }

  const {
    question
  } = contentfulData;

  const onSubmit = (variant, value) => {
    if(activeStep == 0) {
      createChild(value[0])
        .then(childId => postAnswer(variant, value, childId));
    } else {
      postAnswer(variant, value, childId);
    }
  }

  const nextQuestion = () => {
    setActiveStep(activeStep + 1);
    window.scrollTo(0, 0);
  }

  const createChild = async (title) => {
    const account = {
      title,
      account_type_id: accountType.id
    }

    try {
      const response = await makeRequest({
        url: '/api/v1/accounts',
        method: 'POST',
        body: JSON.stringify(account),
        authType: 'auth0',
      });
      setChildId(response.data.id);
      return response.data.id;
    } catch (error) {
      console.error(error);
      throw new Error('Failed to create child account');
    }
  }

  const postAnswer = (variant, value, childId) => {
    try {
      const answer = {
        value: value,
        question_id: variant.question_id,
        account_id: childId
      }

      makeRequest({
        url: '/api/v1/answers',
        method: 'POST',
        body: JSON.stringify(answer),
        authType: 'auth0',
      }).then(() => {
        nextQuestion();
      });
    } catch (ex) {
      console.log(ex.message);
    }
  };

  return (
    <div className="page-wrap waitlist-questions-page">
      <QuizHeader
        step={activeStep}
        maxStep={variants?.length}
        onBackClick={() => setActiveStep(activeStep -1)}
        titlePrefix={documentToPlainTextString(question?.content)}
        onCloseUrl={-1}
        hideCloseButton={hideCloseButton}
      />
      <>
        {variants.map((variant, index) => (
          <QuestionItem
            key={index}
            variant={variant}
            activeStep={activeStep}
            maxStep={variants.length - 1}
            onSubmit={onSubmit}
            onFinish={onFinishQuiz}
          />
        ))[activeStep]}
      </>
    </div>
  )
}

export default RegisterBabyQuiz;
