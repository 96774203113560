import dayjs from 'dayjs';
import Button from '../../ui/button/Button';
import Typography from '../../ui/typography/Typography';

const KitTrackingCard = ({
  kit,
  onActivate,
  title,
  text,
}) => {
  const { lineItems } = kit;
  const [lineItem] = lineItems;

  return (
    <div className="kit-tracking-section">
      <div className="kit-card">
        <img src={lineItem.variant.image?.url} alt="" />
        <div>
          <Typography textType="heading4" text={lineItem.name} className="mb-2" />
          <div className="card-information">
            <span>Order</span>
            <b>{kit.orderNumber}</b>
            <span>Order date</span>
            <b>{dayjs(kit.createdAt).format('MMM DD, YYYY')}</b>
          </div>
        </div>
      </div>
      <div className="kit-status">
        <Typography textType="heading4" text="Status:" className="mb-2" />
        <Typography textType="heading3" text={title} className="mb-2" />
        <Typography textType="body1B" text={text} className="mb-2" />
        <Button
          type="primary"
          label="Activate kit"
          fullWidth
          disabled
        />
      </div>
    </div>
  )
}

export default KitTrackingCard;
