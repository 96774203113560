import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import Typography from '../ui/typography/Typography'
import ProgressLine from './ProgressLine'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'

const BacteriaResultCard = ({
  bacteriaData,
  contentfulData,
  title,
  babyName,
}) => {
  const [showModal, setShowModal] = useState()

  const renderBacterias = () => {
    return contentfulData?.bacteria.map(bacteria => {
      const result = bacteriaData.find(item => item.title === bacteria.fields.bacteria)

      return (
        <ProgressLine
          key={bacteria.fields.bacteria}
          name={bacteria.fields.bacteria}
          lowLabel={bacteria.fields.lowLabel}
          highLabel={bacteria.fields.highLabel}
          popupTitle={bacteria.fields.bacteria}
          popupText={bacteria.fields.popUpText}
          inverted={result?.target_type !== 'beneficial'}
          value={result?.summary}
        />
      )
    })
  }

  return (
    <div>
      <Typography
        textType="heading4"
        font="Moranga"
        text={documentToPlainTextString(title?.fields.content).replace('{{baby.name}}', babyName)}
        className="mb-4"
      />
      <div className="card">
        <div className="d-flex flex-column gap-4 mb-4">
          {renderBacterias()}
        </div>
        <div className="mt-4 text-center d-flex gap-4 align-items-center justify-content-center cursor-pointer" onClick={() => setShowModal(true)}>
          <img src={contentfulData?.howItIsMeasuredPopUpIcon?.fields.file.url} alt="" width={14} />
          <Typography textType="body3B" text="How is it measured?" />
        </div>

        <Modal show={showModal} contentClassName="question-modal" onHide={() => setShowModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title className="gap-4 d-flex">
              <img src={contentfulData?.howItIsMeasuredPopUpIcon?.fields.file.url} alt="" width={24} />
              {contentfulData?.howItIsMeasuredPopUpTitle}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="question-modal-text text-start" dangerouslySetInnerHTML={{ __html: contentfulData?.howItIsMeasuredPopUpText }} />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  )
}

export default BacteriaResultCard
