import { configureStore } from '@reduxjs/toolkit';
import product from './slices/products';
import auth from './slices/auth/authSlice';
import cart from './slices/cart/cartSlice';
import global from './slices/global'
import { dashboardApi } from '../pages/wellness/dashboard/dashboardSlice';
import { accountApi } from '../pages/account/accountSlice';
import { accountTypeApi } from '../pages/account/accountTypeSlice';

const middlewares = [
  dashboardApi.middleware,
  accountApi.middleware,
  accountTypeApi.middleware,
]

export const createReduxStore = (initialState = {}) =>
  configureStore({
    reducer: {
      auth,
      product,
      cart,
      global,
      [dashboardApi.reducerPath]: dashboardApi.reducer,
      [accountApi.reducerPath]: accountApi.reducer,
      [accountTypeApi.reducerPath]: accountTypeApi.reducer,
    },
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(middlewares)
  })
