import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import TrashIcon from '../ui/icons/TrashIcon';
import { useNavigate } from "react-router-dom";

import useUpdateItem from '../../hooks/cart/useUpdateItem';
import useRemoveItem from '../../hooks/cart/useRemoveItem';
import Typography from '../ui/typography/Typography';

const CartItem = ({
  lineItem,
  hideControls,
  showQuantity,
}) => {
  const updateItem = useUpdateItem()
  const removeItem = useRemoveItem()
  const { name, variant, quantity, path, id } = lineItem
  const { displayPrice } = variant
  let navigate = useNavigate();

  const increaseQuantity = () => {
    updateItem({
      quantity: quantity + 1,
      line_item_id: id
    })
  }

  const decreaseQuantity = () => {
    updateItem({
      quantity: quantity - 1,
      line_item_id: id
    })
  }

  const onRemoveClick = () => {
    removeItem({ id })
  }

  return (
    <div className="cart-item d-flex align-items-center">
      <div className="col-4">
        {variant.image && (
          <img
            data-testid="cart-item-img"
            src={variant.image.url}
            alt=""
            width="160"
            height="160"
          />
        )}
      </div>
        <div className="col-8 ps-4">
          <div className="cart-item-row">
            <Typography textType="body1B" text={name} />
            <Typography textType="body2B" text={displayPrice} />
          </div>
          {showQuantity && (
            <div className="cart-item-row">
              <Typography textType="body1B" text="Quantity:" />
              <Typography textType="body2B" text={quantity} />
            </div>
          )}
          {!hideControls && (
            <div className="mt-6">
              <div className="cart-controls">
                <div className="btn-group align-items-center stepper">
                  <Button
                    onClick={decreaseQuantity}
                    disabled={quantity <= 1}
                    data-testid="cart-item-decrease"
                  >
                    -
                  </Button>
                  <span className="px-2">{quantity}</span>
                  <Button
                    onClick={increaseQuantity}
                    data-testid="cart-item-increase"
                  >
                    +
                  </Button>
                </div>
                <Button onClick={onRemoveClick} data-testid="cart-item-remove" variant="link" className="p-0">
                  <TrashIcon />
                </Button>
              </div>
            </div>
          )}
        </div>
    </div>
  )
}

CartItem.propTypes = {
  lineItem: PropTypes.shape({
    name: PropTypes.string,
    displayTotal: PropTypes.string,
    variant: PropTypes.shape({
      displayPrice: PropTypes.string,
      image: PropTypes.object
    }),
    quantity: PropTypes.number,
    path: PropTypes.string,
    id: PropTypes.string
  }).isRequired
}

export default CartItem
