import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import AdvisorySlider from '../components/sliders/AdvisorySlider';
import ReviewSlider from '../components/sliders/ReviewSlider';
import Button from '../components/ui/button/Button';
import Typography from '../components/ui/typography/Typography';
import { fetchContentfulPage } from '../services/apiService';
import { LANDING_PAGE_SLUGS } from '../constants/pageSlugs';
import DirectionLineRight from '../components/ui/icons/DirectionLineRight';
import Logo from '../components/ui/icons/logo.svg';
import LandingHeroWaveMask from '../components/ui/icons/LandingHeroWaveMask';
import Link from '../components/ui/link/Link';
import { getContentfulContent } from '../utils/get-contentful-content';
import ColoredText from '../components/contentful/ColoredText';

const HomeMvp = () => {
  const [pageData, setPageData] = useState(null)
  const [error, setError] = useState(false)

  useEffect(() => {
    fetchContentfulPage(LANDING_PAGE_SLUGS)
    .then((res) => {
      setPageData(res.data);
      setError(false);
    }).catch(() => {
      setError(true);
    })
  }, [])

  if (error) {
    return <>Some error happened</>
  }

  if (!pageData) return (
    <>Loading...</>
  )

  const {
    landing_hero_title,
    landing_hero_subtitle,
    mvp_new_hero_image,
    landing_how_it_works_title,
    landing_how_it_works_1_step,
    landing_how_it_works_2_step,
    landing_how_it_works_3_step,
    landing_how_it_works_1_text,
    landing_how_it_works_2_text,
    landing_how_it_works_3_text,
    landing_how_it_works_image_1,
    landing_how_it_works_image_2,
    landing_how_it_works_image_3,
    landing_advisors_intro,
    landing_quiz_intro_title,
    landing_quiz_intro_text,
    landing_skin_title,
    landing_skin_text1,
    landing_skin_text2,
    landing_activities_title,
    landing_activities_text1,
    landing_activities_text2,
    landing_nutrition_title,
    landing_nutrition_text1,
    landing_nutrition_text2,
    landing_baby_gut_bacteria,
    landing_baby_names_text,
    about_us_terracycle_logo_image,
    tc_discovery_logo_gray,
    first_step_diaper_icon,
    second_step_tube_icon,
    blue_arrow_icon,
    third_step_check_icon,
    first_step_diaper_text,
    second_step_tube_text,
    third_step_check_text,
    landing_tiniest_biome,
    landing_tiniest_biome_decodes,
    tiniest_biome_decodes,
    button_more_about_us,
    brought_to_you_text,
    landing_review_title,
    landing_review_1,
    landing_review_1_name,
    landing_review_2,
    landing_review_2_name,
    landing_review_3,
    landing_review_3_name,
    button_get_started,
    button_take_quiz,
    landing_meet_our_experts_title,
    brought_to_you_title,
    tiniest_biome_what_you_can_do_mobile_screen,
    testimonials_title,
    testimonials_legal_comment
  } = pageData;

  return (
    <div className="landing">
      <section className="landing-hero mb-md-10 mt-md-10">
        <div className="section-gradient" />
        <Container>
          <Row className='d-flex align-items-center'>
            <Col md={6} className="hero-image order-1 order-md-1 h-100">
              {mvp_new_hero_image && <div className="hero-image-background d-md-none" style={{
                background: `url(${mvp_new_hero_image.image.fields.file.url})`
              }}><LandingHeroWaveMask/></div>}
              {mvp_new_hero_image && <img src={mvp_new_hero_image.image.fields.file.url} alt="" className="d-none d-md-block" />}
            </Col>
            <Col xs={12} md={6} className="order-2 order-md-2 px-6 px-md-9">
              <div className="landing-hero-content">
                <div>
                  <div className="d-none d-md-block mb-4">
                    <Logo />
                  </div>
                  {landing_hero_title && <Typography textType="heading1" className="font-Moranga mt-6 mb-6 mb-md-8 title-text" text={documentToPlainTextString(landing_hero_title.content)} />}
                  <div className='hero-button-container py-4 py-md-0 px-9 px-md-0 d-block w-100'>
                    <Link to="/kits/baby-microbiome-gut-health-test-kit">
                      {button_get_started && (
                        <Button
                          className="mt-md-0 mb-md-6 mx-auto mx-md-0 hero-button"
                          label={documentToPlainTextString(button_get_started.content)}
                        />
                      )}
                    </Link>
                  </div>
                  {landing_hero_subtitle && <Typography textType="heading4" text={documentToReactComponents(landing_hero_subtitle.content)} className="subtitle" />}
                  <div className="landing-steps d-none">
                    {first_step_diaper_icon?.image && (
                      <div className="landing-step">
                        <img src={first_step_diaper_icon.image.fields.file.url} alt="" />
                        {first_step_diaper_text && <span className="mt-2">{documentToPlainTextString(first_step_diaper_text?.content)}</span>}
                      </div>
                    )}
                    {blue_arrow_icon?.image && <img src={blue_arrow_icon.image.fields.file.url} className="landing-step-arrow" alt="" />}
                    {second_step_tube_icon?.image && (
                      <div className="landing-step">
                        <img src={second_step_tube_icon.image.fields.file.url} alt="" />
                        {second_step_tube_text && <span className="mt-2">{documentToPlainTextString(second_step_tube_text?.content)}</span>}
                      </div>
                    )}
                    {blue_arrow_icon?.image && <img src={blue_arrow_icon.image.fields.file.url} className="landing-step-arrow" alt="" />}
                    {third_step_check_icon?.image && (
                      <div className="landing-step">
                        <img src={third_step_check_icon.image.fields.file.url} alt="" />
                        {third_step_check_text && <span className="mt-2">{documentToPlainTextString(third_step_check_text?.content)}</span>}
                      </div>
                    )}
                  </div>
                </div>
                
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      
      <div className="section-gradient" />
      <section className="mt-8 mt-md-0">
        <Container>
          <Row className='justify-content-center'>
            <Col xs={7} md={7} className="d-none d-md-flex mb-12 text-center font-Moranga">
              {landing_how_it_works_title && <Typography textType="heading2" text={documentToPlainTextString(landing_how_it_works_title.content)} />}
            </Col>
            <Col>
              <div className="landing-card-big">
                {landing_how_it_works_title && <Typography textType="heading2" className="text-center d-md-none mb-8 font-Moranga" text={documentToPlainTextString(landing_how_it_works_title.content)} />}
                <div className="items-wrap">
                  <div className="item">
                    {landing_how_it_works_1_step && <Typography textType="heading3" text={documentToPlainTextString(landing_how_it_works_1_step.content)} className="mb-6" />}
                    {landing_how_it_works_image_1?.image && <img src={landing_how_it_works_image_1.image.fields.file.url} className="how-it-works-image" alt="" />}
                    {landing_how_it_works_1_text && <span>{documentToPlainTextString(landing_how_it_works_1_text.content)}</span>}
                  </div>
                  <div className="item">
                    {landing_how_it_works_2_step && <Typography textType="heading3" text={documentToPlainTextString(landing_how_it_works_2_step.content)} className="mb-6" />}
                    {landing_how_it_works_image_2?.image && <img src={landing_how_it_works_image_2.image.fields.file.url} className="how-it-works-image" alt="" />}
                    {landing_how_it_works_2_text && <span>{documentToPlainTextString(landing_how_it_works_2_text.content)}</span>}
                  </div>
                  <div className="item">
                    {landing_how_it_works_3_step && <Typography textType="heading3" text={documentToPlainTextString(landing_how_it_works_3_step.content)} className="mb-6" />}
                    {landing_how_it_works_image_3?.image && <img src={landing_how_it_works_image_3.image.fields.file.url} className="how-it-works-image" alt="" />}
                    {landing_how_it_works_3_text && <span>{documentToPlainTextString(landing_how_it_works_3_text.content)}</span>}
                  </div>
                </div>
                <Link to="/kits/baby-microbiome-gut-health-test-kit" className='d-none d-md-block how-it-works-get-started-btn'>
                  {button_get_started && (
                    <Button
                      className="mt-md-0 mb-md-6 me-auto mx-auto"
                      label={documentToPlainTextString(button_get_started.content)}
                    />
                  )}
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="blue-gradient-section">
        <div className="gradient-start" />
        <div className="gradient-content py-8 py-md-0">
          <Container>
            <Row className="mb-8 mb-md-16 text-center text-md-start about-hero">
              <Col xs={12} md={4} className="text-center">
                {landing_baby_gut_bacteria?.image && <img src={landing_baby_gut_bacteria.image.fields.file.url} className="mb-8 mb-md-0" alt="" />}
              </Col>
              <Col xs={12} md={7}>
                <span className="about-tiniest-biome-title">
                  <ColoredText content={tiniest_biome_decodes?.text} color="magenta" />
                </span>
              </Col>

            </Row>
            <Row>
              <Col>
                <div className="info-cards-wrap">
                  <div className="info-card">
                    {landing_nutrition_title && <Typography textType="heading3" text={documentToPlainTextString(landing_nutrition_title.content)} block className="mb-3 text-center" />}
                    {landing_nutrition_text1 && <div className="text">{documentToPlainTextString(landing_nutrition_text1.content)}</div>}
                    {landing_nutrition_text2 && <div className="text">{documentToPlainTextString(landing_nutrition_text2.content)}</div>}
                  </div>
                  <div className="info-card">
                    {landing_activities_title && <Typography textType="heading3" text={documentToPlainTextString(landing_activities_title.content)} block className="mb-3 text-center" />}
                    {landing_activities_text1 && <div className="text">{documentToPlainTextString(landing_activities_text1.content)}</div>}
                    {landing_activities_text2 && <div className="text">{documentToPlainTextString(landing_activities_text2.content)}</div>}
                  </div>
                  <div className="info-card">
                    {landing_skin_title && <Typography textType="heading3" text={documentToPlainTextString(landing_skin_title.content)} block className="mb-3 text-center" />}
                    {landing_skin_text1 && <div className="text">{documentToPlainTextString(landing_skin_text1.content)}</div>}
                    {landing_skin_text2 && <div className="text">{documentToPlainTextString(landing_skin_text2.content)}</div>}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className='d-none d-md-block'>
              <Col>
                <Link to="/kits/baby-microbiome-gut-health-test-kit">
                  {button_get_started && (
                    <Button
                      className="mt-md-16 mb-md-0 me-auto mx-auto infocards-get-started-btn"
                      label={documentToPlainTextString(button_get_started.content)}
                    />
                  )}
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="gradient-end" />
      </section>

      <div className="white-gradient-section">

        <div className="gradient-content">
          <section className="landing-quiz-section pt-12 pb-1">
            <Container>
              <Row className='align-items-center justify-content-center'>
                <Col xs={12} md={5}>
                  {tiniest_biome_what_you_can_do_mobile_screen?.image && <img src={tiniest_biome_what_you_can_do_mobile_screen.image.fields.file.url} className="d-none d-md-block" alt="" />}
                </Col>
                <Col xs={12} md={5} className="text-center text-md-start">
                  {landing_quiz_intro_title && <Typography textType="heading3" block className="mb-8 font-Moranga" text={documentToPlainTextString(landing_quiz_intro_title.content)} />}
                  {tiniest_biome_what_you_can_do_mobile_screen?.image && <img src={tiniest_biome_what_you_can_do_mobile_screen.image.fields.file.url} alt="" className="d-md-none mb-8" />}
                  {landing_quiz_intro_text && <Typography textType="body1" className="mb-8" block text={documentToPlainTextString(landing_quiz_intro_text.content)} />}
                  <Link to="waitlist-quiz">
                    <Button label={getContentfulContent(button_take_quiz)} className="w-auto" />
                  </Link>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="pt-10 pb-8 pt-md-18 pb-md-16">
            <Container>
              <Row>
                <Col xs={12}>
                  <Typography block textType="heading2" className="font-Moranga mt-12 mb-8 mt-md-20 mt-mb-12 text-center" text={getContentfulContent(landing_meet_our_experts_title)} />
                </Col>
                <Col>
                  {landing_advisors_intro && <Typography textType="heading2" text={documentToPlainTextString(landing_advisors_intro.content)} block className="landing-subtitle mb-6 mb-md-10" />}
                  <AdvisorySlider />
                  <Link to="about-us">
                    {button_more_about_us && <Button
                        label={documentToPlainTextString(button_more_about_us.content)}
                        type="secondary"
                        size="large"
                        className="m-auto w-100 w-md-auto"
                        rightIcon={<DirectionLineRight />}
                    />}
                  </Link>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="landing-review-section py-20 pb-8 pb-md-20">
            <Container>
              <Row className='mb-16'>
                <Col xs={12} md={12}>
                  {testimonials_title && <Typography textType="heading2" block className="mb-4 mb-md-16 font-Moranga text-center mx-auto pink-title-tiniest-biome" text={documentToReactComponents(testimonials_title?.text)} />}
                </Col>
                <Row className="d-none d-sm-flex align-items-center justify-content-center review-gap">
                  <Col xs={12} md={3}>
                    {landing_review_1 && <Typography textType="heading5" className="mb-8 text-center" block text={documentToPlainTextString(landing_review_1.content)} />}
                    {landing_review_1_name && <Typography textType="body1" className="mb-8 text-center" block text={documentToPlainTextString(landing_review_1_name.content)} />}
                  </Col>
                  <Col xs={12} md={3}>
                    {landing_review_2 && <Typography textType="heading5" className="mb-8 text-center" block text={documentToPlainTextString(landing_review_2.content)} />}
                    {landing_review_2_name && <Typography textType="body1" className="mb-8 text-center" block text={documentToPlainTextString(landing_review_2_name.content)} />}
                  </Col>
                  <Col xs={12} md={3}>
                    {landing_review_3 && <Typography textType="heading5" className="mb-8 text-center" block text={documentToPlainTextString(landing_review_3.content)} />}
                    {landing_review_3_name && <Typography textType="body1" className="mb-8 text-center" block text={documentToPlainTextString(landing_review_3_name.content)} />}
                  </Col>
                </Row>
                <Row className="d-block d-sm-none">
                  <ReviewSlider />
                </Row>
              </Row>
              <Row className='mb-0 mb-md-10 align-items-center justify-content-center'>
                <Col xs={12} md={6} className='px-12'>
                  {testimonials_legal_comment && <Typography textType="body1" className="text-center review-note" block text={documentToReactComponents(testimonials_legal_comment.content)} />}
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </div>

      <section className="tcd-section">
        <Container>
          <Row>
            <Col xs={12} md={8} lg={5} className='px-9 px-md-0'>
              <Typography textType="heading2" className="mb-2" text={getContentfulContent(brought_to_you_title)} />
              <div className="tcd-section-content">
                {tc_discovery_logo_gray && <img src={tc_discovery_logo_gray.image.fields.file.url} alt="TerraCycle Discovery" />}
                {brought_to_you_text && <Typography textType="body1" text={documentToReactComponents(brought_to_you_text.content)} />}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

    </div>
  )
}

export default HomeMvp
