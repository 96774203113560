import clsx from 'clsx';
import { useEffect, useState } from 'react';
import ScrollspyNav from 'react-scrollspy-nav';
import ChevronDownIcon from '../../../components/ui/icons/ChevronDown';
import Typography from '../../../components/ui/typography/Typography';
import { fetchContentfulPage } from '../../../services/apiService';
import Filters from './Filters';
import useKnowledgeLibrary from '../../../hooks/useKnowledgeLibrary';
import RecipeCard from './RecipeCard';
import KnowledgeList from './KnowledgeList';
import GlossaryItem from './GlossaryItem';
import StudyCard from './StudyCard';
import ExerciseCard from './ExerciseCard';
import { useLocation } from 'react-router-dom';

const slugs = [
  'category_recipes',
  'category_exercises',
  'category_studies',
  'category_glossary',
];

const KnowledgeLibrary = () => {
  const location = useLocation()
  const [pageData, setPageData] = useState({})
  const [wellnessAreas, setWellnessAreas] = useState([])
  const [recipes, setRecipes] = useState([])
  const [activities, setActivities] = useState([])
  const [studies, setStudies] = useState([])
  const [glossary, setGlossary] = useState([])
  const [appliedFilters, setAppliedFilters] = useState([])
  const [error, setError] = useState(false)
  const [filtersCollapsed, setFiltersCollapsed] = useState(false)
  const {
    getRecipes,
    getPhysicalActivities,
    getStudies,
    getGlossary,
    getWellnessAreas,
  } = useKnowledgeLibrary()

  const filteredRecipes = appliedFilters.length == 0 ? recipes : recipes.filter(recipe => appliedFilters.some(item => item === recipe.wellnessArea.id));
  const filteredExercises = appliedFilters.length == 0 ? activities : activities.filter(activity => appliedFilters.some(item => activity.wellnessAreas.some(area => area.id === item)));
  const filteredStudies = appliedFilters.length == 0 ? studies : studies.filter(study => appliedFilters.some(item => study.wellnessArea.some(area => area.id === item)));
  const filteredGlossary = appliedFilters.length == 0 ? glossary : glossary.filter(glossaryItem => appliedFilters.some(item => glossaryItem.wellnessAreas?.some(area => area.id === item)));
  const sortedGlossary = filteredGlossary.sort((a, b) => {
    if (a.term > b.term) return 1;
    if (a.term < b.term) return -1;
    return 0;
  });

  useEffect(() => {
    fetchContentfulPage(slugs)
    .then((res) => {
      setPageData(res.data);
      setError(false);
    }).catch(() => {
      setError(true);
    })
  }, [])

  useEffect(() => {
    getWellnessAreas().then(areas => setWellnessAreas(areas));
    getRecipes().then(recipes => setRecipes(recipes));
    getPhysicalActivities().then(activities => setActivities(activities));
    getStudies().then(studies => setStudies(studies));
    getGlossary().then(glossaries => setGlossary(glossaries));
  }, [])

  const handleToggleFilter = (id) => {
    if (!id) {
      return setAppliedFilters([])
    }

    if (appliedFilters.some(filter => filter === id)) {
      setAppliedFilters(appliedFilters.filter(filter => filter !== id))
    } else {
      setAppliedFilters([id, ...appliedFilters])
    }

    window.scrollTo(0, 0);
  }

  return (
    <div className="knowledge-library">
      <div className="header-mobile d-lg-none">
        <div className="page-title">
          <Typography textType="heading2" text="Knowledge Library" className="font-Moranga" />
        </div>
        <div className="filters">
          <Filters wellnessAreas={wellnessAreas} appliedFilters={appliedFilters} onFilterClick={handleToggleFilter} />
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-3 sidebar">
            <div className="sidebar-content">
              {/* <Typography textType="heading5" className="mb-6" text="Back to [Page name]" /> */}
              <Typography textType="heading3" className="mb-8 font-Moranga" text="Knowledge Library" block />

              <div className="categories">
                <Typography textType="heading4" className="mb-6" text="Category" />
                <div className="list">
                  <ScrollspyNav
                    scrollTargetIds={["recipes", "activities", "studies", "glossary"]}
                    offset={70}
                    activeNavClass="is-active"
                    scrollDuration="250"
                    headerBackground="true"
                  >
                    <ul>
                      <li><a href="#recipes">{pageData.category_recipes?.name}</a></li>
                      <li><a href="#activities">{pageData.category_exercises?.name}</a></li>
                      <li><a href="#studies">{pageData.category_studies?.name}</a></li>
                      <li><a href="#glossary">{pageData.category_glossary?.name}</a></li>
                    </ul>
                  </ScrollspyNav>
                </div>
                <div className="filters">
                  <div className={clsx('filters-title', { collapsed: filtersCollapsed })} onClick={() => setFiltersCollapsed(!filtersCollapsed)}>
                    Wellness Area
                    <ChevronDownIcon />
                  </div>
                  <Filters wellnessAreas={wellnessAreas} appliedFilters={appliedFilters} onFilterClick={handleToggleFilter} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-lg-9 main-content px-md-6 px-3 pt-4 pt-md-20 mb-6">
            <KnowledgeList
              id="recipes"
              items={filteredRecipes}
              listItemComponent={RecipeCard}
              title={pageData.category_recipes?.name}
              initialItemsVisible={window.innerWidth > 992 ? 8 : 4}
              listClassName="recipes-list"
              expanded={location.state?.recipesExpanded}
            />

            <KnowledgeList
              id="activities"
              items={filteredExercises}
              listItemComponent={ExerciseCard}
              title={pageData.category_exercises?.name}
              initialItemsVisible={window.innerWidth > 992 ? 8 : 4}
              listClassName="recipes-list"
              expanded={location.state?.exercisesExpanded}
            />

            <KnowledgeList
              id="studies"
              items={filteredStudies}
              listItemComponent={StudyCard}
              title={pageData.category_studies?.name}
              initialItemsVisible={window.innerWidth > 992 ? 6 : 2}
              listClassName="studies-list"
              expanded={location.state?.studiesExpanded}
            />

            <KnowledgeList
              id="glossary"
              items={sortedGlossary}
              listItemComponent={GlossaryItem}
              title={pageData.category_glossary?.name}
              initialItemsVisible={window.innerWidth > 992 ? 4 : 2}
              listClassName="glossary-list"
              expanded={location.state?.glossaryExpanded}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default KnowledgeLibrary;
