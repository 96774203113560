import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { useState } from 'react'
import { Form } from 'react-bootstrap'
import { useContentful } from '../../hooks/useContentful'
import Button from '../ui/button/Button'
import { useGetAccountTypesQuery } from '../../pages/account/accountTypeSlice'
import { useGetAccountsQuery } from '../../pages/account/accountSlice'

const slugs = [
  { id: '2Y7cm21wOFxuI4UtpFQ1qd', name: 'register_another_baby' },
  { id: '4gEYJdGwN4BPHN6bNNs12v', name: 'add_your_baby' },
  { id: 'm0eyeWtcP9PyqVlyKQSaX', name: 'please_select' },
  { id: '6r9xkfDfBIBZqEZjdE4xk5', name: 'button_next' },
]

const ChooseBabyForm = ({
  onNextClick,
  onAddBabyClick,
}) => {
  const [selectedAccount, setSelectedAccount] = useState(null);
  const { data: accountTypes } = useGetAccountTypesQuery({
    title: 'Children'
  })
  const { data: babies = [] } = useGetAccountsQuery({
    account_type_id: accountTypes?.[0]?.id
  }, {
    skip: !accountTypes
  })

  const { data } = useContentful({
    'sys.id[in]': slugs.map(i => i.id).join(','),
    convertToObject: true,
  });

  const handleBabyChange = (event) => {
    setSelectedAccount(event.target.value)
  }

  if (!data) return null;

  return (
    <div className="activation-choose-baby">
      {!babies ? (
        <div>
          <Button
            label={documentToPlainTextString(data.add_your_baby.fields.content)}
            onClick={onAddBabyClick}
            fullWidth
          />
        </div>
      ) : (
        <div>
          <Form.Select
            aria-label="Select child"
            className="w-100 px-5 py-4 mb-6"
            onChange={handleBabyChange}
          >
            <option value="">{documentToPlainTextString(data.please_select.fields.content)}</option>
            {babies.map((baby) => (
              <option key={baby.id} value={baby.id}>{baby.title}</option>
            ))}
          </Form.Select>
          <Button
            type="tertiary"
            label={documentToPlainTextString(data.register_another_baby.fields.content)}
            className="mb-6"
            onClick={onAddBabyClick}
            fullWidth
          />
          <Button
            size="large"
            label={documentToPlainTextString(data.button_next.fields.content)}
            disabled={!selectedAccount}
            onClick={() => onNextClick(selectedAccount)}
            fullWidth
          />
        </div>
      )}
    </div>
  )
}

export default ChooseBabyForm
