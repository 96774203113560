import { useEffect } from 'react';
import { Col, Form, Row, FloatingLabel } from 'react-bootstrap'; 
import { Controller, useFormContext } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';
import validateCard from 'card-validator';
import { selectAuthorizationStatus } from '../../store/slices/auth/authSlice';
import { getPaymentMethods } from '../../store/slices/cart/cartSlice';
import CalendarIcon from '../ui/icons/CalendarIcon';
import { yupResolver } from '@hookform/resolvers/yup';
import { paymentSchema } from '../../utils/form-validation';

const PaymentForm = () => {
  const dispatch = useDispatch()
  const { token } = useSelector(selectAuthorizationStatus)
  const {
    control,
    watch,
    register,
    formState: { errors }
  } = useFormContext({ resolver: yupResolver(paymentSchema) })
  const cardCode = validateCard.number(watch('cardNumber')).card

  useEffect(() => {
    dispatch(getPaymentMethods())
  }, [dispatch, token])

  return (
    <Row className='py-3'>
      <Col xs={12}>
        <FloatingLabel label="Cardholder name" className="mb-4" controlId="paymentCardNumber">
          <Form.Control
            type="text"
            className="w-100"
            placeholder="Cardholder name"
            {...register("cardHolder")}
          />
        </FloatingLabel>
        {errors.cardHolder && <Form.Text>{errors.cardHolder.message}</Form.Text>}
      </Col>
      <Col lg={12}>
          <FloatingLabel label="Card number" className="mb-4" controlId="paymentCardNumber">
            <Controller
              name="cardNumber"
              control={control}
              render={({ field }) => (
                <NumberFormat
                  {...field}
                  className="form-control w-100"
                  displayType="input"
                  type="text"
                  allowNegative={false}
                  format="#### #### #### ####"
                  placeholder="Card number"
                />
              )}
            />
          </FloatingLabel>
          {errors.cardNumber && <Form.Text>{errors.cardNumber.message}</Form.Text>}
      </Col>
      <Col xs={12} lg={6}>
        <FloatingLabel label="Expiration Date" className="mb-4" controlId="paymentCardDate">
          <Controller
            name="expDate"
            control={control}
            render={({ field }) => (
              <NumberFormat
                {...field}
                className="form-control w-100"
                format="##/####"
                placeholder="MM/YYYY"
                mask={['M', 'M', 'Y', 'Y', 'Y', 'Y']}
              />
            )}
          />
          <div className="calendar-input-icon"><CalendarIcon /></div>
        </FloatingLabel>
        {errors.expDate && <Form.Text>{errors.expDate.message}</Form.Text>}
      </Col>
      <Col xs={12} md={6}>
        <FloatingLabel label="Security Code" className="mb-4" controlId="paymentCardSecurityCode">
          <Controller
            name="cardCode"
            control={control}
            render={({ field }) => (
              <NumberFormat
                {...field}
                className="form-control w-100"
                format={cardCode?.code.size ? '#'.repeat(cardCode.code.size) : '###'}
                placeholder={cardCode?.code.name || 'CVV'}
              />
            )}
          />
        </FloatingLabel>
        {errors.cardCode && <Form.Text>{errors.cardCode.message}</Form.Text>}
      </Col>
    </Row>
  )
}

export default PaymentForm
