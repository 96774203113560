import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/wellness_areas',
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('auth0Token')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
  
      return headers
    },
  }),
  
  providesTags: (result, error, id) => [{ type: 'Dashboard', id }],
  endpoints: (builder) => ({
    getDashboardById: builder.query({
      query: (id) => `${id}`,
      transformResponse: (response) => response.data,
      transformErrorResponse: (
        response,
      ) => response.status,
    }),
  }),
})

export const { useGetDashboardByIdQuery } = dashboardApi;
