import Button from '../../../components/ui/button/Button'
import Link from '../../../components/ui/link/Link'

const StudyCard = ({ data }) => {
  return (
    <div className="recipe-card">
      <p className="title">{data.ourHeadline}</p>
      <span className="label d-block">Source: {data.journal}</span>
      <span className="label d-block">Published: {data.year}</span>
      <Link
        to={data.link}
        target="_blank"
        className="mx-auto mt-auto"
        external
      >
        <Button
          label="Read the Source"
          // rightIcon={} // TODO
          type="secondary"
          size="medium"
        />
      </Link>
    </div>
  )
}

export default StudyCard
