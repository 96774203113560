import { useEffect, useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Container, Row, Col } from 'react-bootstrap';
import Hero from '../components/Hero';
import { fetchContentfulPage } from '../services/apiService';

const Terms = () => {
  const [document, setDocument] = useState({})

  useEffect(() => {
    fetchContentfulPage(['terms_and_conditions'])
      .then((response) => {
        setDocument(response.data)
      })
  }, [])

  const { terms_and_conditions } = document
  
  return (
    <>
      <Hero title={terms_and_conditions?.pageAndFooterTitle} />
      <Container data-testid="terms-container">
        <Row className="mb-4">
          <Col>{terms_and_conditions && documentToReactComponents(terms_and_conditions.content)}</Col>
        </Row>
      </Container>
    </>
  )
}

export default Terms
