import Hero from '../components/Hero'

const Contact = () => {
  return (
    <>
      <Hero
        title="Contact Us"
        subtitle="The page is being updated"
      />
    </>
  )
}

export default Contact
