import normalizeUser from '../../utils/normalizations/normalize-user';
import ensureToken from '../ensureToken';

const { makeClient, createAxiosFetcher, extractSuccess } = window.SpreeSDK

const client = makeClient({
  host: window.location.origin,
  createFetcher: createAxiosFetcher.default
})

const requestParams = {
  include: [
    'default_billing_address',
    'default_shipping_address',
  ].join(',')
}

const accountInfo = async () => {
  const token = ensureToken();

  const response = await extractSuccess(client.account.accountInfo({
    ...requestParams,
    ...token,
  }))

  return normalizeUser(response, response.data)
};

export const updateAccount = async ({ user }) => {
  const token = ensureToken();

  const response = await extractSuccess(client.account.update({
    ...requestParams,
    ...token,
    user
  }))

  return normalizeUser(response, response.data)
}

export const createAddress = async (address) => {
  const token = ensureToken();

  return await extractSuccess(client.account.createAddress({
    ...token,
    address,
  }))
}

export const updateAddress = async (id, address) => {
  const token = ensureToken();

  return await extractSuccess(client.account.updateAddress({
    ...token,
    id,
    address,
  }))
}

export default {
  accountInfo,
  updateAccount,
  createAddress,
  updateAddress,
}
