import React from "react";

function WarningIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="#F93232"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8.575 3.217L1.516 15a1.667 1.667 0 001.425 2.5h14.117a1.666 1.666 0 001.425-2.5L11.425 3.217a1.667 1.667 0 00-2.85 0v0zM10 7.5v3.333M10 14.167h.008"
      ></path>
    </svg>
  );
}

export default WarningIcon;
