import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { storeState } from '../../../constants/storeState';
import SpreeService from '../../../services/spreeService';

const initialState = {
  authorized: false,
  token: null,
  user: null,
  status: storeState.idle
}

export const getUserInfo = createAsyncThunk(
  'auth/getUserInfo',
  async () => await SpreeService.accountInfo()
)

export const updateAccount = createAsyncThunk(
  'auth/updateUserInfo',
  async (props) => await SpreeService.updateAccount(props)
)

export const login = createAsyncThunk(
  'auth/login',
  async (auth0Token) => {
    const response = await SpreeService.getToken(auth0Token)
    localStorage.setItem('accessToken', response.access_token)
    localStorage.setItem('auth0Token', auth0Token)

    return response
  }
)

export const logout = createAsyncThunk(
  'auth/logout',
  async (props, thunkAPI) => {
    const { token } = thunkAPI.getState().auth.token
    localStorage.removeItem('accessToken')
    localStorage.removeItem('auth0Token')
    return await SpreeService.revokeToken(token)
  }
)

export const createAddress = createAsyncThunk(
  'auth/createAddress',
  async (props) => await SpreeService.createAddress(props)
)

export const updateAddress = createAsyncThunk(
  'auth/updateAddress',
  async ({ id, address }) => await SpreeService.updateAddress(id, address)
)

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginFailed: (state) => {
      localStorage.removeItem('accessToken')
      state.status = storeState.loaded
      state.authorized = false
    }
  },
  extraReducers: {
    [getUserInfo.pending]: () => {},
    [getUserInfo.fulfilled]: (state, action) => {
      state.user = action.payload
      state.status = storeState.loaded
    },
    [getUserInfo.rejected]: () => {},
    [login.pending]: (state) => {
      state.status = storeState.loading
    },
    [login.fulfilled]: (state, action) => {
      state.authorized = true
      state.token = action.payload.access_token
      state.status = storeState.loaded
    },
    [logout.fulfilled]: () => ({
      ...initialState,
      status: storeState.loaded
    }),
    [updateAccount.fulfilled]: (state, action) => {
      state.user = action.payload
      state.status = storeState.loaded
    },
  }
})

export const selectAuthorizationStatus = state => state.auth
export const selectAuthorizationPending = state =>
  state.auth.status === storeState.idle ||
  state.auth.status === storeState.loading
export const selectUser = state => state.auth.user

export const { loginFailed } = authSlice.actions

export default authSlice.reducer
