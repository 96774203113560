import { useEffect, useState } from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import RadialSeparators from './RadialSeparators';

const CircularProgressBar = ({
  value,
  inverted,
}) => {
  const [label, setLabel] = useState('Pending');
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (value === 'less') {
      setLabel(inverted ? 'Low' : 'Fair')
      setPercentage(33)
    }
    if (value === 'avarage') {
      setLabel(inverted ? 'Average' : 'Good')
      setPercentage(66)
    }
    if (value === 'more') {
      setLabel(inverted ? 'Elevated' : 'Excellent')
      setPercentage(100)
    }
  }, [value, inverted])
  
  const getBgColor = () => {
    if (percentage > 66) {
      return inverted ? '#DA291C' : '#77C125';
    }

    if (percentage > 33) {
      return '#FF9A23'
    }

    if (percentage > 0) {
      return inverted ? '#77C125' : '#DA291C'
    }
  }

  return (
    <CircularProgressbarWithChildren
      value={percentage}
      strokeWidth={10}
      styles={buildStyles({
        strokeLinecap: "butt",
        pathColor: getBgColor(),
        rotation: 1/2,
        trailColor: '#F6F6F6',
      })}
    >
      <div className={percentage === 0 ? 'text-grey' : 'text-black'}>{label}</div>
      <RadialSeparators
        count={3}
        style={{
          background: "#fff",
          width: "4px",
          // This needs to be equal to props.strokeWidth
          height: `${10}%`,
        }}
      />
    </CircularProgressbarWithChildren>
  );
}

export default CircularProgressBar
