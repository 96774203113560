import { Col, Container, Row } from 'react-bootstrap'
import { Outlet } from 'react-router-dom'
import AccountMenu from '../../components/account/AccountNav'

const AccountLayout = () => {
  return (
    <div className="sidebar-layout dashboard-container">
      <Col className="sidebar-content d-flex justify-content-center justify-content-md-end">
        <AccountMenu />
      </Col>
      <Container className="main-content">
        <Outlet />
      </Container>
    </div>
  )
}

export default AccountLayout
