import { useEffect, useState } from 'react';
import { FloatingLabel, Form } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Button from '../../components/ui/button/Button'
import { fetchContentfulPage } from '../../services/apiService';
import { getContentfulContent } from '../../utils/get-contentful-content';
import { useCustomAuth0 } from './CustomAuth0Provider';

const slugs = [
  'forgot_password_title',
  'forgot_password_subtitle',
  'form_email_address',
  'button_continue',
  'button_back_to_sign_in',
];

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const {
    forgotPassword
  } = useCustomAuth0();

  const [pageData, setPageData] = useState({})
  const [error, setError] = useState(false)

  useEffect(() => {
    fetchContentfulPage(slugs)
    .then((res) => {
      setPageData(res.data);
      setError(false);
    }).catch(() => {
      setError(true);
    })
  }, [])

  const handleContinueClick = () => {
    forgotPassword(email)
      .then(() => {
        navigate('/auth/forgot-password-success');
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <div className="login-box">
      <div className="login-header">
        <h3>{getContentfulContent(pageData.forgot_password_title)}</h3>
        <span>{getContentfulContent(pageData.forgot_password_subtitle)}</span>
      </div>
      <div className="login-body">
        <FloatingLabel
          controlId="email"
          label={getContentfulContent(pageData.form_email_address)}
          className="mb-4"
        >
          <Form.Control
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            placeholder="name@example.com"
          />
        </FloatingLabel>
        <Button
          label={getContentfulContent(pageData.button_continue)}
          onClick={handleContinueClick}
          className="mb-6"
          fullWidth
        />
      </div>
      <div className="login-footer">
        <button
          type="button"
          id="btn-signup"
          className="btn btn-default btn-block"
          onClick={() => navigate('/auth/sign-in')}
        >
          {getContentfulContent(pageData.button_back_to_sign_in)}
        </button>
      </div>
    </div>
  )
}

export default ForgotPassword
