import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import withAuthorization from '../../components/containers/withAuthorization';
import EditAccount from '../../components/account/EditAccount';
import { getUserInfo, selectUser } from '../../store/slices/auth/authSlice';

const Account = () => {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)

  useEffect(() => {
    dispatch(getUserInfo())
  }, [dispatch])

  if (!user) {
    return <>Loading</>
  }

  return (
    <>
      <EditAccount attributes={user.attributes} />
    </>
  )
}

export default withAuthorization(Account)
