import { useCallback } from 'react';
import { makeRequest } from '../../services/requestHandler';

const useQuestions = () => {
  const getQuestionsByCategorySlug = useCallback(async (slug) => {
    try {
      const { data: categories } = await makeRequest({ url: '/api/v1/question_categories', isPublic: true });
      const { id: categoryId } = categories.find(item => item.title === slug);
      const { data: questionVariants } = await makeRequest({
        url: `/api/v1/question_variants?category_id=${categoryId}`,
        isPublic: true,
      });

      return questionVariants;
    } catch (error) {
      console.error(error.message);
      return [];
    }
  }, []);

  return {
    getQuestionsByCategorySlug,
  };
};

export default useQuestions;
