import clsx from 'clsx';

const Filters = ({ wellnessAreas, appliedFilters, onFilterClick }) => {

  return (
    <div className="filters-list">
      <ul>
        <li className="reset-filters" onClick={() => onFilterClick()}>
          All
        </li>
        {wellnessAreas.map(area => (
          <li
            className={clsx('filter-item', {
              'active': appliedFilters.some(filter => filter === area.id)}
            )}
            key={area.id}
            onClick={() => onFilterClick(area.id)}
          >
            <img src={area.icon} alt="" />
            <span>{area.name}</span>
          </li>
        ))}
      </ul>
    </div>
  )
};

export default Filters;
