import { useEffect, useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Container, Row, Col } from 'react-bootstrap';
import Hero from '../components/Hero';
import { fetchContentfulPage } from '../services/apiService';

const CookiesPolicy = () => {
  const [document, setDocument] = useState({});

  useEffect(() => {
    fetchContentfulPage(['cookies_policy'])
      .then((response) => {
        setDocument(response.data)
      })
  }, [])

  const { cookies_policy } = document;

  return (
    <>
      <Hero title={cookies_policy?.pageAndFooterTitle} />
      <Container data-testid="cookies-policy-container">
        <Row>
          <Col>{cookies_policy && documentToReactComponents(cookies_policy.content)}</Col>
        </Row>
        <Row className="mb-10">
          <Col>
            {/* <!-- OneTrust Cookies Settings button start --> */}
            <button id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie Settings</button>
            {/* <!-- OneTrust Cookies Settings button end --> */}

            {/* <!-- OneTrust Cookies List start --> */}
            <div id="ot-sdk-cookie-policy"></div>
            {/* <!-- OneTrust Cookies List end --> */}
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default CookiesPolicy;
