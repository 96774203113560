import { useRef, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import Button from '../../ui/button/Button'
import { EXCLUDED_SHIPPING_US_STATES } from '../../../constants/constants'
import Typography from '../../ui/typography/Typography'
import PencilIcon from '../../ui/icons/PencilIcon'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import AccountAddressForm from './AccountAddressForm'
import { useDispatch, useSelector } from 'react-redux'
import { createAddress, getUserInfo, updateAccount, updateAddress } from '../../../store/slices/auth/authSlice'
import { unwrapResult } from '@reduxjs/toolkit'
import { customToast } from '../../ui/notification/customToast'

const ShippingAddressForm = () => {
  const formMethods = useForm()
  const {
    handleSubmit,
    reset,
  } = formMethods;
  const transitionRef = useRef(null)
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.auth)
  const { shipping_address: address } = user;
  const [isEditing, setIsEditing] = useState(false)
  const [pending, setPending] = useState(false)

  const resetForm = () => {
    reset({
      firstname: address.firstname,
      lastname: address.lastname,
      address1: address.address1,
      city: address.city,
      country_iso: address.country_iso,
      state_name: address.state_code,
      zipcode: address.zipcode,
      phone: address.phone,
    })
  }

  const onSubmit = async (formData) => {
    setPending(true)
    if (address.id) {
      try {
        await dispatch(updateAddress({ id: address.id, address: formData }))
        setPending(false)
        setIsEditing(false)
        customToast({
          title: 'Changes saved',
          message: 'Your changes have been successfully saved.',
          type: 'success'
        });
        dispatch(getUserInfo());
      } catch (error) {
        console.log(error)
        setPending(false)
      }
    } else {
      const newAddress = await dispatch(createAddress(formData)).then(unwrapResult)
      try {
        await dispatch(updateAccount({
          user: {
            email: user.email,
            ship_address_id: newAddress.data.id,
          }
        })).then(unwrapResult)
        setPending(false)
        customToast({
          title: 'Changes saved',
          message: 'Your changes have been successfully saved.',
          type: 'success'
        });
        dispatch(getUserInfo());
      } catch (error) {
        console.log(error)
        setPending(false)
      }
    }
  }

  const onCancelEditing = () => {
    resetForm();
    setIsEditing(false);
  }

  const toggleEditing = (event) => {
    event.preventDefault();
    if (isEditing) {
      onCancelEditing()
    } else {
      setIsEditing(true);
    }
  };

  const form = (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AccountAddressForm
          data={address}
          excludedStates={EXCLUDED_SHIPPING_US_STATES}
        />
        <Row className="py-3 py-md-0">
          <Col className="mb-3 mb-lg-0">
            <Button
              label="Save Changes"
              onClick={handleSubmit(onSubmit)}
              disabled={pending}
              fullWidth
            />
          </Col>
          <Col>
            <Button
              label="Cancel"
              onClick={toggleEditing}
              type="secondary"
              fullWidth
            />
          </Col>
        </Row>
      </form>
    </FormProvider>
  )

  return (
    <div>
    <div className="d-flex justify-content-between align-items-center mb-2">
      <Typography textType="heading5" text="Shipping Address" />
      <button onClick={toggleEditing} className={`edit-button ${isEditing ? 'editing' : ''}`}>
        <span className="edit-button-text">{isEditing ? 'Editing...' : 'Edit'}</span>
        <PencilIcon />
      </button>
    </div>
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={isEditing ? 'form' : 'default'}
        timeout={300}
        classNames="form-part"
        nodeRef={transitionRef}
      >
        {isEditing ? (
          <div ref={transitionRef}>
            {form}
          </div>
        ) : (
          <div ref={transitionRef}>
            {address?.id ? (
              <div>
                <Typography textType="body1" text={`${address.firstname} ${address.lastname}`} block />
                <Typography textType="body1" text={`${address.address1}, ${address.city}, ${address.state_code}, ${address.zipcode}`} block />
                <Typography textType="body1" text={`${address.country_name}`} block />
                <Typography textType="body1" text={`${address.phone}`} block />
              </div>
            ) : (
              <div>
                <Typography textType="body1" text="Address not set" block />
              </div>
            )}
          </div>
        )}
      </CSSTransition>
    </SwitchTransition>
  </div>
  )
}

export default ShippingAddressForm
