import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { Col, FloatingLabel, Form } from 'react-bootstrap'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import Button from '../../components/ui/button/Button'
import ErrorIcon from '../../components/ui/icons/Error'
import { useCustomAuth0 } from './CustomAuth0Provider'
import Checkbox from '../../components/ui/forms/Checkbox'
import { fetchContentfulPage } from '../../services/apiService'
import { getContentfulContent, getContentfulImage } from '../../utils/get-contentful-content'
import PasswordFormControl from '../../components/ui/forms/PasswordFormControl'

const slugs = [
  'sign_up_with_email_title',
  'sign_up_with_email_subtitle',
  'form_first_name',
  'form_last_name',
  'form_email_address',
  'form_password',
  'confirm_password',
  'eye_off_icon',
  'checkbox_confirm_I_read_policies',
  'checkbox_news',
  'button_create_account',
  'already_have_account',
  'sign_in_title',
];

const SignUp = () => {
  const [apiErrors, setApiErrors] = useState(null);
  const navigate = useNavigate();
  const { signUp } = useCustomAuth0();
  const LoginSchema = yup.object().shape({
    given_name: yup.string().required('Please enter your First name.'),
    family_name: yup.string().required('Please enter your Last name'),
    email: yup.string().required('Please enter your email address.'),
    password: yup.string().required('Please enter your password.'),
    confirmPassword: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
    terms: yup.bool().oneOf([true], 'Please check therms of service.'),
    news: yup.bool(),
  });
  const validationOpt = {resolver: yupResolver(LoginSchema)};
  
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm(validationOpt);
  const formData = watch();

  const [pageData, setPageData] = useState({})
  const [error, setError] = useState(false)

  useEffect(() => {
    fetchContentfulPage(slugs)
    .then((res) => {
      setPageData(res.data);
      setError(false);
    }).catch(() => {
      setError(true);
    })
  }, [])

  const onSubmit = () => {
    signUp({
      given_name: formData.givenName,
      family_name: formData.family_name,
      email: formData.email,
      password: formData.password,
    })
    .catch(errors => {
      setApiErrors(errors.policy)
    })
  }

  return (
    <div className="login-box">
      <div className="login-header">
        <h3>{getContentfulContent(pageData.sign_up_with_email_title)}</h3>
        <span>{getContentfulContent(pageData.sign_up_with_email_subtitle)}</span>
      </div>
      <div className="login-body">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row gx-3">
            <Col>
              <FloatingLabel
                controlId="firstName"
                label={getContentfulContent(pageData.form_first_name)}
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder={getContentfulContent(pageData.form_first_name)}
                  {...register('given_name')}
                />
                {errors.given_name && <Form.Text><ErrorIcon />{errors.given_name.message}</Form.Text>}
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel
                controlId="lastName"
                label={getContentfulContent(pageData.form_last_name)}
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  placeholder={getContentfulContent(pageData.form_last_name)}
                  {...register('family_name')}
                />
                {errors.family_name && <Form.Text><ErrorIcon />{errors.family_name.message}</Form.Text>}
              </FloatingLabel>
            </Col>
          </div>
          <FloatingLabel
            controlId="email"
            label={getContentfulContent(pageData.form_email_address)}
            className="mb-3"
          >
            <Form.Control
              type="email"
              placeholder="name@example.com"
              {...register('email')}
            />
            {errors.email && <Form.Text><ErrorIcon />{errors.email.message}</Form.Text>}
          </FloatingLabel>
          <FloatingLabel
            controlId="password"
            label={getContentfulContent(pageData.form_password)}
            className="mb-3"
          >
            <PasswordFormControl
              placeholder={getContentfulContent(pageData.form_password)}
              icon={getContentfulImage(pageData.eye_off_icon).url}
              register={register}
              name="password"
            />
            {errors.password && <Form.Text><ErrorIcon />{errors.password.message}</Form.Text>}
          </FloatingLabel>
          <FloatingLabel
            controlId="confirmPassword"
            label={getContentfulContent(pageData.confirm_password)}
            className="mb-3"
          >
            <PasswordFormControl
              placeholder={getContentfulContent(pageData.confirm_password)}
              icon={getContentfulImage(pageData.eye_off_icon).url}
              register={register}
              name="confirmPassword"
            />
            {errors.confirmPassword && <Form.Text><ErrorIcon />{errors.confirmPassword.message}</Form.Text>}
          </FloatingLabel>
          <Checkbox
            label={getContentfulContent(pageData.checkbox_confirm_I_read_policies, 'rich')}
            wrapClassName="px-0"
            name="terms"
            register={register}
          />
          {errors.terms && <Form.Text><ErrorIcon />{errors.terms.message}</Form.Text>}
          <Checkbox
            label={getContentfulContent(pageData.checkbox_news)}
            wrapClassName="px-0"
            name="news"
            register={register}
          />
          {apiErrors && apiErrors.split('\n').map((error, index) => <Form.Text key={index}><ErrorIcon />{error.slice(2)}</Form.Text>)}
          <div className="captcha-container form-group"></div>
          <Button
            label={getContentfulContent(pageData.button_create_account)}
            fullWidth
            className="mb-4 mt-4"
          />
        </form>
      </div>
      <div className="login-footer">
        {getContentfulContent(pageData.already_have_account)}
        <button
          type="button"
          id="btn-signup"
          className="btn btn-default btn-block"
          onClick={() => navigate('/auth/sign-in')}
        >
          {getContentfulContent(pageData.sign_in_title)}
        </button>
      </div>
    </div>
  )
}

export default SignUp
