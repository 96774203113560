import { useNavigate } from 'react-router-dom';
import RegisterBabyQuiz from '../components/questions/RegisterBabyQuiz';

const RegisterBabyPage = () => {
  const navigate = useNavigate();

  const onFinishQuiz = () => {
    navigate(`/account`);
  }

  return (
    <RegisterBabyQuiz onFinishQuiz={onFinishQuiz} />
  )
}

export default RegisterBabyPage;
