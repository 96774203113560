import React, { useState, useEffect } from 'react';
import Protect from 'react-app-protect';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'react-bootstrap';
import { Auth0Provider } from '@auth0/auth0-react';
import TagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';
import { ToastContainer } from 'react-toastify';
import { createReduxStore } from '../store/store';
import Root from '../Root';
import "slick-carousel/slick/slick.scss"; 
import "slick-carousel/slick/slick-theme.scss";
import 'react-app-protect/dist/index.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-circular-progressbar/dist/styles.css';
import '../stylesheets/index.scss';
import ScrollToTop from '../utils/scroll-top';
import { ContentfulProvider } from '../providers/ContentfulProvider';
import * as contentful from 'contentful';
import FullStory from 'react-fullstory';

const App = () => {
  const [authConfig, setAuthConfig] = useState(null)

  const client = contentful.createClient({
    space: process.env.CONTENTFUL_SPACE_ID,
    environment: 'master',
    accessToken: process.env.CONTENTFUL_API_KEY,
  })

  useEffect(() => {
    fetch('/api/v1/configs')
      .then(res => res.json())
      .then(res => setAuthConfig(res.data))

    // GTM tracking
    const tagManagerArgs = {
      gtmId: process.env.GTM_MEASUREMENT_ID
    }
    TagManager.initialize(tagManagerArgs)

  }, [])

  if (!authConfig) {
    return null
  }

  // Meta Pixel tracking
  const options = {
    autoConfig: true,
    debug: false,
  };
  ReactPixel.init(authConfig.meta_pixel_id, null, options);
  
  ReactPixel.pageView(); // For tracking page view

  const AppContent = () => (
    <Auth0Provider
      domain={authConfig.auth0_domain}
      clientId={authConfig.client_id}
      audience={authConfig.api_identifier}
      scope="openid read:permissions"
      cacheLocation="localstorage"
      redirectUri={window.location.origin}
    >
      <Provider store={createReduxStore()}>
        <BrowserRouter>
          <ThemeProvider>
            <ContentfulProvider client={client}>
              <Root />
              <ScrollToTop />
              <ToastContainer
                autoClose={5000}
                position="top-center"
                hideProgressBar
              />
              <FullStory org={authConfig.fullstory_org_id} />
            </ContentfulProvider>
          </ThemeProvider>
        </BrowserRouter>
      </Provider>
    </Auth0Provider>
  )

  if (process.env.NODE_ENV === 'development') {
    return (
      <React.StrictMode>
        <AppContent />
      </React.StrictMode>
    )
  }

  return (
    <React.StrictMode>
      <Protect sha512={authConfig.protect_password_hash}>
        <AppContent />
      </Protect>
    </React.StrictMode>
  )
}

export default App
