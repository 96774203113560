import { Accordion as BootstrapAccordion } from 'react-bootstrap'

const Accordion = ({
  title,
  eventKey = '0',
  children,
}) => {
  return (
    <BootstrapAccordion defaultActiveKey={eventKey} bsPrefix="knowledge-library-accordion" flush alwaysOpen>
      <BootstrapAccordion.Item eventKey={eventKey}>
        <BootstrapAccordion.Header as="h3">{title}</BootstrapAccordion.Header>
        <BootstrapAccordion.Body>
          {children}
        </BootstrapAccordion.Body>
      </BootstrapAccordion.Item>
    </BootstrapAccordion>
  )
}

export default Accordion
