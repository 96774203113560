export const ABOUT_US_SLUGS = [
  'about_us_top_image',
  'about_us_title',
  'about_us_subtitle',
  'about_us_text',

  'about_us_our_mission_title',
  'about_us_our_mission_text',
  'about_us_our_mission_image',

  'about_us_microbiome_title',
  'about_us_microbiome_text',
  'about_us_microbiome_image',
  'landing_how_it_works_image_2',
  'about_us_insights_title',
  'about_us_insights_text',
  'button_take_quiz',
  'button_get_started',

  'landing_meet_our_experts_title',
  'advisor_slide_1',
  'advisor_slide_2',
  'advisor_slide_3',

  'about_us_our_values_title',
  'about_us_ease_title',
  'about_us_ease_image',
  'about_us_ease_text',
  'about_us_positivity_title',
  'about_us_positivity_image',
  'about_us_positivity_text',
  'about_us_fuss_free_title',
  'about_us_fuss_free_image',
  'about_us_fuss_free_text',

  'brought_to_you_text',

  'about_us_waste_wellness',
  'about_us_terracycle_logo_image',
  'tc_discovery_logo_gray',
  'brought_to_you_title',
];

export const BABY_DASBBOARD_SLUGS = [
  'arrow_icon_right_darkgray',
  'button_view'
];

export const PRODUCT_VIEW_SLUGS = [
  'for_ages_text',
  'free_shipping_text',
  'button_add_to_cart',
  'shipping_restrictions_note_mvp',
  'shipping_restrictions_note_mvp_out_of_stock',
  'out_of_stock_text',
  'out_of_stock_notice',
  'button_take_quiz_waitlist',
  'product_what_it_is',
  'product_what_it_is_notes',
  'why_it_matters',
  'what_is_in_the_box',
  'what_is_in_the_box_video',
  'baby_wellness_experience',
  'baby_dashboard_walkthrough_gif',
  'product_page_how_it_works_section',
  'how_it_works_video',
  'product_step_by_step',
  'product_what_you_get',
  'why_tiniest_biome',
  'product_step_1',
  'product_step_2',
  'product_step_3',
  'product_step_4',
];

export const ADVISORY_SLIDER_SLUGS = [
  'advisor_slide_1',
  'advisor_slide_2',
  'advisor_slide_3'
]

export const WAITLIST_FORM_SLUGS = [
  'waitlist_landing_sign_up_consent',
  'button_join_waitlist',
  'waitlist_popup_title',
  'waitlist_popup_subtitle',
  'form_first_name',
  'form_last_name',
  'form_email_address',
  'button_submit',
  'country',
  'state_province',
  'postal_code'
]

export const LANDING_PAGE_SLUGS = [
  'button_take_quiz',
  'landing_hero_title',
  'landing_hero_subtitle',
  'mvp_new_hero_image',
  'landing_benefit_1',
  'landing_benefit_2',
  'landing_benefit_3',
  'landing_gut_health_slide_1',
  'landing_gut_health_slide_2',
  'landing_gut_health_slide_3',
  'landing_why_gut_health_matters',
  'landing_how_it_works_1_step',
  'landing_how_it_works_2_step',
  'landing_how_it_works_3_step',
  'landing_how_it_works_1_text',
  'landing_how_it_works_2_text',
  'landing_how_it_works_3_text',
  'landing_how_it_works_image_1',
  'landing_how_it_works_image_2',
  'landing_how_it_works_image_3',
  'landing_how_it_works_title',
  'landing_who_we_are',
  'landing_advisors_intro',
  'landing_quiz_intro',
  'landing_quiz_intro_title',
  'landing_quiz_intro_text',
  'landing_skin_title',
  'landing_skin_text1',
  'landing_skin_text2',
  'landing_activities_title',
  'landing_activities_text1',
  'landing_activities_text2',
  'landing_nutrition_title',
  'landing_nutrition_text1',
  'landing_nutrition_text2',
  'landing_baby_gut_bacteria',
  'landing_baby_names_text',
  'about_us_terracycle_logo_image',
  'tc_discovery_logo_gray',
  'button_more_about_us',
  'first_step_diaper_icon',
  'second_step_tube_icon',
  'blue_arrow_icon',
  'third_step_check_icon',
  'first_step_diaper_text',
  'second_step_tube_text',
  'third_step_check_text',
  'landing_tiniest_biome',
  'landing_tiniest_biome_decodes',
  'tiniest_biome_decodes',
  'waitlist_landing_sign_up_title',
  'waitlist_landing_sign_up_subtitle',
  'brought_to_you_text',
  'landing_review_title',
  'landing_review_1',
  'landing_review_1_name',
  'landing_review_2',
  'landing_review_2_name',
  'landing_review_3',
  'landing_review_3_name',
  'button_get_started',
  'landing_meet_our_experts_title',
  'brought_to_you_title',
  'tiniest_biome_what_you_can_do_mobile_screen',
  'testimonials_title',
  'testimonials_legal_comment'
]

export const REVIEW_SLIDER_SLUGS = [
  'landing_review_1',
  'landing_review_1_name',
  'landing_review_2',
  'landing_review_2_name',
  'landing_review_3',
  'landing_review_3_name'
]

export const FOOTER_SLUGS = [
  'footer_phone',
  'footer_address_1',
  'footer_address_2',
  'footer_address_3',
  'footer_email',
  'footer_copyright_text',
  'social_media_icon_facebook',
  'social_media_icon_instagram',
  'footer_menu_category_ccpa',
  'footer_menu_category_who_we_are',
  'footer_menu_category_take_the_quiz',
  'footer_menu_category_privacy_policy',
  'footer_menu_category_terms',
  'footer_menu_category_cookie_policy',
  'checkbox_sign_me_up',
  'tiniest_biome_white_logo_png',
  'footer_menu_category_about',
  'footer_menu_category_babys_dashboard',
  'footer_menu_category_my_babies',
  'footer_menu_category_my_kits',
  'footer_menu_category_account_settings',
  'footer_menu_category_legal',
  'footer_menu_category_insights_kit',
  'footer_menu_category_knowledge_library',
  'footer_menu_category_faqs',
  'footer_menu_category_account',
  'footer_menu_category_kit_activation',
  'footer_sign_up_to_receive_updates_1',
  'footer_sign_up_to_receive_updates_2',
  'button_sign_up_for_updates'
]

export const KIT_ACTIVATION_SLUGS = [
  'kit_activation_title',
  'kit_activation_subtitle',
  'kit_activation_intro',
  'kit_activation_step_1',
  'kit_activation_step_2',
  'kit_activation_step_3',
  'kit_activation_code',
  'kit_activation_login_title',
  'kit_activation_login_intro'
]
  
export const KIT_ACTIVATED_SLUGS = [
  'kit_activated_closing',
  'kit_activated_dashboard_intro',
  'kit_activated_title'
]

export const SIGNOUT_SLUGS = [
  'button_sign_back_in',
  'button_go_to_home',
  'signed_out_title',
  'signed_out_subtitle'
]

export const SIGNUP_SLUGS = [
  'success_title',
  'sign_up_success_text1',
  'sign_up_success_text2',
  'button_about_us',
  'button_order_test_kit'
]

export const ACCOUNT_NAV_SLUGS = [
  'account_settings_title',
  'baby_icon_black',
  'box_icon_black',
  'footer_menu_category_my_babies',
  'footer_menu_category_my_kits',
  'gear_icon_black',
  'hello_first_name'
]

export const MY_BABIES_SLUGS = [
  'add_a_new_baby_and_start',
  'arow_icon_right_black',
  'baby_avatar_icon',
  'baby_name',
  'button_add_baby',
  'checkbox_icon_black',
  'footer_menu_category_my_babies',
  'icon_arrow_right_white',
  'no_registered_babies',
  'plus_icon_black',
  'plus_icon_white',
  'user_plus_icon_black',
  'your_next_steps',
  'your_next_step1',
  'your_next_step2',
  'your_next_step3',
  'your_next_step4'
]

export const MY_KITS_SLUGS = [
  'my_kits_title',
  'order',
  'order_date',
  'product_name',
  'product_small_image_baby_microbiome',
  'button_track_kit',
  'icon_arrow_right_white',
  'button_add_baby',
  'your_next_steps',
  'your_next_step1',
  'your_next_step2',
  'your_next_step3',
  'your_next_step4'
]

export const REGISTER_BABY_SLUGS = [
  'question'
]

export const QUIZ_SLUGS = [
  'quiz'
]

export const FAQ_SLUGS = [
  'frequently_asked_questions_title',
  'menu_category'
]

export const CART_SLUGS = [
  'cart_title',
  'cart_empty_subtitle',
  'promo_code',
  'button_apply',
  'cart_subtotal',
  'cart_sales_tax',
  'cart_shipping',
  'shipping_free',
  'cart_total',
  'button_checkout',
  'icon_arrow_right_white',
]
