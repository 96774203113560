function YoutubeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      fill="none"
      viewBox="0 0 35 35"
    >
      <path
        fill="#44C8F5"
        fillRule="evenodd"
        d="M0 17.5C0 7.835 7.835 0 17.5 0S35 7.835 35 17.5 27.165 35 17.5 35 0 27.165 0 17.5z"
        clipRule="evenodd"
        opacity="0.1"
      ></path>
      <path
        fill="#44C8F5"
        fillRule="evenodd"
        d="M24.793 11.484a2.37 2.37 0 011.65 1.695c.39 1.494.39 4.613.39 4.613s0 3.118-.39 4.613a2.37 2.37 0 01-1.65 1.695c-1.455.4-7.293.4-7.293.4s-5.837 0-7.293-.4a2.37 2.37 0 01-1.65-1.695c-.39-1.495-.39-4.613-.39-4.613s0-3.119.39-4.613a2.37 2.37 0 011.65-1.695c1.456-.4 7.293-.4 7.293-.4s5.838 0 7.293.4zm-9.043 3.683V21l4.667-2.916-4.667-2.917z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default YoutubeIcon;
