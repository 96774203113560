const { findRelationshipDocuments } = window.SpreeSDK

const normalizeCountry = (
  spreeSuccessResponse,
  spreeCountry
) => {
  const spreeStates =
    findRelationshipDocuments(
      spreeSuccessResponse,
      spreeCountry,
      'checkout_zone_applicable_states'
    )


  const { iso, states_required, zipcode_required, name } = spreeCountry.attributes

  const states = spreeStates.map(state => ({
    id: state.id,
    name: state.attributes.name,
    abbr: state.attributes.abbr
  }))

  return {
    id: spreeCountry.id,
    iso,
    name,
    statesRequired: states_required,
    zipcodeRequired: zipcode_required,
    states: states,
    default: spreeCountry.attributes.default
  }
}

export default normalizeCountry
