import { useDispatch } from 'react-redux'
import { removeItem } from '../../store/slices/cart/cartSlice'
import SpreeService from '../../services/spreeService'
import ensureToken from '../../services/ensureToken'

const useRemoveItem = () => {
  const dispatch = useDispatch()

  const removeCartItem = async (data) => {
    let token = ensureToken()

    if (!token) {
      const response = await SpreeService.createCart()

      token = response.customerId
      localStorage.setItem('orderToken', token)

      removeItem({
        ...data
      })
    } else {
      return dispatch(removeItem({
        ...data,
        ...token
      }))
    }
  }

  return removeCartItem
}

export default useRemoveItem
