import Link from '../../../components/ui/link/Link';

const ExerciseCard = ({ data }) => {

  return (
    <Link to={`/knowledge-library/activity/${data.url}`} className="recipe-card">
      <img src={data.image?.[0]} alt="" />
      <p className="title">{data.title}</p>
      <span className="label">Ideal for: {data.ageGroup.join(', ')}</span>
      <p className="text">{data.wellnessAreas?.[0].name}</p>
    </Link>
  )
}

export default ExerciseCard;
