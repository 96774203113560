import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { useEffect, useState } from 'react'
import { REGISTER_BABY_SLUGS } from '../../constants/pageSlugs';
import { fetchContentfulPage } from '../../services/apiService';
import QuestionItem from './QuestionItem';
import QuizHeader from './QuizHeader';

const QuestionsList = ({
  variants,
  onSubmit,
  onFinishQuiz,
  hideCloseButton,
}) => {
  const [contentfulData, setContentfulData] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  
  useEffect(() => {
    fetchContentfulPage(REGISTER_BABY_SLUGS).then(res => setContentfulData(res.data))
  }, [])

  const handleSubmit = (variant, value) => {
    onSubmit(variant, value).then(() => {
      setActiveStep(activeStep + 1);
      window.scrollTo(0, 0);
    }).catch(error => {
      console.error(error.message)
    })
  }

  return (
    <div className="page-wrap waitlist-questions-page">
      <QuizHeader
        step={activeStep}
        maxStep={variants?.length}
        onBackClick={() => setActiveStep(activeStep -1)}
        titlePrefix={documentToPlainTextString(contentfulData?.question.content)}
        onCloseUrl="/account"
        hideCloseButton={hideCloseButton}
      />
      <>
        {variants.map((variant, index) => (
          <QuestionItem
            key={index}
            variant={variant}
            activeStep={activeStep}
            maxStep={variants.length - 1}
            onSubmit={handleSubmit}
            onFinish={onFinishQuiz}
          />
        ))[activeStep]}
      </>
    </div>
  )
}

export default QuestionsList
