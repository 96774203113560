function Separator(props) {
  return (
    <div
      style={{
        position: "absolute",
        height: "100%",
        transform: `rotate(${0.5 + props.turns}turn)`
      }}
    >
      <div style={props.style} />
    </div>
  );
}

function RadialSeparators(props) {
  const turns = 1 / props.count;
  return Array.from(Array(props.count), (_, i) => i).map(index => (
    <Separator key={index} turns={index * turns} style={props.style} />
  ));
}

export default RadialSeparators;
