import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { useContentful } from '../../hooks/useContentful'
import Button from '../ui/button/Button'
import Link from '../ui/link/Link'

const VisitSourceLink = ({
  link
}) => {
  const { data } = useContentful({
    'sys.id[in]': '3LiDdW1kRLwVYXC7STfzdh,65tr8fqzjwvNxTHbY40tCP',
    convertToObject: true,
  })

  if (!data) return null;

  return (
    <div className="d-flex justify-content-center">
    <Link to={link} target="_blank" external>
      <Button
        label={documentToPlainTextString(data?.button_visit_source.fields.content)}
        type="secondary"
        size="small"
        rightIcon={<img src={data?.icon_hyperlink_black.fields.image.fields.file.url} alt="" />}
      />
    </Link>
  </div>
  )
}

export default VisitSourceLink
