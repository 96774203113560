import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Hero from '../../components/Hero'

const Experience = () => {
  return (
    <>
      <Hero
        title="Wellness experience"
        subtitle="Lorem ipsum dolor sit amet"
      />
      <Container>
        <Row>
          <Col>
            <h1>Wellness experience</h1>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Experience
