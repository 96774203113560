import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getUserInfo, selectUser } from '../../store/slices/auth/authSlice';
import withAuthorization from '../../components/containers/withAuthorization';
import { useSelector } from 'react-redux';
import { Card, Col, Row } from 'react-bootstrap';
import Typography from '../../components/ui/typography/Typography';
import Button from '../../components/ui/button/Button';
import Link from '../../components/ui/link/Link';
import NextStepCard from '../../components/account/NextStepCard';

import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { MY_BABIES_SLUGS } from '../../constants/pageSlugs';
import { fetchContentfulPage } from '../../services/apiService';
import { useGetAccountTypesQuery } from './accountTypeSlice';
import { useGetAccountsQuery } from './accountSlice';

const Account = () => {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const [contentfulData, setContentfulData] = useState(null);
  const { data: accountTypes } = useGetAccountTypesQuery({
    title: 'Children'
  })
  const { data: babies = [] } = useGetAccountsQuery({
    account_type_id: accountTypes?.[0]?.id
  }, {
    skip: !accountTypes
  })

  useEffect(() => {
    dispatch(getUserInfo())

    fetchContentfulPage(MY_BABIES_SLUGS)
      .then((res) => {
        setContentfulData(res.data);
      })
  }, [dispatch])

  if (!user || !contentfulData) {
    return <>Loading</>
  }

  const {
    add_a_new_baby_and_start,
    arow_icon_right_black,
    baby_avatar_icon,
    button_add_baby,
    footer_menu_category_my_babies,
    icon_arrow_right_white,
    no_registered_babies,
    plus_icon_black,
    plus_icon_white,
    user_plus_icon_black,
    your_next_steps,
    your_next_step1,
    your_next_step2,
    your_next_step3,
    your_next_step4
  } = contentfulData;

  const noBabiesCard = (
    <Card className="mb-3">
      <Card.Body className='flex-md-column'>
        <Row>
          <Col>
            <Typography 
              textType={'heading4'} 
              className="mb-6 mb-md-4 title-text d-flex" 
              block 
              text={documentToPlainTextString(no_registered_babies?.content)}
              leftIcon={user_plus_icon_black?.image && (<img src={user_plus_icon_black.image.fields.file.url} alt='' />)}
            />
            <Typography textType="body1" text={documentToPlainTextString(add_a_new_baby_and_start?.content)} className="subtitle" />
          </Col>
          <Col className='md-button-container'>
            <Link to="/register-baby-quiz">
              <Button
                role="button" 
                className="me-auto hero-button w-100" 
                label={documentToPlainTextString(button_add_baby?.content)}
                leftIcon={plus_icon_white?.image && (<img src={plus_icon_white.image.fields.file.url} alt='' />)} />
            </Link>
          </Col>
        </Row> 
      </Card.Body>
    </Card>
  );

  return (
    <>
      <Row>
        <Col>
          <Typography 
            textType={'heading3'} 
            className="font-Moranga mb-6 mb-md-8 title-text d-none d-md-block" 
            text={documentToPlainTextString(footer_menu_category_my_babies?.content)} />
          <div className={babies && babies.length > 0 ? 'baby-list' : 'baby-list no-baby'}>
            {babies && babies.length > 0 ?
              babies.map(baby => {
                return (
                  <Link to={"/account/my-babies/" + baby.id} className="baby-profile-link" key={baby.id}>
                    {baby_avatar_icon?.image && (<img src={baby_avatar_icon.image.fields.file.url} alt='' className='baby-image' />)}
                    <Typography textType={'heading5'} className="baby-name" text={baby.title} />
                    {arow_icon_right_black?.image && (<img src={arow_icon_right_black.image.fields.file.url} alt='' />)}
                  </Link>
                );
              })
            : noBabiesCard}
          </div>
          {babies && babies.length > 0 && (<Link to="/register-baby-quiz" className="button-inline">
            <Button 
              role="button"
              type="secondary"
              className="w-100" 
              label={documentToPlainTextString(button_add_baby?.content)} 
              leftIcon={plus_icon_black?.image && (<img src={plus_icon_black.image.fields.file.url} alt='' />)}
            />
          </Link>)}
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <Typography textType={'heading3'} className="font-Moranga mb-6 mb-md-6 title-text" text={documentToPlainTextString(your_next_steps?.content)} />
        </Col>
      </Row>
      <Row className='your-next-steps'>
        {babies && babies.length === 0 && (
          <NextStepCard 
            title={your_next_step1?.stepTitle}
            titleIcon={your_next_step1?.icon}
            details={your_next_step1?.stepDetails}
            linkTo="/register-baby-quiz"
            button={your_next_step1?.button} 
            buttonIcon={icon_arrow_right_white?.image}
          />
        )}
        <NextStepCard 
          title={your_next_step2?.stepTitle}
          titleIcon={your_next_step2?.icon}
          details={your_next_step2?.stepDetails}
          linkTo="/kits/baby-microbiome-gut-health-test-kit"
          button={your_next_step2?.button} 
          buttonIcon={icon_arrow_right_white?.image}
        />
        {babies && babies.length > 0 && (
          <NextStepCard 
            title={your_next_step3?.stepTitle}
            titleIcon={your_next_step3?.icon}
            details={your_next_step3?.stepDetails}
            linkTo="/account/kits"
            button={your_next_step3?.button}
            buttonIcon={icon_arrow_right_white?.image}
          />
        )}
      </Row>
    </>
  )
}

export default withAuthorization(Account)
