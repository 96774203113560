import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Col, Container, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom';
import Accordion from '../../../../components/KnowledgeLibrary/Accordion'
import VisitSourceLink from '../../../../components/KnowledgeLibrary/VisitSourceLink';
import Button from '../../../../components/ui/button/Button';
import DirectionLineRight from '../../../../components/ui/icons/DirectionLineRight';
import Typography from '../../../../components/ui/typography/Typography'
import { useContentful } from '../../../../hooks/useContentful'
import normalizeActivity from '../../../../utils/normalizations/normalize-activity';
import ExerciseCard from '../ExerciseCard';
import WellnessAreaBlock from '../WellnessAreaBlock';
import { useEffect, useState } from 'react';
import { fetchContentfulPage } from '../../../../services/apiService';

const slugs = [
  'category_recipes',
  'category_exercises',
  'category_studies',
  'category_glossary',
];

const Activity = () => {
  const [pageData, setPageData] = useState({})
  const [error, setError] = useState(false)
  const { activityUrl } = useParams()
  const navigate = useNavigate()
  const { data } = useContentful({
    content_type: 'physicalActivities',
    'fields.url': activityUrl,
    limit: 1,
  })

  useEffect(() => {
    fetchContentfulPage(slugs)
    .then((res) => {
      setPageData(res.data);
      setError(false);
    }).catch(() => {
      setError(true);
    })
  }, [])

  const { data: moreActivities, fetched: activitiesFetched } = useContentful({
    content_type: 'physicalActivities',
    'fields.url[nin]': activityUrl,
    limit: 6,
  })

  const sanitizedActivities = moreActivities?.items?.map(item => normalizeActivity(item))

  if (!data) {
    return <>Loading...</>
  }

  if (data.total === 0) {
    return <>Not found</>
  }

  const onShowAllClick = () => {
    navigate('/knowledge-library', {
      state: {
        exercisesExpanded: true,
      }
    })
  }

  const activity = normalizeActivity(data.items[0]);

  return (
    <div className="knowledge-library">
      <Container> 
        <Row>
          <Col lg={3} className="sidebar">
            <div className="sidebar-content d-none">
              <Typography textType="heading3" className="mb-1 font-Moranga" text="Ben’s Biome Analysis" block />
              <Typography textType="body1B" className="text-darkGrass" text="Last Updated: 2/01/2022" block />
              <hr />
              <Typography textType="heading4" text="Categories" className="mb-6" />
              <WellnessAreaBlock activeArea={activity.wellnessAreas?.[0]?.id} />
            </div>
            <div className="sidebar-content">
              <Typography textType="heading3" className="mb-8 font-Moranga" text="Knowledge Library" block />

              <div className="categories">
                <Typography textType="heading4" className="mb-6" text="Category" />
                <div className="list">
                  <ul>
                    <li><a href="/knowledge-library#recipes">{pageData.category_recipes?.name}</a></li>
                    <li><a href="/knowledge-library#exercises">{pageData.category_exercises?.name}</a></li>
                    <li><a href="/knowledge-library#studies">{pageData.category_studies?.name}</a></li>
                    <li><a href="/knowledge-library#glossary">{pageData.category_glossary?.name}</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={9} xs={12} className="recipe-page">
            <div className="recipe-summary">
              <div className="recipe-image">
              <img src={activity.image[0]} alt="" />
              </div>
              <div className="recipe-summary-content">
                <Typography textType="heading2" text={activity.title} className="font-Moranga" />
                <div className="recipe-area justify-content-start">
                  <img src={activity.wellnessAreas[0]?.wellnessAreaIcon.fields.file.url} alt="" />
                  <Typography textType="body2B" text={activity.wellnessAreas[0]?.name} />
                </div>
                <div className="recipe-area justify-content-start">
                  <img src={activity.ageGroupIcon.fields.file.url} alt="" />
                  <div className="d-flex flex-column">
                    <Typography textType="body3" text={activity.ageGroupTitle} />
                    <Typography textType="body2B" text={activity.ageGroup.join(', ')} />
                  </div>
                </div>
                <div className="recipe-area justify-content-start">
                  <img src={activity.skillsDevelopedIcon.fields.file.url} alt="" />
                  <div className="d-flex flex-column">
                    <Typography textType="body3" text={activity.skillsDevelopedTitle} />
                    <Typography textType="body2B" text={activity.skillsDeveloped} />
                  </div>
                </div>
              </div>
            </div>

            <div className="recipe-steps">
              {/* TODO: contentful slug */}
              <Accordion title={activity.stepsTitle}>
                {documentToReactComponents(activity.excercise)}
              </Accordion>
            </div>

            <VisitSourceLink link={activity.source} />

            <hr className="m-0" />

            {/* TODO: Add contentful slug */}
            <Typography textType="heading3" text="Explore more physical activities" className="font-Moranga" />
            <div className="recipes-list">
              {activitiesFetched && sanitizedActivities?.map((activity, index) => (
                <ExerciseCard
                  key={index}
                  data={activity}
                />
              ))}
            </div>

            <Button
              label="See all activities"
              type="secondary"
              rightIcon={<DirectionLineRight />}
              onClick={onShowAllClick}
              fullWidth
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Activity
