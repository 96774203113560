function ImmunityIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        stroke="#444"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="3.111"
        d="M20 3S13.905 7.407 4 7.407V20s0 13.852 16 17c16-3.148 16-17 16-17V7.407C26.095 7.407 20 3 20 3z"
      ></path>
      <path
        stroke="#444"
        strokeMiterlimit="10"
        strokeWidth="3.111"
        d="M22.925 16.667V11h-5.85v5.667h-5.85v5.666h5.85V28h5.85v-5.667h5.85v-5.666h-5.85z"
      ></path>
    </svg>
  );
}

export default ImmunityIcon;
