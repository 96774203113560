import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import WellnessAreaTile from '../../components/KnowledgeLibrary/WellnessAreaTile'
import Typography from '../../components/ui/typography/Typography';
import useKnowledgeLibrary from '../../hooks/useKnowledgeLibrary';
import { useGetDashboardByIdQuery } from '../wellness/dashboard/dashboardSlice';
import Link from '../../components/ui/link/Link';
import { useContentful } from '../../hooks/useContentful';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { Card } from 'react-bootstrap';
import { fetchContentfulPage } from '../../services/apiService';
import { BABY_DASBBOARD_SLUGS } from '../../constants/pageSlugs';

const slugs = [
  'dashboard_pending_data_subtitle',
  'superfood_note'
]

const BabyDashboard = () => {
  const { childId } = useParams();
  const { getWellnessAreas } = useKnowledgeLibrary()
  const { data: contentfulData } = useContentful({
    content_type: 'randomParagraph',
    'fields.name[in]': slugs.join(','),
    convertToObject: true,
  })
  const [pageData, setPageData] = useState(null)
  const [error, setError] = useState(false)

  useEffect(() => {
    fetchContentfulPage(BABY_DASBBOARD_SLUGS)
    .then((res) => {
      setPageData(res.data);
      setError(false);
    }).catch(() => {
      setError(true);
    })
  }, [])
  const { data, isLoading } = useGetDashboardByIdQuery(childId)
  const [wellnessAreas, setWellnessAreas] = useState([])
  const resultsPending = !data?.find(result => result.summary);

  useEffect(() => {
    getWellnessAreas().then(areas => setWellnessAreas(areas.sort()));
  }, [childId])

  if (isLoading || wellnessAreas.length === 0 || !pageData) return (
    <div>Loading...</div>
  )

  const renderAreas = (pageData) => {
    return data.map(dataItem => {
      const contentfulData = wellnessAreas.find(contentfulItem => contentfulItem.slug === dataItem.contentful_slug)
      const inverted = dataItem?.targets.some(item => item.target_type !== 'beneficial')

      const tile = (
        <WellnessAreaTile
          icon={contentfulData.icon}
          title={contentfulData.name}
          size="big"
          graphData={dataItem.summary}
          inverted={inverted}
          viewButton={true}
          viewButtonLabel={documentToPlainTextString(pageData?.button_view?.content)}
          viewButtonIconRight={pageData?.arrow_icon_right_darkgray}
        />
      )

      if (!dataItem.summary) {
        return tile;
      }

      return (
        <Link to={`/wellness/dashboard/${childId}/${contentfulData.slug}`} key={contentfulData.id}>
          {tile}
        </Link>
      )
    })
  }

  return (
    <div>
      <Typography text="Wellness Areas" textType="heading4" className="font-Moranga mb-8" />
      {resultsPending && (
        <Card className="mb-8">
          <Typography textType="body1" text={documentToPlainTextString(contentfulData?.dashboard_pending_data_subtitle.fields.content)} />
        </Card>
      )}
      <div className="wellness-dashboard-list">
        {renderAreas(pageData)}
      </div>
      <Typography className="mt-8" text={documentToPlainTextString(contentfulData?.superfood_note.fields.content)} textType="body1B" />
    </div>
  )
}

export default BabyDashboard
