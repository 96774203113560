import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Container, Row } from 'react-bootstrap'
import WaitlistSection from '../components/landing/WaitlistSection'
import { fetchContentfulPage } from '../services/apiService'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { getContentfulContent } from '../utils/get-contentful-content'
import useAddItem from '../hooks/cart/useAddItem';
import Button from '../components/ui/button/Button';
import DirectionLineRight from '../components/ui/icons/DirectionLineRight';
import { clearProduct, fetchProduct, selectProduct } from '../store/slices/products/singleProductSlice';

const generalSlugs = [
  'info_icon_png',
  'orange_bacteria_icon',
  'blue_bacteria_icon',
  'green_bacteria_icon',
  'yellow_bacteria_icon',
  'coming_soon',
  'product_name',
  'product_image_with_hand_png',
  'product_info_bullet_points',
  'food_and_nutrition_jpg',
  'physical_touch_jpg',
  'around_the_house_jpg',
  'outdoors_jpg',
  'pets_jpg',
  'entertainment_jpg',
  'quiz_result_your_custom_results',
  'waitlist_landing_sign_up_title',
  'waitlist_landing_sign_up_subtitle',
  'find_out_more_title',
  'get_your_babys_results_via_email_title',
  'get_your_babys_results_via_email_text',
  'form_first_name',
  'form_email_address',
  'waitlist_landing_sign_up_consent'
];

const justStartingSlugs = [
  'quiz_result_stage1_hero',
  'quiz_result_stage1_title',
  'quiz_result_stage1_subtitle',
  'quiz_result_stage_1_hero_png',
  'quiz_result_stage1_intro_first_part',
  'quiz_result_stage1_bacterium1',
  'quiz_result_stage1_bacterium2',
  'quiz_result_stage1_bacterium3',
  'quiz_result_stage1_intro_2nd_part',
  'quiz_result_stage1_reco_title',
  'quiz_result_stage1_reco_step1_title',
  'quiz_result_stage1_reco_step1_text',
  'quiz_result_stage1_reco_step2_title',
  'quiz_result_stage1_reco_step2_text',
  'quiz_result_stage1_reco_step3_title',
  'quiz_result_stage1_reco_step3_text',
  'quiz_result_stage1_reco_step4_title',
  'quiz_result_stage1_reco_step4_text',
  ...generalSlugs
];

const transitioningSystemSlugs = [
  'quiz_result_stage2_hero',
  'quiz_result_stage2_title',
  'quiz_result_stage_2_hero_png',
  'quiz_result_stage2_subtitle',
  'quiz_result_stage2_intro_first_part',
  'quiz_result_stage2_bacterium1',
  'quiz_result_stage2_bacterium2',
  'quiz_result_stage2_bacterium3',
  'quiz_result_stage2_intro_2nd_part',
  'quiz_result_stage2_reco_title',
  'quiz_result_stage2_reco_step1_title',
  'quiz_result_stage2_reco_step1_text',
  'quiz_result_stage2_reco_step2_text',
  'quiz_result_stage2_reco_step2_title',
  'quiz_result_stage2_reco_step3_title',
  'quiz_result_stage2_reco_step3_text',
  'quiz_result_stage2_reco_step4_title',
  'quiz_result_stage2_reco_step4_text',
  ...generalSlugs
];

const stableAndStrongSlugs = [
  'quiz_result_stage3_hero',
  'quiz_result_stage3_title',
  'quiz_result_stage_3_hero_png',
  'quiz_result_stage3_subtitle',
  'quiz_result_stage3_intro_first_part',
  'quiz_result_stage3_bacterium1',
  'quiz_result_stage3_bacterium2',
  'quiz_result_stage3_bacterium3',
  'quiz_result_stage3_intro_2nd_part',
  'quiz_result_stage3_reco_title',
  'quiz_result_stage3_reco_step1_title',
  'quiz_result_stage3_reco_step1_text',
  'quiz_result_stage3_reco_step2_text',
  'quiz_result_stage3_reco_step2_title',
  'quiz_result_stage3_reco_step3_title',
  'quiz_result_stage3_reco_step3_text',
  'quiz_result_stage3_reco_step4_title',
  'quiz_result_stage3_reco_step4_text',
  ...generalSlugs
]

const getStageSlugs = (stage, slugs) => {
  if (stage === 'just-starting-out') {
    return {
      firstBacteriaIcon: slugs.orange_bacteria_icon,
      secondBacteriaIcon: slugs.yellow_bacteria_icon,
      thirdBacteriaIcon: slugs.green_bacteria_icon,
      stageHero: slugs.quiz_result_stage1_hero,
      stageTitle: slugs.quiz_result_stage1_title,
      stageImage: slugs.quiz_result_stage_1_hero_png,
      stageSubtitle: slugs.quiz_result_stage1_subtitle,
      stageIntroFirst: slugs.quiz_result_stage1_intro_first_part,
      bacterium1: slugs.quiz_result_stage1_bacterium1,
      bacterium2: slugs.quiz_result_stage1_bacterium2,
      bacterium3: slugs.quiz_result_stage1_bacterium3,
      stageIntroSecond: slugs.quiz_result_stage1_intro_2nd_part,
      recoTitle: slugs.quiz_result_stage1_reco_title,
      recoStep1Title: slugs.quiz_result_stage1_reco_step1_title,
      recoStep2Title: slugs.quiz_result_stage1_reco_step2_title,
      recoStep3Title: slugs.quiz_result_stage1_reco_step3_title,
      recoStep4Title: slugs.quiz_result_stage1_reco_step4_title,
      recoStep1Text: slugs.quiz_result_stage1_reco_step1_text,
      recoStep2Text: slugs.quiz_result_stage1_reco_step2_text,
      recoStep3Text: slugs.quiz_result_stage1_reco_step3_text,
      recoStep4Text: slugs.quiz_result_stage1_reco_step4_text,
      recoStep1Image: slugs.food_and_nutrition_jpg,
      recoStep2Image: slugs.physical_touch_jpg,
      recoStep3Image: slugs.around_the_house_jpg,
      recoStep4Image: slugs.outdoors_jpg,
    }
  }

  if (stage === 'transitioning-system') {
    return {
      firstBacteriaIcon: slugs.blue_bacteria_icon,
      secondBacteriaIcon: slugs.orange_bacteria_icon,
      thirdBacteriaIcon: slugs.green_bacteria_icon,
      stageHero: slugs.quiz_result_stage2_hero,
      stageTitle: slugs.quiz_result_stage2_title,
      stageImage: slugs.quiz_result_stage_2_hero_png,
      stageSubtitle: slugs.quiz_result_stage2_subtitle,
      stageIntroFirst: slugs.quiz_result_stage2_intro_first_part,
      bacterium1: slugs.quiz_result_stage2_bacterium1,
      bacterium2: slugs.quiz_result_stage2_bacterium2,
      bacterium3: slugs.quiz_result_stage2_bacterium3,
      stageIntroSecond: slugs.quiz_result_stage2_intro_2nd_part,
      recoTitle: slugs.quiz_result_stage2_reco_title,
      recoStep1Title: slugs.quiz_result_stage2_reco_step1_title,
      recoStep1Text: slugs.quiz_result_stage2_reco_step1_text,
      recoStep2Title: slugs.quiz_result_stage2_reco_step2_title,
      recoStep2Text: slugs.quiz_result_stage2_reco_step2_text,
      recoStep3Title: slugs.quiz_result_stage2_reco_step3_title,
      recoStep3Text: slugs.quiz_result_stage2_reco_step3_text,
      recoStep4Title: slugs.quiz_result_stage2_reco_step4_title,
      recoStep4Text: slugs.quiz_result_stage2_reco_step4_text,
      recoStep1Image: slugs.food_and_nutrition_jpg,
      recoStep2Image: slugs.entertainment_jpg,
      recoStep3Image: slugs.pets_jpg,
      recoStep4Image: slugs.around_the_house_jpg,
    }
  }

  if (stage === 'stable-and-strong') {
    return {
      firstBacteriaIcon: slugs.orange_bacteria_icon,
      secondBacteriaIcon: slugs.blue_bacteria_icon,
      thirdBacteriaIcon: slugs.green_bacteria_icon,
      stageTitle: slugs.quiz_result_stage3_title,
      stageImage: slugs.quiz_result_stage_3_hero_png,
      stageSubtitle: slugs.quiz_result_stage3_subtitle,
      stageIntroFirst: slugs.quiz_result_stage3_intro_first_part,
      bacterium1: slugs.quiz_result_stage3_bacterium1,
      bacterium2: slugs.quiz_result_stage3_bacterium2,
      bacterium3: slugs.quiz_result_stage3_bacterium3,
      stageIntroSecond: slugs.quiz_result_stage3_intro_2nd_part,
      recoTitle: slugs.quiz_result_stage3_reco_title,
      recoStep1Title: slugs.quiz_result_stage3_reco_step1_title,
      recoStep1Text: slugs.quiz_result_stage3_reco_step1_text,
      recoStep2Title: slugs.quiz_result_stage3_reco_step2_title,
      recoStep2Text: slugs.quiz_result_stage3_reco_step2_text,
      recoStep3Title: slugs.quiz_result_stage3_reco_step3_title,
      recoStep3Text: slugs.quiz_result_stage3_reco_step3_text,
      recoStep4Title: slugs.quiz_result_stage3_reco_step4_title,
      recoStep4Text: slugs.quiz_result_stage3_reco_step4_text,
      recoStep1Image: slugs.food_and_nutrition_jpg,
      recoStep2Image: slugs.entertainment_jpg,
      recoStep3Image: slugs.outdoors_jpg,
      recoStep4Image: slugs.pets_jpg,
    }
  }
}

const requestSlugs = {
  'just-starting-out': justStartingSlugs,
  'transitioning-system': transitioningSystemSlugs,
  'stable-and-strong': stableAndStrongSlugs,
}

const QuizResults = () => {
  const [slugs, setSlugs] = useState();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const quizResultsType = searchParams.get('result');
  const addItem = useAddItem();
  const dispatch = useDispatch()
  const slug = 'baby-microbiome-gut-health-test-kit'
  const { data } = useSelector(selectProduct)

  if( !quizResultsType ) navigate('/')

  useEffect(() => {
    dispatch(fetchProduct({ id: slug }))

    return () => dispatch(clearProduct())
  }, [dispatch, slug])


  const addToCart = () => {
    addItem({
      variant_id: data.primaryVariant.id,
      quantity: 1
    })
    navigate('/cart', { replace: true })
  }

  useEffect(() => {
    fetchContentfulPage(requestSlugs[quizResultsType])
    .then((response) => {
      setSlugs(response.data)
    })
  }, [quizResultsType])

  if (!slugs || !data) return <div>Loading...</div>

  const getFormProps = () => {
    switch (quizResultsType) {
      case 'just-starting-out':
        return { quiz_result: 1 };
      case 'transitioning-system':
        return { quiz_result: 2 };
      case 'stable-and-strong':
        return { quiz_result: 3 };
      default:
        return navigate('/404');
    }
  }

  const {
    product_image_with_hand_png,
    product_name,
    product_info_bullet_points,
    quiz_result_your_custom_results,
    find_out_more_title,
    get_your_babys_results_via_email_title,
    get_your_babys_results_via_email_text,
    form_first_name,
    form_email_address,
    waitlist_landing_sign_up_consent
  } = slugs;

  const {
    firstBacteriaIcon,
    secondBacteriaIcon,
    thirdBacteriaIcon,
    stageTitle,
    stageImage,
    stageSubtitle,
    stageIntroFirst,
    bacterium1,
    bacterium2,
    bacterium3,
    stageIntroSecond,
    recoTitle,
    recoStep1Title,
    recoStep2Title,
    recoStep3Title,
    recoStep4Title,
    recoStep1Text,
    recoStep2Text,
    recoStep3Text,
    recoStep4Text,
    recoStep1Image,
    recoStep2Image,
    recoStep3Image,
    recoStep4Image
  } = getStageSlugs(quizResultsType, slugs);

  return (
    <div className="quiz-results-page">
      <Container>
        <Row>
          <Col xs={12}>
            <div className="curved-corner-bottom-start ms-3" />
            <div className="panel-badge">{getContentfulContent(quiz_result_your_custom_results)}</div>
            <div className="curved-corner-bottom-end" />
          </Col>
          <Col md={7}>
            <div className="quiz-result-panel">
              <div className="panel-header d-md-none">
                <img src={stageImage.image.fields.file.url} alt="" />
              </div>
              <div className="panel-body">
                <h1 className="font-Moranga">{getContentfulContent(stageTitle)}</h1>
                <h4>{getContentfulContent(stageSubtitle)}</h4>
                <p>{getContentfulContent(stageIntroFirst)}</p>
                <div className="panel-bacterias-list">
                  <div className="list-item">
                    {bacterium1 && <img src={firstBacteriaIcon.image.fields.file.url} alt="" />}
                    {getContentfulContent(bacterium1)}
                    {/* <img src={info_icon_png.image.fields.file.url} alt="" /> */}
                  </div>
                  {quizResultsType !== "just-starting-out" && (
                    <div className="list-item">
                      {bacterium2 && <img src={secondBacteriaIcon.image.fields.file.url} alt="" />}
                      {getContentfulContent(bacterium2)}
                      {/* <img src={info_icon_png.image.fields.file.url} alt="" /> */}
                    </div>
                  )}
                  <div className="list-item">
                    {bacterium3 && <img src={thirdBacteriaIcon.image.fields.file.url} alt="" />}
                    {getContentfulContent(bacterium3)}
                    {/* <img src={info_icon_png.image.fields.file.url} alt="" /> */}
                  </div>
                </div>
                <p>{getContentfulContent(stageIntroSecond)}</p>
              </div>
            </div>
          </Col>
          <Col md={5} className="d-none d-md-block">
            <img src={stageImage.image.fields.file.url} className="quiz-result-panel-image" alt="" />
          </Col>
        </Row>
      </Container>

      <section>
        <div className="white-gradient-section">
          <div className="gradient-start" />
          <div className="gradient-content">
            <Container>
              <Row>
                <Col>
                  <h1 className="text-center text-black mb-16 d-none d-md-block">{getContentfulContent(recoTitle)}</h1>
                  <div className="card-big mt-10">
                    <Row>
                      <Col xs={12} className="d-md-none">
                        <h1 className="text-center text-black mb-9">{getContentfulContent(recoTitle)}</h1>
                      </Col>
                      <Col xs={12} md={3}>
                        <div className="card-item">
                          <img src={recoStep1Image.image.fields.file.url} alt="" />
                          <h3 className="title">{getContentfulContent(recoStep1Title)}</h3>
                          <p>{getContentfulContent(recoStep1Text)}</p>
                        </div>
                      </Col>
                      <Col xs={12} md={3}>
                        <div className="card-item">
                          <img src={recoStep2Image.image.fields.file.url} alt="" />
                          <h3 className="title">{getContentfulContent(recoStep2Title)}</h3>
                          <p>{getContentfulContent(recoStep2Text)}</p>
                        </div>
                      </Col>
                      <Col xs={12} md={3}>
                        <div className="card-item">
                          <img src={recoStep3Image.image.fields.file.url} alt="" />
                          <h3 className="title">{getContentfulContent(recoStep3Title)}</h3>
                          <p>{getContentfulContent(recoStep3Text)}</p>
                        </div>
                      </Col>
                      <Col xs={12} md={3}>
                        <div className="card-item">
                          <img src={recoStep4Image.image.fields.file.url} alt="" />
                          <h3 className="title">{getContentfulContent(recoStep4Title)}</h3>
                          <p>{getContentfulContent(recoStep4Text)}</p>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="gradient-end half" />
        </div>
      </section>

      <section>
        <div className="white-gradient-section">
          <div className="gradient-start half" />
          <div className="gradient-content">
            <h1 className="text-center mb-12 text-black">{getContentfulContent(find_out_more_title)}</h1>
            <Container>
              <Row>
                <Col xs={12} md={{ span: 8, offset: 2 }}>
                  <div className="coming-soon-card">
                    <img src={product_image_with_hand_png.image.fields.file.url} alt="" />
                    <div className="coming-soon-content">
                      <h3 className="product-head d-flex mb-4">
                        <Col xs={10}>{getContentfulContent(product_name)}</Col>
                        <Col xs={2} className="product-price">${data.attributes.price}</Col>
                      </h3>
                      <div className="product-highlights">{documentToReactComponents(product_info_bullet_points.content)}</div>
                      <div className="d-grid gap-2">
                        <Button id='addBtn' onClick={addToCart} label={'Add to Cart'} rightIcon={<DirectionLineRight />} className='rounded-pill'fullWidth />
                        <a className='text-center typography link mt-3' href='kits/baby-microbiome-gut-health-test-kit'>Learn More</a>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="gradient-end half" />
        </div>
      </section>
      
      <section>
        <div className="white-gradient-section mb-10 mb-md-30">
          <div className="gradient-start half" />
          <div className="gradient-content">
            <WaitlistSection
              title={getContentfulContent(get_your_babys_results_via_email_title)}
              subtitle={getContentfulContent(get_your_babys_results_via_email_text)}
              formProps={getFormProps()}
            />
          </div>
        </div>
      </section>
    </div>
  )
}

export default QuizResults;
