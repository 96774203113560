import { useEffect, useState } from 'react'
import useQuestions from '../../hooks/questions/useQuestions'
import { makeRequest } from '../../services/requestHandler';
import QuestionsList from '../questions/QuestionsList';

const AnswerQuestions = ({ accountId, onFinish }) => {
  const [variants, setVariants] = useState([]);
  const { getQuestionsByCategorySlug } = useQuestions();

  useEffect(() => {
    getQuestionsByCategorySlug('quiz').then(res => setVariants(res))
  }, [getQuestionsByCategorySlug])

  const handleSubmitAnswer = (variant, value) => {
    const answer = {
      value: value,
      question_id: variant.question_id,
      account_id: accountId
    }

    return makeRequest({
      url: '/api/v1/answers',
      method: 'POST',
      body: JSON.stringify(answer),
      authType: 'auth0',
    })
  }

  const handleFinishQuiz = () => {
    onFinish()
  }

  return (
    <div>
      <QuestionsList
        variants={variants}
        onSubmit={handleSubmitAnswer}
        onFinishQuiz={handleFinishQuiz}
        hideCloseButton
      />
    </div>
  )
}

export default AnswerQuestions
