import { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '../ui/button/Button';
import Input from '../ui/forms/Input';
import DateQuestion from './DateQuestion';
import OptionQuestion from './OptionQuestion';
import QuestionModal from './QuestionModal';
import DirectionLineRight from '../ui/icons/DirectionLineRight'
import Typography from '../ui/typography/Typography'

const QuestionItem = ({
  variant,
  activeStep,
  maxStep,
  onSubmit,
  onFinish,
}) => {
  const [value, setValue] = useState()
  const [error, setError] = useState()
  const type = variant.question.settings.find(item => item === 'multi')
    ? 'checkbox'
    : 'radio'

  const renderQuestionType = () => {
    if (variant.question.answer_type === 'date') {
      return <DateQuestion onChange={setValue} setError={setError} />
    }

    if (variant.question.answer_type === 'option') {
      if (!variant.question.options) return;

      const options = variant.question.options.map(option => ({
        value: option.id,
        label: option.title,
      }))

      return (
        <OptionQuestion
          type={type}
          options={options}
          name={variant.id}
          id={variant.id}
          onChange={setValue}
        />
      )
    }

    if (variant.question.answer_type === 'string') {
      let instruction;
      if(variant.instruction) {
        instruction = <p><Typography textType="body1" text={variant.instruction} color="grey" block /></p>
      }
      return (
        <div className="question-answer">
          {instruction}
          <Input placeholder={variant.static_content} onChange={(event) => setValue([event.target.value])} />
        </div>
      )
    }
  }

  const onFinishClick = () => {
    onSubmit(variant, value);
    onFinish();
  }

  const className = "question-" + variant.title.replaceAll(" ", "-").toLowerCase().replaceAll(/[^a-z0-9\-]/gi, '');

  return (
    <div className="question-card">
      <div className="question-body">
        {variant.question.image_mobile_url && <img src={variant.question.image_mobile_url} className="question-image img-fluid d-md-none" alt="" />}
        {variant.question.image_desktop_url && <img src={variant.question.image_desktop_url} className="question-image img-fluid d-none d-md-block" alt="" />}
        <div className="question-title">{variant.title}</div>
        <div dangerouslySetInnerHTML={{ __html: variant.body.body }} className="body1 block" />
        <div className="question-text">{variant.question_text}</div>
        {renderQuestionType()}
        {error && <span>{error}</span>}
      </div>

      <div className="question-footer">
        {variant.info?.body && (
          <QuestionModal
            title={variant.title}
            text={variant.info.body}
          />
        )}
        {value && value.length > 0 ?
          <>
            {activeStep === maxStep ? (
              <Button
                label="Finish"
                onClick={onFinishClick}
                rightIcon={<DirectionLineRight />}
                className={className + "-finish"}
                fullWidth
              />
            ) : (
              <Button
                label="Next"
                onClick={() => onSubmit(variant, value)}
                disabled={!value || value.length === 0 || error}
                rightIcon={<DirectionLineRight />}
                className={className + "-next"}
                fullWidth
              />
            )}
          </> : null
        }
      </div>
    </div>
  )
}

QuestionItem.propTypes = {
  variant: PropTypes.object,
  onSubmit: PropTypes.func,
  activeStep: PropTypes.number,
  maxStep: PropTypes.number,
}

export default QuestionItem
