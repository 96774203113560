import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { buildQueryString } from '../../services/apiService'

export const accountTypeApi = createApi({
  reducerPath: 'accountTypeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/account_types',
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('auth0Token')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
  
      return headers
    },
  }),
  
  providesTags: (result, error, id) => [{ type: 'Account Type', id }],
  endpoints: (builder) => ({
    getAccountTypes: builder.query({
      query: (params) => {
        const queryString = buildQueryString(params);
        return `${queryString ? `?${queryString}` : ''}`;
      },
      transformResponse: (response) => response.data,
      transformErrorResponse: (
        response,
      ) => response.status,
    }),
    getAccountTypesById: builder.query({
      query: (id) => `${id}`,
      transformResponse: (response) => response.data,
      transformErrorResponse: (
        response,
      ) => response.status,
    }),
  }),
})

export const {
  useGetAccountTypesQuery,
  useGetAccountTypesByIdQuery,
} = accountTypeApi;
