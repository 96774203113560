import { useEffect } from 'react';
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchCountries } from '../../store/slices/global/countriesSlice';
import { getUserInfo } from "../../store/slices/auth/authSlice";

import Typography from '../ui/typography/Typography';
import UserNameForm from './editAccount/UserNameForm';
import ShippingAddressForm from './editAccount/ShippingAddressForm';
import BillingAddressForm from './editAccount/BillingAddressForm';

const EditAccount = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);

  useEffect(() => {
    dispatch(getUserInfo())
    dispatch(fetchCountries());
  }, [dispatch]);

  return (
    <div>
      <Typography textType="heading3" font="Moranga" text="Settings" className="mb-8" />
      <Card className="mb-10">
        <UserNameForm />
        <hr className="my-6" />

        <ShippingAddressForm />
        <hr className="my-6" />

        <BillingAddressForm />
        <hr className="my-6" />

        <div>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <Typography textType="heading5" text="Email Address" />
          </div>
          <Typography textType="body1" text={`Your email address is ${user.email}`} block />
        </div>
      </Card>
    </div>
  );
};

EditAccount.propTypes = {
  attributes: PropTypes.object,
  closeForm: PropTypes.func,
};

export default EditAccount;
