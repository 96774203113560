import { Col, Container, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { cartSelector, completeOrder } from '../../store/slices/cart/cartSlice';
import Button from '../ui/button/Button';
import ChevronLeft from '../ui/icons/ChevronLeft';
import DirectionLineRight from '../ui/icons/DirectionLineRight';
import Link from '../ui/link/Link';
import Typography from '../ui/typography/Typography';
import OrdersArea from './OrdersArea';
import { customToast } from '../ui/notification/customToast';

const OrderConfirmation = () => {
  const dispatch = useDispatch();
  const { data } = useSelector(cartSelector);
  const [, setSearchParams] = useSearchParams();
  const {
    shippingAddress,
    billingAddress,
    shippingMethod,
    payment,
  } = data;
  const freeOrder = data.totalPrice === 0;

  const handlePlaceOrderClick = async () => {
    const result = await dispatch(completeOrder())
    if (!result.error) {
      setSearchParams({ step: 'confirm' });
    } else {
      customToast({
        message: result.error.message,
        type: 'error'
      })
    }
  }

  return (
    <div className="order-confirmation-wrap">
      <Container>
        <Row>
          <Col>
            <Link to={freeOrder ? '/checkout?step=address' : '/checkout?step=payment'} className="back-btn mb-4 d-none d-md-block">
              <ChevronLeft />
            </Link>
            <div className="d-flex justify-content-between align-items-center mb-6">
              <div className="checkout-step-title">Confirm Your Order</div>
            </div>

            <div className="order-card">
              <Row className="gx-md-5">
                <Col id='invoice-section' md={5} xs={12} className='mt-0 d-block d-md-none'>
                  <OrdersArea hideInvoiceOnMobile={true} />
                </Col>
                <Col md={7}>
                  <div className="checkout-data-section">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <Typography textType="heading4" text="Account Information" block />
                    </div>
                    <div className="section-item">
                      <div className="section-title">Email Address</div>
                      <div className="section-content">{data?.email}</div>
                    </div>
                  </div>
                  {!freeOrder && (
                    <div className="checkout-data-section">
                      <div className="d-flex justify-content-between align-items-center mb-4">
                        <Typography textType="heading4" text="Billing Details" block />
                        <Link to="/checkout?step=payment">Edit</Link>
                      </div>
                        {billingAddress && (
                          <div className="section-item">
                            <div className="section-title">Billing Address</div>
                            <div className="section-content">{`${billingAddress.firstname} ${billingAddress.lastname}`}</div>
                            <div className="section-content">{`${billingAddress.address1}, ${billingAddress.city}, ${billingAddress.state_code} ${billingAddress.zipcode}`}</div>
                            <div className="section-content">{billingAddress.country_name}</div>
                            <div className="section-content">{billingAddress.phone}</div>
                          </div>
                        )}
                        {payment?.cardData && (
                          <div className="section-item">
                            <div className="section-title">Payment Information</div>
                            <div className="section-content">
                              <span className="text-mainBlue">{payment.cardData.type} </span>
                              Card ending with {payment.cardData.lastDigits}
                            </div>
                          </div>
                        )}
                    </div>
                  )}
                  <div className="checkout-data-section">
                    <div className="d-flex justify-content-between align-items-center mb-4">
                      <Typography textType="heading4" text="Shipping Details" block />
                      <Link to="/checkout?step=address">Edit</Link>
                    </div>
                    {shippingAddress && (
                      <div className="section-item">
                        <div className="section-title">Billing Address</div>
                        <div className="section-content">{`${shippingAddress.firstname} ${shippingAddress.lastname}`}</div>
                        <div className="section-content">{`${shippingAddress.address1}, ${shippingAddress.city}, ${shippingAddress.state_code} ${shippingAddress.zipcode}`}</div>
                        <div className="section-content">{shippingAddress.country_name}</div>
                        <div className="section-content">{shippingAddress.phone}</div>
                      </div>
                    )}
                    {shippingMethod && (
                      <div className="section-item">
                        <div className="section-title">Ships with {shippingMethod?.name}</div>
                      </div>
                    )}

                    <div className="section-item p-3 p-md-0 place-order-button">
                      <Button
                        label="Place Order"
                        rightIcon={<DirectionLineRight />}
                        onClick={handlePlaceOrderClick}
                        fullWidth
                      />
                    </div>
                  </div>
                </Col>
                <Col id='invoice-section' md={5} xs={12} className='mt-0'>
                  <OrdersArea hideInvoiceOnMobile={false} hideLineItemOnMobile={true} />
                </Col>
              </Row>
            </div>

          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default OrderConfirmation
