import PropTypes from 'prop-types';
import Typography from '../typography/Typography';

const Radio = ({
  label,
  name,
  onChange,
  value,
  checked,
}) => {
  return (
    <label className="form-control-wrap">
      <input
        className="form-control-input"
        name={name}
        type="radio"
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <span className="form-control-checkmark" />
      <Typography textType="textField" text={label} />
    </label>
  )
}

Radio.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
}

export default Radio
