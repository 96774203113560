import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getUserInfo, selectUser } from '../store/slices/auth/authSlice';
import withAuthorization from '../components/containers/withAuthorization';
import { useSelector } from 'react-redux';
import { Card, Col, Row } from 'react-bootstrap';
import Typography from '../components/ui/typography/Typography';
import Button from '../components/ui/button/Button';
import NextStepCard from '../components/account/NextStepCard';
import { format } from 'date-fns'

import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { MY_KITS_SLUGS } from '../constants/pageSlugs';
import { fetchContentfulPage } from '../services/apiService';
import SpreeService from '../services/spreeService';
import Link from '../components/ui/link/Link';
import { useGetAccountTypesQuery } from './account/accountTypeSlice';
import { useGetAccountsQuery } from './account/accountSlice';

const MyKits = () => {
  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const [contentfulData, setContentfulData] = useState(null);
  const [orders, setOrders] = useState();
  const { data: accountTypes } = useGetAccountTypesQuery({
    title: 'Children'
  })
  const { data: babies = [] } = useGetAccountsQuery({
    account_type_id: accountTypes?.[0]?.id
  }, {
    skip: !accountTypes
  })

  const formatDate = (date) => {
    return(format(new Date(date), 'MMM dd, yyyy'));
  }

  useEffect(() => {
    dispatch(getUserInfo())
    SpreeService.getOrders().then((res) => {
      let sortedOrders = res.sort((a,b) => new Date(b.completedAt) - new Date(a.completedAt));
      setOrders(sortedOrders);
    })
    fetchContentfulPage(MY_KITS_SLUGS)
      .then((res) => {
        setContentfulData(res.data);
      })
  }, [dispatch])

  if (!user || !contentfulData) {
    return <>Loading</>
  }

  const {
    my_kits_title,
    order,
    order_date,
    product_name,
    product_small_image_baby_microbiome,
    button_track_kit,
    button_add_baby,
    your_next_steps,
    your_next_step1,
    your_next_step2,
    your_next_step3,
    icon_arrow_right_white
  } = contentfulData;

  return (
    <>
      <div className='my-kits'>
        <Row>
          <Col>
            <Typography 
              textType={'heading3'} 
              className="font-Moranga mb-6 mb-md-8 title-text d-none d-md-block" 
              text={documentToPlainTextString(my_kits_title?.content)} />
            <div className={orders && orders.length > 0 ? 'my-kits' : 'no-kits'}>
              {orders && orders.length > 0 ? 
                orders.map((order) =>
                  order.lineItems.map(lineItem =>
                    lineItem.lineItemUnits.map(lineItemUnit => (
                      <Card className='p-4 my-4' key={`${order.id}-${lineItemUnit.id}`}>
                        <Row>
                          <Col md={7} xs={12}>
                            <div className="col-12 order-info">
                              <div className="order-id col-sm-6">
                                <span className="label mb-0">{documentToPlainTextString(order.content)}</span>
                                <span className="value mb-0 fw-bold float-end">{order.orderNumber}</span>
                              </div>
                              <div className="order-date col-sm-6">
                                <span className="label mb-0">{documentToPlainTextString(order_date.content)}</span>
                                <span className="value mb-0 fw-bold float-end">{formatDate(order.completedAt)}</span>
                              </div>
                            </div>
                            <div className="col-12 row product-info py-3">
                              <div className="col-5 col-md-3">
                                <img src={product_small_image_baby_microbiome.image.fields.file.url} alt="" />
                              </div>
                              <div className="col-7 col-md-9">
                                <p className="product-name mt-3 mb-0">{documentToPlainTextString(product_name.content)}</p>
                              </div>
                            </div>
                          </Col>
                          <Col md={5} xs={12} className="m-auto">
                            <Link to={`tracking/${order.orderNumber}?line_item_unit=${lineItemUnit.id}`} className="tracking-link">
                              <Button 
                                role="button"
                                type="secondary"
                                size="large"
                                className="w-75 mx-auto" 
                                label={documentToPlainTextString(button_track_kit?.content)} 
                              />
                            </Link>
                          </Col>
                        </Row>
                      </Card>
                )))
              ) : null}
            </div>
          </Col>
        </Row>
        <hr />
        
        <Row>
          <Col>
            <Typography textType={'heading3'} className="font-Moranga mb-6 mb-md-6 title-text" text={documentToPlainTextString(your_next_steps?.content)} />
          </Col>
        </Row>          

        <Row className='your-next-steps'>
          {babies && babies.length === 0 && (
            <NextStepCard 
              title={your_next_step1?.stepTitle}
              titleIcon={your_next_step1?.icon}
              details={your_next_step1?.stepDetails}
              linkTo="/register-baby-quiz"
              button={your_next_step1?.button} 
              buttonIcon={icon_arrow_right_white?.image}
            />
          )}
          <NextStepCard 
            title={your_next_step2?.stepTitle}
            titleIcon={your_next_step2?.icon}
            details={your_next_step2?.stepDetails}
            linkTo="/kits/baby-microbiome-gut-health-test-kit"
            button={your_next_step2?.button} 
            buttonIcon={icon_arrow_right_white?.image}
          />
          {orders && orders.length > 0 && (
            <NextStepCard 
              title={your_next_step3?.stepTitle}
              titleIcon={your_next_step3?.icon}
              details={your_next_step3?.stepDetails}
            />
          )}
        </Row>

      </div>
    </>
  )
}

export default withAuthorization(MyKits)
