import { Col, Container, Row } from 'react-bootstrap'
import Button from '../../components/ui/button/Button'
import ArrowLeft from '../../components/ui/icons/ArrowLeft'
import BagIcon from '../../components/ui/icons/Bag'
import BrainIcon from '../../components/ui/icons/Brain'
import ChevronLeft from '../../components/ui/icons/ChevronLeft'
import ChevronLeftIcon from '../../components/ui/icons/ChevronLeftIcon'
import CloseIcon from '../../components/ui/icons/CloseIcon'
import DiaperIcon from '../../components/ui/icons/Diaper'
import DirectionArrowLeftIcon from '../../components/ui/icons/DirectionArrowLeftIcon'
import DirectionLineRight from '../../components/ui/icons/DirectionLineRight'
import EmailIcon from '../../components/ui/icons/EmailIcon'
import ErrorIcon from '../../components/ui/icons/Error'
import FacebookIcon from '../../components/ui/icons/FacebookIcon'
import GoogleIcon from '../../components/ui/icons/GoogleIcon'
import HamburgerMenuIcon from '../../components/ui/icons/HamburgerMenu'
import ImmunityIcon from '../../components/ui/icons/Immunity'
import InfoCircleIcon from '../../components/ui/icons/InfoCircleIcon'
import InstagramIcon from '../../components/ui/icons/InstagramIcon'
import MicrosoftIcon from '../../components/ui/icons/MicrosoftIcon'
import PlusIcon from '../../components/ui/icons/PlusIcon'
import RecipeBookIcon from '../../components/ui/icons/RecipeBook'
import RecommendationsIcon from '../../components/ui/icons/Recommendations'
import ShoppingCartIcon from '../../components/ui/icons/ShoppingCart'
import TrashIcon from '../../components/ui/icons/TrashIcon'
import TwitterIcon from '../../components/ui/icons/TwitterIcon'
import UserPlusIcon from '../../components/ui/icons/UserPlus'
import WellnessUpdateIcon from '../../components/ui/icons/WellnessUpdate'
import YoutubeIcon from '../../components/ui/icons/YoutubeIcon'
import InfoIcon from '../../components/ui/icons/InfoIcon'
import WarningIcon from '../../components/ui/icons/WarningIcon'
import SuccessIcon from '../../components/ui/icons/SuccessIcon'
import Link from '../../components/ui/link/Link'
import Typography from '../../components/ui/typography/Typography'

const UIElements = () => {
  return (
    <Container>
      <Row>
        <Col>
          <h2 className="my-4">Buttons</h2>
          <h4 className="my-4">Primary</h4>
          <div className="d-flex gap-4">
            <Button label="Button" rightIcon={<DirectionLineRight size={24} />} size="large" />
            <Button label="Button" size="normal" />
            <Button label="Button" size="medium" />
            <Button label="Button" size="small" />
            <Button label="Button" size="extra-small" />
          </div>
          <h4 className="my-4">Primary disabled</h4>
          <div className="d-flex gap-4">
            <Button label="Button" size="large" disabled />
            <Button label="Button" size="normal" disabled />
            <Button label="Button" size="medium" disabled />
            <Button label="Button" size="small" disabled />
            <Button label="Button" size="extra-small" disabled />
          </div>

          <h4 className="my-4">Secondary</h4>
          <div className="d-flex gap-4">
            <Button label="Button" rightIcon={<DirectionLineRight size={24} />} size="large" type="secondary" />
            <Button label="Button" size="normal" type="secondary" />
            <Button label="Button" size="medium" type="secondary" />
            <Button label="Button" size="small" type="secondary" />
            <Button label="Button" size="extra-small" type="secondary" />
          </div>

          <h4 className="my-4">Tertiary</h4>
          <div className="d-flex gap-4">
            <Button label="Button" rightIcon={<DirectionLineRight size={24} />} size="large" type="tertiary" />
            <Button label="Button" size="normal" type="tertiary" />
            <Button label="Button" size="medium" type="tertiary" />
            <Button label="Button" size="small" type="tertiary" />
            <Button label="Button" size="extra-small" type="tertiary" />
          </div>
          
          <h2 className="my-4">Links</h2>
          <div className="d-flex gap-4">
            <Link to="#"><Typography text="Link" textType="link" color="mainBlue" /></Link>
          </div>
          <h2 className="my-4">Icons</h2>
          <div className="d-flex flex-wrap gap-4">
            <ArrowLeft />
            <BagIcon />
            <BrainIcon />
            <ChevronLeft />
            <ChevronLeftIcon />
            <CloseIcon />
            <DiaperIcon />
            <DirectionLineRight />
            <EmailIcon />
            <ErrorIcon />
            <FacebookIcon />
            <GoogleIcon />
            <HamburgerMenuIcon />
            <ImmunityIcon />
            <InfoCircleIcon />
            <InstagramIcon />
            <MicrosoftIcon />
            <PlusIcon />
            <RecipeBookIcon />
            <RecommendationsIcon />
            <ShoppingCartIcon />
            <TrashIcon />
            <TwitterIcon />
            <UserPlusIcon />
            <WellnessUpdateIcon />
            <YoutubeIcon />
            <DirectionArrowLeftIcon />
            <InfoIcon />
            <WarningIcon />
            <SuccessIcon />
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default UIElements
