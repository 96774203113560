import { useEffect, useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { Container, Row, Col } from 'react-bootstrap';
import Hero from '../components/Hero';
import { fetchContentfulPage } from '../services/apiService';

const Ccpa = () => {
  const [document, setDocument] = useState({})

  useEffect(() => {
    fetchContentfulPage(['do_not_sell_my_personal_info', 'footer_menu_category_ccpa'])
      .then((response) => {
        setDocument(response.data)
      })
  }, [])

  const { do_not_sell_my_personal_info, footer_menu_category_ccpa } = document

  return (
    <>
      <Hero title={do_not_sell_my_personal_info?.pageAndFooterTitle} />
      <Container data-testid="ccpa-container">
        <Row className='my-5'>
          <Col md={11} className='mx-auto'>{do_not_sell_my_personal_info && documentToReactComponents(do_not_sell_my_personal_info.content)}</Col>
        </Row>
        <Row>
          <Col>
            <div className="ot-form-wrapper">
              <iframe src="https://privacyportal.onetrust.com/webform/b8c23cad-e1f0-4b9a-b984-072a1c303c05/4cd67439-1c85-4214-a472-7181be3ee616">
              </iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Ccpa
