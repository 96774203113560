import { useDispatch } from 'react-redux'
import { setQuantity } from '../../store/slices/cart/cartSlice'
import SpreeService from '../../services/spreeService'
import ensureToken from '../../services/ensureToken'

const useUpdateItem = () => {
  const dispatch = useDispatch()

  const updateItem = async (data) => {
    let token = ensureToken()

    if (!token) {
      const response = await SpreeService.createCart()

      token = response.customerId
      localStorage.setItem('orderToken', token)

      updateItem({
        ...data
      })
    } else {
      return dispatch(setQuantity({
        ...data,
        ...token
      }))
    }
  }

  return updateItem
}

export default useUpdateItem
