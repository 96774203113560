import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { selectAuthorizationStatus } from '../../store/slices/auth/authSlice';
import { storeState } from '../../constants/storeState';

const withAuthorization = (Component) => {
  return AuthorizationRequired

  function AuthorizationRequired() {
    const { authorized, status } = useSelector(selectAuthorizationStatus)
    const location = useLocation()

    if (!authorized && (status !== storeState.idle && status !== storeState.loading)) {
      return <Navigate to="/auth/sign-in" state={{ from: location }} replace />
    }

    return authorized
      ? <Component/>
      : <div>Loading...</div>
  }
}

export default withAuthorization
