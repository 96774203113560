import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Outlet, useParams } from 'react-router-dom'
import Button from '../../components/ui/button/Button'
import Link from '../../components/ui/link/Link'
import Typography from '../../components/ui/typography/Typography'
import { fetchContentfulPage } from '../../services/apiService'
import { useGetAccountByIdQuery } from './accountSlice'

const slugs = [
  'button_back',
  'arow_icon_left_black',
  'baby_avatar_icon',
  'baby_profile',
  'registration_date',
  'last_updated',
  'user_icon_black',
  'baby_profile_title',
  'pie_chart_icon_black',
  'dashboard',
];

const BabyProfileLayout = () => {
  const { childId } = useParams();
  const [contentfulData, setContentfulData] = useState(null);
  const { data: accountData } = useGetAccountByIdQuery(childId)

  useEffect(() => {
    fetchContentfulPage(slugs).then((res) => {
      setContentfulData(res.data);
    })
  }, [])

  if (!contentfulData) {
    return <>Loading...</>
  }

  const babyNameLabel = documentToPlainTextString(contentfulData.baby_profile.content).replace('{{baby.name}}', accountData?.title || 'Baby')

  return (
    <div className="sidebar-layout bg-disabled">
      <div id="mobile-main-content-head" />
      <Container className='py-0'>
        <Row>
          <Col lg={3} className="sidebar">
            <div className="sidebar-content">
              <Link to="/account" className="mb-6 d-flex align-items-center">
                {contentfulData.arow_icon_left_black && <img src={contentfulData.arow_icon_left_black.image.fields.file.url} alt="" />}
                <Typography textType="heading5" text={documentToPlainTextString(contentfulData.button_back.content)} />
              </Link>
              <div>
                <Typography textType="heading3" className="mb-1 font-Moranga" text={babyNameLabel} block />
                {/* TODO: discuss from where to take last updated data */}
                {/* <Typography textType="body1B" className="text-darkGrass" text="Last Updated: 2/01/2022" block /> */}
                <hr />
              </div>
              <div className="sidebar-navigation">
                <Link to={`/account/my-babies/${childId}`}>
                  <Button
                    type="secondary"
                    leftIcon={<img src={contentfulData.user_icon_black.image.fields.file.url} width="16" alt="" />}
                    label={documentToPlainTextString(contentfulData.baby_profile_title.content)}
                    fullWidth
                  />
                </Link>
                <Link to={`/account/my-babies/${childId}/dashboard`}>
                  <Button
                    type="secondary"
                    leftIcon={<img src={contentfulData.pie_chart_icon_black.image.fields.file.url} width="16" alt="" />}
                    label={documentToPlainTextString(contentfulData.dashboard.content)}
                    fullWidth
                  />
                </Link>
              </div>
            </div>
          </Col>
          <Col lg={9} className="main-content py-20">
            <Outlet />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default BabyProfileLayout
