import { Col } from 'react-bootstrap';
import { useSelector} from 'react-redux';
import CartItem from '../cart/CartItem';
import { cartSelector } from '../../store/slices/cart/cartSlice';

const OrdersArea = ({
  hideLineItemOnMobile,
  hideInvoiceOnMobile
}) => {
  const { data } = useSelector(cartSelector)
  return (
    <>
      <div className={hideLineItemOnMobile ? 'd-none d-md-block' : ''}>
        <h4 className='d-none d-md-block'>Your Order</h4>
        <br/>
        {data.lineItems.map(lineItem => (
          <CartItem
            key={lineItem.id}
            lineItem={lineItem}
            hideControls
            showQuantity
          />
        ))}
      </div>
      <hr className='d-none d-md-block' />
      <Col id="invoice-area" className={hideInvoiceOnMobile ? 'd-none d-md-block mx-auto p-4 px-6 p-sm-0' : 'mx-auto p-4 px-6 p-sm-0'}>
        <h4 className="d-flex justify-content-between mb-2">
          <span>Subtotal:</span>
          <span className="fw-bold">{data.displayItemTotal}</span>
        </h4>
        <p className="d-flex justify-content-between">
          <span>Sales Tax</span>
          <span className="fw-bold">-</span>
        </p>
        <p className="d-flex justify-content-between">
          <span>Shipping</span>
          <span className="fw-bold">Free</span>
        </p>
        {data.promotions?.map(promo => (
          <div className="invoice-line" key={promo.id}>
            <span>{`Promo ${promo.name}`}</span>
            <span className="promo-value fw-bold">{promo.displayAmount}</span>
          </div>
        ))}
        <hr />
        <h4 className="d-flex justify-content-between">
          <span>Total</span>
          {data.displayTotalPrice}
        </h4>
      </Col>
    </>
  )
}

export default OrdersArea
