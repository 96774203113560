import { Route, Routes } from 'react-router-dom'
import BabyProfile from './BabyProfile'
import Account from './Account'
import MyBabies from './MyBabies'
import AccountLayout from './AccountLayout'
import BabyProfileLayout from './BabyProfileLayout'
import EditBabyQuestion from './EditBabyQuestion'
import withAuthorization from '../../components/containers/withAuthorization'
import MyKits from '../MyKits'
import KitTracking from './KitTracking'
import BabyDashboard from './BabyDashboard'

const AccountArea = () => {
  return (
    <div>
      <Routes>
        
        <Route path="/" element={<AccountLayout />}>
          <Route path="/" index element={<MyBabies />} />
          <Route path="/settings" element={<Account />} />
          <Route path="/kits" element={<MyKits />} />
          <Route path="/kits/tracking/:orderNumber" element={<KitTracking />} />
        </Route>
        <Route path="/my-babies" element={<BabyProfileLayout />}>
          <Route path="/my-babies/:childId" element={<BabyProfile />} />
          <Route path="/my-babies/:childId/:questionSlug" element={<EditBabyQuestion />} />
          <Route path="/my-babies/:childId/dashboard" element={<BabyDashboard />} />
        </Route>
      </Routes>
    </div>
  )
}

export default withAuthorization(AccountArea)
