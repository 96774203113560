import { useEffect, useState } from 'react'
import { useNavigate, useParams, createSearchParams } from 'react-router-dom'
import DateQuestion from '../../components/questions/DateQuestion'
import OptionQuestion from '../../components/questions/OptionQuestion'
import QuestionModal from '../../components/questions/QuestionModal'
import Button from '../../components/ui/button/Button'
import Input from '../../components/ui/forms/Input'
import Typography from '../../components/ui/typography/Typography'
import { makeRequest } from '../../services/requestHandler'

const EditBabyQuestion = () => {
  const { childId, questionSlug } = useParams();
  const navigate = useNavigate();
  const [question, setQuestion] = useState(null);
  const [answer, setAnswer] = useState(null);
  const [value, setValue] = useState(null);
  const [error, setError] = useState(null);
  const variant = question?.variants[0]

  const renderQuestionType = () => {
    const { answer_type, options, id } = question;
    
    switch (answer_type) {
      case 'date': {
        const dateDefaultValue = answer?.value?.[0];
        return (
          <DateQuestion
            onChange={setValue}
            setError={setError}
            defaultValue={dateDefaultValue}
          />
        );
      }
      case 'option': {
        if (!options) {
          return null;
        }
        const optionValues = options.map(({ id, title }) => ({
          value: id,
          label: title,
        }));
        const optionDefaultValue = answer?.value || [];

        return (
          <OptionQuestion
            type={type}
            options={optionValues}
            name={id}
            id={id}
            onChange={setValue}
            defaultValue={optionDefaultValue}
          />
        );
      }
      case 'string': {
        const { instruction, static_content } = variant;
        const stringDefaultValue = answer?.value?.[0] || '';
        const instructionElement = instruction ? (
          <p>
            <Typography textType="body1" text={instruction} color="grey" block />
          </p>
        ) : null;

        return (
          <div className="question-answer">
            {instructionElement}
            <Input
              placeholder={static_content}
              onChange={(event) => setValue([event.target.value])}
              defaultValue={stringDefaultValue}
            />
          </div>
        );
      }
      default:
        return null;
    }
  };

  const updateAnswer = () => {
    const updateAnswerRequest = () => makeRequest({
      url: `/api/v1/answers/${answer.id}`,
      method: 'PUT',
      body: JSON.stringify({ value }),
      authType: 'auth0',
    });

    const editBabyNameRequest = () => {
      return makeRequest({
        url: `/api/v1/accounts/${childId}`,
        method: 'PUT',
        body: JSON.stringify({ title: value[0] }),
        authType: 'auth0',
      })
    }

    if (questionSlug === 'babys_name') {
      return Promise.all([
        updateAnswerRequest(),
        editBabyNameRequest(),
      ])
    }

    return updateAnswerRequest()
  };

  const addAnswer = () => {
    const body = {
      value,
      question_id: question.id,
      account_id: childId,
    };

    return makeRequest({
      url: '/api/v1/answers',
      method: 'POST',
      body: JSON.stringify(body),
      authType: 'auth0',
    });
  };

  const onSaveClick = async () => {
    try {
      answer?.id
        ? await updateAnswer()
        : await addAnswer()
      navigate({
        pathname: `/account/my-babies/${childId}`,
        search: createSearchParams({'view_all': 1}).toString()
      })
    } catch (error) {
      setError(error.message)
    } 
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [questionRes, answerRes] = await Promise.all([
          makeRequest({
            url: `/api/v1/questions?question_slug=${questionSlug}`,
            authType: 'auth0',
          }),
          makeRequest({
            url: `/api/v1/answers?question_slug=${questionSlug}&account_id=${childId}`,
            authType: 'auth0',
          }),
        ]);

        const [question] = questionRes.data;
        const [answer] = answerRes.data;

        if (question) setQuestion(question);
        if (answer) setAnswer(answer);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, [questionSlug, childId]);

  if (!question || !variant) {
    return null
  }

  const type = question.settings.find(item => item === 'multi')
    ? 'checkbox'
    : 'radio'

  return (
    <>
      <div className="question-card question-card-edit">
        <div className="question-body">
          {question.image_mobile_url && <img src={question.image_mobile_url} className="question-image img-fluid d-md-none" alt="" />}
          {question.image_desktop_url && <img src={question.image_desktop_url} className="question-image img-fluid d-none d-md-block" alt="" />}
          <div className="question-title">{variant.title}</div>
          <div dangerouslySetInnerHTML={{ __html: variant.body.body }} className="body1 block" />
          <div className="question-text">{variant.question_text}</div>
          {renderQuestionType()}
          {error && <span>{error}</span>}
        </div>

        <div className="question-footer">
          {variant.info?.body && (
            <QuestionModal
              title={variant.title}
              text={variant.info.body}
            />
          )}
          <Button
            label="Save"
            onClick={onSaveClick}
            fullWidth
          />
        </div>
      </div>
      <button className="question-why-we-ask mx-auto fw-700" onClick={() => navigate(-1)}>Cancel</button>
    </>
  )
}

export default EditBabyQuestion
