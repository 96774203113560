import normalizeCart from '../../utils/normalizations/normalize-cart';
import ensureToken from '../ensureToken';
import { makeRequest } from '../requestHandler';

const { makeClient, createAxiosFetcher, extractSuccess } = window.SpreeSDK

const client = makeClient({
  host: window.location.origin,
  createFetcher: createAxiosFetcher.default
})

const cartParams = {
  include: [
    'line_items',
    'line_items.variant',
    'line_items.variant.product',
    'line_items.variant.product.images',
    'line_items.variant.images',
    'line_items.variant.option_values',
    'line_items.variant.product.option_types',
    'promotions',
    'shipping_address',
    'billing_address',
    'shipments',
    'shipments.selected_shipping_rate',
    'shipments.shipping_rates',
    'shipments.stock_location',
    'payments',
    'payments.payment_method',
    'payments.source',
    'line_items.line_item_units',
    'line_items.line_item_units.labels',
    'line_items.line_item_units.qr_codes',
  ].join(','),
  image_transformation: {
    quality: 100,
    size: '160x160',
  }
}

const createCart = async (props) => {
  const response = await extractSuccess(client.cart.create(props))

  return normalizeCart(response, response.data)
}

const showCart = async (props) => {
  const response = await extractSuccess(client.cart.show({
    ...props,
    ...cartParams
  }))

  return normalizeCart(response, response.data)
}

const addItem = async (props) => {
  const response = await extractSuccess(client.cart.addItem({
    ...props,
    ...cartParams
  }))

  return normalizeCart(response, response.data)
}

const setQuantity = async (props) => {
  const response = await extractSuccess(client.cart.setQuantity({
    ...props,
    ...cartParams
  }))

  return normalizeCart(response, response.data)
}

const removeItem = async (props) => {
  const response = await extractSuccess(client.cart.removeItem({
    ...props,
    ...cartParams
  }, props.id))

  return normalizeCart(response, response.data)
}

const applyCouponCode = async (props) => {
  const response = await makeRequest({
    url: '/api/v2/storefront/cart/apply_coupon_code',
    method: 'PATCH',
    body: JSON.stringify({
      ...props,
      ...cartParams
    })
  })

  return normalizeCart(response, response.data)
}

const removeCouponCode = async (props) => {
  const response = await extractSuccess(client.cart.removeCouponCode({
    ...props,
    ...cartParams
  }))

  return normalizeCart(response, response.data)
}

const associateGuestCart = async (props) => {
  const response = await extractSuccess(client.cart.associateGuestCart({
    ...props,
    ...cartParams
  }))

  localStorage.removeItem('orderToken')
  return normalizeCart(response, response.data)
}

const orderUpdate = async (props) => {
  const response = await extractSuccess(client.checkout.orderUpdate({
    ...props,
    ...cartParams
  }))

  return normalizeCart(response, response.data)
}

const orderAdvance = async () => {
  const token = ensureToken()
  const response = await extractSuccess(client.checkout.advance({
    ...token,
    ...cartParams
  }))

  return normalizeCart(response, response.data)
}

const orderNext = async () => {
  const token = ensureToken()
  const response = await extractSuccess(client.checkout.orderNext({
    ...token,
    ...cartParams
  }))

  return normalizeCart(response, response.data)
}

const orderComplete = async () => {
  const token = ensureToken()
  try {
    const response = await extractSuccess(client.checkout.complete({
      ...token,
      ...cartParams
    }))
  
    return normalizeCart(response, response.data)
  } catch (error) {
    throw new Error(error.summary)
  }
}

const paymentMethods = async (props) => {
  return await extractSuccess(client.checkout.paymentMethods({
    ...props
  }))
}

const addPayment = async (props) => {
  const response = await extractSuccess(client.checkout.addPayment({
    ...props,
    ...cartParams
  }))

  return normalizeCart(response, response.data)
}

const emptyCart = async (props) => {
  const response = await extractSuccess(client.checkout.emptyCart({
    ...props,
    ...cartParams
  }))

  return normalizeCart(response, response.data)
}

const getOrders = async () => {
  const token = ensureToken()
  const response = await extractSuccess(client.account.completedOrdersList({
    ...token,
    ...cartParams
  }))

  return response.data?.map(order => normalizeCart(response, order))
}

const getOrderByNumber = async (orderNumber) => {
  const token = ensureToken()
  const response = await extractSuccess(client.account.completedOrder({
    order_number: orderNumber,
    ...token,
    ...cartParams
  }))

  return normalizeCart(response, response.data)
}

export default {
  showCart,
  createCart,
  addItem,
  setQuantity,
  removeItem,
  applyCouponCode,
  removeCouponCode,
  associateGuestCart,
  orderUpdate,
  orderNext,
  orderAdvance,
  orderComplete,
  paymentMethods,
  addPayment,
  emptyCart,
  getOrders,
  getOrderByNumber,
}
