import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { buildQueryString } from '../../services/apiService'
import { getHeaderConfig } from '../../services/requestHandler'

export const accountApi = createApi({
  reducerPath: 'accountApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/v1/accounts',
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('auth0Token')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
  
      return headers
    },
  }),
  tagTypes: ['Account'],
  providesTags: (result, error, id) => [{ type: 'Account', id }],
  endpoints: (builder) => ({
    getAccounts: builder.query({
      query: (params) => {
        const queryString = buildQueryString(params);
        return `${queryString ? `?${queryString}` : ''}`;
      },
      providesTags: ['Account'],
      transformResponse: (response) => response.data,
      transformErrorResponse: (
        response,
      ) => response.status,
    }),
    getAccountById: builder.query({
      query: (id) => `${id}`,
      transformResponse: (response) => response.data,
      transformErrorResponse: (
        response,
      ) => response.status,
    }),
    deleteAccount: builder.mutation({
      query: (id) => ({
        url: `${id}`,
        method: 'DELETE',
        credentials: 'include',
        headers: getHeaderConfig(false, 'auth0')
      }),
      invalidatesTags: ['Account']
    })
  }),
})

export const {
  useGetAccountsQuery,
  useGetAccountByIdQuery,
  useDeleteAccountMutation,
} = accountApi;
