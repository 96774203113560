function HamburgerMenuIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="48"
      fill="none"
      viewBox="0 0 46 48"
    >
      <path
        stroke="#444"
        strokeLinecap="round"
        strokeWidth="3"
        d="M12.5 15L32.5 15"
      ></path>
      <path
        stroke="#444"
        strokeLinecap="round"
        strokeWidth="3"
        d="M12.5 23L32.5 23"
      ></path>
      <path
        stroke="#444"
        strokeLinecap="round"
        strokeWidth="3"
        d="M12.5 31L32.5 31"
      ></path>
    </svg>
  );
}

export default HamburgerMenuIcon;
