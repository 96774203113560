import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import { clearProduct, fetchProduct, selectProduct } from '../../store/slices/products/singleProductSlice';
import ProductView from '../../components/product/ProductView/ProductView'

const Product = () => {
  const dispatch = useDispatch()
  const { slug } = useParams();
  const { data, loading, error } = useSelector(selectProduct)

  useEffect(() => {
    dispatch(fetchProduct({ id: slug }))

    return () => dispatch(clearProduct())
  }, [dispatch, slug])

  if (error) {
    return 'Error'
  }

  if (loading || !data) {
    return 'Loading'
  }

  return (
    <ProductView product={data} />
  )
}

export default Product
