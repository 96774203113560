const GlossaryItem = ({ data }) => {
  return (
    <div className="row mb-4" key={data.id}>
      <div className="col-12 col-lg-3 text-lg-end"><b>{data.term}</b></div>
      <div className="col-12 col-lg-9">{data.definition}</div>
    </div>
  )
}

export default GlossaryItem
