import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { Container, Modal } from 'react-bootstrap'
import Button from '../../../components/ui/button/Button';
import DirectionLineRight from '../../../components/ui/icons/DirectionLineRight';
import Link from '../../../components/ui/link/Link';
import { useContentful } from '../../../hooks/useContentful';

const KnowledgeLibrarySignedOut = () => {
  const { data } = useContentful({
    'sys.id[in]': '5qavDQ9PzJm8qD9KAyo03i,1fSxcf0KcTCW4rUgIpiEA4,7HI8tgh9KHQlIDIQNomiEi,408DESqEGFrqt9JGMtCuYF',
    convertToObject: true,
  })

  if (!data) return null
  
  const {
    button_sign_in,
    knowledge_library_faded_background_desktop,
    knowledge_library_faded_background_mobile,
    knowledge_library_signed_out_title,
  } = data;
  const bgImage = window.innerWidth < 992 ? knowledge_library_faded_background_mobile : knowledge_library_faded_background_desktop;

  return (
    <Container>
      <div className="knowledge-library-placeholder" style={{ backgroundImage: `url(${bgImage?.fields.image.fields.file.url })` }}>
        <Modal contentClassName="question-modal" centered show>
          <Modal.Header className="pb-6">
            <Modal.Title bsPrefix="knowledge-library-modal__title">{documentToPlainTextString(knowledge_library_signed_out_title.fields.content)}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="pb-6">
            <div className="question-modal-text justify-content-center d-flex">
              <Link to={`/auth/sign-in?redirect_url=${window.location.pathname}`}>
                <Button
                  label={documentToPlainTextString(button_sign_in.fields.content)}
                  rightIcon={<DirectionLineRight />}
                />
              </Link>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </Container>
  )
}

export default KnowledgeLibrarySignedOut
