function UserPlusIcon() {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20 26.25V23.75C20 22.4239 19.4732 21.1521 18.5355 20.2145C17.5979 19.2768 16.3261 18.75 15 18.75H6.25C4.92392 18.75 3.65215 19.2768 2.71447 20.2145C1.77678 21.1521 1.25 22.4239 1.25 23.75V26.25" stroke="#444444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.625 13.75C13.3864 13.75 15.625 11.5114 15.625 8.75C15.625 5.98858 13.3864 3.75 10.625 3.75C7.86358 3.75 5.625 5.98858 5.625 8.75C5.625 11.5114 7.86358 13.75 10.625 13.75Z" stroke="#444444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M25 10V17.5" stroke="#444444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M28.75 13.75H21.25" stroke="#444444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

export default UserPlusIcon;
