import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import Button from '../ui/button/Button'
import { useNavigate } from 'react-router-dom'
import Typography from '../ui/typography/Typography'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import DirectionLineRight from '../ui/icons/DirectionLineRight'

const DashboardOtherRecipesCard = ({
  data,
}) => {
  const navigate = useNavigate()
  const {
    title,
    titleAndText,
    subtitleAndText,
    subtext,
    image,
    buttonText,
  } = data.fields;

  return (
    <div className="card dashboard-card d-lg-block">
      <div className="overflow-auto">
        <img src={image[0].fields.file.url} alt="" className="float-lg-start me-lg-6 mb-4 mb-lg-2 dashboard-card__image" />
        <div className="dashboard-card__text">
          {title && <Typography textType="heading4" font="Moranga" className="mb-4" text={documentToPlainTextString(title)} block />}
          {documentToReactComponents(titleAndText)}
          {documentToReactComponents(subtitleAndText)}
          {documentToReactComponents(subtext)}
        </div>
      </div>

      <div className="px-6 px-lg-0">
        <Button
          label={buttonText}
          type="secondary"
          className="mt-6"
          onClick={() => navigate('/knowledge-library', { state: { studiesExpanded: true } })}
          rightIcon={<DirectionLineRight />}
          fullWidth
        />
      </div>
    </div>
  )
}

export default DashboardOtherRecipesCard
