import ensureToken from './ensureToken';

const getAuthHeader = (authType) => {
  return authType === 'auth0'
    ? getAuth0Token()
    : getSpreeAuthToken()
}

const getSpreeAuthToken = () => {
  const { bearer_token, order_token } = ensureToken()

  if (bearer_token) {
    return { authorization: `Bearer ${bearer_token}` }
  } else if (order_token) {
    return { 'X-Spree-Order-Token': order_token }
  }

  return undefined
}

const getAuth0Token = () => {
  const token = localStorage.getItem('auth0Token')

  return token ? { authorization: `Bearer ${token}` } : undefined
}

export const getHeaderConfig = (isPublic, authToken) => {
  const headers = {
    'Content-Type': 'application/json',
    accept: 'application/json, text/plain, */*'
  }

  return isPublic
    ? headers
    : { ...headers, ...getAuthHeader(authToken) }
}

export const makeRequest = async (request) => {
  const { url, method = 'GET', body, isPublic, authType = 'spree' } = request;
  const headers = getHeaderConfig(isPublic, authType)

  const response = await fetch(url, {
    method,
    body,
    headers
  })

  const data = await response.json()
  if (!response.ok) throw new Error(data.error)

  return data
}
