import IconPropTypes from './IconPropTypes';

function ChevronLeft() {
  return (
    <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 18L2 10L10 2" stroke="#444444" strokeWidth="2.66667" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

ChevronLeft.propTypes = IconPropTypes

export default ChevronLeft;
