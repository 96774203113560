import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const ColoredText = ({
  content,
  color,
}) => {
  const Default = ({ children }) => <span>{children}</span>;
  const Colored = ({ children }) => <span className={`text-${color}`}>{children}</span>;

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <Colored>{text}</Colored>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Default>{children}</Default>,
    },
    renderText: (text) => {
      return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);
    },
  };

  return documentToReactComponents(content, options);
}

export default ColoredText;
