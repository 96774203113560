const ensureToken = () => {
  const bearerToken = localStorage.getItem('accessToken')
  if (bearerToken) {
    return {
      bearer_token: bearerToken
    }
  }

  const orderToken = localStorage.getItem('orderToken')

  if (orderToken) {
    return {
      order_token: orderToken
    }
  }

  return null
}

export default ensureToken
