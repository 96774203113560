import { useState, useEffect } from 'react';
import { makeRequest } from '../services/requestHandler';
import QuestionItem from '../components/questions/QuestionItem';
import QuizHeader from '../components/questions/QuizHeader';
import { useNavigate } from 'react-router-dom';
import { parse, differenceInMonths } from 'date-fns';

import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { QUIZ_SLUGS } from '../constants/pageSlugs';
import { fetchContentfulPage } from '../services/apiService';

const WaitlistQuiz = () => {
  const navigate = useNavigate();
  const [variants, setVariants] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [values, setValues] = useState();
  const [contentfulData, setContentfulData] = useState(null);

  useEffect(() => {
    fetchContentfulPage(QUIZ_SLUGS)
      .then((res) => {
        setContentfulData(res.data);
      })

    makeRequest({
      url: '/api/v1/question_categories',
      isPublic: true
    })
    .then((response) => {
      const category = response.data?.find(item => item.title === 'quiz')

      makeRequest({
        url: `/api/v1/question_variants?category_id=${category.id}`,
        isPublic: true
      }).then((response) => {
        setVariants(response.data.sort((a, b) => a.question.position - b.question.position));
      })
    })
  }, [])

  if (!variants || !contentfulData) {
    return <>Loading</>
  }

  const {
    quiz
  } = contentfulData;

  const onFinishQuiz = () => {
    const dateQuestion = variants.find(item => item.question.answer_type === 'date');
    const childDate = values[dateQuestion.id][0];
    const parsedDate = parse(childDate, 'dd/MM/yyyy', new Date());
    const monthsDifference = differenceInMonths(new Date(), parsedDate); 
    
    let result = 'just-starting-out';
    if (monthsDifference > 15 && monthsDifference <= 30) {
      result = 'transitioning-system';
    } else if (monthsDifference > 31) {
      result = 'stable-and-strong';
    }

    navigate(`/quiz-results?result=${result}`);
  }

  const onSubmit = (variant, value) => {
    setValues((values) => ({
      ...values,
      [variant.id]: value
    }));

    try {
      const answer = {
        value: value,
        question_id: variant.question_id,
      }
  
      makeRequest({
        url: '/api/v1/answers',
        method: 'POST',
        body: JSON.stringify(answer),
        isPublic: true,
      }).then(() => {
        setActiveStep(activeStep + 1);
        window.scrollTo(0, 0);
      });
    } catch (ex) {
      console.log(ex.message);
    }
  }

  return (
    <div className="page-wrap waitlist-questions-page">
      <QuizHeader
        step={activeStep}
        maxStep={variants?.length}
        onBackClick={() => setActiveStep(activeStep -1)}
        titlePrefix={documentToPlainTextString(quiz?.content)}
      />
      <>
        {variants.map((variant, index) => (
          <QuestionItem
            key={index}
            variant={variant}
            activeStep={activeStep}
            maxStep={variants.length - 1}
            onSubmit={onSubmit}
            onFinish={onFinishQuiz}
          />
        ))[activeStep]}
      </>
    </div>
  )
}

export default WaitlistQuiz;
