import clsx from 'clsx';
import PropTypes from 'prop-types';
import Typography from '../typography/Typography';

const Checkbox = ({
  label,
  name,
  onChange,
  value,
  checked,
  wrapClassName,
  labelClassName,
  register,
}) => {
  let additionalProps = {};
  if (register) {
    additionalProps = {
      ...register(name)
    }
  }

  return (
    <label className={clsx('form-control-wrap', { [`${wrapClassName}`]: wrapClassName })}>
      <input
        className="form-control-input"
        name={name}
        type="checkbox"
        value={value}
        onChange={onChange}
        checked={checked}
        {...additionalProps}
      />
      <span className="form-control-checkmark" />
      <Typography textType="textField" text={label} className={labelClassName} />
    </label>
  )
}

Checkbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  wrapClassName: PropTypes.string,
}

export default Checkbox
