import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import SpreeService from '../../../services/spreeService';
import { storeState } from '../../../constants/storeState';

const initialState = {
  data: [],
  status: storeState.idle,
  error: ''
}

export const fetchCountries = createAsyncThunk(
  'countries/fetchCountries',
  async () => await SpreeService.getCountries()
)

export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchCountries.pending]: (state) => {
      state.status = storeState.loading
      state.error = ''
    },
    [fetchCountries.fulfilled]: (state, action) => {
      state.data = action.payload
      state.status = storeState.loaded
    },
    [fetchCountries.rejected]: (state, action) => {
      state.status = storeState.failed
      state.error = action.error.message
    }
  }
})

export const selectCountries = state => state.global.countries

export default countriesSlice.reducer
