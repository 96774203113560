import { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import DashboardTextCard from '../../../../components/dashboard/DashboardTextCard'
import Button from '../../../../components/ui/button/Button'
import Typography from '../../../../components/ui/typography/Typography'
import { useContentful } from '../../../../hooks/useContentful'
import { getDynamicText } from '../dashboardHelpers';

const RashBacteria = ({
  images,
  userData,
  title,
}) => {
  const { client } = useContentful();
  const [pageData, setPageData] = useState({});
  const {
    contentful_slugs,
    summary
  } = userData;

  const { data: slugData } = useContentful({
    content_type: 'recommendationsText',
    'fields.slug[in]': contentful_slugs.join(','),
    convertToObject: true,
  })

  useEffect(() => {
    const getAbcdeCards = async () => {
      const response = await client.getEntries({
        content_type: 'recommendationsForPresenceOfRashBacteria'
      })

      setPageData(response.items[0].fields);
    }

    getAbcdeCards();
  }, [])

  const renderBoldText = (content) => {
    const Default = ({ children }) => <span>{children}</span>;
  
    const options = {
      renderMark: {
        [MARKS.BOLD]: (text) => <Default>{text}</Default>,
      },
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <>{children}</>,
      },
    };
  
    return documentToReactComponents(content, options);
  }

  return (
    <div>
      {(images) && (
        <Col xs={12}>
          <DashboardTextCard
            image={images[`presence_rash_bacteria_image_${summary}`].fields.image.fields.file.url}
            title={title}
            text={getDynamicText(contentful_slugs, slugData)}
          />
          <hr className="mt-8 mb-6" />
        </Col>
      )}

      <Col xs={12}>
        <div className="p-6">
          <Typography textType="heading4" font="Moranga" className="mb-6" text={pageData.titlePreventDiaperRash} />
          <div className="gap-4 d-flex flex-column">
            <div className="card abcde-card">
              <img src={pageData.stepAImage?.fields.file.url} alt="" />
              <div>
                <Typography textType="heading5" text={renderBoldText(pageData.stepATitle)} />
                <Typography textType="body1" text={pageData.stepAText} />
              </div>
            </div>

            <div className="card abcde-card">
              <img src={pageData.stepBImage?.fields.file.url} alt="" />
              <div>
                <Typography textType="heading5" text={renderBoldText(pageData.stepBTitle)} />
                <Typography textType="body1" text={pageData.stepBText} />
              </div>
            </div>

            <div className="card abcde-card">
              <img src={pageData.stepCImage?.fields.file.url} alt="" />
              <div>
                <Typography textType="heading5" text={renderBoldText(pageData.stepCTitle)} />
                <Typography textType="body1" text={pageData.stepCText} />
              </div>
            </div>

            <div className="card abcde-card">
              <img src={pageData.stepDImage?.fields.file.url} alt="" />
              <div>
                <Typography textType="heading5" text={renderBoldText(pageData.stepDTitle)} />
                <Typography textType="body1" text={pageData.stepDText} />
              </div>
            </div>

            <div className="card abcde-card">
              <img src={pageData.stepEImage?.fields.file.url} alt="" />
              <div>
                <Typography textType="heading5" text={renderBoldText(pageData.stepETitle)} />
                <Typography textType="body1" text={pageData.stepEText} />
              </div>
            </div>
          </div>

          <Button
            label={pageData.buttonText}
            type="secondary"
            className="mt-6"
            fullWidth
          />
        </div>
      </Col>
    </div>
  )
}

export default RashBacteria
