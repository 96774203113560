import IconPropTypes from './IconPropTypes';

function DirectionLineRight({
  size = 16,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 16 16"
    >
      <path d="M2 8h12M9 3l5 5-5 5"></path>
    </svg>
  );
}

DirectionLineRight.propTypes = IconPropTypes

export default DirectionLineRight;
