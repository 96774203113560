import IconPropTypes from './IconPropTypes';

function RecommendationsIcon({
  size = 54,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 70 70"
    >
      <path
        stroke="#44C8F5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.1"
        d="M38.733 14.458h-3.816a2.606 2.606 0 00.776-1.848 2.61 2.61 0 00-5.22 0c0 .724.295 1.38.777 1.848h-3.817a2.878 2.878 0 00-2.874 2.874 2.878 2.878 0 002.874 2.874h11.3a2.878 2.878 0 002.875-2.874 2.878 2.878 0 00-2.874-2.874v0zM36.961 54.016H21.919A2.917 2.917 0 0119 51.096V20.502a2.917 2.917 0 012.92-2.92h.972M43.554 17.574h1.071a2.917 2.917 0 012.92 2.919V41.2M25.525 29.462h-.287M29.621 29.462h11.987M25.525 36.877h-.287M29.621 36.877h11.987M25.525 44.285h-.287M29.621 44.285h9.482M50.199 58.928a8.026 8.026 0 10-6.143-14.83 8.026 8.026 0 006.143 14.83z"
      ></path>
      <path
        stroke="#44C8F5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.1"
        d="M42.83 51.444l3.19 3.19 5.402-5.408"
      ></path>
    </svg>
  );
}

RecommendationsIcon.propTypes = IconPropTypes

export default RecommendationsIcon;
