import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { storeState } from '../../../constants/storeState';
import SpreeService from '../../../services/spreeService';

const initialState = {
  data: [],
  meta: {},
  status: storeState.idle,
  error: ''
}

export const fetchProducts = createAsyncThunk(
  'product/fetchAllProducts',
  async (props) => await SpreeService.getProducts(props)
)

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchProducts.pending]: (state) => {
      state.status = storeState.loading
      state.error = ''
    },
    [fetchProducts.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.meta = action.payload.meta
      state.status = storeState.loaded
    },
    [fetchProducts.rejected]: (state, action) => {
      state.error = action.error.message
      state.status = storeState.failed
    }
  }
})

export const selectProducts = state => state.product.list

export default productsSlice.reducer
