function MicrosoftIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      fill="none"
      viewBox="0 0 33 32"
    >
      <path fill="#F35325" d="M0.5 0H15.642V15.068H0.5z"></path>
      <path
        fill="#81BC06"
        d="M17.359 0H32.501000000000005V15.068H17.359z"
      ></path>
      <path
        fill="#81BC06"
        d="M17.359 0H32.501000000000005V15.068H17.359z"
      ></path>
      <path
        fill="#81BC06"
        d="M17.359 0H32.501000000000005V15.068H17.359z"
      ></path>
      <path
        fill="#FFBA08"
        d="M17.359 16.932H32.501000000000005V32H17.359z"
      ></path>
      <path fill="#05A6F0" d="M0.5 16.932H15.642V32H0.5z"></path>
    </svg>
  );
}

export default MicrosoftIcon;
