function InstagramIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      fill="none"
      viewBox="0 0 35 35"
    >
      <path
        fill="#44C8F5"
        fillRule="evenodd"
        d="M0 17.5C0 7.835 7.835 0 17.5 0S35 7.835 35 17.5 27.165 35 17.5 35 0 27.165 0 17.5z"
        clipRule="evenodd"
        opacity="0.1"
      ></path>
      <path
        fill="#44C8F5"
        fillRule="evenodd"
        d="M17.501 8.166c-2.535 0-2.853.012-3.848.057-.994.045-1.672.203-2.266.434a4.57 4.57 0 00-1.653 1.076 4.582 4.582 0 00-1.077 1.653c-.232.594-.39 1.272-.434 2.265-.044.996-.056 1.314-.056 3.849s.011 2.852.056 3.847c.046.994.203 1.672.434 2.266a4.573 4.573 0 001.076 1.653 4.57 4.57 0 001.653 1.077c.594.231 1.273.389 2.266.434.996.045 1.313.056 3.848.056s2.852-.01 3.848-.056c.993-.046 1.672-.203 2.266-.434a4.566 4.566 0 001.652-1.077c.52-.519.839-1.04 1.078-1.653.23-.594.387-1.272.433-2.265.045-.996.057-1.313.057-3.848s-.012-2.853-.057-3.849c-.046-.993-.204-1.671-.433-2.265a4.582 4.582 0 00-1.078-1.653 4.561 4.561 0 00-1.652-1.076c-.595-.231-1.274-.389-2.268-.434-.995-.045-1.312-.057-3.848-.057h.003zm-.837 1.682h.837c2.492 0 2.788.01 3.772.054.91.042 1.404.194 1.733.322.435.169.746.37 1.072.698.327.326.529.637.699 1.073.127.329.28.822.321 1.732.045.984.054 1.28.054 3.77 0 2.492-.01 2.787-.054 3.771-.042.91-.194 1.404-.321 1.733-.17.435-.372.745-.699 1.072a2.885 2.885 0 01-1.072.698c-.33.128-.823.28-1.733.322-.984.044-1.28.054-3.772.054s-2.787-.01-3.771-.054c-.91-.043-1.404-.195-1.733-.322a2.891 2.891 0 01-1.074-.698A2.893 2.893 0 0110.225 23c-.128-.329-.28-.823-.322-1.733-.044-.984-.053-1.28-.053-3.772s.009-2.786.053-3.77c.042-.91.194-1.404.322-1.733.169-.435.372-.747.698-1.073a2.897 2.897 0 011.074-.699c.329-.128.823-.28 1.733-.321.86-.04 1.194-.05 2.934-.053v.002zm5.82 1.55a1.12 1.12 0 10-.001 2.24 1.12 1.12 0 000-2.24zM17.5 12.707a4.793 4.793 0 100 9.586 4.793 4.793 0 000-9.586zm0 1.682a3.111 3.111 0 110 6.222 3.111 3.111 0 010-6.222z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default InstagramIcon;
