import createGetAbsoluteImageUrl from '../create-get-absolute-image-url';
import getMediaGallery from '../get-media-gallery';

const { findSingleRelationshipDocument, findRelationshipDocuments } = window.SpreeSDK

const isColorProductOption = (productOptionType) => {
  return productOptionType.attributes.presentation === 'Color'
}

const normalizePromotion = (
  spreeSuccessResponse,
  spreePromotion
) => {
  return {
    id: spreePromotion.id,
    description: spreePromotion.attributes.description,
    name: spreePromotion.attributes.name,
    amount: parseFloat(spreePromotion.attributes.amount),
    displayAmount: spreePromotion.attributes.display_amount,
    code: spreePromotion.attributes.code
  }
}

const normalizeVariant = (
  spreeSuccessResponse,
  spreeVariant
) => {
  const spreeProduct = findSingleRelationshipDocument(
    spreeSuccessResponse,
    spreeVariant,
    'product'
  )

  if (spreeProduct === null) {
    throw new Error(
      `Couldn't find product for variant with id ${spreeVariant.id}.`
    )
  }

  const spreeVariantImageRecords =
    findRelationshipDocuments(
      spreeSuccessResponse,
      spreeVariant,
      'images'
    )

  let lineItemImage

  const variantImage = getMediaGallery(
    spreeVariantImageRecords,
    createGetAbsoluteImageUrl()
  )[0]

  if (variantImage) {
    lineItemImage = variantImage
  } else {
    const spreeProductImageRecords =
      findRelationshipDocuments(
        spreeSuccessResponse,
        spreeProduct,
        'images'
      )

    const productImage = getMediaGallery(
      spreeProductImageRecords,
      createGetAbsoluteImageUrl()
    )[0]

    lineItemImage = productImage
  }

  const image = lineItemImage

  return {
    id: spreeVariant.id,
    sku: spreeVariant.attributes.sku,
    name: spreeProduct.attributes.name,
    requiresShipping: true,
    price: parseFloat(spreeVariant.attributes.price),
    listPrice: parseFloat(spreeVariant.attributes.price),
    displayPrice: spreeVariant.attributes.display_price,
    image,
    isInStock: spreeVariant.attributes.in_stock,
    availableForSale: spreeVariant.attributes.purchasable,
    ...(spreeVariant.attributes.weight === '0.0'
      ? {}
      : {
          weight: {
            value: parseFloat(spreeVariant.attributes.weight),
            unit: 'Kg',
          },
        })
  }
}

const normalizeLineItem = (
  spreeSuccessResponse,
  spreeLineItem
) => {
  const variant = findSingleRelationshipDocument(
    spreeSuccessResponse,
    spreeLineItem,
    'variant'
  )

  const product = findSingleRelationshipDocument(
    spreeSuccessResponse,
    variant,
    'product'
  )

  const lineItemUnits = findRelationshipDocuments(
    spreeSuccessResponse,
    spreeLineItem,
    'line_item_units'
  )

  const path = `${product.attributes.slug}`

  const spreeOptionValues = findRelationshipDocuments(
    spreeSuccessResponse,
    variant,
    'option_values'
  )

  const options = spreeOptionValues.map(
    (spreeOptionValue) => {
      const spreeOptionType =
        findSingleRelationshipDocument(
          spreeSuccessResponse,
          spreeOptionValue,
          'option_type'
        )

      if (spreeOptionType === null) {
        throw new Error(
          `Couldn't find option type of option value with id ${spreeOptionValue.id}.`
        )
      }

      const label = isColorProductOption(spreeOptionType)
        ? spreeOptionValue.attributes.name
        : spreeOptionValue.attributes.presentation

      return {
        id: spreeOptionValue.id,
        name: spreeOptionType.attributes.presentation,
        value: label,
      }
    }
  )

  return {
    id: spreeLineItem.id,
    lineItemUnits: lineItemUnits,
    variantId: variant.id,
    productId: product.id,
    name: spreeLineItem.attributes.name,
    quantity: spreeLineItem.attributes.quantity,
    displayPrice: spreeLineItem.attributes.display_price,
    discounts: [], // TODO: Implement when the template starts displaying them.
    path,
    variant: normalizeVariant(spreeSuccessResponse, variant),
    options,
  }
}


const normalizeShipment = (
  spreeSuccessResponse,
  shipment
) => {
  const selectedShippingRate = findSingleRelationshipDocument(spreeSuccessResponse, shipment, 'selected_shipping_rate')

  return {
    displayFinalPrice: shipment.attributes.display_final_price,
    finalPrice: shipment.attributes.final_price,
    free: shipment.attributes.free,
    number: shipment.attributes.number,
    shippedAt: shipment.attributes.shipped_at,
    state: shipment.attributes.state,
    trackingUrl: shipment.attributes.tracking_url,
    name: selectedShippingRate.attributes.name
  }
}

const normalizePayment = (
  spreeSuccessResponse,
  payment
) => {
  let result;

  const cardData = findSingleRelationshipDocument(
    spreeSuccessResponse,
    payment,
    'source'
  );

  if (cardData) {
    const { cc_type, last_digits } = cardData.attributes;

    result = {
      ...result,
      cardData: {
        type: cc_type,
        lastDigits: last_digits
      }
    }
  }
  
  return result;
}

const normalizeCart = (
  spreeSuccessResponse,
  spreeCart
) => {
  const lineItems = findRelationshipDocuments(
      spreeSuccessResponse,
      spreeCart,
      'line_items'
    )
    .map(lineItem => normalizeLineItem(spreeSuccessResponse, lineItem))

  const promotions = findRelationshipDocuments(
      spreeSuccessResponse,
      spreeCart,
      'promotions'
    )
    .map(promotion => normalizePromotion(spreeSuccessResponse, promotion))
  
  let shippingAddress
  if (spreeCart.relationships.shipping_address.data !== null) {
    const spreeShippingAddress = findSingleRelationshipDocument(
      spreeSuccessResponse,
      spreeCart,
      'shipping_address'
    )
    if (spreeShippingAddress)  {
      shippingAddress = spreeShippingAddress.attributes
    }  
  }

  let billingAddress
  if (spreeCart.relationships.billing_address.data !== null) {
    const spreeBillingAddress = findSingleRelationshipDocument(
      spreeSuccessResponse,
      spreeCart,
      'billing_address'
    )
    if (spreeBillingAddress === null) {
      throw new Error('No billing address')
    }

    if (spreeBillingAddress) {
      billingAddress = spreeBillingAddress.attributes
    }
  }

  let shippingMethod = findRelationshipDocuments(
    spreeSuccessResponse,
    spreeCart,
    'shipments'
  ).map(shipment => normalizeShipment(spreeSuccessResponse, shipment))[0]

  let payment;
  if (spreeCart.relationships.payments.data.length > 0) {
    const spreePayment = findSingleRelationshipDocument(
      spreeSuccessResponse,
      spreeCart,
      'payments'
    )

    payment = normalizePayment(spreeSuccessResponse, spreePayment);
  }

  return {
    id: spreeCart.id,
    state: spreeCart.attributes.state,
    orderNumber: spreeCart.attributes.number,
    createdAt: spreeCart.attributes.created_at.toString(),
    completedAt: spreeCart.attributes?.completed_at?.toString() || '',
    currency: { code: spreeCart.attributes.currency },
    taxesIncluded: true,
    lineItems,
    lineItemsSubtotalPrice: parseFloat(spreeCart.attributes.item_total),
    subtotalPrice: parseFloat(spreeCart.attributes.item_total),
    totalPrice: parseFloat(spreeCart.attributes.total),
    taxTotal: parseFloat(spreeCart.attributes.tax_total),
    displayItemTotal: spreeCart.attributes.display_item_total,
    displayTaxTotal: spreeCart.attributes.display_tax_total,
    displayTotalPrice: spreeCart.attributes.display_total,
    displayShipPrice: spreeCart.attributes.display_ship_total,
    displayDiscountPrice: spreeCart.attributes.display_promo_total,
    customerId: spreeCart.attributes.token,
    email: spreeCart.attributes.email,
    promotions,
    shippingAddress,
    billingAddress,
    shippingMethod,
    payment,
  }
}

export { normalizeLineItem }

export default normalizeCart
