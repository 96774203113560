import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import EmailIcon from '../../components/ui/icons/EmailIcon';
import GoogleIcon from '../../components/ui/icons/GoogleIcon'
import MicrosoftIcon from '../../components/ui/icons/MicrosoftIcon'
import { fetchContentfulPage } from '../../services/apiService';
import { getContentfulContent } from '../../utils/get-contentful-content';
import { useCustomAuth0 } from './CustomAuth0Provider'

const slugs = [
  'sign_up_title',
  'sign_up_with_your_email',
  'sign_up_with_google',
  'sign_up_with_microsoft',
  'already_have_account',
  'sign_in_title',
];

const SignUpMethod = () => {
  const navigate = useNavigate();
  const {
    loginWithGoogle,
    loginWithMicrosoft,
  } = useCustomAuth0();

  const [pageData, setPageData] = useState({})
  const [error, setError] = useState(false)

  useEffect(() => {
    fetchContentfulPage(slugs)
    .then((res) => {
      setPageData(res.data);
      setError(false);
    }).catch(() => {
      setError(true);
    })
  }, [])

  return (
    <div className="login-box">
      <div className="login-header">
        <h3>{getContentfulContent(pageData.sign_up_title)}</h3>
      </div>
      <div className="login-body">
        <button
          type="button"
          id="btn-google"
          className="email-sign-in-btn"
          onClick={() => navigate('/auth/sign-up')}
        >
          <EmailIcon />
          {getContentfulContent(pageData.sign_up_with_your_email)}
        </button>
        <button
          type="button"
          id="btn-google"
          className="social-sign-in-btn"
          onClick={() => loginWithGoogle()}
        >
          <GoogleIcon />
          {getContentfulContent(pageData.sign_up_with_google)}
        </button>
        <button
          type="button"
          id="btn-microsoft"
          className="social-sign-in-btn d-none"
          onClick={() => loginWithMicrosoft()}
        >
          <MicrosoftIcon />
          {getContentfulContent(pageData.sign_up_with_microsoft)}
        </button>
      </div>
      <div className="login-footer">
        {getContentfulContent(pageData.already_have_account)}
        <button
          type="button"
          id="btn-signup"
          className="btn btn-default btn-block"
          onClick={() => navigate('/auth/sign-in')}
        >
          {getContentfulContent(pageData.sign_in_title)}
        </button>
      </div>
    </div>
  )
}

export default SignUpMethod
