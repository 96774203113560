import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

export const getDynamicText = (keys, slugs) => {
  let dynamicText = [];

  keys.forEach(item => {
    if (!slugs || !slugs[item]) return;
    dynamicText.push(documentToPlainTextString(slugs[item].fields.recommentationText))
  })

  return dynamicText.join(' ')
}
