function InfoCircleIcon({
  width = 16,
  height = 16
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 17 16"
    >
      <g
        stroke="#444"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#clip0_8904_70085)"
      >
        <path d="M8.499 14.666a6.667 6.667 0 100-13.333 6.667 6.667 0 000 13.333zM8.5 10.667V8M8.5 5.333h.007"></path>
      </g>
      <defs>
        <clipPath id="clip0_8904_70085">
          <path fill="#fff" d="M0 0H16V16H0z" transform="translate(.5)"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default InfoCircleIcon;
