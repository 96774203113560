import { createContext } from 'react'


const ContentfulContext = createContext();

const ContentfulProvider = (props) => {
  const { children, context } = props;
  const Context = context || ContentfulContext;

  return (
    <Context.Provider value={props}>
      {children}
    </Context.Provider>
  )
}



export { ContentfulProvider, ContentfulContext }
