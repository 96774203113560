import { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import Layout from './components/Layout';
import HomeMvp from './pages/HomeMvp';
import Terms from './pages/Terms';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Ccpa from './pages/Ccpa';
import NotFound from './pages/404';
import FAQ from './pages/FAQ';
import DashboardArea from './pages/wellness/dashboard';
import Experience from './pages/wellness/Experience';
import KnowledgeLibrary from './pages/wellness/knowledge-library/KnowledgeLibrary';
import Product from './pages/products/Product';
import Activation from './pages/products/Activation';
import Activated from './pages/products/Activated';
import Payments from './pages/Payments';
import { login, loginFailed } from './store/slices/auth/authSlice';
import useCart from './hooks/cart/useCart';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import Questions from './pages/Questions';
import UIElements from './pages/test/UIElements';
import SignOut from './pages/SignOut';
import CookiesPolicy from './pages/CookiesPolicy';
import WaitlistQuiz from './pages/WaitlistQuiz';
import WaitlistSignupConfirmation from './pages/WaitlistSignupConfirmation';
import QuizResults from './pages/QuizResults'
import ForgotPassword from './pages/auth/ForgotPassword';
import SignIn from './pages/auth/SignIn';
import SignUp from './pages/auth/SignUp';
import AuthLayout from './components/Layout/AuthLayout';
import ResetPasswordSuccess from './pages/auth/ResetPasswordSuccess';
import SignUpMethod from './pages/auth/SignUpMethod';
import Recipe from './pages/wellness/knowledge-library/Recipe/Recipe';
import KnowledgeLibrarySignedOut from './pages/wellness/knowledge-library/KnowledgeLibrarySignedOut';
import Activity from './pages/wellness/knowledge-library/Activity/Activity';
import AccountArea from './pages/account';
import RegisterBabyPage from './pages/RegisterBabyPage';
import EmailVerification from './pages/auth/EmailVerification';

const Root = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    getAccessTokenSilently,
    isLoading,
    isAuthenticated,
  } = useAuth0()

  const authenticated = !isLoading && isAuthenticated;

  useCart()

  useEffect(() => {
    const hasAccessToken = window.location.hash.includes('access_token');
  
    if (hasAccessToken) {
      const redirectUrl = sessionStorage.getItem('redirectUrl');
      sessionStorage.removeItem('redirectUrl');
      navigate(redirectUrl, { replace: true })
    }
  }, [navigate]);

  useEffect(() => {
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        dispatch(login(token))
      } catch (e) {
        dispatch(loginFailed());
      }
    })();
  }, [dispatch, getAccessTokenSilently]);

  return (
    <Routes>
      <Route path="test/ui" element={<UIElements />} />
      <Route path="/" element={<Layout />}>
        <Route index element={<HomeMvp />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="contact-us" element={<Contact />} />
        <Route path="faq" element={<FAQ />} />
        <Route path="terms-of-service" element={<Terms />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="cpra" element={<Ccpa />} />
        <Route path="ccpa" element={<Ccpa />} />
        <Route path="cookies-policy" element={<CookiesPolicy />} />
        <Route path="wellness/dashboard/*" element={<DashboardArea />} />
        <Route path="wellness/experience" element={<Experience />} />
        <Route path="register-baby-quiz" element={<RegisterBabyPage />} />
        <Route path="account/*" element={<AccountArea />} />
        <Route path="activation" element={<Activation />} />
        <Route path="activation/:code" element={<Activation />} />
        <Route path="activated" element={<Activated />} />
        <Route path="kits/:slug" element={<Product />} />
        <Route path="payments" element={<Payments />} />
        <Route path="cart" element={<Cart />} />
        <Route path="checkout" element={<Checkout />} />
        <Route path="signout" element={<SignOut />} />
        <Route path="verified" element={<WaitlistSignupConfirmation />} />
        <Route path="verify-email" element={<EmailVerification />} />
        <Route path="quiz-results" element={<QuizResults />} />
        <Route path="waitlist-quiz" element={<WaitlistQuiz />} />
        <Route path="knowledge-library" element={authenticated ? <KnowledgeLibrary /> : <KnowledgeLibrarySignedOut />} />
        <Route path="knowledge-library/recipe/:recipeUrl" element={authenticated ? <Recipe /> : <KnowledgeLibrarySignedOut />} />
        <Route path="knowledge-library/activity/:activityUrl" element={authenticated ? <Activity /> : <KnowledgeLibrarySignedOut />} />
        <Route path="*" element={<NotFound />} />
      </Route>
      <Route path="quiz" element={<Questions />} />
      <Route path="auth" element={<AuthLayout />}>
        <Route path="sign-in" element={<SignIn />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="sign-up" element={<SignUp />} />
        <Route path="sign-up-method" element={<SignUpMethod />} />
        <Route path="forgot-password-success" element={<ResetPasswordSuccess />} />
      </Route>
    </Routes>
  )
}

export default Root;
