import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Button from '../components/ui/button/Button';
import Link from '../components/ui/link/Link';
import { useSearchParams } from 'react-router-dom'
import { SIGNUP_SLUGS } from '../constants/pageSlugs';
import { fetchContentfulPage } from '../services/apiService';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import Typography from '../components/ui/typography/Typography';

const WaitlistSignupConfirmation = () => {
  const [contentfulData, setContentfulData] = useState(null);
  const [searchParams] = useSearchParams();
  const waitlistCode = searchParams.get('code');

  useEffect(() => {
    fetchContentfulPage(SIGNUP_SLUGS)
      .then((res) => {
        setContentfulData(res.data);
      })
  }, [])
  const waitlistUpdateUrl = `/api/v1/waitlists/${waitlistCode}`;
  useEffect(() => {
    fetch(waitlistUpdateUrl, {
      method: 'PATCH'
    })
  }, [])

  if (!contentfulData) {
    return (
      <>
        Loading...
      </>
    )
  }

  const {
    success_title,
    sign_up_success_text1,
    sign_up_success_text2,
    button_about_us,
    button_order_test_kit
  } = contentfulData;

  return (
    <div id='signup-confirmation'>
      <Container>
        <Row className="mb-4">
          <Col>
            <div className="text-center summary-section pt-10">
              <Typography
                textType="heading3" 
                text={success_title && documentToPlainTextString(success_title?.content)} 
                className="mb-4 font-Moranga d-block" />
              <Typography
                textType="heading4" 
                text={sign_up_success_text1 && documentToPlainTextString(sign_up_success_text1?.content)} 
                className="mb-4 mx-auto d-block" />
              <Typography
                textType="body" 
                text={sign_up_success_text2 && documentToPlainTextString(sign_up_success_text2?.content)} 
                className="px-3 mb-6 mx-auto d-block fw-700" />
            </div>
            <hr className='d-md-none' />
            <div className="navigation py-2">
              <Link to="/kits/baby-microbiome-gut-health-test-kit">
                <Button className='mx-auto' role="button" label={button_order_test_kit && documentToPlainTextString(button_order_test_kit.content)} type="primary" />
              </Link>
            </div>
            <div className="navigation py-2">
              <Link to="/about-us">
                <Button className='mx-auto' role="button" label={button_about_us && documentToPlainTextString(button_about_us.content)} type="secondary" />
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default WaitlistSignupConfirmation
