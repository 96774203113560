import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  useFloating,
  useInteractions,
  offset,
  shift,
  useClick,
  useDismiss,
  FloatingPortal,
  autoPlacement,
  autoUpdate,
  size,
} from '@floating-ui/react-dom-interactions';
import ChevronDownIcon from '../icons/ChevronDown';
import Input from './Input';

const Dropdown = ({
  placeholder,
  options = [],
  value,
  onChange,
}) => {
  const [open, setOpen] = useState(false);
  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({ mainAxis: 4, alignmentAxis: 0 }),
      shift(),
      autoPlacement({
        allowedPlacements: ['top', 'bottom'],
      }),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
          });
        },
        padding: 8
      })
    ],
  });

  const { getReferenceProps, getFloatingProps } = useInteractions(
    [
      useClick(context, { toggle: false }),
      useDismiss(context)
    ]
  )

  const onOptionClick = (value) => {
    onChange(value)
    setOpen(false)
  }

  return (
    <>
      <button
        {...getReferenceProps({
          ref: reference,
        })}
      >
        <Input
          placeholder={placeholder}
          suffixIcon={<ChevronDownIcon />}
          value={value}
        />
      </button>
      <FloatingPortal>
        {open && (
          <div
            {...getFloatingProps({
              ref: floating,
              style: {
                position: strategy,
                left: x ?? 0,
                top: y ?? 0,
              },
            })}
            className="dropdown-list"
          >
            <ul>
              {options.map((option) => (
                <li
                  key={option.value}
                  onClick={() => onOptionClick(option.value)}
                  role="presentation"
                  className="dropdown-list--item"
                >
                  {option.label}
                </li>
              ))}
            </ul>
          </div>
        )}
      </FloatingPortal>
    </>
  )
}

Dropdown.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.string,
}

export default Dropdown
