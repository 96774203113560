import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../ui/forms/Checkbox';
import Radio from '../ui/forms/Radio';
import Typography from '../ui/typography/Typography'

const OptionQuestion = ({
  type,
  options,
  name,
  onChange,
  defaultValue = [],
}) => {
  const [values, setValues] = useState(defaultValue)

  useEffect(() => {
    onChange(values)
  }, [onChange, values])

  const onCheckboxChange = (event) => {
    const { value } = event.target;
    if (values.some(item => item === value)) {
      return setValues(values.filter(item => item !== value))
    }

    setValues([...values, value])
  }

  const onRadioChange = (event) => {
    setValues([event.target.value])
  }

  return (
    <div>
      <Typography className="mt-4" textType="body1" text={type === 'checkbox' ? 'Select all that apply.' : '(Please select one)'} color="grey" />
      <div className="question-answer">
        {options.map((option, index) => {
          return type === 'radio' ? (
            <Radio
              label={option.label}
              key={index}
              name={name}
              value={option.label}
              onChange={onRadioChange}
            />
          ) : (
            <Checkbox
              label={option.label}
              key={index}
              name={name}
              value={option.label}
              onChange={onCheckboxChange}
            />
          )
        })}
      </div>
    </div>
  )
}

OptionQuestion.propTypes = {
  type: PropTypes.oneOf(['radio', 'checkbox']),
  options: PropTypes.array,
  name: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.array,
}

export default OptionQuestion
