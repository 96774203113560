import { createAsyncThunk } from '@reduxjs/toolkit'
import ensureToken from '../../../services/ensureToken'
import spreeCart from '../../../services/spree/spreeCart'
import SpreeService from '../../../services/spreeService'

export const createCart = createAsyncThunk(
  'cart/create',
  async () => {
    const token = ensureToken()
    return await SpreeService.createCart(token)
  })

export const getCart = createAsyncThunk(
  'cart/get',
  async (props, thunkAPI) => {
    try {
      return await SpreeService.showCart(props)
    } catch (error) {
      localStorage.removeItem('orderToken')
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const associateGuestCart  = createAsyncThunk(
  'cart/associateGuestCart',
  async (orderToken) => {
    const token = ensureToken()

    return await SpreeService.associateGuestCart({
      ...token,
      guest_order_token: orderToken
    })
  }
)

export const addItem = createAsyncThunk(
  'cart/addItem',
  async (props) => {
    return await SpreeService.addItem({
      ...props
    })
  }
)

export const setQuantity = createAsyncThunk(
  'cart/setQuantity',
  async (props) => {
    return await SpreeService.setQuantity({
      ...props
    })
  }
)

export const removeItem = createAsyncThunk(
  'cart/removeItem',
  async (props) => {
    return await SpreeService.removeItem({
      ...props
    })
  }
)

export const applyCouponCode = createAsyncThunk(
  'cart/applyCouponCode',
  async (props, thunkAPI) => {

    try {
      return await SpreeService.applyCouponCode(props)
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const removeCouponCode = createAsyncThunk(
  'cart/removeCouponCode',
  async (props) => {
    const token = ensureToken()

    return await SpreeService.removeCouponCode({
      ...token,
      ...props
    })
  }
)

export const orderUpdate = createAsyncThunk(
  'cart/orderUpdate',
  async (props) => {
    const token = ensureToken()

    await SpreeService.orderUpdate({
      ...token,
      order: props,
    })

    return await SpreeService.orderNext()
  }
)

export const addPayment = createAsyncThunk(
  'cart/addPayment',
  async (props, thunkAPI) => {
    const token = ensureToken()

    try {
      await SpreeService.addPayment({
        ...token,
        ...props
      })

      return await SpreeService.orderNext()
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const getPaymentMethods = createAsyncThunk(
  'cart/getPaymentMethods',
  async () => {
    const token = ensureToken()

    return await SpreeService.paymentMethods({
      ...token      
    })
  }
)

export const completeOrder = createAsyncThunk(
  'cart/completeOrder',
  async () => await spreeCart.orderComplete()
)
