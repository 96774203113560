import { makeRequest } from './requestHandler';
import queryString from 'query-string';

export const fetchContentfulPage = async (slugs) => {
  const params = queryString.stringify({ slugs }, {
    arrayFormat: 'bracket'
  })
  
  return await makeRequest({
    url: `/api/v1/pages?${params}`,
    isPublic: true,
  });
};

export const fetchFaqPage = async () => {
  return await makeRequest({
    url: `/api/v1/faqs`,
    isPublic: true,
  })
}

export function buildQueryString(params) {
  const queryParamArray = Object.entries(params)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`);

  return queryParamArray.join('&');
}
