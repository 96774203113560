import React from "react";

function SuccessIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <g
        stroke="#439F6E"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#clip0_14547_1653)"
      >
        <path d="M18.335 9.234V10a8.333 8.333 0 11-4.942-7.616"></path>
        <path d="M18.333 3.333L10 11.675l-2.5-2.5"></path>
      </g>
      <defs>
        <clipPath id="clip0_14547_1653">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default SuccessIcon;
