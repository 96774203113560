import { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import clsx from 'clsx';
import { storeState } from '../constants/storeState';
import { cartSelector } from '../store/slices/cart/cartSlice';
import { fetchCountries, selectCountries } from '../store/slices/global/countriesSlice';
import ShippingStep from '../components/checkout/ShippingStep';
import BillingStep from '../components/checkout/BillingStep';
import OrdersArea from '../components/checkout/OrdersArea';
import OrderConfirmation from '../components/checkout/OrderConfirmation';
import GuestEmail from '../components/checkout/GuestEmail';
import OrderSummary from '../components/checkout/OrderSummary';
import DirectionArrowLeftIcon from '../components/ui/icons/DirectionArrowLeftIcon';
import Typography from '../components/ui/typography/Typography';

const Checkout = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isAuthenticated } = useAuth0()
  const [searchParams] = useSearchParams()
  const urlStep = searchParams.get('step')
  const { data, status } = useSelector(cartSelector)
  const { status: countriesStatus } = useSelector(selectCountries)
  const [checkoutStep, setCheckoutStep] = useState('address')
  const titleUrl = {
    address: {
      url: 'cart',
      title: 'Shipping Address'
    },
    payment: {
      url: 'checkout?step=address',
      title: 'Billing Address'
    }
  }

  useEffect(() => {
    if (status === storeState.failed && data === null) {
      navigate('/', { replace: true })
    }
  }, [status, data, navigate])

  useEffect(() => {
    if (!urlStep && data?.state) {
      navigate(`/checkout?step=${data.state}`, { replace: true })
    }
  }, [urlStep, navigate, data?.state])

  useEffect(() => {
    if (data?.lineItems.length === 0) {
      navigate('/', { replace: true })
    } else {
      setCheckoutStep(urlStep)
    }
  }, [urlStep, navigate, data])

  useEffect(() => {
    if (countriesStatus === storeState.idle) {
      dispatch(fetchCountries())
    }
  }, [dispatch, countriesStatus])

  useEffect(() => {
    if (!isAuthenticated && !data?.email) {
      setCheckoutStep('guest')
    }
  }, [isAuthenticated, data])

  if (data == null) {
    return <>Loading...</>
  }

  if (checkoutStep === 'confirmation') {
    return <OrderConfirmation />;
  }

  return (
    <div className="checkout-layout">
      <Container className={"gx-4 gx-md-auto px-3 px-md-6 " + checkoutStep} id="checkout">
        {titleUrl[checkoutStep] &&
          <>
            <Link to={`/${titleUrl[checkoutStep].url}`} className="back-btn d-none d-md-block">
              <DirectionArrowLeftIcon />
            </Link>
            <Typography textType="heading3" text={titleUrl[checkoutStep].title} className="mt-4 mb-12 d-none d-md-block font-Moranga" />
          </>
        }
        {['address', 'payment'].includes(checkoutStep) &&
          <Card body className="checkout-card p-0 p-md-6" border="light">
            <Row className={clsx('gx-md-5', { 'row-cols-2': checkoutStep === 'summary' })}>
              <Col md={7} xs={12} className="order-1 order-md-0">
                {checkoutStep === 'address' ? <ShippingStep /> : <BillingStep />}
              </Col>
              <Col id='invoice-section' md={5} xs={12} className='mt-10 mt-md-0 d-none d-sm-block'>
                <OrdersArea />
              </Col>
            </Row>
          </Card>
        }
        {checkoutStep === 'guest' && <GuestEmail />}
        {checkoutStep === 'confirm' && <OrderSummary />}
      </Container>
    </div>
  )
}

export default Checkout
