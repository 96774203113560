import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Col, Container, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom';
import Accordion from '../../../../components/KnowledgeLibrary/Accordion'
import VisitSourceLink from '../../../../components/KnowledgeLibrary/VisitSourceLink';
import Button from '../../../../components/ui/button/Button';
import Checkbox from '../../../../components/ui/forms/Checkbox';
import DirectionLineRight from '../../../../components/ui/icons/DirectionLineRight';
import Typography from '../../../../components/ui/typography/Typography'
import { useContentful } from '../../../../hooks/useContentful'
import normalizeRecipe from '../../../../utils/normalizations/normalize-recipe';
import RecipeCard from '../RecipeCard';
import WellnessAreaBlock from '../WellnessAreaBlock';
import { useEffect, useState } from 'react';
import { fetchContentfulPage } from '../../../../services/apiService';
import ScrollspyNav from 'react-scrollspy-nav';
import Link from '../../../../components/ui/link/Link';

const slugs = [
  'category_recipes',
  'category_exercises',
  'category_studies',
  'category_glossary',
];

const Recipe = () => {
  const [pageData, setPageData] = useState({})
  const [error, setError] = useState(false)
  const { recipeUrl } = useParams()
  const navigate = useNavigate()
  const { data } = useContentful({
    content_type: 'recipes',
    'fields.url': recipeUrl,
    limit: 1,
  })

  useEffect(() => {
    fetchContentfulPage(slugs)
    .then((res) => {
      setPageData(res.data);
      setError(false);
    }).catch(() => {
      setError(true);
    })
  }, [])

  const { data: moreRecipes, fetched: recipesFetched } = useContentful({
    content_type: 'recipes',
    'fields.url[nin]': recipeUrl,
    limit: 6,
  })

  const sanitizedRecipes = moreRecipes?.items?.map(item => normalizeRecipe(item))

  if (!data) {
    return <>Loading...</>
  }

  if (data.total === 0) {
    return <>Not found</>
  }

  const onShowAllClick = () => {
    navigate('/knowledge-library', {
      state: {
        recipesExpanded: true,
      }
    })
  }

  const recipe = normalizeRecipe(data.items[0]);
  const ingredients = documentToPlainTextString(recipe.listOfIngredients).split(/\r?\n/).filter(item => item.length > 0);

  return (
    <div className="knowledge-library">
      <Container>
        <Row>
          <Col lg={3} className="sidebar">
            <div className="sidebar-content d-none">
              <Typography textType="heading3" className="mb-1 font-Moranga" text="Ben’s Biome Analysis" block />
              <Typography textType="body1B" className="text-darkGrass" text="Last Updated: 2/01/2022" block />
              <hr />
              <Typography textType="heading4" text="Categories" className="mb-6" />
              <WellnessAreaBlock activeArea={recipe.wellnessArea?.id} />
            </div>
            <div className="sidebar-content">
              <Typography textType="heading3" className="mb-8 font-Moranga" text="Knowledge Library" block />

              <div className="categories">
                <Typography textType="heading4" className="mb-6" text="Category" />
                <div className="list">
                  <ul>
                    <li><a href="/knowledge-library#recipes">{pageData.category_recipes?.name}</a></li>
                    <li><a href="/knowledge-library#exercises">{pageData.category_exercises?.name}</a></li>
                    <li><a href="/knowledge-library#studies">{pageData.category_studies?.name}</a></li>
                    <li><a href="/knowledge-library#glossary">{pageData.category_glossary?.name}</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={9} xs={12} className="recipe-page">
            <div className="recipe-summary">
              <div className="recipe-image" style={{ backgroundImage: `url(${recipe.recipePicture.file.url})`}} />
              <div className="recipe-summary-content">
                <Typography textType="heading2" text={recipe.title} className="font-Moranga" />
                <div className="recipe-area">
                  <img src={recipe.wellnessArea.wellnessAreaIcon.fields.file.url} alt="" />
                  <Typography textType="body2B" text={recipe.wellnessArea.name} />
                </div>
                <div className="recipe-details">
                  <div className="recipe-details__item">
                    <img src={recipe.foodTypeIcon.file.url} alt="" />
                    <span className="recipe-details__title">{recipe.foodTypeTitle}</span>
                    <span className="recipe-details__value">{recipe.foodTypeValue}</span>
                  </div>
                  <div className="recipe-details__item">
                    <img src={recipe.servingsIcon.file.url} alt="" />
                    <span className="recipe-details__title">{recipe.servingsTitle}</span>
                    <span className="recipe-details__value">{recipe.servingsValue}</span>
                  </div>
                  <div className="recipe-details__item">
                    <img src={recipe.totalTimeIcon.file.url} alt="" />
                    <span className="recipe-details__title">{recipe.totalTimeTitle}</span>
                    <span className="recipe-details__value">{recipe.totalTimeValue}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="recipe-igredients">
              <Accordion title={recipe.ingredientsTitle}>
                <div className="ingredients-tip">{documentToReactComponents(recipe.tip)}</div>
                {ingredients.map((ingredient, index) => (
                  <div key={index} className="recipe-ingredients__item">
                    <Checkbox key={ingredient} label={ingredient} wrapClassName="px-0" />
                  </div>
                ))}
              </Accordion>
            </div>

            <div className="recipe-steps">
              <Accordion title={recipe.stepsTitle}>
                {documentToReactComponents(recipe.listOfSteps)}
                {recipe.note && (
                  <div className="recipe-steps-note body2R">
                    {documentToReactComponents(recipe.note)}
                  </div>
                )}
              </Accordion>
            </div>

            <VisitSourceLink link={recipe.sourceLink} />

            <hr className="m-0" />

            <Typography textType="heading3" text="Explore more recipes" className="font-Moranga" />
            <div className="recipes-list">
              {recipesFetched && sanitizedRecipes?.map((recipe, index) => (
                <RecipeCard
                  key={index}
                  data={recipe}
                />
              ))}
            </div>

            <Button
              label="See all recipes"
              type="secondary"
              rightIcon={<DirectionLineRight />}
              onClick={onShowAllClick}
              fullWidth
            />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Recipe
