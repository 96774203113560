import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { ACCOUNT_NAV_SLUGS } from '../../constants/pageSlugs';
import { fetchContentfulPage } from '../../services/apiService';
import { Nav } from 'react-bootstrap';
import Typography from '../ui/typography/Typography';
import { useAuth0 } from '@auth0/auth0-react';
import Link from '../ui/link/Link';

const AccountMenu = () => {
  const { user } = useAuth0();
  const [contentfulData, setContentfulData] = useState(null);

  useEffect(() => {
    fetchContentfulPage(ACCOUNT_NAV_SLUGS)
      .then((res) => {
        setContentfulData(res.data);
      })
  }, [])

  if (!contentfulData) {
    return (
      <>
        Loading...
      </>
    )
  }

  const {
    account_settings_title,
    baby_icon_black,
    box_icon_black,
    footer_menu_category_my_babies,
    footer_menu_category_my_kits,
    gear_icon_black,
    hello_first_name
  } = contentfulData

  return (
    <aside className='account-nav w-100 mx-md-6 mx-xl-0'>
      <Typography 
        textType={'heading3'} 
        className="font-Moranga title-text d-none d-md-block mt-md-20 mb-md-10" 
        block 
        text={user && user.given_name ? 
          documentToPlainTextString(hello_first_name?.content).replace("{{first name}}", (user?.given_name))
          :
          documentToPlainTextString(hello_first_name?.content).replace(", {{first name}}", "")
        }
      />
      <Typography 
        textType={'heading2'} 
        className="font-Moranga title-text text-center d-bock d-md-none mt-10 mb-6 mx-auto" 
        block 
        text={user && user.given_name ? 
          documentToPlainTextString(hello_first_name?.content).replace("{{first name}}", (user?.given_name))
          :
          documentToPlainTextString(hello_first_name?.content).replace(", {{first name}}", "")
        }
      />
      <Nav justify variant="tabs" defaultActiveKey="/account" className="flex-md-column px-3 px-md-0" activeKey={window.location.pathname}>
        <Link to="/account" className="nav-link">
          {baby_icon_black?.image && 
            (<img 
              src={baby_icon_black.image.fields.file.url} 
              alt={documentToPlainTextString(footer_menu_category_my_babies?.content)} />)}
          {documentToPlainTextString(footer_menu_category_my_babies?.content)}
        </Link>
        <Link to="/account/kits" className="nav-link">
          {box_icon_black?.image && 
            (<img 
              src={box_icon_black.image.fields.file.url} 
              alt={documentToPlainTextString(footer_menu_category_my_kits?.content)} />)}
          {documentToPlainTextString(footer_menu_category_my_kits?.content)}
        </Link>
        <Link to="/account/settings" className="nav-link">
          {gear_icon_black?.image && 
            (<img 
              src={gear_icon_black.image.fields.file.url} 
              alt={documentToPlainTextString(account_settings_title?.content)} />)}
          {documentToPlainTextString(account_settings_title?.content)}
        </Link>
      </Nav>
    </aside>
  )
}

AccountMenu.propTypes = {
  type: PropTypes.string
}

export default AccountMenu
