import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { REVIEW_SLIDER_SLUGS } from '../../constants/pageSlugs';
import { fetchContentfulPage } from '../../services/apiService';
import Typography from '../ui/typography/Typography';

const ReviewSlider = () => {
  const [sliderData, setSliderData] = useState(null)

  useEffect(() => {
    fetchContentfulPage(REVIEW_SLIDER_SLUGS)
    .then((res) => {
      setSliderData(res.data);
    })
  }, [])
  
  if (!sliderData) return null;

  const {
    landing_review_1,
    landing_review_1_name,
    landing_review_2,
    landing_review_2_name,
    landing_review_3,
    landing_review_3_name,
  } = sliderData;
  
  const settings = {
    slidesToShow: 1,
    dots: true,
    infinite: true,
    centerMode: true,
    variableWidth: true,
    arrow: true,
    className: "slider review-slider variable-width",
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          variableWidth: false,
          centerMode: false,
        }
      }
    ]
  }

  return (
    <Slider
      className="review-slider"
      {...settings}
    >
      <div className="slide-item" key={1} style={{ width: 650 }}>
        {landing_review_1 && <Typography textType="heading5" className="px-6 mb-8 text-center" block text={documentToPlainTextString(landing_review_1.content)} />}
        {landing_review_1_name && <Typography textType="body1" className="mb-8 text-center" block text={documentToPlainTextString(landing_review_1_name.content)} />}
      </div>
      <div className="slide-item" key={2} style={{ width: 650 }}>
        {landing_review_2 && <Typography textType="heading5" className="px-6 mb-8 text-center" block text={documentToPlainTextString(landing_review_2.content)} />}
        {landing_review_2_name && <Typography textType="body1" className="mb-8 text-center" block text={documentToPlainTextString(landing_review_2_name.content)} />}
      </div>
      <div className="slide-item" key={3} style={{ width: 650 }}>
        {landing_review_3 && <Typography textType="heading5" className="px-6 mb-8 text-center" block text={documentToPlainTextString(landing_review_3.content)} />}
        {landing_review_3_name && <Typography textType="body1" className="mb-8 text-center" block text={documentToPlainTextString(landing_review_3_name.content)} />}
      </div>
    </Slider>
  )
}

export default ReviewSlider
