import normalizeCountry from '../utils/normalizations/normalize-country';
import spreeCart from './spree/spreeCart';
import spreeUser from './spree/spreeUser';

const {
  makeClient,
  createAxiosFetcher,
  findSingleRelationshipDocument,
  findRelationshipDocuments,
  extractSuccess,
} = window.SpreeSDK

const client = makeClient({
  host: window.location.origin,
  createFetcher: createAxiosFetcher.default
})

const productParams = {
  include: 'primary_variant,variants,product_properties,images,option_types,variants.option_values',
  image_transformation: {
    quality: 100,
    size: '800x800',
  }
}

export const getProducts = async (props) =>
  await extractSuccess(client.products.list({
    ...props,
    include: 'images'
  }))

export const getProduct = async (props) => {
  const productResponse = await extractSuccess(client.products.show({
    ...props,
    ...productParams
  }))

  const primaryVariant = findSingleRelationshipDocument(productResponse, productResponse.data, 'primary_variant')
  const images = findRelationshipDocuments(productResponse, productResponse.data, 'images')
  const product_properties = findRelationshipDocuments(productResponse, productResponse.data, 'product_properties')

  return {
    ...productResponse,
    data: {
      ...productResponse.data,
      images,
      primaryVariant,
      product_properties
    }
  }
}

export const getToken = async (auth0Token) => (
  await extractSuccess(client.authentication.getToken({
    password: auth0Token
  }))
)

export const refreshToken = async (token) => (
  await extractSuccess(client.authentication.refreshToken({
    refresh_token: token
  }))
)

export const revokeToken = async (token) => (
  await extractSuccess(client.authentication.revokeToken({
    token
  }))
)

const getCountries = async () => {
  const response = await extractSuccess(client.countries.list({
    include: 'checkout_zone_applicable_states'
  }))
  const countries = response.data.map(country => normalizeCountry(response, country))

  return countries
}

const SpreeService = {
  getProduct,
  getProducts,
  getToken,
  refreshToken,
  revokeToken,
  getCountries,
  ...spreeCart,
  ...spreeUser,
}

export default SpreeService
