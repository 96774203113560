function GoogleIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="38"
      fill="none"
      viewBox="0 0 38 38"
    >
      <path
        fill="#FFC107"
        d="M36.978 15.41h-1.477v-.077h-16.5v7.333h10.361C27.851 26.936 23.79 30 19.002 30c-6.075 0-11-4.925-11-11s4.925-11 11-11c2.803 0 5.355 1.058 7.297 2.786L31.485 5.6C28.21 2.548 23.83.667 19 .667 8.877.667.668 8.875.668 19c0 10.124 8.209 18.333 18.333 18.333 10.125 0 18.334-8.209 18.334-18.333 0-1.23-.127-2.43-.357-3.59z"
      ></path>
      <path
        fill="#FF3D00"
        d="M2.781 10.467l6.024 4.417C10.435 10.849 14.382 8 19 8c2.804 0 5.355 1.058 7.297 2.786L31.484 5.6C28.21 2.548 23.83.667 19 .667c-7.042 0-13.149 3.975-16.22 9.8z"
      ></path>
      <path
        fill="#4CAF50"
        d="M19 37.334c4.736 0 9.04-1.813 12.293-4.76l-5.675-4.801A10.917 10.917 0 0119.001 30c-4.769 0-8.818-3.04-10.343-7.284L2.68 27.323c3.034 5.937 9.196 10.01 16.32 10.01z"
      ></path>
      <path
        fill="#1976D2"
        d="M36.977 15.41H35.5v-.076H19v7.333h10.361a11.037 11.037 0 01-3.746 5.107l.002-.002 5.675 4.801c-.402.365 6.041-4.406 6.041-13.573 0-1.23-.126-2.429-.356-3.59z"
      ></path>
    </svg>
  );
}

export default GoogleIcon;
