import { useNavigate, useParams } from 'react-router-dom'

import BrainDevelopment from './recommendations/BrainDevelopment'
import ImmuneSystemDevelopment from './recommendations/ImmuneSystemDevelopment'
import RashBacteria from './recommendations/RashBacteria'
import SuperfoodsIntake from './recommendations/SuperfoodsIntake'
import TummyAgitators from './recommendations/TummyAgitators'
import WeightRegulation from './recommendations/WeightRegulation'
import { useGetDashboardByIdQuery } from './dashboardSlice'
import { useContentful } from '../../../hooks/useContentful'

const imageSlugs = [
  'brain_development_image_more',
  'brain_development_image_avarage',
  'brain_development_image_less',
  'immune_system_image_more',
  'immune_system_image_avarage',
  'immune_system_image_less',
  'healthy_weight_regulation_image_more',
  'healthy_weight_regulation_image_avarage',
  'healthy_weight_regulation_image_less',
  'superfood_intake_image_more',
  'superfood_intake_image_avarage',
  'superfood_intake_image_less',
  'tummy_agitators_image_more',
  'tummy_agitators_image_avarage',
  'tummy_agitators_image_less',
  'presence_rash_bacteria_image_more',
  'presence_rash_bacteria_image_avarage',
  'presence_rash_bacteria_image_less',
]

const Recommendations = () => {
  const { childId, wellnessArea } = useParams()
  const navigate = useNavigate()
  const { data: images } = useContentful({
    content_type: 'randomImage',
    'fields.name[in]': imageSlugs.join(','),
    convertToObject: true
  })

  const { data: resultTitle } = useContentful({
    content_type: 'recommendationPageResult',
    convertToObject: true
  })

  const { data, error, isLoading } = useGetDashboardByIdQuery(childId)
  const userData = data?.find(item => item.contentful_slug === wellnessArea);

  if (isLoading) {
    return <>Loading...</>
  }

  if (error) {
    return <>Something went wrong.</>
  }
  
  const getResultTitle = (result) => {
    const inverted = userData?.targets.some(item => item.target_type !== 'beneficial')
    if (result === 'less') {
      return inverted
      ? resultTitle?.result_excellent_or_low.fields.resultTitle
      : resultTitle?.result_fair_or_elevated.fields.resultTitle
    }
    if (result === 'avarage') {
      return resultTitle?.result_good_or_average.fields.resultTitle
    }
    if (result === 'more') {
      return inverted
        ? resultTitle?.result_fair_or_elevated.fields.resultTitle
        : resultTitle?.result_excellent_or_low.fields.resultTitle
    }

    return 'Results are not ready yet.'
  }

  switch (wellnessArea) {
    case 'wellness_area_brain_development':
      return <BrainDevelopment images={images} userData={userData} title={getResultTitle(userData.summary)} />

    case 'wellness_area_immune_system_development':
      return <ImmuneSystemDevelopment images={images} userData={userData} title={getResultTitle(userData.summary)} />

    case 'wellness_area_healthy_weight_regulation':
      return <WeightRegulation images={images} userData={userData} title={getResultTitle(userData.summary)} />
    
    case 'wellness_area_superfoods_intake':
      return <SuperfoodsIntake images={images} userData={userData} title={getResultTitle(userData.summary)} />

    case 'wellness_area_presence_of_rash_bacteria':
      return <RashBacteria images={images} userData={userData} title={getResultTitle(userData.summary)} />

    case 'wellness_area_presence_of_gut_irritating_bacteria':
      return <TummyAgitators images={images} userData={userData} title={getResultTitle(userData.summary)} />

    default:
      navigate(`/wellness/dashboard/${childId}`)
  }
}

export default Recommendations
