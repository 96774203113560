export const getFromCache = (key) => {
  const cachedData = sessionStorage.getItem(key);
  const cachedDataTimestamp = sessionStorage.getItem(`${key}_timestamp`);

  if (cachedData && cachedDataTimestamp) {
    return {
      data: JSON.parse(cachedData),
      timestamp: parseInt(cachedDataTimestamp, 10),
    };
  }

  return null;
};

export const storeInCache = (key, data) => {
  sessionStorage.setItem(key, JSON.stringify(data));
  sessionStorage.setItem(`${key}_timestamp`, new Date().getTime());
};
