import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { Card } from 'react-bootstrap'
import Link from '../../ui/link/Link'
import Typography from '../../ui/typography/Typography'

const BabyGeneralInfo = ({
  questions,
  answers,
  contentfulData,
}) => {
  const getAnswerBySlug = (slug) => {
    const question = getQuestionBySlug(slug)
    if (!question) return null;
    const answer = answers.find(item => item.question_id === question.question_id);
    if (!answer) return null;

    return answer.value?.join(', ')
  }

  const getQuestionBySlug = (slug) => {
    return questions
      .filter(variant => variant.question.question_slug !== null)
      .find(variant => variant.question.question_slug.title === slug);
  }

  return (
    <Card className='p-6'>
      <div className="mb-4">
        <div className="d-flex justify-content-between align-items-center">
          <Typography textType="heading5" text={documentToPlainTextString(contentfulData?.baby_name.fields.content)} className="mb-2" />
          <Link to={'babys_name'}>
            <img src={contentfulData?.highlight_pen_icon_black?.fields.image.fields.file.url} alt="" />
          </Link>
        </div>
        <Typography textType="heading4" text={getAnswerBySlug('babys_name')} />
      </div>
      <div className="mb-4">
        <div className="d-flex justify-content-between align-items-center">
          <Typography textType="heading5" text={documentToPlainTextString(contentfulData?.birthday.fields.content)} className="mb-2" />
          <Link to={'baby_birthday'}>
            <img src={contentfulData?.highlight_pen_icon_black?.fields.image.fields.file.url} alt="" />
          </Link>
        </div>
        <Typography textType="heading4" text={getAnswerBySlug('baby_birthday')} />
      </div>
    </Card>
  )
}

export default BabyGeneralInfo
