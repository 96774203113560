import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { useState } from 'react'
import { FloatingLabel, Form } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useContentful } from '../../hooks/useContentful'
import Button from '../ui/button/Button'
import Typography from '../ui/typography/Typography'
import ReactPlayer from "react-player";

const slugs = [
  { id: '7DGTA4QICGH3qH7MAc0uP2', name: 'kit_activation_code' },
  { id: 'wOlR90UiHLAVcATUtoheM', name: 'enter_code_manually' },
  { id: '1x5q51aeoQgeWyuI4QgvyB', name: 'code_placeholder' },
  { id: '5SCzCOEZ25z5dY8ryEKEWO', name: 'button_activate' },
  { id: 'ocr3ZxfZZNr0oHy6qUXZR', name: 'how_it_works_video_intro' },
  { id: '4uqb4R09RwYI1r04FAIzAC', name: 'activate_your_kit_video' },
]
const testPattern = /^[A-Za-z0-9.]+$/;

const ActivationForm = ({
  onSuccess,
}) => {
  const [code, setCode] = useState(useParams()?.code || '');
  const [error, setError] = useState(null);

  const handleChangeCode = (event) => {
    setCode(event.target.value);
    setError(null);
  }

  const performKitActivation = (e) => {
    e.preventDefault();

    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    }
    const url = `/api/v1/qr_codes/${code}/validate`

    fetch(url, requestOptions)
    .then(res => res.json())
    .then((response) => {
      if (response) {
        onSuccess(code)
      } else {
        setError('This code is expired or not valid.')
      }
    })
    .catch((error) => {
      setError(error.message)
    });
  }

  const { data } = useContentful({
    'sys.id[in]': slugs.map(i => i.id).join(','),
    convertToObject: true,
  });

  if (!data) return null

  return (
    <div className="activation-card">
      <Form onSubmit={performKitActivation}>
        <Form.Group className="mb-3 text-start">
          <Typography textType="body1B" text={data.enter_code_manually && documentToPlainTextString(data.enter_code_manually?.fields.content)} className="mb-4" />
          <FloatingLabel
            controlId="activationCode"
            label={documentToPlainTextString(data.code_placeholder?.fields.content)}
          >
            <Form.Control
              size="lg"
              className="w-100"
              placeholder={documentToPlainTextString(data.code_placeholder?.fields.content)}
              defaultValue={code}
              value={code}
              onChange={handleChangeCode}
            />
          </FloatingLabel>
          {error && <Form.Text id='error-msg' className="text-danger">{error}</Form.Text>}
          <Button
            label={documentToPlainTextString(data.button_activate?.fields.content)}
            className="mt-10"
            fullWidth
            disabled={!testPattern.test(code)}
            onSubmit={performKitActivation}
          />
          <hr className='my-6 my-md-10'/>
          <Typography 
            textType="heading3"
            text={documentToPlainTextString(data.how_it_works_video_intro?.fields.content)}
            className="mb-4 text-center"
            block
          />
          {data.activate_your_kit_video && (
            <div className='video-wrapper'>
              <ReactPlayer
                url={documentToPlainTextString(data.activate_your_kit_video?.fields.videoUrl)}
                controls
                className="video-responsive"
              />
            </div>
          )}
        </Form.Group>
      </Form>
    </div>
  )
}

export default ActivationForm
