import { useState } from 'react'
import { Button, Col, Form } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { storeState } from '../../constants/storeState'
import { cartSelector, orderUpdate } from '../../store/slices/cart/cartSlice'
import AddressForm from './AddressForm'
import DirectionLineRight from '../ui/icons/DirectionLineRight';
import { EXCLUDED_SHIPPING_US_STATES } from '../../constants/constants'

const ShippingStep = () => {
  const dispatch = useDispatch()
  const [, setSearchParams] = useSearchParams()
  const { data, status } = useSelector(cartSelector)
  const { shippingAddress } = data;
  const [useSavedData, setUseSavedData] = useState(false)
  const { handleSubmit, ...methods } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      country_iso: shippingAddress?.country_iso || 'US',
      phone: shippingAddress?.phone || '+1',
      firstname: shippingAddress?.firstname,
      lastname: shippingAddress?.lastname,
      address1: shippingAddress?.address1,
      city: shippingAddress?.city,
      state_name: shippingAddress?.state_code,
      zipcode: shippingAddress?.zipcode,
    }
  })
  const freeOrder = data.totalPrice === 0;

  const onSubmit = (data) => {
    const requestData = {
      ship_address_attributes: data,
      bill_address_attributes: freeOrder ? data : undefined,
    }

    dispatch(orderUpdate(requestData)).then(() => {
      setSearchParams({ step: freeOrder ? 'confirmation' : 'payment' })
    })
  }

  return (
    <>
      <Col md={12} className='mx-auto'>
        <h3 className='my-3 d-md-none checkout-step-title px-3'>Shipping Address</h3>
        {data.shippingAddress && (
          <Form.Check
            type="checkbox"
            checked={useSavedData}
            onChange={() => setUseSavedData(!useSavedData)}
            id="shipping-use-saved"
            label="Use my saved shipping address"
            className="mt-4"
          />
        )}
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onSubmit)} className="py-1 py-md-0">
            <AddressForm
              data={data.shippingAddress}
              useSaved={useSavedData}
              excludedStates={EXCLUDED_SHIPPING_US_STATES}
            />
            <Col>
            <div className="d-grid gap-2 mt-6">
              <Button
                type="submit"
                onSubmit={handleSubmit(onSubmit)}
                disabled={status === storeState.loading}
                className="btn btn-fh btn-light-blue rounded-pill"
              >
                {freeOrder ? 'Review Order' : 'Continue to Billing'}
                <DirectionLineRight />
              </Button>
            </div>

            </Col>
          </Form>
        </FormProvider>
      </Col>
    </>
  )
}

export default ShippingStep
