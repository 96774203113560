import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export const getContentfulContent = (key, type = 'text') => {
  if (!key) return '';

  if (type === 'rich') {
    return documentToReactComponents(key.content);
  }

  return documentToPlainTextString(key.content);
};

export const getRichContentfulContent = (key) => {
  if (!key || !key.content) return '';

  return documentToReactComponents(key);
}

export const getContentfulImage = (key) => {
  if (!key?.image && !key?.photo) return {};

  if (key.photo) {
    return key.photo?.fields?.file;
  }

  return key.image?.fields?.file;
}
