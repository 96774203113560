import * as contentful from 'contentful';
import normalizeActivity from '../utils/normalizations/normalize-activity';
import normalizeRecipe from '../utils/normalizations/normalize-recipe';
import { getFromCache, storeInCache } from '../services/contentfulCacheManager';

const useKnowledgeLibrary = () => {
  const client = contentful.createClient({
    space: process.env.CONTENTFUL_SPACE_ID,
    environment: 'master',
    accessToken: process.env.CONTENTFUL_API_KEY,
  })

  const fetchData = async (contentType, normalizerFunc, cacheDurationInMinutes = 60) => {
    const cache = getFromCache(contentType);

    if (cache) {
      const currentTime = new Date().getTime();
      const differenceInMinutes = (currentTime - cache.timestamp) / (1000 * 60);

      if (differenceInMinutes < cacheDurationInMinutes) {
        return cache.data;
      }
    }

    const entries = await client.getEntries({ content_type: contentType });
    const sanitizedEntries = entries.items.map(item => normalizerFunc(item));
    storeInCache(contentType, sanitizedEntries);

    return sanitizedEntries;
  };

  const getWellnessAreas = async () => {
    const normalizerFunc = (item) => ({
      id: item.sys.id,
      slug: item.fields.slug,
      name: item.fields.name,
      icon: item.fields.wellnessAreaIcon?.fields.file.url,
      order: item.fields.order,
    });

    return fetchData('wellnessArea', normalizerFunc).then((result) => result.sort((a, b) => a.order - b.order))
  };

  const getRecipes = async () => {
    return fetchData('recipes', normalizeRecipe);
  };

  const getPhysicalActivities = async () => {
    return fetchData('physicalActivities', normalizeActivity);
  };

  const getStudies = async () => {
    const normalizerFunc = (item) => ({
      ...item.fields,
      id: item.sys.id,
      bacteria: item.fields.bacteria?.map(bacteria => bacteria.fields.bacteria),
      studyCategory: item.fields.studyCategory.map(category => category.fields.studyCategory),
      wellnessArea: item.fields.wellnessArea.map(area => ({
        ...area.fields,
        id: area.sys.id,
      })),
    });

    return fetchData('studies', normalizerFunc);
  };

  const getGlossary = async () => {
    const normalizerFunc = (item) => ({
      ...item.fields,
      id: item.sys.id,
      topics: item.fields.topics?.map(topic => topic.fields.topics),
      wellnessAreas: item.fields.wellnessAreas?.map(area => ({
        ...area.fields,
        id: area.sys.id,
      })),
    });

    return fetchData('glossary', normalizerFunc);
  };

  return {
    getRecipes,
    getPhysicalActivities,
    getStudies,
    getGlossary,
    getWellnessAreas,
  };
}

export default useKnowledgeLibrary;
