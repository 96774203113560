import { yupResolver } from '@hookform/resolvers/yup';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import Typography from '../../ui/typography/Typography';
import PencilIcon from '../../ui/icons/PencilIcon';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../ui/button/Button';
import { getUserInfo, updateAccount } from '../../../store/slices/auth/authSlice';
import { customToast } from '../../ui/notification/customToast';

const schema = yup
  .object()
  .shape({
    first_name: yup.string().required('first name is missing.'),
    last_name: yup.string().required('last name is missing.'),
  })
  .required();

const UserNameForm = () => {
  const transitionRef = useRef(null)
  const dispatch = useDispatch()
  const { user } = useSelector(state => state.auth)
  const { first_name, last_name } = user;
  const [isEditing, setIsEditing] = useState(false)
  const [pending, setPending] = useState(false)

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      first_name,
      last_name,
    }
  })

  const resetForm = () => {
    reset({
      first_name: first_name,
      last_name: last_name,
    })
  }

  const onSubmit = async (formData) => {
    try {
      await dispatch(updateAccount({
        user: {
          email: user.email,
          ...formData
        }
      }))
      setPending(false)
      setIsEditing(false)
      dispatch(getUserInfo())
      customToast({
        title: 'Changes saved',
        message: 'Your changes have been successfully saved.',
        type: 'success'
      });
    } catch (error) {
      console.log(error)
      setPending(false)
    }
  }

  const onCancelEditing = () => {
    resetForm();
    setIsEditing(false);
  }

  const toggleEditing = (event) => {
    event.preventDefault();
    if (isEditing) {
      onCancelEditing()
    } else {
      setIsEditing(true);
    }
  };

  const form = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col md={6}>
          <FloatingLabel controlId="firstname" label="First name" className="mb-3">
            <Form.Control
              type="text"
              placeholder="First name"
              {...register("first_name", {
                required: 'First name is required'
              })}
              className="w-100"
            />
          </FloatingLabel>
          {errors.firstname && <Form.Text>{errors.first_name.message}</Form.Text>}
        </Col>
        <Col md={6}>
          <FloatingLabel controlId="lastname" label="Last name" className="mb-3">
            <Form.Control
              type="text"
              placeholder="Last name"
              {...register("last_name", {
                required: 'Last name is required'
              })}
              className="w-100"
            />
            {errors.lastname && <Form.Text>{errors.last_name.message}</Form.Text>}
          </FloatingLabel>
        </Col>
        <Col className="mb-3 mb-lg-0">
          <Button
            label="Save Changes"
            onClick={handleSubmit(onSubmit)}
            disabled={pending}
            fullWidth
          />
        </Col>
        <Col>
          <Button
            label="Cancel"
            onClick={toggleEditing}
            type="secondary"
            fullWidth
          />
        </Col>
      </Row>
    </form>
  )

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <Typography textType="heading5" text="User Name" />
        <button onClick={toggleEditing} className={`edit-button ${isEditing ? 'editing' : ''}`}>
          <span className="edit-button-text">{isEditing ? 'Editing...' : 'Edit'}</span>
          <PencilIcon />
        </button>
      </div>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={isEditing ? 'form' : 'default'}
          timeout={300}
          classNames="form-part"
          nodeRef={transitionRef}
        >
          {isEditing ? (
            <div ref={transitionRef}>
              {form}
            </div>
          ) : (
            <div ref={transitionRef}>
              {first_name || last_name ? (
                <Typography textType="body1" text={`${first_name} ${last_name}`} />
              ) : (
                <Typography textType="body1" text="Set your user name" />
              )}
             
            </div>
          )}
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
}

export default UserNameForm
