import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { storeState } from '../../constants/storeState';
import { associateGuestCart, getCart, setEmptyCart } from '../../store/slices/cart/cartSlice';
import { selectAuthorizationStatus } from "../../store/slices/auth/authSlice";
import ensureToken from '../../services/ensureToken';

const useCart = () => {
  const dispatch = useDispatch()
  const { status: authStatus } = useSelector(selectAuthorizationStatus)

  useEffect(() => {
    if (authStatus === storeState.idle || authStatus === storeState.loading) return null
    const token = ensureToken()

    if (!token) {
      dispatch(setEmptyCart())
    } else {
      if (token.bearer_token && localStorage.getItem('orderToken')) {
        dispatch(associateGuestCart(localStorage.getItem('orderToken'))).then(() => {
          dispatch(getCart(token))
        })
      } else {
        dispatch(getCart(token))
      }
    }
  }, [dispatch, authStatus])
}

export default useCart;
