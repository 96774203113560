import { useEffect, useRef, useState } from 'react';
import Button from '../../../components/ui/button/Button';

function KnowledgeList({
  items,
  id,
  listItemComponent: ListItemComponent,
  title,
  initialItemsVisible,
  listClassName,
  expanded,
}) {
  const ref = useRef();
  const [showMore, setShowMore] = useState(expanded);
  const list = showMore ? items : items.slice(0, initialItemsVisible);

  if (ref.current && expanded) {
    ref.current.scrollIntoView()
  }

  const onShowMoreClick = () => {
    if (showMore) {
      ref.current.scrollIntoView()
    }
    setShowMore(!showMore)
  }

  return (
    <div className="section-card" id={id} ref={ref}>
      <h4 className="mb-4">{title} ({items.length})</h4>
      <div className={listClassName}>
        {list.map(item => (
          <ListItemComponent key={item.id} data={item} />
        ))}
      </div>
      {items.length > initialItemsVisible && (
        <Button
          label={showMore ? "Show less" : "Show more"}
          type="secondary"
          size="medium"
          onClick={onShowMoreClick}
          className="mx-auto mt-8"
        />
      )}
    </div>
  )
}

export default KnowledgeList;
