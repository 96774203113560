import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import Typography from '../ui/typography/Typography'
import { useContentful } from '../../hooks/useContentful';
import clsx from 'clsx';

const slugs = [
  { id: '6cmtRIWWYacKy707MealFi', name: 'kit_tracking_kit_activated_icon_inactive' },
  { id: '76ZnA6FQuQhK8zy8g2B3Lj', name: 'kit_tracking_kit_activated_icon_active' },
  {id: '3i56heUlDLPge3LSi8N2Il', name: 'assign_baby_icon_inactive' },
  { id: 'LNQkGZjOS5iJzLMUSbL9F', name: 'assign_baby_icon_active' },
  { id: '7HCdhHAXekWPGxR7wyFdQb', name: 'answer_questions_icon_inactive' },
  { id: '5dNks0ygjP9ac5mkCfymwy', name: 'answer_questions_icon_active' },
  { id: 'eFHGDQgeJRyulQ2XdtXRp', name: 'kit_activation_title' },
  { id: '38C2T5vtL7r1eeHedY9IQC', name: 'kit_activation_subtitle' },
  { id: '4sbBJP67knNEefud7ARbrC', name: 'activate_your_kit_title' },
  { id: '9xnBFwTVuA2vtc4xrJwbo', name: 'activate_your_kit_text' },
  { id: '6qFeSdI3tqvAAgRCctiMI1', name: 'answer_questions_title' },
  { id: '1FyCd3jhKBsX1ilI1He30V', name: 'answer_questions_text' },
  { id: '4mM1zQ8gO39P5C26xKMmLm', name: 'assign_kit_to_baby_title' },
  { id: '5bXo1oYCaeXevhcbQmr6Nh', name: 'assign_kit_to_baby_text' },
  { id: '12wpIee0tIvGtphxVtsAjY', name: 'kit_activation_register_baby_title' },
  { id: '3oBcU38zjvQPwcMXReZF5P', name: 'kit_activation_register_baby_text' },
]

const ActivationStatusBar = ({
  activeStep,
}) => {
  const { data } = useContentful({
    'sys.id[in]': slugs.map(i => i.id).join(','),
    convertToObject: true,
  });

  if (!data) return null

  const stepsText = [{
    title: documentToPlainTextString(data.activate_your_kit_title?.fields.content),
    text: documentToPlainTextString(data.activate_your_kit_text?.fields.content),
  }, {
    title: documentToPlainTextString(data.assign_kit_to_baby_title?.fields.content),
    text: documentToPlainTextString(data.assign_kit_to_baby_text?.fields.content),
  }, {
    title: documentToPlainTextString(data.kit_activation_register_baby_title?.fields.content),
    text: documentToPlainTextString(data.kit_activation_register_baby_text?.fields.content),
  }, {
    title: documentToPlainTextString(data.answer_questions_title?.fields.content),
    text: documentToPlainTextString(data.answer_questions_text?.fields.content),
  }];

  const getStepClassName = (step) => {
    return clsx('progress-bar-item', {
      'progress-bar-item--completed': step <= activeStep,
    });
  };

  const getImageUrl = (slug, isActiveStep) => {
    const iconName = isActiveStep ? `${slug}_active` : `${slug}_inactive`;
    return data[iconName]?.fields.image.fields.file.url;
  };

  return (
    <div className="activation-card mb-6">
      <div className="activation-progress-bar">
        <div className={getStepClassName(0)}>
          <img src={data.kit_tracking_kit_activated_icon_active?.fields.image.fields.file.url} alt="" />
          <Typography textType="body2" text="Activate Kit" />
        </div>
        <div className={getStepClassName(1)}>
          <img
            src={getImageUrl('assign_baby_icon', activeStep >= 1)}
            alt=""
          />
          <Typography textType="body2" text="Assign Baby" />
        </div>
        <div className={getStepClassName(3)}>
          <img
            src={getImageUrl('answer_questions_icon', activeStep >= 3)}
            alt=""
          />
          <Typography textType="body2" text="Answer Questions" />
        </div>
      </div>
      <Typography textType="heading3" text={stepsText[activeStep].title} block />
      <Typography textType="body1" text={stepsText[activeStep].text} />
    </div>
  )
}

export default ActivationStatusBar
