import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { storeState } from '../../../constants/storeState';
import SpreeService from '../../../services/spreeService';

const initialState = {
  status: storeState.idle,
  error: ''
}

export const fetchProduct = createAsyncThunk(
  'singleProduct/fetchProduct',
  async (params) => await SpreeService.getProduct(params)
)

export const singleProductSlice = createSlice({
  name: 'singleProduct',
  initialState,
  reducers: {
    clearProduct: () => ({
      ...initialState
    })
  },
  extraReducers: {
    [fetchProduct.pending]: (state) => {
      state.status = storeState.loading
      state.error = ''
    },
    [fetchProduct.fulfilled]: (state, action) => {
      state.data = action.payload.data
      state.status = storeState.loaded
    },
    [fetchProduct.rejected]: (state, action) => {
      state.status = storeState.failed
      state.error = action.error.message
    }
  }
})

export const { clearProduct } = singleProductSlice.actions

export const selectProduct = state => state.product.singleProduct

export default singleProductSlice.reducer
