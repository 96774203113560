import { useEffect, useState } from 'react';
import { Accordion, Col, Container, Row } from 'react-bootstrap';
import { FOOTER_SLUGS } from '../../constants/pageSlugs';
import { fetchContentfulPage } from '../../services/apiService';
import Button from '../ui/button/Button';
import Link from '../ui/link/Link';
import Typography from '../ui/typography/Typography';
import CookiesConsent from '../cookiebar/CookiesConsent'
import { useAuth0 } from '@auth0/auth0-react';
import { getContentfulContent, getContentfulImage } from '../../utils/get-contentful-content';

const FooterNew = () => {
  const [contentfulData, setContentfulData] = useState(null);
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    fetchContentfulPage(FOOTER_SLUGS)
      .then((res) => {
        setContentfulData(res.data);
      })
  }, [])

  return (
    <footer>
      <Container>
        <Col className="footer-category-links d-flex flex-column flex-md-row py-3">
          <Col md={2} className="d-none d-md-flex flex-column">
            <div className="category-label"><Typography textType="heading3" text={getContentfulContent(contentfulData?.footer_menu_category_about)} block /></div>
            <Link className='w-auto' to="about-us"><Typography textType="body1B" className="footer-link" text={getContentfulContent(contentfulData?.footer_menu_category_who_we_are)} block /></Link>
            <Link className='w-auto' to="/kits/baby-microbiome-gut-health-test-kit"><Typography textType="body1B" className="footer-link" text={getContentfulContent(contentfulData?.footer_menu_category_insights_kit)} block /></Link>
            <Link className='w-auto' to="knowledge-library"><Typography textType="body1B" className="footer-link" text={getContentfulContent(contentfulData?.footer_menu_category_knowledge_library)} block /></Link>
            <Link className='w-auto' to="faq"><Typography textType="body1B" className="footer-link" text={getContentfulContent(contentfulData?.footer_menu_category_faqs)} block /></Link>
          </Col>
          <Col md={2} className="d-none d-md-flex flex-column">
            <div className="category-label"><Typography textType="heading3" text={getContentfulContent(contentfulData?.footer_menu_category_account)} block /></div>
            {isAuthenticated ? (
              <>
                <Link className='w-auto' to="account"><Typography textType="body1B" className="footer-link" text={getContentfulContent(contentfulData?.footer_menu_category_my_babies)} block /></Link>
                <Link className='w-auto' to="account/kits"><Typography textType="body1B" className="footer-link" text={getContentfulContent(contentfulData?.footer_menu_category_my_kits)} block /></Link>
                <Link className='w-auto' to="account/settings"><Typography textType="body1B" className="footer-link" text={getContentfulContent(contentfulData?.footer_menu_category_account_settings)} block /></Link>
              </>
            ) : ('')}
            <Link className='w-auto' to="waitlist-quiz"><Typography textType="body1B" className="footer-link" text={getContentfulContent(contentfulData?.footer_menu_category_take_the_quiz)} block /></Link>
            <Link className='w-auto' to="activation"><Typography textType="body1B" className="footer-link" text={getContentfulContent(contentfulData?.footer_menu_category_kit_activation)} block /></Link>
          </Col>
          {isAuthenticated ? (
            <Col md={2} className="d-none d-md-flex flex-column">
              <div className="category-label"><Typography textType="heading3" text={getContentfulContent(contentfulData?.footer_menu_category_legal)} block /></div>
              <Link className='w-auto' to="privacy-policy"><Typography textType="body1B" className="footer-link" text={getContentfulContent(contentfulData?.footer_menu_category_privacy_policy)} block /></Link>
              <Link className='w-auto' to="terms-of-service"><Typography textType="body1B" className="footer-link" text={getContentfulContent(contentfulData?.footer_menu_category_terms)} block /></Link>
              <Link className='w-auto' to="cookies-policy"><Typography textType="body1B" className="footer-link" text={getContentfulContent(contentfulData?.footer_menu_category_cookie_policy)} block /></Link>
            </Col>
          ) : ('')}
          <Col md={3} xs={12} className='d-flex ms-auto flex-column col-3 text-right'>
            <div className='align-right'>
              <Typography textType="body1B" text={(getContentfulContent(contentfulData?.footer_sign_up_to_receive_updates_1))} block />
              <Typography textType="body1B" text={(getContentfulContent(contentfulData?.footer_sign_up_to_receive_updates_2))} block />
            </div>
            <Link to='/activation' className="button button--primary button--small link-light mt-3 align-right d-none">{getContentfulContent(contentfulData?.button_sign_up_for_updates)}</Link>
          </Col>
          <Col xs={12} className="d-md-none d-flex flex-column mt-4 py-2">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div className="category-label"><Typography textType="heading3" text={getContentfulContent(contentfulData?.footer_menu_category_about)} block /></div>
                </Accordion.Header>
                <Accordion.Body>
                  <Link className='w-auto' to="about-us"><Typography textType="body1B" className="footer-link" text={getContentfulContent(contentfulData?.footer_menu_category_who_we_are)} block /></Link>
                  <Link className='w-auto' to="/kits/baby-microbiome-gut-health-test-kit"><Typography textType="body1B" className="footer-link" text={getContentfulContent(contentfulData?.footer_menu_category_insights_kit)} block /></Link>
                  <Link className='w-auto' to="knowledge-library"><Typography textType="body1B" className="footer-link" text={getContentfulContent(contentfulData?.footer_menu_category_knowledge_library)} block /></Link>
                  <Link className='w-auto' to="faq"><Typography textType="body1B" className="footer-link" text={getContentfulContent(contentfulData?.footer_menu_category_faqs)} block /></Link>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <div className="category-label"><Typography textType="heading3" text={getContentfulContent(contentfulData?.footer_menu_category_account)} block /></div>
                </Accordion.Header>
                <Accordion.Body>
                  {isAuthenticated ? (
                    <>
                      <Link className='w-auto' to="account"><Typography textType="body1B" className="footer-link" text={getContentfulContent(contentfulData?.footer_menu_category_my_babies)} block /></Link>
                      <Link className='w-auto' to="account/kits"><Typography textType="body1B" className="footer-link" text={getContentfulContent(contentfulData?.footer_menu_category_my_kits)} block /></Link>
                      <Link className='w-auto' to="account/settings"><Typography textType="body1B" className="footer-link" text={getContentfulContent(contentfulData?.footer_menu_category_account_settings)} block /></Link>
                    </>
                  ) : ('')}
                  <Link className='w-auto' to="waitlist-quiz"><Typography textType="body1B" className="footer-link" text={getContentfulContent(contentfulData?.footer_menu_category_take_the_quiz)} block /></Link>
                  <Link className='w-auto' to="activation"><Typography textType="body1B" className="footer-link" text={getContentfulContent(contentfulData?.footer_menu_category_kit_activation)} block /></Link>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Col>
        <Col className="footer-logo-address d-flex flex-column flex-md-row py-3">
          <Col md={6} xs={12} className="">
            <Link to="/" className="footer-logo mb-2">
              <img className="main-logo" src={getContentfulImage(contentfulData?.tiniest_biome_white_logo_png).url} alt="Tiniest biome" />
            </Link>
            <div className="footer-contacts">
              <Typography textType="heading5" className="mt-1" text={getContentfulContent(contentfulData?.footer_address_1)} />
              <Typography textType="heading5" className="mt-1" text={getContentfulContent(contentfulData?.footer_address_2)} />
              <Typography textType="heading5" className="mt-1" text={getContentfulContent(contentfulData?.footer_address_3)} />
              <Typography textType="heading5" className="mt-4" text={getContentfulContent(contentfulData?.footer_email)} />
              <Typography textType="heading5" className="mt-4" text={getContentfulContent(contentfulData?.footer_phone)} />
            </div>
          </Col>
          <Col md={6} xs={12} className="footer-social-icons justify-content-center justify-content-md-end">
            <Link to="https://www.facebook.com/tiniestbiome" target="_blank" external>
              <img src={getContentfulImage(contentfulData?.social_media_icon_facebook).url} alt="Facebook"/>
            </Link>
            <Link to="https://www.instagram.com/tiniest.biome" target="_blank" external>
              <img src={getContentfulImage(contentfulData?.social_media_icon_instagram).url} alt="Instagram"/>
            </Link>
          </Col>
        </Col>
        <Col className="footer-legal-links align-items-center align-items-md-center">
          <Link className='w-auto' to="privacy-policy"><Typography textType="body1B" className="footer-link" text={getContentfulContent(contentfulData?.footer_menu_category_privacy_policy)} block /></Link>
          <Link className='w-auto' to="terms-of-service"><Typography textType="body1B" className="footer-link" text={getContentfulContent(contentfulData?.footer_menu_category_terms)} block /></Link>
          <Link className='w-auto' to="cookies-policy"><Typography textType="body1B" className="footer-link" text={getContentfulContent(contentfulData?.footer_menu_category_cookie_policy)} block /></Link>
          <Link className='w-auto' to="cpra"><Typography textType="body1B" className="footer-link" text={ getContentfulContent(contentfulData?.footer_menu_category_ccpa) } block /></Link>
        </Col>
        <Col className="footer-copyright">
          <div className="text-center my-10 mb-20 mb-md-0">
            <Typography textType="body2" text={getContentfulContent(contentfulData?.footer_copyright_text)}/>
          </div>
        </Col>
      </Container>
    </footer>
  )
}

export default FooterNew
