import { useContext, useEffect, useState } from 'react';
import { ContentfulContext } from '../providers/ContentfulProvider';

const defaultProps = {
  include: 10,
  query: {},
};

// TODO: finalize content fetching status.
export const useContentful = (props = {}) => {
  const contentful = useContext(ContentfulContext);
  const { convertToObject, ...queryProps } = props;

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [fetched, setFetched] = useState(!!data);
  const internalProps = { ...defaultProps, ...queryProps };

  const arrayToObject = (data) => {
    const keys = data.items.map(item => item.fields.slug || item.fields.name);
    
    let responseObject = {};
    keys.forEach((key, index) => {
      responseObject[key] = data.items[index];
    });

    return responseObject;
  }

  useEffect(() => {
    const fetchEntry = async () => {
      setLoading(true);
      try {
        const result = await contentful.client.getEntries(internalProps)
        setLoading(false)
        setFetched(true);
        if (convertToObject) {
          setData(arrayToObject(result.toPlainObject()))
        } else {
          setData(result.toPlainObject())
        }
      } catch (error) {
        setError(error.mesage);
        setLoading(false)
        setFetched(false)
      }
    }

    fetchEntry()
  }, [JSON.stringify(props)])

  return ({ data, error, fetched, loading, client: contentful.client });
}

/**
 * @param {string} id           Contentful entry id.
 * 
 */
export const useGetSingleEntry = (id) => {
  const contentful = useContext(ContentfulContext);

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [fetched, setFetched] = useState(!!data);

  useEffect(() => {
    const fetchEntry = async () => {
      setLoading(true);
      try {
        const result = await contentful.client.getEntry(id)
        setLoading(false)
        setFetched(true);
        setData(result.toPlainObject())
      } catch (error) {
        setError(error.mesage);
        setLoading(false)
        setFetched(false)
      }
    }

    fetchEntry()
  }, [contentful.client, id])

  return ({ data, error, fetched, loading });
}
