import { render } from "react-dom";
import App from './App';

const root = document.createElement('div')
root.setAttribute('id', 'root')

document.addEventListener("DOMContentLoaded", () => {
  render(<App />, document.body.appendChild(root))

  // Enable Hot Module Replacement
  if (module.hot) {
    module.hot.accept();
  }
});
