import { Col, Container, FloatingLabel, Form, Row, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Button from '../ui/button/Button';
import Typography from '../ui/typography/Typography';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCountries, selectCountries } from '../../store/slices/global/countriesSlice';
import { useEffect } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { makeRequest } from '../../services/requestHandler';
import { fetchContentfulPage } from '../../services/apiService'
import { WAITLIST_FORM_SLUGS } from '../../constants/pageSlugs'
import { getContentfulContent } from '../../utils/get-contentful-content';

const contentfulSlugs = [
  'form_first_name',
  'form_last_name',
  'form_email_address',
  'button_submit',
  'country',
  'state_province',
  'postal_code'
]

const WaitlistSection = ({
  title,
  subtitle,
  formProps,
}) => {
  const dispatch = useDispatch();
  const [pageData, setPageData] = useState(null)
  const [error, setError] = useState(false)

  useEffect(() => {
    fetchContentfulPage(contentfulSlugs)
    .then((res) => {
      setPageData(res.data);
      setError(false);
    }).catch(() => {
      setError(true);
    })
  }, [slugs])

  const { data: countries } = useSelector(selectCountries);
  const us = countries.find(country => country.name === "United States");
  const ca = countries.find(country => country.name === "Canada");

  const [states, setStates] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [slugs, setSlugs] = useState();

  useEffect(() => {
    fetchContentfulPage(WAITLIST_FORM_SLUGS)
    .then((res) => {
      setSlugs(res.data);
    })
  }, [])

  useEffect(() => {
    dispatch(fetchCountries());
  }, [dispatch]);
  
  const schema = yup.object({
    first_name: yup.string().required(),
    email: yup.string().required().email(),
  });

  const {
    register,
    handleSubmit,
    resetField,
    watch,
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const formData = watch();

  useEffect(() => {
    if (countries && formData.country_id) {
      setStates(countries.find(country => country.id === formData.country_id)?.states || [])
    }

    resetField('state_id')
  }, [countries, resetField, formData.country_id])

  const onSubmit = data => {
    makeRequest({
      url: '/api/v1/waitlists',
      method: 'POST',
      body: JSON.stringify({ ...data, ...formProps }),
      isPublic: true,
    }).then(() => {
      reset();
      setShowModal(true);
    }).catch( (error) => {
      reset();
      setShowModal(true);
    })
  };

  if (!slugs) return (
    <>Loading...</>
  )

  const {
    waitlist_landing_sign_up_consent,
    button_join_waitlist,
    button_submit,
    waitlist_popup_title,
    waitlist_popup_subtitle,
  } = slugs;

  return (
    <section className="landing-waitlist-section">
      <Container>
        <Row>
          <Col xs={{ span: 10, offset: 1 }} md={{ span: 12, offset: 0 }}>
            <Typography textType="heading1" className="font-Moranga text-center mb-md-6" block text={title} />
            <Typography textType="heading3" className="mb-6 mb-md-12 text-center" block text={subtitle} />
          </Col>
        </Row>
        <div className="waitlist-form-card">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="gx-3">
              <Col xs={12} md={12}>
                <FloatingLabel controlId="first_name" label={getContentfulContent(pageData?.form_first_name)} className="mb-4 mb-md-6">
                  <Form.Control
                    type="text"
                    placeholder="First name*"
                    {...register("first_name")}
                    className="w-100"
                  />
                </FloatingLabel>
              </Col>
              <Col xs={12} md={12}>
                <FloatingLabel controlId="email" label={getContentfulContent(pageData?.form_email_address)} className="mb-4 mb-md-6">
                  <Form.Control
                    type="email"
                    placeholder="Email*"
                    {...register("email")}
                    className="w-100"
                  />
                </FloatingLabel>
              </Col>
              <Col xs={12}>
                <span className="p-0 mb-4 agreement-text">{documentToReactComponents(waitlist_landing_sign_up_consent?.content)}</span>
              </Col>
              <Col xs={12}>
                <Button
                  label={getContentfulContent(button_submit)}
                  disabled={!schema.isValidSync(watch())}
                  fullWidth
                />
              </Col>
            </Row>
          </form>
        </div>
      </Container>

      <Modal
        size="m"
        aria-labelledby="contained-modal-title-vcenter"
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <h4 className="typography heading3 d-block text-black mb-3 text-center font-Moranga">{getContentfulContent(waitlist_popup_title)}</h4>
          <p>{getContentfulContent(waitlist_popup_subtitle)}</p>
        </Modal.Body>
      </Modal>
    </section>
  )
}

export default WaitlistSection;
