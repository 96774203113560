import { useEffect, useState } from 'react';
import WellnessAreaTile from '../../../components/KnowledgeLibrary/WellnessAreaTile'
import useKnowledgeLibrary from '../../../hooks/useKnowledgeLibrary'

const WellnessAreaBlock = ({
  activeArea,
  stats,
}) => {
  const { getWellnessAreas } = useKnowledgeLibrary();
  const [wellnessAreas, setWellnessAreas] = useState([]);

  useEffect(() => {
    getWellnessAreas().then(areas => setWellnessAreas(areas.sort()));
  }, [])

  return (
    <div className="wellness-area-list">
      {wellnessAreas.map(area => (
        <WellnessAreaTile
          key={area.id}
          icon={area.icon}
          title={area.name}
          active={area.id === activeArea}
          // graphData= TO BE DEFINED.
        />
      ))}
    </div>
  )
}

export default WellnessAreaBlock
