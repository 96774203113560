import { Outlet } from 'react-router-dom';
import { Auth0Provider } from '../../pages/auth/CustomAuth0Provider';

export const AuthLayout = () => {
  return (
    <Auth0Provider>
      <div className="sign-in-layout">
        <Outlet />
      </div>
    </Auth0Provider>
  )
}

export default AuthLayout
