import { createSelector, createSlice } from '@reduxjs/toolkit';
import { storeState } from '../../../constants/storeState';
import {
  addItem,
  addPayment,
  applyCouponCode,
  associateGuestCart,
  createCart,
  getCart,
  getPaymentMethods,
  orderUpdate,
  removeCouponCode,
  removeItem,
  setQuantity,
  completeOrder,
} from './asyncThunks';

const initialState = {
  data: null,
  paymentMethods: [],
  status: storeState.idle,
  error: null
}

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    setEmptyCart: (state) => {
      state.status = storeState.loaded
    }
  },
  extraReducers: {
    [createCart.pending]: (state) => {
      state.status = storeState.loading
      state.error = null
    },
    [createCart.fulfilled]: (state, action) => {
      state.data = action.payload
      state.status = storeState.loaded
    },
    [createCart.rejected]: (state, action) => {
      state.status = storeState.failed
      state.error = action.payload
    },
    [getCart.pending]: (state) => {
      state.error = null
      state.status = storeState.loading
    },
    [getCart.fulfilled]: (state, action) => {
      state.data = action.payload
      state.status = storeState.loaded
    },
    [getCart.rejected]: (state, action) => {
      state.status = storeState.failed
      state.error = action.payload
    },
    [addItem.fulfilled]: (state, action) => {
      state.data = action.payload
      state.status = storeState.loaded
    },
    [setQuantity.fulfilled]: (state, action) => {
      state.data = action.payload
      state.status = storeState.loaded
    },
    [removeItem.fulfilled]: (state, action) => {
      state.data = action.payload
      state.status = storeState.loaded
    },
    [applyCouponCode.fulfilled]: (state, action) => {
      state.data = action.payload
      state.status = storeState.loaded
      state.error = null
    },
    [applyCouponCode.rejected]: (state, action) => {
      state.error = action.payload
      state.status = storeState.loaded
    },
    [removeCouponCode.fulfilled]: (state, action) => {
      state.data = action.payload
      state.status = storeState.loaded
    },
    [removeCouponCode.rejected]: (state, action) => {
      state.data = action.payload
      state.status = storeState.loaded
    },
    [associateGuestCart.fulfilled]: (state, action) => {
      state.data = action.payload
      state.status = storeState.loaded
    },
    [orderUpdate.pending]: (state) => {
      state.status = storeState.loading
      state.error = null
    },
    [orderUpdate.fulfilled]: (state, action) => {
      state.data = action.payload
      state.status = storeState.loaded
    },
    [orderUpdate.rejected]: (state, action) => {
      state.status = storeState.failed
      state.error = action.error.message
    },
    [getPaymentMethods.fulfilled]: (state, action) => {
      state.paymentMethods = action.payload.data
    },
    [addPayment.pending]: (state) => {
      state.status = storeState.loading
      state.error = null
    },
    [addPayment.fulfilled]: (state, action) => {
      state.data = action.payload
      state.status = storeState.loaded
      state.error = null
    },
    [addPayment.rejected]: (state, action) => {
      state.status = storeState.failed
      state.error = action.payload
    },
    [completeOrder.fulfilled]: (state, action) => {
      state.data = action.payload
      state.status = storeState.loaded
      state.error = null
    },
    [completeOrder.rejected]: (state, action) => {
      state.status = storeState.failed
      state.erorr = action.error.message
    }
  }
})

export const { setEmptyCart } = cartSlice.actions

export const cartSelector = state => state.cart
export const cartItemsCountSelector = createSelector(cartSelector, ({ data }) => {
  if (!data?.lineItems) return 0
  if (data.state === 'complete') return 0

  return data.lineItems.reduce((value, item) => value + item.quantity, 0)
})

export * from './asyncThunks'

export default cartSlice.reducer
