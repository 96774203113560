
import { Card, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Typography from '../ui/typography/Typography';
import Button from '../ui/button/Button';
import Link from '../ui/link/Link';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

const NextStepCard = ({
  title,
  titleIcon,
  details,
  linkTo,
  button,
  buttonIcon
}) => {

  let buttonLabel = button?.filter(p => p.fields.hasOwnProperty('content'));
  buttonLabel = buttonLabel && buttonLabel.length ? buttonLabel[0] : null;
  return (
<Col>
  <Card className="mb-4 mb-md-3 p-0 px-1">
    <Card.Body className='d-flex flex-column d-flex justify-content-between'>
      <Typography 
        textType={'heading4'} 
        className="mb-0 title-text d-flex" 
        block 
        text={title}
        leftIcon={titleIcon && (<img src={titleIcon.fields.file.url} alt='' />)}
      />
      <Typography textType="body1" text={details} className="subtitle my-4" />
      {button ? (
        <Link to={linkTo}>
          <Button 
            role="button" 
            className="mt-md-4 me-auto hero-button" 
            label={documentToPlainTextString(buttonLabel?.fields.content.content[0])} 
            rightIcon={buttonIcon && (<img src={buttonIcon.fields.file.url} alt='' />)}
          />
        </Link>
      ) : (<span> </span>)}
    </Card.Body>
  </Card>
</Col>
)}

NextStepCard.propTypes = {
  type: PropTypes.string
}

export default NextStepCard
