import clsx from 'clsx'
import CircularProgressBar from '../ui/progress/CircularProgressBar'
import Typography from '../ui/typography/Typography'
import Button from '../ui/button/Button'

const WellnessAreaTile = ({
  icon,
  title,
  graphData,
  size,
  active,
  inverted,
  viewButton,
  viewButtonLabel,
  viewButtonIconRight
}) => {
  const className = clsx('wellness-area-tile', {
    'wellness-area-tile--active': active,
    'wellness-area-tile--big': size === 'big',
    'wellness-area-tile--button': viewButton,
  })

  return (
    <div className={className}>
      <div className="wellness-area-tile__text">
        <img src={icon} alt="" />
        <Typography textType="body1B" text={title} />
      </div>
      <CircularProgressBar value={graphData} inverted={inverted} />

      {(viewButton && 
        <Button 
          label={(viewButtonLabel ? viewButtonLabel : "View")}
          size="small"
          type="tertiary"
          rightIcon={(viewButtonIconRight && <img src={viewButtonIconRight?.image.fields.file.url} alt="" />)}
        />
      )}
    </div>
  )
}
//{documentToPlainTextString(button_view?.fields.content.content[0])}
//rightIcon={buttonIcon && (<img src={buttonIcon.fields.file.url} alt='' />)}

export default WellnessAreaTile
