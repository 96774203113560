import { useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import validateCard from 'card-validator';
import { useSearchParams } from 'react-router-dom'
import { addPayment, cartSelector, orderUpdate } from '../../store/slices/cart/cartSlice';
import AddressForm from './AddressForm';
import PaymentForm from './PaymentForm';
import DirectionLineRight from '../ui/icons/DirectionLineRight';
import Checkbox from '../ui/forms/Checkbox';
import Typography from '../ui/typography/Typography';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '../ui/button/Button';
import { billingStepSchema } from '../../utils/form-validation';
import { customToast } from '../ui/notification/customToast';

const schema = billingStepSchema;

const BillingStep = () => {
  const dispatch = useDispatch()
  const [, setSearchParams] = useSearchParams()
  const { data } = useSelector(cartSelector)
  const { paymentMethods } = useSelector(cartSelector)
  const [useSavedData, setUseSavedData] = useState(false)
  const { billingAddress, shippingAddress } = data;
  const { handleSubmit, ...methods} = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      country_iso: billingAddress?.country_iso || 'US',
      phone: billingAddress?.phone || '+1',
      firstname: billingAddress?.firstname,
      lastname: billingAddress?.lastname,
      address1: billingAddress?.address1,
      city: billingAddress?.city,
      state_name: billingAddress?.state_code,
      zipcode: billingAddress?.zipcode,
    }
  })
  const { watch, reset } = methods;

  const onSubmit = async (data) => {
    const { card } = validateCard.number(data.cardNumber)
    const { month, year } = validateCard.expirationDate(data.expDate)
    const paymentMethodId = paymentMethods[0]?.id

    const paymentInfo = {
      payment_method_id: paymentMethodId,
      source_attributes: {
        cc_type: card.type,
        number: data.cardNumber.replace(/\s/g, ''),
        last_digits: data.cardNumber.replace(/\s/g, '').slice(-4),
        name: data.cardHolder,
        month,
        year,
        verification_value: data.cardCode
      }
    }
    
    const billingResponse = await dispatch(orderUpdate({
      bill_address_attributes: data
    }))

    if (billingResponse.error) {
      return
    }

    const paymentResponse = await dispatch(addPayment(paymentInfo))
    
    if (paymentResponse.error) {
      customToast({
        title: 'Something went wrong',
        message: paymentResponse.payload,
        type: 'error'
      });

      reset({
        ...watch(),
        cardCode: '',
        cardNumber: '',
        expDate: '',
      })

      return
    }

    setSearchParams({ step: 'confirmation' })
  }

  return (
    <>
      <Col md={12} className='mx-auto'>
        <h3 className='my-3 d-md-none font-Moranga'>Billing Address</h3>
        {shippingAddress && (
          <Checkbox
            label="Billing same as shipping address"
            value={useSavedData}
            onChange={() => setUseSavedData(!useSavedData)}
            wrapClassName="px-0 pb-4"
            labelClassName="checkout-checkbox"
          />
        )}
        <FormProvider {...methods}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <AddressForm
              data={shippingAddress}
              useSaved={useSavedData}
            />
            <hr className="my-6" />
            <Typography textType="heading4" text="Payment information" className="pb-2 d-none d-md-block" />
            <Typography textType="heading3" text="Payment information" className="pb-2 font-Moranga d-md-none" />
            <PaymentForm />
            <div className="d-grid gap-2">
              <Button
                disabled={!schema.isValidSync(watch())}
                label="Review Order"
                rightIcon={<DirectionLineRight />}
              />
            </div>
          </Form>
        </FormProvider>
      </Col>
    </>
  )
}

export default BillingStep
