import { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Typography from '../components/ui/typography/Typography';
import Button from '../components/ui/button/Button';
import Link from '../components/ui/link/Link';
import DirectionLineRight from '../components/ui/icons/DirectionLineRight';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { fetchContentfulPage } from '../services/apiService';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { SIGNOUT_SLUGS } from '../constants/pageSlugs';

const SignOut = () => {
  const [contentfulData, setContentfulData] = useState(null);
  const {
    isAuthenticated,
    loginWithRedirect
  } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    fetchContentfulPage(SIGNOUT_SLUGS)
      .then((res) => {
        setContentfulData(res.data);
      })
  }, [])

  if (isAuthenticated) {
    return navigate(-1);
  }

  if (!contentfulData) {
    return (
      <>
        Loading...
      </>
    )
  }
  const {
    button_sign_back_in,
    button_go_to_home,
    signed_out_title,
    signed_out_subtitle
  } = contentfulData;
  return (
    <>
      <Container>
        <Row className="mb-4">
          <Col>
            <div className="text-center summary-section py-10">
              <Typography text={signed_out_title && documentToPlainTextString(signed_out_title.content)} textType="heading3" block />
              <Typography text={signed_out_subtitle && documentToPlainTextString(signed_out_subtitle.content)} textType="heading4" className="mt-6 mt-md-0" block />
            </div>
            <div className="d-grid gap-2 d-md-flex justify-content-md-center my-2">
              <Link to="/" >
                <Button className="btn btn-light-blue btn-fh" label={button_go_to_home && documentToPlainTextString(button_go_to_home.content)} rightIcon={<DirectionLineRight />} fullWidth />
              </Link>
            </div>
            <div className="d-grid gap-2 d-md-flex justify-content-md-center">
              <Button className="btn btn-light-blue btn-fh" label={button_sign_back_in && documentToPlainTextString(button_sign_back_in.content)} rightIcon={<DirectionLineRight />} onClick={loginWithRedirect} />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default SignOut
