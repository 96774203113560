import { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import DashboardTextCard from '../../../../components/dashboard/DashboardTextCard'
import Button from '../../../../components/ui/button/Button'
import Typography from '../../../../components/ui/typography/Typography'
import useKnowledgeLibrary from '../../../../hooks/useKnowledgeLibrary'
import getRandomArrayItems from '../../../../utils/get-random-array-items'
import RecipeCard from '../../knowledge-library/RecipeCard'
import { useContentful } from '../../../../hooks/useContentful'
import { getDynamicText } from '../dashboardHelpers'
import DashboardOtherRecipesCard from '../../../../components/dashboard/DashboardOtherRecipesCard'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { useNavigate } from 'react-router-dom'
import DirectionLineRight from '../../../../components/ui/icons/DirectionLineRight'

const randomParagrapgSlugs = [
  'explore_recipes_title_weight_regulation',
  'button_see_more_recipes',
]

const WeightRegulation = ({
  userData,
  images,
  title,
}) => {
  const navigate = useNavigate();
  const [recipes, setRecipes] = useState([]);
  const { getRecipes } = useKnowledgeLibrary();
  const {
    contentful_slugs,
    summary
  } = userData;

  const { data: slugData } = useContentful({
    content_type: 'recommendationsText',
    'fields.slug[in]': contentful_slugs.join(','),
    convertToObject: true,
  })
  const { data: bacteriaComponent } = useContentful({
    content_type: 'ruleEngineContet',
    'fields.slug': 'food_to_research_weight_regulation',
    convertToObject: true,
  })
  const { data: text } = useContentful({
    content_type: 'randomParagraph',
    'fields.name[in]': randomParagrapgSlugs.join(','),
    convertToObject: true,
  })

  useEffect(() => {
    const fetchRecipes = async () => {
      const allRecipes = await getRecipes();
      const categoryRecipes = allRecipes.filter(recipe => recipe.wellnessArea.slug === 'wellness_area_healthy_weight_regulation');
      setRecipes(getRandomArrayItems(categoryRecipes, window.innerWidth < 992 ? 4 : 6));
    };

    fetchRecipes();
  }, [])

  return (
    <div>
      {(images) && (
        <Col xs={12}>
          <DashboardTextCard
            image={images[`healthy_weight_regulation_image_${summary}`].fields.image.fields.file.url}
            title={title}
            text={getDynamicText(contentful_slugs, slugData)}
          />
          <hr className="mt-8 mb-6" />
        </Col>
      )}

      <Col xs={12}>
        {text && (
          <Typography
            textType="heading4"
            font="Moranga"
            className="mb-6"
            text={documentToPlainTextString(text.explore_recipes_title_weight_regulation.fields.content)}
            block
          />
        )}
        <div className="dashboard-recipes-list">
          {recipes.map(recipe => (
            <RecipeCard
              key={recipe.id}
              data={recipe}
            />
          ))}
        </div>
        {text && (
          <Button
            label={documentToPlainTextString(text.button_see_more_recipes.fields.content)}
            type="secondary"
            className="mt-6"
            onClick={() => navigate('/knowledge-library', { state: { recipesExpanded: true } })}
            rightIcon={<DirectionLineRight />}
            fullWidth
          />
        )}
        <hr className="my-8" />
      </Col>
      <Col>
        {bacteriaComponent && (
          <DashboardOtherRecipesCard
            data={bacteriaComponent.food_to_research_weight_regulation}
          />
        )}
      </Col>
    </div>
  )
}

export default WeightRegulation
