import { useState } from 'react';
import Button from '../../components/ui/button/Button';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from "react-router-dom";
import ActivationStatusBar from '../../components/activation/ActivationStatusBar';
import ActivationForm from '../../components/activation/ActivationForm';
import ChooseBabyForm from '../../components/activation/ChooseBabyForm';
import AnswerQuestions from '../../components/activation/AnswerQuestions';
import RegisterBabyQuiz from '../../components/questions/RegisterBabyQuiz';
import Link from '../../components/ui/link/Link';

const Activation = () => {
  const navigate = useNavigate();
  const {
    isLoading,
    isAuthenticated,
  } = useAuth0();
  const [activeStep, setActiveStep] = useState(0);
  const [accountId, setAccountId] = useState(null);
  const [activationCode, setActivationCode] = useState(null);
  const [error, setError] = useState(false);

  if (isLoading) {
    return (
      <>Loading...</>
    )
  }

  const onSuccessCodeValidation = (code) => {
    setActivationCode(code);
    setActiveStep(1);
  }

  const handleAnswerQuestionsStep = (accountId) => {
    setAccountId(accountId);
    setActiveStep(3)
  }

  const handleActivateKit = () => {
    const body = {
      code: activationCode,
      account_id: accountId,
    }
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    }
    const url = `/api/v1/qr_codes/${activationCode}/activate`

    fetch(url, requestOptions)
      .then((response) => {
        if (response.status == 204) {
          navigate(`/activated?account_id=${accountId}`)
        } else {
          setError('Invalid code!')
        }
      })
      .catch((error) => {
        setError(error.message)
      });
  }

  if (isAuthenticated) {
    return (
      <>
        <div className="page-wrap activation-page">
          <ActivationStatusBar activeStep={activeStep} />

          <div className="activation-page-steps">
            {activeStep === 0 && (
              <ActivationForm onSuccess={onSuccessCodeValidation} />
            )}

            {activeStep === 1 && (
              <ChooseBabyForm
                onAddBabyClick={() => setActiveStep(2)}
                onNextClick={handleAnswerQuestionsStep}
              />
            )}

            {activeStep === 2 && (
              <RegisterBabyQuiz
                onFinishQuiz={() => setActiveStep(1)}
                hideCloseButton
              />
            )}

            {activeStep === 3 && (
              <AnswerQuestions
                accountId={accountId}
                onFinish={handleActivateKit}
              />
            )}
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="container py-30 text-center">
        <h2 className='header2 pb-5 font-Moranga mt-30'>Please login to activate your Kit</h2>
        <Link to={`/auth/sign-in?redirect_url=${window.location.pathname}`}>
          <Button label="Login" type="primary" size="large" className="mx-auto mb-30" />
        </Link>
      </div>
    </>
  )
}

export default Activation
