import Modal from 'react-bootstrap/Modal';
import InfoCircleIcon from '../ui/icons/InfoCircleIcon'
import { useState } from 'react'

const QuestionModal = ({
  title,
  text,
}) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      <button className="question-why-we-ask" onClick={() => setShowModal(true)}><InfoCircleIcon />Why do we ask?</button>

      <Modal show={showModal} contentClassName="question-modal" onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title><InfoCircleIcon width={24} height={24} /></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="question-modal-title">{title}</div>
          <div className="question-modal-text" dangerouslySetInnerHTML={{ __html: text }} />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default QuestionModal;
