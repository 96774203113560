import { Route, Routes } from 'react-router-dom'
import withAuthorization from '../../../components/containers/withAuthorization'
import DashboardLayout from './DashboardLayout'
import DashboardResults from './DashboardResults'
import Recommendations from './Recommendations'

const DashboardArea = () => {
  return (
    <div>
      <Routes>
        <Route path="/:childId/" element={<DashboardLayout />}>
          <Route path="/:childId/:wellnessArea" index element={<DashboardResults />} />
          <Route path="/:childId/:wellnessArea/recommendations" element={<Recommendations />} />
        </Route>
      </Routes>
    </div>
  )
}

export default withAuthorization(DashboardArea)
