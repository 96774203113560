import * as contentful from 'contentful';
import { makeRequest } from './../services/requestHandler';

const useKitTracking = () => {
  const client = contentful.createClient({
    space: process.env.CONTENTFUL_SPACE_ID,
    environment: 'master',
    accessToken: process.env.CONTENTFUL_API_KEY,
  })

  const getTrackingStates = async () => {
    try {
      const entries = await client.getEntries({
        'content_type': 'kitTrackingState',
      })

      const sanitizedEntries = entries.items.map(item => {
        return {
          ...item.fields,
          id: item.sys.id,
          icon: item.fields.icon.fields?.file,
        }
      })

      return sanitizedEntries;
    } catch (error) {
      console.log(error)
    }
  }

  const getTrackingData = async (orderId, lineItemId) => {
    try {
      const response = await makeRequest({
        url: `/api/v1/tracking?order_id=${orderId}&line_item_unit_id=${lineItemId}`,
      })

      return response;
    } catch (error) {
      console.log(error)
    }
  }

  return {
    getTrackingStates,
    getTrackingData,
  }
}

export default useKitTracking
