import { useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import QuestionItem from '../components/questions/QuestionItem';
import Typography from '../components/ui/typography/Typography';
import { makeRequest } from '../services/requestHandler';
import CloseIcon from '../components/ui/icons/CloseIcon'
import ChevronLeftIcon from '../components/ui/icons/ChevronLeftIcon'
import QuizHeader from '../components/questions/QuizHeader'

const Questions = () => {
  const [questions, setQuestions] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  let navigate = useNavigate()
  const nodeRef = useRef(null)
  const [searchParams] = useSearchParams()
  const accountId = searchParams.get('account-id')

  if (!accountId) {
    navigate('/account')
  }

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await makeRequest({
          url: '/api/v1/questions',
          authType: 'auth0'
        })
        
        setQuestions(response.data)
      } catch (error) {
        console.log(error.message)
      }
    }

    fetchQuestions()
  }, [])

  const sendAnswer = (question, value) => {
    const answer = {
      value: value,
      question_id: question.id,
      account_id: accountId,
    }

    makeRequest({
      url: '/api/v1/answers',
      method: 'POST',
      body: JSON.stringify(answer),
      authType: 'auth0',
    }).then(() => {
      setActiveStep(activeStep + 1)
    }).catch(ex => {
      // setError(ex.message)
    })
  }

  const questionsElements = questions && questions.map((property, index) => (
    <QuestionItem
      key={index}
      question={property}
      activeStep={activeStep}
      maxStep={questions.length - 1}
    />
  )) || []

  if (!questions) {
    return <>Loading...</>
  }

  return (
    <div className="questions">
      <QuizHeader
        step={activeStep}
        maxStep={questions?.length}
        onBackClick={() => setActiveStep(activeStep -1)}
      />

      <SwitchTransition mode="out-in">
        <CSSTransition
          nodeRef={nodeRef}
          key={activeStep}
          addEndListener={(node, done) => node.addEventListener("transitionend", done, false)}
          classNames='fade'
        >
          <>{questionsElements[activeStep]}</>
        </CSSTransition>
      </SwitchTransition>
    </div>
  )
}

export default Questions
