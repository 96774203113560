import PropTypes from 'prop-types';
import { useEffect } from 'react';

const Input = ({
  label,
  value,
  defaultValue,
  placeholder,
  prefixIcon,
  suffixIcon,
  onChange,
  ...props
}) => {

  return (
    <div {...props}>
      <label className="form-control">
        {label && <span>{label}</span>}
        <div>
          {prefixIcon && (
            <div className="input-prefix-icon">
              {prefixIcon}
            </div>
          )}
          <input type="text" value={value} defaultValue={defaultValue} placeholder={placeholder} onChange={onChange} />
          {suffixIcon && (
            <div className="input-suffix-icon">
              {suffixIcon}
            </div>
          )}
        </div>
      </label>
    </div>
  )
}

Input.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  prefixIcon: PropTypes.element,
  suffixIcon: PropTypes.element,
}

export default Input
