import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Col, Card } from 'react-bootstrap';
import { cartSelector } from '../../store/slices/cart/cartSlice';
import Typography from '../ui/typography/Typography';
import Link from '../ui/link/Link';
import Button from '../ui/button/Button';
import DirectionLineRight from '../ui/icons/DirectionLineRight';

const OrderSummary = () => {
  const { data } = useSelector(cartSelector)
  const {
    email,
    createdAt,
    shippingMethod,
    shippingAddress,
    displayItemTotal,
    displayTotalPrice,
    displayShipPrice,
    payment,
    displayDiscountPrice
  } = data

  return (
    <>
      <Col lg={{ span: 8, offset: 2 }} className="mt-0 mt-mb-20 mb-0 order-summary">
        <div className="text-center summary-section pb-10">
          <Typography text="Thank you for your order" textType="heading2" className="d-none d-md-block font-Moranga mb-md-4" block />
          <Typography text="Thank you for your order" textType="heading2" className="d-block d-md-none" block />
          <Typography text="Tracking information has been sent to the provided email." textType="heading4" className="mt-6 mt-md-0" block />
        </div>

        <hr className='d-block d-md-none' />
        <Card body border='light px-0 px-md-6 pt-0 pt-md-6'>
          <div className="summary-section pb-6">
            <div className="pt-0 text-center">
              <Typography text="Order Summary" textType="heading4" block />
              <h3 className='heading3 my-4 fw-700 d-block text-black order-number'>Order Number: <big>{data.orderNumber}</big></h3>
            </div>
            <br/>
            <div className="summary-products">
              {data.lineItems.map((lineItem) => (
                <div className="product py-2" key={lineItem.id}>
                  {lineItem.variant?.image?.url && <img src={lineItem.variant.image.url} alt={lineItem.name} />}
                  <div className="product-description">
                    <div className="product-title">
                      <Typography text={lineItem.name} textType="heading4" />
                      <Typography text={lineItem.displayPrice} textType="heading4" />
                    </div>
                    <div className='product-title'>
                      <Typography text="Quantity:" textType="heading5" />
                      <Typography text={lineItem.quantity} textType="heading5" />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <hr/>
          <div className="p-3 p-0 mt-0 mt-md-6 w-md-50 mx-md-auto my-account-button">
            <Link to="/account">
              <div className="d-grid gap-2 col-12 mx-auto">
                <Button className="btn btn-light-blue btn-fh" label="My Account" rightIcon={<DirectionLineRight />}  />
              </div>
            </Link>
          </div>
          <div className="summary-section py-6">
            <Typography text={`Purchase date: ${dayjs(createdAt).format('M/D/YYYY')}`} textType="heading5" className="mb-4 fw-700" block />
            {payment?.cardData && 
              (<Typography text={`Card ending with ${payment.cardData.lastDigits}`} textType="heading5" className="mb-4 fw-700" block />)}
            <Typography text="Ship to:" textType="heading5" className="mb-2 fw-700" block />
            <Typography text={`${shippingAddress.firstname} ${shippingAddress.lastname}`} textType="body1" className="mb-2 fw-600" block />
            <Typography text={`${shippingAddress.address1}, ${shippingAddress.city} ${shippingAddress.state_code} ${shippingAddress.zipcode}`} textType="body1" className="mb-2 fw-600" block />
            <Typography text={shippingAddress.country_name} textType="body1" className="mb-2 fw-600" block />
            <Typography text={shippingAddress.phone} textType="body1" className="mb-2 fw-600" block />
            <Typography text={email} textType="body1" className="fw-600" block />
          </div>
          <hr/>
          <div className="summary-section py-6">
            <div className="d-flex justify-content-between mb-4">
              <Typography textType="heading4" text="Receipt:" />
              <Typography textType="heading4" text={displayItemTotal} />
            </div>
            <div className="d-flex justify-content-between mb-2">
              <Typography textType="body1" text="Order Subtotal" className="fw-600"/>
            </div>
            <div className="d-flex justify-content-between mb-2">
              <Typography textType="body1" text="Sales Tax" className="fw-600"/>
              <Typography textType="body1" text="-" className="fw-700" />
            </div>
            <div className="d-flex justify-content-between mb-4">
              <Typography textType="body1" text="Shipping" className="fw-600"/>
              <Typography textType="body1" text="Free" className="fw-700" />
            </div>
            <div className="d-flex justify-content-between mb-2">
              <Typography textType="body1" text="Discount" className="fw-600"/>
              <Typography textType="body1" text={displayDiscountPrice} className="fw-700" />
            </div>
            <div className="d-flex justify-content-between">
              <Typography textType="heading4" text="Total" />
              <Typography textType="heading4" text={displayTotalPrice} />
            </div>
          </div>
        </Card>
      </Col>
    </>
  )
}

export default OrderSummary
