function FacebookIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="35"
      height="35"
      fill="none"
      viewBox="0 0 35 35"
    >
      <path
        fill="#44C8F5"
        fillRule="evenodd"
        d="M0 17.5C0 7.835 7.835 0 17.5 0S35 7.835 35 17.5 27.165 35 17.5 35 0 27.165 0 17.5z"
        clipRule="evenodd"
        opacity="0.1"
      ></path>
      <path
        fill="#44C8F5"
        d="M18.58 26.25v-8.204h2.427l.321-2.827H18.58l.004-1.415c0-.738.075-1.133 1.21-1.133h1.517V9.844h-2.427c-2.916 0-3.942 1.371-3.942 3.678v1.697h-1.817v2.827h1.817v8.204h3.638z"
      ></path>
    </svg>
  );
}

export default FacebookIcon;
