import { useEffect, useState } from 'react'
import InfoCircleIcon from '../ui/icons/InfoCircleIcon'
import ComparisonCircularProgressBar from '../ui/progress/ComparisonCircularProgressBar'
import Typography from '../ui/typography/Typography'
import { Modal } from 'react-bootstrap'

const ComparisonGraphCard = ({
  value,
  contentfulData,
  name,
  inverted,
}) => {
  const [showModal, setShowModal] = useState()
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (value === 'less') {
      setPercentage(33)
    }
    if (value === 'avarage') {
      setPercentage(66)
    }
    if (value === 'more') {
      setPercentage(100)
    }
  }, [value])

  const getPathColor = () => {
    if (percentage > 66) {
      return inverted ? '#DA291C' : '#77C125';
    }

    if (percentage > 33) {
      return '#FF9A23'
    }

    if (percentage > 0) {
      return inverted ? '#77C125' : '#DA291C'
    }
  }

  return (
    <div>
      <Typography
        textType="heading4"
        font="Moranga"
        text={contentfulData?.howBabyComparesTitle.replace('{{baby.name}}', name)}
        className="mb-4"
      />
      <div className="card justify-content-between gap-4 flex-row align-items-center">
        <div>
          <Typography textType="body1B" className="mb-4" text={contentfulData?.comparedToBabiesText} />
          <div className="comparison-legend">
            <div className="legend-item">
              <i className="legend-marker" style={{ backgroundColor: getPathColor() }}></i>
              <Typography textType="body1" text={name} />
            </div>
            <div className="legend-item">
              <i className="legend-marker average"></i>
              <Typography textType="body1" text={contentfulData?.averageText} />
              <div className="ms-auto cursor-pointer" onClick={() => setShowModal(true)}>
                <InfoCircleIcon />
              </div>
            </div>
          </div>
        </div>
        <div>
          <ComparisonCircularProgressBar
            pathColor={getPathColor()}
            percentage={percentage}
          />
        </div>
      </div>
      <Modal show={showModal} contentClassName="question-modal" onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton />
        <Modal.Body>
          <div className="question-modal-text text-start" dangerouslySetInnerHTML={{ __html: contentfulData?.averagePopUpText }} />
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ComparisonGraphCard
