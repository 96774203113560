import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { orderUpdate } from '../../store/slices/cart/asyncThunks';
import Button from '../ui/button/Button';
import Typography from '../ui/typography/Typography';

const GuestEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');

  const saveEmail = () => {
    dispatch(orderUpdate({
      email
    })).then(() => {
      navigate('/checkout?step=address')
    }).catch(error => {
      setError(error)
    })
  }

  const validateEmail = () => email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )

  const handleChangeEmail = (event) => {
    setError('')
    setEmail(event.target.value);
  }

  return (
    <>
      <Typography textType="heading3" text="Please, put your email to continue" />
      <Form.Group className="mb-3" controlId="email">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="Email"
          value={email}
          onChange={handleChangeEmail}
      />
        {error && <Form.Text>{error.message}</Form.Text>}
      </Form.Group>
      <Button
        label="Submit"
        onClick={saveEmail}
        disabled={!validateEmail(email)}
      />
    </>
  )
}

export default GuestEmail;
