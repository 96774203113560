import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { ADVISORY_SLIDER_SLUGS } from '../../constants/pageSlugs';
import { fetchContentfulPage } from '../../services/apiService';
import Typography from '../ui/typography/Typography';

const AdvisorySlider = () => {
  const [sliderData, setSliderData] = useState(null)

  useEffect(() => {
    fetchContentfulPage(ADVISORY_SLIDER_SLUGS)
    .then((res) => {
      setSliderData(res.data);
    })
  }, [])

  if (!sliderData) return null;

  const settings = {
    slidesToShow: 1,
    dots: true,
    infinite: true,
    centerMode: true,
    variableWidth: true,
    className: "slider advisory-slider variable-width",
    responsive: [
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          variableWidth: false,
          centerMode: false,
        }
      }
    ]
  }

  return (
    <Slider
      className="advisory-slider"
      {...settings}
    >
      {Object.values(sliderData).map((advisor, index) => (
        <div className="slide-item" key={index} style={{ width: 650 }}>
          {advisor.photo && (
            <img
              src={`https:${advisor.photo.fields.file.url}`}
              alt={advisor.photo.fields.title}
            />
          )}
          <div className="advisory-description">
            <b className="advisor-name">{advisor.name}</b>
            <b className="advisor-job">{advisor.jobTitle}</b>
            <Typography textType={'body1'} text={documentToPlainTextString(advisor.intro)} />
          </div>
        </div>
      ))}
    </Slider>
  )
}

export default AdvisorySlider
