import clsx from 'clsx';
import Typography from '../ui/typography/Typography'
import { useState } from 'react';
import InfoCircleIcon from '../ui/icons/InfoCircleIcon';
import { Modal } from 'react-bootstrap';

const ProgressLine = ({
  name,
  lowLabel,
  highLabel,
  inverted,
  value,
  popupTitle,
  popupText,
}) => {
  const [showModal, setShowModal] = useState(false)

  const getProgress = (sections) => {
    let activeSections;
    if (value === 'less') {
      activeSections = 1
    }
    if (value === 'avarage') {
      activeSections = 2
    }
    if (value === 'more') {
      activeSections = 3
    }

    return Array.from(Array(sections)).map((_, index) => (
      <div className={clsx('progress-line-bar', { [value]: index < activeSections })} key={index}></div>
    ))
  }

  return (
    <div className={`progress-line-item ${inverted ? 'inverted' : '' }`}>
      <div className="d-flex align-items-center gap-4">
        <div onClick={() => setShowModal(true)} className="cursor-pointer lh-1">
          <InfoCircleIcon />
        </div>
        <Typography textType="body3B" text={name} />
      </div>
      <div>
        <div className="d-flex justify-content-between mb-1">
          <Typography textType="body3" text={lowLabel} />
          <Typography textType="body3" text={highLabel} />
        </div>
        <div className="progress-line-bars">
          {getProgress(3)}
        </div>
      </div>

      <Modal show={showModal} contentClassName="question-modal" onHide={() => setShowModal(false)} centered>
          <Modal.Header closeButton>
            <Modal.Title className="gap-4 d-flex align-items-center">
              <InfoCircleIcon />
              {popupTitle}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="question-modal-text text-start" dangerouslySetInnerHTML={{ __html: popupText }} />
          </Modal.Body>
        </Modal>
    </div>
  )
}

export default ProgressLine
