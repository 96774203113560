import IconPropTypes from './IconPropTypes';

function WellnessUpdateIcon({
  size = 54,
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 70 70"
    >
      <path
        stroke="#44C8F5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4.019"
        d="M54.847 35.595c.582-2.044.894-4.2.894-6.428 0-13.24-11.03-23.982-24.634-23.982-13.604 0-24.626 10.741-24.626 23.982 0 13.24 11.03 23.981 24.626 23.981 2.247 0 4.428-.295 6.495-.846"
      ></path>
      <path
        stroke="#44C8F5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4.019"
        d="M64.543 59.275c1.104-2.11 1.366-4.752.34-7.776l-.009-.035a2.012 2.012 0 00-.088-.27 2.802 2.802 0 00-.03-.08l-.011-.03-.05-.131a140.178 140.178 0 00-.777-2.028c-.453-1.153-1.03-2.582-1.456-3.454a5.211 5.211 0 00-.353-.637l-.008-.013c-1.01-1.648-2.968-1.786-4.159-1.311a3.669 3.669 0 00-.096.04 3.512 3.512 0 00-2.917-.073 3.617 3.617 0 00-.67.362 3.56 3.56 0 00-1.983-.032l-1.04-2.909c-.602-1.69-2.558-3.413-4.835-2.472-2.149.888-2.542 3.37-1.938 5.054l.001.002 1.759 4.921a3.2 3.2 0 00-1.61.568h-.001a2.854 2.854 0 00-1.25 2.481c.026.778.34 1.456.58 1.901.26.48.584.952.857 1.34l.188.266c.213.302.393.556.552.806.773 1.217 2.413 3.613 2.967 4.262h0l.008.009c.805.932 1.685 1.82 2.546 2.411 2.777 2.125 5.974 2.194 8.803 1.031h0c1.748-.719 3.58-2.1 4.68-4.203zM30.82 41.295c-.465-.305-11.375-7.518-11.375-15.163 0-3.687 2.786-6.688 6.21-6.688 2.307 0 4.386 1.382 5.456 3.484 1.07-2.11 3.149-3.483 5.456-3.483 3.424 0 6.21 3 6.21 6.687 0 7.645-10.91 14.858-11.375 15.163l-.29.186-.292-.186v0z"
      ></path>
    </svg>
  );
}

WellnessUpdateIcon.propTypes = IconPropTypes

export default WellnessUpdateIcon;
