import PropTypes from 'prop-types';
import clsx from 'clsx';

const Button = ({
  label,
  onClick,
  type = 'primary',
  leftIcon,
  rightIcon,
  fullWidth,
  className,
  size = 'normal',
  disabled,
  ...props
}) => {

  const renderPrefix = leftIcon && (
    <span className="d-flex align-items-center">{leftIcon}</span>
  )

  const renderSuffix = rightIcon && (
    <span className="d-flex align-items-center">{rightIcon}</span>
  )

  const btnContentClassNames = clsx('button', {
    [`button--${type}`]: type,
    [`button--${size}`]: size,
    'button-disabled': disabled,
  })

  return (
    <button
      className={`${fullWidth ? 'w-100' : ''} ${btnContentClassNames} ${className}`}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {renderPrefix}
      {label}
      {renderSuffix}
    </button>
  )
}

Button.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'outline-primary',
  ]),
  icon: PropTypes.any,
  leftIcon: PropTypes.any,
  rightIcon: PropTypes.any,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf([
    'large',
    'normal',
    'medium',
    'small',
    'extra-small',
  ])
}

export default Button
