import { useEffect, useState } from 'react';
import { Col, Container, FloatingLabel, Form, Row } from 'react-bootstrap'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import useKnowledgeLibrary from '../../../hooks/useKnowledgeLibrary';
import Link from '../../../components/ui/link/Link';
import WellnessAreaTile from '../../../components/KnowledgeLibrary/WellnessAreaTile';
import { useGetDashboardByIdQuery } from './dashboardSlice';
import Typography from '../../../components/ui/typography/Typography';
import BackButton from '../../../components/sidebar/BackButton';
import { useGetAccountByIdQuery } from '../../account/accountSlice';
import { useContentful } from '../../../hooks/useContentful';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import Button from '../../../components/ui/button/Button';
import DirectionLineRight from '../../../components/ui/icons/DirectionLineRight';

const slugs = [
  'babys_biome_analysis',
  'recommendations_superfoods_intake_note',
]

const DashboardLayout = () => {
  const navigate = useNavigate()
  const { childId, wellnessArea: wellnessAreaSlug } = useParams()
  const location = useLocation()
  const { getWellnessAreas } = useKnowledgeLibrary();
  const { data: contentfulText } = useContentful({
    content_type: 'randomParagraph',
    'fields.name[in]': slugs.join(','),
    convertToObject: true,
  })
  const [wellnessAreas, setWellnessAreas] = useState([]);
  const { data, isLoading } = useGetDashboardByIdQuery(childId)
  const { data: accountData } = useGetAccountByIdQuery(childId)
  const currentWellnessArea = wellnessAreas.find(item => item.slug === wellnessAreaSlug)
  const isRecommendationPage = location.pathname.includes('recommendations');
  const sidebarTitle = isRecommendationPage ? `${accountData?.title || 'Baby'}’s Recommendations` : `${accountData?.title || 'Baby'}’s Biome Analysis`
  const mobileTitle = isRecommendationPage ? `${accountData?.title || 'Baby'}’s Recommendations` : documentToPlainTextString(contentfulText?.babys_biome_analysis.fields.content).replace('{{baby.name}}', accountData?.title)
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    getWellnessAreas().then(areas => setWellnessAreas(areas.sort()));
  }, [childId])

  const toggleVisibility = () => {
    if (window.scrollY > 500) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const renderAreas = () => {
    return data.map(dataItem => {
      const contentfulData = wellnessAreas.find(contentfulItem => contentfulItem.slug === dataItem.contentful_slug)
      const inverted = dataItem?.targets.some(item => item.target_type !== 'beneficial')

      return (
        <Link to={`/wellness/dashboard/${childId}/${contentfulData.slug}`} key={contentfulData.id}>
          <WellnessAreaTile
            icon={contentfulData.icon}
            title={contentfulData.name}
            graphData={dataItem.summary}
            active={wellnessAreaSlug === contentfulData.slug}
            inverted={inverted}
          />
        </Link>
      )
    })
  }

  const handleChangeArea = (event) => {
    navigate(`/wellness/dashboard/${childId}/${event.target.value}`)
  }

  if (isLoading || wellnessAreas.length === 0) return (
    <div>Loading...</div>
  )

  return (
    <div className="bg-disabled">
      <div className="d-lg-none bg-white px-6 py-3">
        <div className="d-flex align-items-center justify-content-between mb-2">
          <Typography textType="heading3" text={mobileTitle} />
          <img src={currentWellnessArea?.icon} alt={currentWellnessArea?.name} width={40} height={40} />
        </div>
        <FloatingLabel>
          <Form.Select
            aria-label="Selected wellness area"
            className="w-100 area-select px-4 py-0"
            defaultValue={wellnessAreaSlug}
            onChange={handleChangeArea}
          >
            {data.map(area => (
              <option
                key={area.contentful_slug}
                value={area.contentful_slug}
                >{area.title}</option>
            ))}
          </Form.Select>
        </FloatingLabel>
      </div>
      <Container className='py-0'>
        <Row>
          <Col lg={3} className="sidebar">
            <div className="sidebar-content">
              <BackButton to={-1} text="Back" />
              <Typography textType="heading4" font="Moranga" text={sidebarTitle} title={sidebarTitle} />
              <hr className="my-6" />
              <div>
                <Typography textType="heading4" text="Wellness Areas" className="mb-10" block />
                <div className="d-flex flex-column gap-4 mb-8">{renderAreas()}</div>
              </div>
            </div>
          </Col>
          <Col lg={9} className="main-content py-10 py-lg-20">
            <Row>
              <Col xs={12}>
                <div className="d-none d-lg-flex gap-5 align-items-center">
                  <img src={currentWellnessArea?.icon} alt={currentWellnessArea?.name} width={40} height={40} />
                  <Typography textType="heading3" font="Moranga" text={currentWellnessArea.name} />
                </div>
                {wellnessAreaSlug === 'wellness_area_superfoods_intake' && <Typography textType="body1B" className="mt-2" text={documentToPlainTextString(contentfulText?.recommendations_superfoods_intake_note.fields.content)} />}
              </Col>
            </Row>
            <Outlet />

            <Row id='navBtns' className={`mt-md-8 gap-2 py-3 mx-0 d-lg-none  ${!isRecommendationPage && isVisible ? '' : 'd-none'}`}>
              {isRecommendationPage ? (
                <Link to={`${wellnessAreaSlug}`} className="recommendations-button">
                  <Button
                    label="Back to Dashboard"
                    rightIcon={<DirectionLineRight />}
                    fullWidth
                  />
                </Link>
              ) : (
                <Link to={`${wellnessAreaSlug}/recommendations`} className="recommendations-button">
                  <Button
                    label="View recommendations"
                    rightIcon={<DirectionLineRight />}
                    fullWidth
                  />
                </Link>
              )}
            </Row>

          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default DashboardLayout
