import { useEffect, useState } from 'react';
import Button from '../../components/ui/button/Button';
import { Col, Container } from 'react-bootstrap'
import { useAuth0 } from '@auth0/auth0-react';
import { useSearchParams } from 'react-router-dom';
import Link from '../../components/ui/link/Link';
import Typography from '../../components/ui/typography/Typography';
import { fetchContentfulPage } from '../../services/apiService';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import ReactPlayer from "react-player";
import DirectionLineRight from '../../components/ui/icons/DirectionLineRight';

const Activated = () => {
  const [searchParams] = useSearchParams();
  const accountId = searchParams.get('account_id');
  const [contentfulData, setContentfulData] = useState(null);

  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect,
  } = useAuth0();

  useEffect(() => {
    fetchContentfulPage([
        'activate_your_kit_video',
        'kit_activation_success_title',
        'kit_activation_success_text',
        'button_view_dashboard'
      ])
      .then((res) => {
        setContentfulData(res.data);
      })
  }, [])

  if (isLoading || !contentfulData) {
    return (
      <>
        Loading...
      </>
    )
  }
  const {
    activate_your_kit_video,
    kit_activation_success_title,
    kit_activation_success_text,
    button_view_dashboard,
  } = contentfulData;
  
  if (isAuthenticated) {
    return (
      <div className="activated-page">
        <Container>
          <Col xs={12} md={7} className="text-center mx-auto">
            {kit_activation_success_title && (
              <Typography textType="heading2" text={documentToPlainTextString(kit_activation_success_title?.content)} className="font-Moranga mb-4" />
            )}
            {kit_activation_success_text && (
              <Typography textType="body1B" text={documentToPlainTextString(kit_activation_success_text?.content)} className="mb-8" />
            )}
            <div className='my-6 my-md-8 video-block'>
              {activate_your_kit_video && (
                <div className='video-wrapper mx-auto'>
                  <ReactPlayer
                    url={documentToPlainTextString(activate_your_kit_video?.videoUrl)}
                    controls
                    className="video-responsive"
                  />
                </div>
              )}
            </div>
            {accountId && (
              <Link to={`/account/my-babies/${accountId}`} className="mt-8 d-inline-block">
                <Button 
                  label={button_view_dashboard && documentToPlainTextString(button_view_dashboard?.content)}
                  rightIcon={<DirectionLineRight />}
                  />
              </Link>
            )}
          </Col>
        </Container>
      </div>
    )
  }
  
  return (
    <>
      <div className="container py-10 text-center">
        <h5 className='pb-5'>Please login to continue</h5>
        <Button onClick={loginWithRedirect} label="Log In" type="secondary" size="large" />
      </div>
    </>
  )
}

export default Activated
