import { useState, useEffect } from 'react'
import { Col, Row } from 'react-bootstrap'
import BacteriaResultCard from '../../../components/dashboard/BacteriaResultCard'
import ComparisonGraphCard from '../../../components/dashboard/ComparisonGraphCard'
import DashboardTextCard from '../../../components/dashboard/DashboardTextCard'
import Button from '../../../components/ui/button/Button'
import { useParams } from 'react-router-dom'
import useKnowledgeLibrary from '../../../hooks/useKnowledgeLibrary'
import { useGetDashboardByIdQuery } from './dashboardSlice'
import Link from '../../../components/ui/link/Link'
import Typography from '../../../components/ui/typography/Typography'
import { useContentful } from '../../../hooks/useContentful'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import DirectionLineRight from '../../../components/ui/icons/DirectionLineRight'
import { useGetAccountByIdQuery } from '../../account/accountSlice'

const resultSlugs = [
  'result_title_brain_development_excellent',
  'result_title_brain_development_good',
  'result_title_brain_development_fair',

  'result_title_immune_system_excellent',
  'result_title_immune_system_good',
  'result_title_immune_system_fair',

  'result_title_weight_regulation_excellent',
  'result_title_weight_regulation_good',
  'result_title_weight_regulation_fair',

  'result_title_superfoods_excellent',
  'result_title_superfoods_good',
  'result_title_superfoods_fair',

  'result_title_tummy_agitators_low',
  'result_title_tummy_agitators_average',
  'result_title_tummy_agitators_elevated',

  'result_title_rash_bacteria_low',
  'result_title_rash_bacteria_average',
  'result_title_rash_bacteria_elevated',
]

const DashboardResults = () => {
  const { wellnessArea: wellnessAreaSlug, childId } = useParams()
  const { getWellnessAreas } = useKnowledgeLibrary()
  const [wellnessAreas, setWellnessAreas] = useState([])
  const currentWellnessArea = wellnessAreas.find(item => item.slug === wellnessAreaSlug)
  const { data, isLoading } = useGetDashboardByIdQuery(childId)
  const { data: babyData } = useGetAccountByIdQuery(childId)
  const userData = data?.find(item => item.contentful_slug === wellnessAreaSlug)
  const { data: accountData } = useGetAccountByIdQuery(childId)
  const inverted = userData?.targets.some(item => item.target_type !== 'beneficial')

  const { data: resultText } = useContentful({
    content_type: 'randomParagraph',
    'fields.name[in]': resultSlugs.join(','),
    convertToObject: true,
  })

  const { data: pageData } = useContentful({
    content_type: 'wellnessAreaResultPageBrainDevelopment',
    convertToObject: true,
  })

  const getResultPageData = () => {
    let data;
    switch(wellnessAreaSlug) {
      case 'wellness_area_brain_development':
        data = {
          ...pageData?.result_template_brain_development,
          title_more: resultText?.result_title_brain_development_excellent,
          title_avarage: resultText?.result_title_brain_development_good,
          title_less: resultText?.result_title_brain_development_fair,
        }
        break
      case 'wellness_area_immune_system_development':
        data = {
          ...pageData?.result_template_immune_system_development,
          title_more: resultText?.result_title_immune_system_excellent,
          title_avarage: resultText?.result_title_immune_system_good,
          title_less: resultText?.result_title_immune_system_fair,
        }
        break
      case 'wellness_area_healthy_weight_regulation':
        data = {
          ...pageData?.result_template_healthy_weight_regulation,
          title_more: resultText?.result_title_weight_regulation_excellent,
          title_avarage: resultText?.result_title_weight_regulation_good,
          title_less: resultText?.result_title_weight_regulation_fair,
        }
        break
      case 'wellness_area_superfoods_intake':
        data = {
          ...pageData?.result_template_superfoods_intake,
          title_more: resultText?.result_title_superfoods_excellent,
          title_avarage: resultText?.result_title_superfoods_good,
          title_less: resultText?.result_title_superfoods_fair,
        }
        break
      case 'wellness_area_presence_of_rash_bacteria':
        data = {
          ...pageData?.result_template_rash_bacteria,
          title_more: resultText?.result_title_rash_bacteria_elevated,
          title_avarage: resultText?.result_title_rash_bacteria_average,
          title_less: resultText?.result_title_rash_bacteria_low,
        }
        break
      case 'wellness_area_presence_of_gut_irritating_bacteria':
        data = {
          ...pageData?.result_template_gut_irritating_bacteria,
          title_more: resultText?.result_title_tummy_agitators_elevated,
          title_avarage: resultText?.result_title_tummy_agitators_average,
          title_less: resultText?.result_title_tummy_agitators_low,
        }
        break
    }

    return {
      ...data?.fields,
      title_more: data.title_more,
      title_avarage: data.title_avarage,
      title_less: data.title_less,
    };
  }


  useEffect(() => {
    getWellnessAreas().then(areas => setWellnessAreas(areas));
  }, [childId])

  if (!currentWellnessArea || isLoading || !accountData || !resultText || !pageData) {
    return <>Loading...</>;
  }

  const dashboardData = getResultPageData();
  
  return (
    <div>
      <Row>
        <Col xs={12} lg={6} className="d-flex align-items-end mb-8">
          <ComparisonGraphCard
            value={userData.summary}
            contentfulData={dashboardData?.howBabyComparesComponent.fields}
            name={babyData?.title || 'Baby'}
            inverted={inverted}
          />
        </Col>
        <Col xs={12} lg={6} className="d-flex align-items-end mb-lg-8">
          <BacteriaResultCard
            bacteriaData={userData.targets}
            contentfulData={dashboardData?.bacteriaResultComponent.fields}
            title={userData.summary && dashboardData?.[`title_${userData.summary}`]}
            babyName={accountData.title}
          />
        </Col>
        <Col xs={12}>
          {dashboardData?.recommendationButtonNote && <Typography textType="body1" className="mt-8 mt-md-2" text={documentToReactComponents(dashboardData?.recommendationButtonNote)} />}
          <Link 
            to={`/wellness/dashboard/${childId}/${wellnessAreaSlug}/recommendations`} 
            className="recommendations-button d-block">
            <Button
              label={dashboardData?.recommentationButton}
              rightIcon={<DirectionLineRight />}
              fullWidth
            />
          </Link>
        </Col>

        <Col xs={12}>
          <hr className="my-8" />
        </Col>

        <Col xs={12}>
          <DashboardTextCard
            image={dashboardData?.image[0].fields.file.url}
            title={documentToPlainTextString(dashboardData?.whyItMatters)}
            text={documentToReactComponents(dashboardData?.whyItMattersText)}
            button={dashboardData?.readTheStudiesButton}
            rightIcon={<DirectionLineRight />}
          />
        </Col>
      </Row>
    </div>
  )
}

export default DashboardResults
