import { FloatingLabel, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Button from '../../components/ui/button/Button'
import GoogleIcon from '../../components/ui/icons/GoogleIcon'
import MicrosoftIcon from '../../components/ui/icons/MicrosoftIcon'
import { useCustomAuth0 } from './CustomAuth0Provider'
import { useEffect, useState } from 'react'
import ErrorIcon from '../../components/ui/icons/Error'
import withoutAuthorization from '../../components/containers/withoutAuthorization'
import { fetchContentfulPage } from '../../services/apiService'
import { getContentfulContent, getContentfulImage } from '../../utils/get-contentful-content'
import PasswordFormControl from '../../components/ui/forms/PasswordFormControl'

const slugs = [
  'sign_in_title',
  'sign_in_subtitle',
  'form_email_address',
  'form_password',
  'eye_off_icon',
  'forgot_password',
  'button_sign_in',
  'separator_or',
  'button_sign_in_google',
  'button_sign_in_microsoft',
  'checkout_sign_in_new_customer',
  'button_create_account',
  'wrong_password',
];

const SignIn = () => {
  const [searchParams] = useSearchParams();
  const {
    loginWithGoogle,
    loginWithMicrosoft,
    loginWithUsernamePassword,
  } = useCustomAuth0({
    redirectUrl: searchParams.get('redirect_url') || '/'
  });
  const navigate = useNavigate();
  const [authError, setAuthError] = useState(null);

  const LoginSchema = yup.object().shape({
    username: yup.string().required('Please enter your email address.'),
    password: yup.string().required('Please enter your password.'),
  });

  const validationOpt = {resolver: yupResolver(LoginSchema)};
  const {
    register,
    formState: { errors },
    watch,
  } = useForm(validationOpt);
  const formData = watch();

  const [pageData, setPageData] = useState({})
  const [error, setError] = useState(false)

  useEffect(() => {
    fetchContentfulPage(slugs)
    .then((res) => {
      setPageData(res.data);
      setError(false);
    }).catch(() => {
      setError(true);
    })
  }, [])

  const onSignInClick = () => {
    loginWithUsernamePassword(formData.username, formData.password)
      .catch(error => {
        setAuthError(error.description)
      });
  }

  return (
    <div className="login-box">
      <div className="login-header">
        <h3>{getContentfulContent(pageData.sign_in_title)}</h3>
        <span>{getContentfulContent(pageData.sign_in_subtitle)}</span>
      </div>
      <div className="login-body">
        <FloatingLabel
          controlId="email"
          label={getContentfulContent(pageData.form_email_address)}
          className="mb-3"
        >
          <Form.Control
            type="email"
            placeholder="name@example.com"
            {...register('username', {
                onChange: () => {
                  setAuthError(null)
                },
            })}
          />
        </FloatingLabel>
        <FloatingLabel
          controlId="password"
          label={getContentfulContent(pageData.form_password)}
          className="mb-3"
        >
          <PasswordFormControl
            placeholder={getContentfulContent(pageData.form_password)}
            register={register}
            name="password"
            icon={getContentfulImage(pageData.eye_off_icon).url}
          />
        </FloatingLabel>
        {authError && <p className='body2 p-1 mb-0 fw-600'><ErrorIcon className='mx-1' />{authError}</p>}
        <div className="captcha-container form-group"></div>
        <div className="forgot-password-link-wrap">
          <button id="link-text-forgot" className="forgot-password-link" onClick={() => navigate('/auth/forgot-password')}>
            {getContentfulContent(pageData.forgot_password)}
          </button>
        </div>
        <Button
          label={getContentfulContent(pageData.button_sign_in)}
          onClick={onSignInClick}
          name="sign-in-button"
          fullWidth
        />
        <div className="or-label">
          <span>{getContentfulContent(pageData.separator_or)}</span>
        </div>
        <button
          type="button"
          id="btn-google"
          className="social-sign-in-btn"
          onClick={loginWithGoogle}
        >
          <GoogleIcon />
          {getContentfulContent(pageData.button_sign_in_google)}
        </button>
        <button
          type="button"
          id="btn-microsoft"
          className="social-sign-in-btn d-none"
          onClick={loginWithMicrosoft}
        >
          <MicrosoftIcon />
          {getContentfulContent(pageData.button_sign_in_microsoft)}
        </button>
      </div>
      <div className="login-footer">
        {getContentfulContent(pageData.checkout_sign_in_new_customer)}
        <button
          type="button"
          id="btn-signup"
          className="btn btn-default btn-block"
          onClick={() => navigate('/auth/sign-up-method')}
        >
          {getContentfulContent(pageData.button_create_account)}
        </button>
      </div>
    </div>
  )
}

export default withoutAuthorization(SignIn);
