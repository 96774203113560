import { useEffect, useState } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Typography from '../components/ui/typography/Typography';
import Button from '../components/ui/button/Button';
import { ABOUT_US_SLUGS } from '../constants/pageSlugs';
import { fetchContentfulPage } from '../services/apiService';
import Link from '../components/ui/link/Link'
import { getContentfulContent, getContentfulImage } from '../utils/get-contentful-content';

const AboutUs = () => {
  const [contentfulData, setContentfulData] = useState(null);

  useEffect(() => {
    fetchContentfulPage(ABOUT_US_SLUGS)
      .then((res) => {
        setContentfulData(res.data);
      })
  }, [])

  if (!contentfulData) {
    return (
      <>
        Loading...
      </>
    )
  }

  const {
    about_us_top_image,
    about_us_title,
    about_us_subtitle,
    about_us_text,

    about_us_our_mission_title,
    about_us_our_mission_text,
    about_us_our_mission_image,

    about_us_microbiome_title,
    about_us_microbiome_text,
    about_us_microbiome_image,
    landing_how_it_works_image_2,
    about_us_insights_title,
    about_us_insights_text,
    button_take_quiz,
    button_get_started,

    landing_meet_our_experts_title,
    advisor_slide_1,
    advisor_slide_2,
    advisor_slide_3,

    about_us_ease_title,
    about_us_ease_image,
    about_us_ease_text,
    about_us_positivity_title,
    about_us_positivity_image,
    about_us_positivity_text,
    about_us_fuss_free_title,
    about_us_fuss_free_image,
    about_us_fuss_free_text,
    about_us_our_values_title,

    brought_to_you_text,

    tc_discovery_logo_gray,
    brought_to_you_title,
  } = contentfulData;

  return (
    <>
      <section className="about-us-page about-us-page-desktop" data-testid="about-us-container">
        <Container>
          <Row>
            <Col>
              <div className="about-us-hero-content">
                <div>
                  {about_us_title && <Typography textType="heading2" className="font-Moranga mb-6 mb-md-8 title-text" text={documentToPlainTextString(about_us_title.content)} />}
                  {about_us_text && <Typography textType="body1" text={documentToReactComponents(about_us_text.text)} className="subtitle pink-title-tiniest-biome" />}
                </div>
              </div>
            </Col>
            <Col>
              <div className="hero-image">
                <div className="hero-image-background" />
                {about_us_top_image && (
                  <img className="rounded img-fluid" src={about_us_top_image.image?.fields.file.url} alt={about_us_top_image.image?.fields.title || ''} />
                )}
                <div className="hero-image-shadow" />
              </div>
            </Col>

          </Row>
        </Container>
      </section>

      <section className="gray-gradient-section microbiome-section" data-testid="microbiome-container">
        <div className="gradient-start d-none d-md-block" />
        <div className="gradient-content py-8 py-md-0">
          <Container className='px-0 px-md-10'>
            <Card className='d-flex align-items-center w-100 py-md-10 gap-md-20'>
              <Row>
                <Col className="d-md-flex mb-12 text-center img-center">
                  {about_us_microbiome_image?.image && <img src={about_us_microbiome_image.image.fields.file.url} className="how-it-works-image" alt="" />}
                </Col>
                <Col>
                  {about_us_microbiome_title && <Typography textType={'heading3'} className="mb-6 mb-md-8 d-none d-md-block title-text" text={documentToPlainTextString(about_us_microbiome_title.content)} />}
                  {about_us_microbiome_title && <Typography textType={'heading2'} className="mb-6 mb-md-8 d-block d-md-none font-Moranga title-text" text={documentToPlainTextString(about_us_microbiome_title.content)} />}
                  {about_us_microbiome_text && <span>{documentToPlainTextString(about_us_microbiome_text.content)}</span>}
                </Col>
              </Row>
              <Row>
                <Col>
                  {about_us_insights_title && <Typography textType={'heading3'} className="mb-6 mb-md-8 d-none d-md-block title-text" text={documentToPlainTextString(about_us_insights_title.content)} />}
                  {about_us_insights_title && <Typography textType={'heading2'} className="mb-6 mb-md-8 d-block d-md-none font-Moranga title-text" text={documentToPlainTextString(about_us_insights_title.content)} />}
                  {about_us_insights_text && <span>{documentToPlainTextString(about_us_insights_text.content)}</span>}
                  {button_get_started &&
                    <Link to="/kits/baby-microbiome-gut-health-test-kit">
                      <Button role="button" className="mt-md-10 me-auto hero-button" label={documentToPlainTextString(button_get_started.content)} />
                    </Link>
                  }
                </Col>
                <Col className="d-md-flex mb-12 text-center img-center">
                  {landing_how_it_works_image_2?.image && <img src={landing_how_it_works_image_2.image.fields.file.url} className="how-it-works-image" alt="" />}
                </Col>
              </Row>
            </Card>
          </Container>
        </div>
        <div className="gradient-end" />
      </section>
        
      <section className="about-us-page about-us-page-desktop about-us-experts" data-testid="experts-container">
        <Container>
          <Row className="about-us-advisors">
            <div className="text-center font-Moranga">
              {landing_meet_our_experts_title && <Typography textType="heading1" className="font-Moranga mb-6 mb-md-8 title-text" text={documentToPlainTextString(landing_meet_our_experts_title.content)} />}
            </div>
            <div className="slide-item" key="advisor_slide_1">
              <img
                className="advisor-portrait d-none d-md-block"
                src={getContentfulImage(advisor_slide_1).url}
                alt={getContentfulImage(advisor_slide_1).title}
              />
              <div className="advisory-description">
                <img
                  className="advisor-portrait d-flex d-md-none mx-auto mb-2"
                  src={getContentfulImage(advisor_slide_1).url}
                  alt={getContentfulImage(advisor_slide_1).title}
                />
                {advisor_slide_1 && <Typography textType={'heading3'} text={advisor_slide_1.name} />}
                {advisor_slide_1 && <Typography textType={'heading5'} text={advisor_slide_1.jobTitle} block />}
                {advisor_slide_1 && <Typography textType={'body1'} text={documentToPlainTextString(advisor_slide_1.bio)} />}
              </div>
            </div>
            <div className="slide-item" key="advisor_slide_2" >
              <img
                className="advisor-portrait d-none d-md-block"
                src={getContentfulImage(advisor_slide_2).url}
                alt={getContentfulImage(advisor_slide_2).title}
              />
              <div className="advisory-description">
                <img
                  className="advisor-portrait d-flex d-md-none mx-auto mb-2"
                  src={getContentfulImage(advisor_slide_2).url}
                  alt={getContentfulImage(advisor_slide_2).title}
                />
                {advisor_slide_2 && <Typography textType={'heading3'} text={advisor_slide_2.name} />}
                {advisor_slide_2 && <Typography textType={'heading5'} text={advisor_slide_2.jobTitle} block />}
                {advisor_slide_2 && <Typography textType={'body1'} text={documentToPlainTextString(advisor_slide_2.bio)} />}
              </div>
            </div>
            <div className="slide-item" key="advisor_slide_3">
              <img
                className="advisor-portrait d-none d-md-block"
                src={getContentfulImage(advisor_slide_3).url}
                alt={getContentfulImage(advisor_slide_3).title}
              />
              <div className="advisory-description">
                <img
                  className="advisor-portrait d-flex d-md-none mx-auto mb-2"
                  src={getContentfulImage(advisor_slide_3).url}
                  alt={getContentfulImage(advisor_slide_3).title}
                />
                {advisor_slide_3 && <Typography textType={'heading3'} text={advisor_slide_3.name} />}
                {advisor_slide_3 && <Typography textType={'heading5'} text={advisor_slide_3.jobTitle} block />}
                {advisor_slide_3 && <Typography textType={'body1'} text={documentToPlainTextString(advisor_slide_3.bio)} />}
              </div>
            </div>
          </Row>
        </Container>
      </section>

      <section className="blue-gradient-section" data-testid="mission">
        <div className="gradient-start" />
        <div className="gradient-content py-8 py-md-0">
          <Container>
            <Row className="text-center text-md-start">
              <Col xs={12} md={4} className="d-flex">
                {about_us_our_mission_image && (
                  <img className="rounded img-fluid m-auto" src={about_us_our_mission_image.image?.fields.file.url} alt={about_us_our_mission_image.image?.fields.title || ''} />
                )}
              </Col>
              <Col xs={12} md={8}>
                {about_us_our_mission_title && <Typography textType="heading1" className="font-Moranga mb-6 mb-md-8 title-text" text={documentToPlainTextString(about_us_our_mission_title.content)} />}
                {about_us_our_mission_text && <Typography textType="heading2"  className="subtitle" text={documentToPlainTextString(about_us_our_mission_text.content)} />}
              </Col>
            </Row>
          </Container>
        </div>
        <div className="gradient-end" />
      </section>
      <section className="mt-8 mt-md-0 our-values" data-testid="values">
        <Container>
          <Row>
            <Col xs={12} className="d-none d-md-block mb-12 text-center font-Moranga">
              {<Typography textType="heading1" block className="font-Moranga mb-6 mb-md-8 title-text text-center" text={getContentfulContent(about_us_our_values_title)} />}
            </Col>
            <Col>
              <div className="about-us-card-big">
                {<Typography textType="heading1" className="d-md-none font-Moranga mb-6 mb-md-8 title-text" text={getContentfulContent(about_us_our_values_title)} />}
                <div className="items-wrap">
                  <div className="item">
                    {about_us_ease_title && <Typography textType="heading3" text={documentToPlainTextString(about_us_ease_title.content)} className="mb-6 d-none d-md-block" />}
                    {about_us_ease_image?.image && <img src={about_us_ease_image.image.fields.file.url} className="how-it-works-image" alt="" />}
                    {about_us_ease_title && <Typography textType="heading2" text={documentToPlainTextString(about_us_ease_title.content)} className="mb-6 d-md-none font-Moranga" />}
                    {about_us_ease_text && <span>{documentToPlainTextString(about_us_ease_text.content)}</span>}
                  </div>
                  <div className="item">
                    {about_us_positivity_title && <Typography textType="heading3" text={documentToPlainTextString(about_us_positivity_title.content)} className="mb-6 d-none d-md-block" />}
                    {about_us_positivity_image?.image && <img src={about_us_positivity_image.image.fields.file.url} className="how-it-works-image" alt="" />}
                    {about_us_positivity_title && <Typography textType="heading2" text={documentToPlainTextString(about_us_positivity_title.content)} className="mb-6 d-md-none font-Moranga" />}
                    {about_us_positivity_text && <span>{documentToPlainTextString(about_us_positivity_text.content)}</span>}
                  </div>
                  <div className="item">
                    {about_us_fuss_free_title && <Typography textType="heading3" text={documentToPlainTextString(about_us_fuss_free_title.content)} className="mb-6 d-none d-md-block" />}
                    {about_us_fuss_free_image?.image && <img src={about_us_fuss_free_image.image.fields.file.url} className="how-it-works-image" alt="" />}
                    {about_us_fuss_free_title && <Typography textType="heading2" text={documentToPlainTextString(about_us_fuss_free_title.content)} className="mb-6 d-md-none font-Moranga" />}
                    {about_us_fuss_free_text && <span>{documentToPlainTextString(about_us_fuss_free_text.content)}</span>}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="tcd-section">
        <Container>
          <Row>
            <Col xs={12} md={8} lg={5} className='px-9 px-md-0'>
              <Typography textType="heading2" className="mb-2" text={getContentfulContent(brought_to_you_title)} />
              <div className="tcd-section-content">
                {tc_discovery_logo_gray && <img src={tc_discovery_logo_gray.image.fields.file.url} alt="TerraCycle Discovery" />}
                {brought_to_you_text && <Typography textType="body1" text={documentToReactComponents(brought_to_you_text.content)} />}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default AboutUs
