import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap'

const Hero = props => {
  const { title, subtitle } = props;

  return (
    <section className="px-4 py-5 mb-5 text-center hero-section" data-testid="hero-container">
      <Container>
        <Row>
          <Col>
            <h1 className="fw-bold" data-testid="title">{title}</h1>
            <div className="col-lg-6 mx-auto" data-testid="subtitle">
              <p>{subtitle}</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

Hero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

export default Hero
