function BrainIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        stroke="#444"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.3"
        d="M20.049 5.116v28.419M28.772 4.894h0c-7.313-4.711-8.553-1.575-8.724.222-.054.576 0 1.015 0 1.015"
      ></path>
      <path
        stroke="#444"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.3"
        d="M30.754 9.737a2.98 2.98 0 00.61-1.803c0-1.489-1.109-2.733-2.591-3.04a3.544 3.544 0 00-.717-.073c-1.827 0-3.307 1.393-3.307 3.113"
      ></path>
      <path
        stroke="#444"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.3"
        d="M35.22 16.083a4.253 4.253 0 00.327-2.667c-.44-2.195-2.498-3.718-4.793-3.68-.269.004-.54.03-.813.079-2.604.462-4.317 2.823-3.826 5.276M32.607 23.835a4.938 4.938 0 002.97-1.711c.828-1.008 1.212-2.33.942-3.68a4.508 4.508 0 00-1.3-2.361"
      ></path>
      <path
        stroke="#444"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.3"
        d="M29.94 14.045c.04.082.077.165.11.251.79 1.98-.274 4.189-2.379 4.933-2.104.743-4.45-.26-5.24-2.24"
      ></path>
      <path
        stroke="#444"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.3"
        d="M25.799 19.451c.027.1.06.2.099.299.592 1.482 2.348 2.233 3.924 1.677.068-.025.134-.051.199-.079M24.803 24.894c.095.01.19.022.287.037 2.214.363 3.697 2.346 3.311 4.43-.385 2.085-2.492 3.48-4.707 3.118M27.471 31.21a3.18 3.18 0 003.214.649M22.022 10.158c-1.184.419-1.928 1.458-1.974 2.577a2.618 2.618 0 000 .235M35.577 22.124c.462.56.792 1.228.941 1.972.423 2.111-.754 4.157-2.728 5.032M33.791 29.128c.223.705.27 1.47.101 2.242-.407 1.864-1.956 3.233-3.818 3.594M20 33.284v.001c.014.085.03.168.049.25.584 2.629 3.26 4.4 6.114 3.988 1.72-.248 3.125-1.232 3.91-2.56M20.048 31.438a4.077 4.077 0 00-.047.249v.001M19.952 31.438V5.116M11.228 4.894h0c7.314-4.711 8.553-1.575 8.724.222.055.576 0 1.015 0 1.015"
      ></path>
      <path
        stroke="#444"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.3"
        d="M15.251 7.934c0-1.72-1.48-3.114-3.307-3.114-.247 0-.486.026-.717.073-1.482.308-2.59 1.552-2.59 3.04 0 .673.226 1.295.61 1.804"
      ></path>
      <path
        stroke="#444"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.3"
        d="M13.885 15.09c.492-2.451-1.222-4.813-3.826-5.275a4.998 4.998 0 00-.812-.078c-2.295-.039-4.354 1.484-4.793 3.679a4.254 4.254 0 00.327 2.667M4.781 16.083a4.51 4.51 0 00-1.3 2.36c-.27 1.35.114 2.673.942 3.68a4.939 4.939 0 002.97 1.712"
      ></path>
      <path
        stroke="#444"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.3"
        d="M10.06 14.045c-.04.082-.077.165-.11.251-.79 1.98.274 4.189 2.379 4.933 2.104.743 4.45-.26 5.24-2.24"
      ></path>
      <path
        stroke="#444"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.3"
        d="M9.979 21.348c.065.028.131.054.199.079 1.575.556 3.332-.195 3.924-1.677.039-.099.072-.199.099-.299M16.005 12.144c.066-.027.131-.055.196-.086 1.5-.717 2.098-2.444 1.336-3.856a2.82 2.82 0 00-.167-.27M15.197 24.894c-.095.01-.19.022-.286.037-2.214.363-3.697 2.346-3.312 4.43.385 2.085 2.493 3.48 4.707 3.118M9.315 31.859a3.18 3.18 0 003.214-.649M19.951 23.524a2.695 2.695 0 000-.234c-.045-1.12-.79-2.16-1.974-2.578M6.21 29.128c-1.975-.875-3.151-2.921-2.729-5.032.15-.744.48-1.413.941-1.972M9.927 34.964c-1.863-.36-3.411-1.73-3.819-3.594a4.327 4.327 0 01.101-2.242"
      ></path>
      <path
        stroke="#444"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.3"
        d="M9.927 34.964c.785 1.327 2.19 2.311 3.91 2.56 2.855.41 5.53-1.36 6.115-3.989a4.84 4.84 0 000-2.097c-.452-2.017-1.6-3.228-3.777-3.884"
      ></path>
    </svg>
  );
}

export default BrainIcon;
