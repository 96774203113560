import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ChevronLeftIcon from '../components/ui/icons/ChevronLeftIcon';

export default function ScrollToTop() {
  const { pathname } = useLocation();
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.scrollY > 500) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, [pathname]);

  return (
    <div className={`scroll-to-top ${isVisible ? 'visible' : ''}`}>
      <div onClick={scrollToTop}>
        <ChevronLeftIcon />
      </div>
    </div>
  );
}
