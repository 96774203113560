import { useState } from 'react';
import { Form } from 'react-bootstrap'

const PasswordFormControl = (props) => {
  const { icon, register, name, ...inputProps } = props;
  const [type, setType] = useState('password');

  const onShowPasswordClick = () => {
    setType(type === 'password' ? 'text' : 'password')
  }

  return (
    <>
      <Form.Control
        type={type}
        {...register(name)}
        {...inputProps}
      />
      <img
        className="show-password-icon"
        src={icon}
        alt=""
        onClick={onShowPasswordClick}
        role="button"
      />
    </>
  )
}

export default PasswordFormControl;
