import PropTypes from 'prop-types';

const Typography = ({
  text,
  textType,
  className,
  color = 'black',
  block,
  leftIcon,
  rightIcon,
  font,
  ...props
}) => {
  if (!text) return null

  const renderPrefix = leftIcon && (
    <span className="d-flex align-items-center">{leftIcon}</span>
  )

  const renderSuffix = rightIcon && (
    <span className="d-flex align-items-center">{rightIcon}</span>
  )

  const classNames = `typography ${textType} ${block ? 'd-block' : 'd-inline-block'} text-${color} ${className} font-${font}`
  const elementProps = { ...props, className: classNames }

  switch (textType) {
    case 'heading1':
      return (
        <h1 {...elementProps}>{renderPrefix}{text}{renderSuffix}</h1>
      )
    case 'heading2':
      return (
        <h2 {...elementProps}>{renderPrefix}{text}{renderSuffix}</h2>
      )

    case 'heading3':
      return (
        <h3 {...elementProps}>{renderPrefix}{text}{renderSuffix}</h3>
      )

    case 'heading4':
      return (
        <h4 {...elementProps}>{renderPrefix}{text}{renderSuffix}</h4>
      )

    case 'heading5':
      return (
        <h5 {...elementProps}>{renderPrefix}{text}{renderSuffix}</h5>
      )

    default:
      return (
        <span {...elementProps}>{text}</span>
      )
  }
}

Typography.propTypes = {
  text: PropTypes.string,
  textType: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  block: PropTypes.bool
}

export default Typography
