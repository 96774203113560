import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useParams, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import KitTrackingCard from '../../components/account/kitTracking/KitTrackingCard';
import withAuthorization from '../../components/containers/withAuthorization';
import Typography from '../../components/ui/typography/Typography';
import useKitTracking from '../../hooks/useKitTracking';
import SpreeService from '../../services/spreeService';
import clsx from 'clsx';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

const sortTrackingStates = (a, b) => {
  if (a.name.split('kit_tracking_step_')[1] > b.name.split('kit_tracking_step_')[1]) {
    return 1;
  }
  if (a.name.split('kit_tracking_step_')[1] < b.name.split('kit_tracking_step_')[1]) {
    return -1;
  }

  return 0;
}

const KitTracking = () => {
  const { getTrackingStates, getTrackingData } = useKitTracking();

  const { orderNumber } = useParams();
  const [searchParams] = useSearchParams();
  const lineItemUnitId = searchParams.get('line_item_unit');
  const [kit, setKit] = useState();
  const [trackingStates, setTrackingStates] = useState([]);
  const [trackingData, setTrackingData] = useState({});
  const trackingDataArray = Object.values(trackingData);
  const activeStateIndex = getActiveStateIndex();

  function getActiveStateIndex() {
    if (trackingDataArray.length === 0) return;
    let activeIndex = 0;
    trackingDataArray.forEach((element, index) => {
      if (element !== null) {
        activeIndex = index;
      }
    });

    return activeIndex
  }

  useEffect(() => {
    getTrackingStates().then(res => {
      setTrackingStates(res.sort(sortTrackingStates))
    });
  }, [])

  useEffect(() => {
    SpreeService.getOrderByNumber(orderNumber).then((kit) => {
      setKit(kit);

      getTrackingData(kit.id, lineItemUnitId).then(res => setTrackingData(res.data))
    })
  }, [orderNumber, lineItemUnitId])

  return (
    <>
      <Typography textType="heading3" text="Kit Tracking" className="d-none d-md-block" />
      <Typography textType="heading3" text="Kit Tracking" className="d-block d-md-none font-Moranga" />
      {kit ? (
        <KitTrackingCard
          kit={kit}
          title={trackingStates[activeStateIndex]?.stepsName}
          text={trackingStates[activeStateIndex]?.ExplanationOnTop}
        />
        ) : null
      }
      <div className="body-card">
        <Typography textType="heading4" text="Tracking Progress" className="mb-6" />
        <div className="tracking-status">
          {trackingStates.map((state, index) => (
            <div key={state.name} className={clsx('tracking-line', {
              loading: trackingDataArray.length === 0,
              completed: trackingDataArray.length !== 0 && trackingDataArray[index] !== null,
              latest: index === activeStateIndex
            })}>
              <img src={state.icon.url} alt="" />
              <div className="tracking-content">
                <div className="tracking-title">
                  <Typography textType="body1B" text={state.stepsName} />
                  {trackingDataArray[index] && (
                    <Typography textType="body1B" text={dayjs(trackingDataArray[index]).format('MMM D, YYYY')} />
                  )}
                </div>
                {index === activeStateIndex && <div>{state.details}</div>}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Row>
        <Col xs={12} lg={6}>
          {trackingStates && trackingDataArray.length > 0 && (
            <div className="body-card">
              <Typography textType="body1B" text={trackingStates[activeStateIndex].instructionTitle} className="mb-4" />
              <Typography textType="body1" text={documentToPlainTextString(trackingStates[activeStateIndex].instructionDietails)} />
            </div>
          )}
        </Col>
      </Row>
    </>
  )
}

export default withAuthorization(KitTracking);
