const normalizeActivity = (item) => {

  return {
    ...item.fields,
    id: item.sys.id,
    ageGroup: item.fields.ageGroup?.map(ageGroup => ageGroup.fields.age),
    image: item.fields.image?.map(image => image.fields.file.url),
    bacteria: item.fields.bacteria?.map(bacteria => bacteria.fields.bacteria),
    wellnessAreas: item.fields.wellnessAreas?.map(area => ({
      ...area.fields,
      id: area.sys.id,
    })),
  }
}

export default normalizeActivity
