import { Container, Nav, Navbar, Offcanvas, NavDropdown } from 'react-bootstrap';
import HamburgerMenu from './../ui/icons/HamburgerMenu';
import Link from '../ui/link/Link';
import CartLink from '../cart/CartLink';
import { getContentfulContent, getContentfulImage } from '../../utils/get-contentful-content';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import Typography from '../ui/typography/Typography';
import { useAuth0 } from '@auth0/auth0-react';
import { useGetAccountTypesQuery } from '../../pages/account/accountTypeSlice';
import { useGetAccountsQuery } from '../../pages/account/accountSlice';
import { useContentful } from '../../hooks/useContentful';

const images = [
  'tiniest_biome_white_logo_png',
  'cart_empty_icon',
  'arrow_icon_down_black',
  'arrow_icon_up_black',
]

const text = [
  'header_shop',
  'header_who_we_are',
  'header_take_the_quiz',
  'header_learn',
  'header_faq',
  'header_knowledge_library',
  'button_get_started',
  'button_activate_kit',
  'button_sign_in',
  'sign_up_text',
  'header_babys_dashboard',
  'header_my_account',
  'header_settings',
  'header_signed_out',
  'header_sign_out',
  'baby_profile',
  'hello_first_name'
]

const HeaderNew = () => {
  const { isAuthenticated, logout, user } = useAuth0();
  const { data: accountTypes } = useGetAccountTypesQuery({
    title: 'Children'
  })
  const { data: babies = [] } = useGetAccountsQuery({
    account_type_id: accountTypes?.[0]?.id
  }, {
    skip: !isAuthenticated || !accountTypes
  })
  const { data: pageImages } = useContentful({
    content_type: 'randomImage',
    'fields.name[in]': images.join(','),
    convertToObject: true,
  })
  const { data: pageText } = useContentful({
    content_type: 'randomParagraph',
    'fields.name[in]': text.join(','),
    convertToObject: true,
  })

  return (
    <header>
      <Navbar expand="md" collapseOnSelect>
        <Container>
          <Navbar.Brand as="div">
            <Link to="/">
              <img className="main-logo" src={getContentfulImage(pageImages?.tiniest_biome_white_logo_png.fields).url} alt="" />
            </Link>
          </Navbar.Brand>
          <div className="d-flex align-items-center gap-4">
            <CartLink className="d-md-none" />
            <Navbar.Toggle aria-controls="basic-navbar-nav">
              <HamburgerMenu />
            </Navbar.Toggle>
          </div>

          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-md`}
            aria-labelledby={`offcanvasNavbarLabel-expand-md`}
            placement="end"
            className="navbar-offcanvas"
          >
            <Offcanvas.Header closeButton className="justify-content-end" />
            <Offcanvas.Body className="justify-content-between">
              <Nav className="navbar-list">
                {isAuthenticated? (
                  <>
                    <div className="d-block d-md-none mb-4">
                      <Typography textType="heading3" 
                        text={user && user.given_name ?
                          documentToPlainTextString(pageText?.hello_first_name?.fields.content).replace("{{first name}}", (user?.given_name))
                          :
                          documentToPlainTextString(pageText?.hello_first_name?.fields.content).replace(", {{first name}}", "")
                        }
                        block />
                    </div>
                    {(babies?.length > 1) ? (
                      <>
                        <NavDropdown title={getContentfulContent(pageText?.header_babys_dashboard.fields)} id="collasible-nav-dropdown" as="span" className="navbar-link py-0">
                          {babies?.map(baby => {
                            return (
                              <Link key={baby.id} to={'/account/my-babies/' + baby.id}>
                                <NavDropdown.Item as="span" className="navbar-link py-2">
                                  {baby.title}
                                </NavDropdown.Item>
                              </Link>
                            )}
                          )}
                        </NavDropdown>
                      </>
                    ) : (babies?.length === 1 ? (
                      <>
                        <Link to={'/account/my-babies/' + babies[0].id}>
                          <Nav.Link as="span" className="navbar-link py-0">
                            {getContentfulContent(pageText?.baby_profile?.fields).replace('{{baby.name}}', babies[0].title)}
                          </Nav.Link>
                        </Link>
                      </>
                    ) : (
                      <>
                      </>
                    ))
                    }
                    <Link to='/account'>
                      <Nav.Link as="span" className="navbar-link py-0">
                        {getContentfulContent(pageText?.header_my_account.fields)}
                      </Nav.Link>
                    </Link>
                    <Link to='/kits/baby-microbiome-gut-health-test-kit'>
                      <Nav.Link as="span" className="navbar-link py-0 ">
                        {getContentfulContent(pageText?.header_shop.fields)}
                      </Nav.Link>
                    </Link>
                    <NavDropdown title={getContentfulContent(pageText?.header_learn.fields)} id="collasible-nav-dropdown" as="span" className="navbar-link py-0">
                      <Link to='/faq'>
                        <NavDropdown.Item as="span" className="navbar-link py-2">
                          {getContentfulContent(pageText?.header_faq.fields)}
                        </NavDropdown.Item>
                      </Link>
                      <Link to='/knowledge-library'>
                        <NavDropdown.Item as="span" className="navbar-link py-2">
                          {getContentfulContent(pageText?.header_knowledge_library.fields)}
                        </NavDropdown.Item>
                      </Link>
                      <Link to='/about-us'>
                        <NavDropdown.Item as="span" className="navbar-link py-2">
                          {getContentfulContent(pageText?.header_who_we_are.fields)}
                        </NavDropdown.Item>
                      </Link>
                    </NavDropdown>
                  </>
                ) : (
                  <>
                    <Link to='/about-us'>
                      <Nav.Link as="span" className="navbar-link py-0">
                        {getContentfulContent(pageText?.header_who_we_are.fields)}
                      </Nav.Link>
                    </Link>
                    <Link to='/waitlist-quiz'>
                      <Nav.Link as="span" className="navbar-link py-0">
                        {getContentfulContent(pageText?.header_take_the_quiz.fields)}
                      </Nav.Link>
                    </Link>
                    <NavDropdown title={getContentfulContent(pageText?.header_learn.fields)} id="collasible-nav-dropdown" as="span" className="navbar-link py-0">
                      <Link to='/faq'>
                        <NavDropdown.Item as="span" className="navbar-link py-2" href="/faq">
                          {getContentfulContent(pageText?.header_faq.fields)}
                        </NavDropdown.Item>
                      </Link>
                      <Link to='/knowledge-library'>
                        <NavDropdown.Item as="span" className="navbar-link py-2" href="/knowledge-library">
                          {getContentfulContent(pageText?.header_knowledge_library.fields)}
                        </NavDropdown.Item>
                      </Link>
                    </NavDropdown>
                  </>
                )}
              </Nav>
              <Nav>
                {!isAuthenticated && (
                  <Link to='/kits/baby-microbiome-gut-health-test-kit' className="link-light text-decoration-none">
                    <Nav.Link as="button" className="button button--primary button--small px-5 header-get-started w-100 mt-6 mt-md-0">
                      {getContentfulContent(pageText?.button_get_started.fields)}
                    </Nav.Link>
                  </Link>
                )}
                {isAuthenticated ? (
                  <Link to='/activation' className="link-light text-decoration-none">
                    <Nav.Link as="button" className="button button--primary button--small px-5 activate-kit">
                      {getContentfulContent(pageText?.button_activate_kit.fields)}
                    </Nav.Link>
                  </Link>
                ) : (
                  <Link to='/activation' className="link-light text-decoration-none">
                    <Nav.Link as="button" className="button button--secondary button--small px-5 activate-kit">
                      {getContentfulContent(pageText?.button_activate_kit.fields)}
                    </Nav.Link>
                  </Link>
                )}
                {isAuthenticated ? (
                  <>
                    <NavDropdown title={user && user.given_name && user.family_name ? user?.given_name[0] + user?.family_name[0] : "Profile"} id="collasible-nav-dropdown" as="span" className="navbar-link d-none d-md-block py-0">
                      <Link to='/account/settings'>
                        <NavDropdown.Item as="span" className="navbar-link py-2" href="/account/settings">
                          {getContentfulContent(pageText?.header_settings.fields)}
                        </NavDropdown.Item>
                      </Link>
                      <Link to='#' className="auth-link">
                        <NavDropdown.Item as="span" className="navbar-link py-2"  onClick={() => logout({ returnTo: window.location.origin })}>
                          {getContentfulContent(pageText?.header_sign_out.fields)}
                        </NavDropdown.Item>
                      </Link>
                    </NavDropdown>
                    <Link to='/account/settings' className="d-block d-md-none">
                      <Nav.Link as="span" className="navbar-link py-2 mt-3 text-center d-block d-md-none small-link">
                        {getContentfulContent(pageText?.header_settings.fields)}
                      </Nav.Link>
                    </Link>
                    <Link to='#' className="auth-link d-block d-md-none">
                      <Nav.Link as="span" className="navbar-link py-0 text-center d-block d-md-none small-link" onClick={() => logout({ returnTo: window.location.origin })}>
                        {getContentfulContent(pageText?.header_sign_out.fields)}
                      </Nav.Link>
                    </Link>
                  </>
                  ) : (
                  <>
                    <Link to={`/auth/sign-in?redirect_url=${window.location.pathname}`}>
                      <Nav.Link as="span" className="navbar-link d-none d-md-block py-0">
                        {getContentfulContent(pageText?.button_sign_in.fields)}
                      </Nav.Link>
                    </Link>
                    <Link className="link-light d-block d-md-none" to={`/auth/sign-in?redirect_url=${window.location.pathname}`}>
                      <Nav.Link as="button" className="button button--link button--small px-5 my-2 w-100 text-center d-block d-md-none">
                        {getContentfulContent(pageText?.button_sign_in.fields)}
                      </Nav.Link>
                    </Link>
                  </>
                )} 
                <CartLink className="d-none d-md-block" />
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </header>
  )
}

export default HeaderNew
