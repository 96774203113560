import { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import Slider from 'react-slick';
import { Col, Container, Row, Card } from 'react-bootstrap'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import InputSpinner from 'react-bootstrap-input-spinner'  
import { PRODUCT_VIEW_SLUGS } from '../../../constants/pageSlugs';
import { fetchContentfulPage } from '../../../services/apiService';
import useAddItem from '../../../hooks/cart/useAddItem';
import Typography from '../../ui/typography/Typography';
import Button from '../../ui/button/Button';
import Link from '../../ui/link/Link';
import ReactPlayer from "react-player";

const ProductView = (props) => {
  const { product } = props;
  const { images, attributes, primaryVariant } = product;
  const { name, display_price, price, compare_at_price, in_stock } = attributes;
  
  const [mainImageSlider, setMainImageSlider] = useState();
  const [thumbnailSlider, setThumbnailSlider] = useState();
  const [quantity, setQuantity] = useState();
  const [contentfulData, setContentfulData] = useState(null);
  const addItem = useAddItem();

  const addToCart = () => {
    addItem({
      variant_id: primaryVariant.id,
      quantity: quantity
    })
  }
  
  useEffect(() => {
    fetchContentfulPage(PRODUCT_VIEW_SLUGS)
      .then((res) => {
        setContentfulData(res.data);
      })
  }, [])

  if (!contentfulData) {
    return (
      <>
        Loading...
      </>
    )
  }

  const {
    for_ages_text,
    free_shipping_text,
    button_add_to_cart,
    shipping_restrictions_note_mvp,
    shipping_restrictions_note_mvp_out_of_stock,
    out_of_stock_text,
    out_of_stock_notice,
    button_take_quiz_waitlist,
    product_what_it_is,
    product_what_it_is_notes,
    why_it_matters,
    what_is_in_the_box,
    what_is_in_the_box_video,
    baby_wellness_experience,
    baby_dashboard_walkthrough_gif,
    how_it_works_video,
    product_page_how_it_works_section,
    product_step_by_step,
    product_what_you_get,
    why_tiniest_biome,
    product_step_1,
    product_step_2,
    product_step_3,
    product_step_4
  } = contentfulData;
  // The first image is only for the Cart/Checkout pages
  const imagesToList = images && images.filter((image, i) => i !== 0);
  
  return (
    <div id="product-view-container">
      <Container id='productView' className='pt-10 pt-md-20 mb-4 mb-md-0'>
        <Row className='mx-0'>
          <Col md='6' xs='12' className='mx-6 mx-md-0 px-0 product-details d-block d-md-none'>
            <h2 id='title'>{name}</h2>
            <p id='subtitle'>{documentToPlainTextString(for_ages_text?.content)}</p>
            <p id='price'>
              <span className='price'>{parseInt(price)}</span> 
              {compare_at_price && compare_at_price > 0 && (<del className='compare-at-price'><span>{parseInt(compare_at_price)}</span></del> )}
              <span className='free-shipping'>{documentToPlainTextString(free_shipping_text?.content)}</span>
            </p>
          </Col>
          <Col md='6' xs='12'>
            <Slider 
              asNavFor={thumbnailSlider}
              arrows={false} 
              ref={(slider1) => setMainImageSlider(slider1)}
              className='d-none d-sm-block'
              >
              {imagesToList && imagesToList.map((image) => 
                <img key={image.id} src={image.attributes.original_url} alt={image.attributes.alt} />
              )}
            </Slider>
            <Slider 
              className='slider-nav d-none d-sm-block' 
              asNavFor={mainImageSlider} 
              arrows={false} 
              ref={(slider2) => setThumbnailSlider(slider2)} 
              slidesToShow={5} 
              swipeToSlide={true} 
              focusOnSelect={true}
              >
              {imagesToList && imagesToList.map((image) => 
                <img key={image.id} src={image.attributes.styles[3].url} alt={image.attributes.alt} width='77' height='77' />
                )}
            </Slider>
            <Slider 
              dots={true} 
              arrows={false} 
              className='d-block d-sm-none mobile-slider mb-16'
              >
              {imagesToList && imagesToList.map((image) => 
                <img key={image.id} src={image.attributes.original_url} alt={image.attributes.alt} />
              )}
            </Slider>
          </Col>
          <Col md='6' xs='12' className='mx-6 mx-md-0 px-0 product-details'>
            <h2 id='title' className='d-none d-md-block'>{name}</h2>
            <p id='subtitle' className='d-none d-md-block'>{documentToPlainTextString(for_ages_text?.content)}</p>
            <p id='price' className='d-none d-md-block'>
              <span className='price'>{parseInt(price)}</span> 
              {compare_at_price && compare_at_price > 0 && (<del className='compare-at-price'><span>{parseInt(compare_at_price)}</span></del> )}
              {in_stock && (
                <span className='free-shipping'>{documentToPlainTextString(free_shipping_text?.content)}</span>
              )}
              {!in_stock && (
                <span className='free-shipping'>{documentToPlainTextString(out_of_stock_text?.content)}</span>
              )}
            </p>
            {in_stock && (
              <Row id='actionBtns' className='mt-md-8 gap-2 py-3 mx-0'>
                <Col md={4} xs={5} className='px-0'>
                  <InputSpinner type={'real'} step={1} value={1} onChange={num=>setQuantity(num)} variant={''} size="lg" />
                </Col>
                <Col md={7} xs={5} className='px-0'>
                  <div className="d-grid gap-2">
                    <Button id='addBtn' 
                      onClick={addToCart} 
                      label={documentToPlainTextString(button_add_to_cart?.content)} 
                      className='rounded-pill'
                      fullWidth >{documentToPlainTextString(button_add_to_cart?.content)}</Button>
                  </div>
                </Col>
              
              </Row>
            )}
            {!in_stock && (
              <Row id="outOfStock" className='mt-md-8 gap-2 py-3 mx-0 mb-8'>
                <Col className='px-0'>
                  <div className="d-grid gap-2">
                    <Typography textType="body1" className="mb-4" block text={documentToReactComponents(out_of_stock_notice?.content)} />
                    <Link to="../waitlist-quiz">
                      <Button label={documentToPlainTextString(button_take_quiz_waitlist?.content)}  className="w-auto btn-take-quiz" />
                    </Link>
                  </div>
                </Col>
              </Row>
            )}
            {in_stock && (
              <Row className="shipping-restrictions-note mb-md-20 py-3">
                <Col>
                  {shipping_restrictions_note_mvp && documentToReactComponents(shipping_restrictions_note_mvp?.content)}
                </Col>
              </Row>
            )}
            {!in_stock && (
              <Row className="shipping-restrictions-note mb-md-20 py-3">
                <Col>
                  {shipping_restrictions_note_mvp_out_of_stock && documentToReactComponents(shipping_restrictions_note_mvp_out_of_stock?.content)}
                </Col>
              </Row>
            )}
            <Row id='whatItIs' className='mt-8 py-4 px-5 pb-md-8 mx-0 rounded-3'>
              {product_what_it_is?.title && (<Typography textType={'heading3'} className="what-it-is-title mb-4 p-0" text={product_what_it_is?.title} />)}
              <p className='what-it-is-text p-0'>{product_what_it_is && documentToReactComponents(product_what_it_is?.content)}</p>
              <p className='what-it-is-smallprint p-0'>{documentToReactComponents(product_what_it_is_notes?.content)}</p>
              <h3 className='what-it-is-title why-it-matters mb-4 p-0'>{why_it_matters?.title}</h3>
              <p className='what-it-is-text why-it-matters p-0 mb-0'>{documentToReactComponents(why_it_matters?.content)}</p>
            </Row>
          </Col>
        </Row>
      </Container>
      <section className="gray-gradient-section">
        <div className="gradient-start" />
        <div className="gradient-content py-8 py-md-0">
          <Container className='mx-0 mx-sm-auto pb-md-0'>
            <Row className='justify-content-center'>
              <Col xs={12} className="font-Moranga mb-12 text-center d-none d-sm-block">
                {product_what_you_get?.content && (<Typography textType={'heading2'} className="what-you-get-title text-center" text={documentToPlainTextString(product_what_you_get?.content)} />)}
              </Col>
              <Card className='flex-column p-6 py-md-10 px-md-20 rounded-9'>
                <Col xs={12} className="font-Moranga mb-12 text-center d-block d-sm-none">
                  {product_what_you_get?.content && (<Typography textType={'heading2'} className="what-you-get-title text-center" text={documentToPlainTextString(product_what_you_get?.content)} />)}
                </Col>
                <Col className='d-flex flex-column flex-md-row mx-md-3  d-none d-sm-block'>
                  <Col xs={12} md={12} className="d-md-flex mb-4 text-center justify-content-center mx-auto">
                    {what_is_in_the_box && (<Typography textType={'heading3'} className="what-you-get-title text-center" text={what_is_in_the_box?.title} />)}
                  </Col>
                </Col>
                <Col className='d-flex flex-column flex-md-row mx-md-3'>
                  <Col xs={12} md={12} className="d-md-flex mb-6 mb-md-12 justify-content-center mx-auto what-is-in-the-box">
                    {what_is_in_the_box_video && (
                      <div className='video-wrapper'>
                        <ReactPlayer
                          url={documentToPlainTextString(what_is_in_the_box_video?.videoUrl)}
                          controls
                          className="video-responsive"
                        />
                      </div>
                    )}
                  </Col>
                </Col>
                <Col className='d-flex flex-column flex-md-row mx-md-3 d-block d-sm-none'>
                  <Col xs={12} md={12} className="d-md-flex mb-4 text-center justify-content-center mx-auto">
                    {what_is_in_the_box && (<Typography textType={'heading3'} className="what-you-get-title text-center" text={what_is_in_the_box?.title} />)}
                  </Col>
                </Col>
                <Col className='d-flex flex-column flex-md-row mx-md-3'>
                  <Col xs={12} md={12} className='columns-2 what-is-in-the-box'>
                    {what_is_in_the_box && documentToReactComponents(what_is_in_the_box?.content)}
                  </Col>
                </Col>
                <Col className="d-flex flex-column flex-md-row mx-md-3 mt-10 mt-md-12">
                  <Col xs={12} md={6} className="d-md-flex text-center mx-auto order-md-1 mb-4 mb-md-0">
                    {baby_dashboard_walkthrough_gif?.image && <img src={baby_dashboard_walkthrough_gif.image.fields.file.url} className="how-it-works-image" alt="" />}
                  </Col>
                  <Col xs={12} md={6} className='my-auto order-md-0 baby-wellness-experience'>
                    {baby_wellness_experience && <Typography textType={'heading3'} className="mb-6 mb-md-8 title-text" text={baby_wellness_experience?.title} />}
                    {baby_wellness_experience && documentToReactComponents(baby_wellness_experience?.content)}
                  </Col>
                </Col>
              </Card>
            </Row>
          </Container>
        </div>
        <div className="gradient-end" />
      </section>
      <section className="my-7 my-md-0 how-it-works">
        <Container className='px-10 px-md-3'>
          <Row className='justify-content-center'>
            <Col xs={12} className="mb-md-8 text-center font-Moranga d-none d-sm-block">
              {product_page_how_it_works_section && <Typography textType="heading2" text={documentToReactComponents(product_page_how_it_works_section?.title)} />}
            </Col>
            <Card className='flex-column flex-md-row p-6 px-md-20 rounded-9'>
              <Col xs={12} className="mb-2 text-center font-Moranga d-block d-sm-none">
                {product_page_how_it_works_section && <Typography textType="heading2" text={documentToReactComponents(product_page_how_it_works_section?.title)} />}
              </Col>
              <Col xs={12} md={6} className='d-md-flex align-items-center mb-3 mb-md-0'>
                {how_it_works_video && (
                  <div className='video-wrapper'>
                    <ReactPlayer
                      url={documentToPlainTextString(how_it_works_video?.videoUrl)}
                      controls
                      className="video-responsive"
                    />
                  </div>
                )}
              </Col>
              <Col xs={12} md={6} className='p-5'>
                <Col className='d-flex flex-row gap-4 mb-2 mb-md-0 mb-2 mb-md-0'>
                  <Col xs={12} md={2} className="d-none d-md-block">
                    {product_page_how_it_works_section?.step1Icon && <img src={product_page_how_it_works_section.step1Icon.fields.file.url} className="how-it-works-icon" alt="" />}
                  </Col>
                  <Col xs={12} md={10}>
                    {product_page_how_it_works_section?.step1Icon &&
                      <img 
                        src={product_page_how_it_works_section.step1Icon.fields.file.url}
                        className="how-it-works-icon mb-2 d-inline-block d-md-none" 
                        alt="" />}
                    {product_page_how_it_works_section?.step1Title && <Typography textType='heading4' className="font-Moranga mb-2" text={product_page_how_it_works_section?.step1Title}/>}
                    {product_page_how_it_works_section?.step1 && documentToReactComponents(product_page_how_it_works_section?.step1)}
                  </Col>
                </Col>
                <Col className='d-none d-md-block '>
                  {product_page_how_it_works_section?.arrow && <img src={product_page_how_it_works_section.arrow.fields.file.url} className="how-it-works-arrow" alt="" />}
                </Col>
                <Col className='d-flex flex-row gap-4 mb-2 mb-md-0'>
                  <Col xs={12} md={2} className="d-none d-md-flex align-items-center">
                    {product_page_how_it_works_section?.step2Icon && <img src={product_page_how_it_works_section.step2Icon.fields.file.url} className="how-it-works-icon" alt="" />}
                  </Col>
                  <Col xs={12} md={10}>
                    {product_page_how_it_works_section?.step2Icon &&
                      <img 
                        src={product_page_how_it_works_section.step2Icon.fields.file.url}
                        className="how-it-works-icon mb-2 d-inline-block d-md-none" 
                        alt="" />}
                    {product_page_how_it_works_section?.step2Title && <Typography textType='heading4' className="font-Moranga mb-2" text={product_page_how_it_works_section?.step2Title}/>}
                    {product_page_how_it_works_section?.step2 && documentToReactComponents(product_page_how_it_works_section?.step2)}
                  </Col>
                </Col>
                <Col className='d-none d-md-block '>
                  {product_page_how_it_works_section?.arrow && <img src={product_page_how_it_works_section.arrow.fields.file.url} className="how-it-works-arrow" alt="" />}
                </Col>
                <Col className='d-flex flex-row gap-4'>
                  <Col xs={12} md={2} className="d-none d-md-flex align-items-center">
                    {product_page_how_it_works_section?.step3Icon && <img src={product_page_how_it_works_section.step3Icon.fields.file.url} className="how-it-works-icon" alt="" />}
                  </Col>
                  <Col xs={12} md={10}>
                    {product_page_how_it_works_section?.step3Icon &&
                      <img 
                        src={product_page_how_it_works_section.step3Icon.fields.file.url}
                        className="how-it-works-icon mb-2 d-inline-block d-md-none" 
                        alt="" />}
                    {product_page_how_it_works_section?.step3Title && <Typography textType='heading4' className="font-Moranga mb-2" text={product_page_how_it_works_section?.step3Title}/>}
                    {product_page_how_it_works_section?.step3 && documentToReactComponents(product_page_how_it_works_section?.step3)}
                  </Col>
                </Col>
              </Col>
            </Card>
          </Row>
        </Container>
      </section>
          
      <section className="blue-gradient-section">
        <div className="gradient-start" />
        <div className="gradient-content py-12 py-md-0">
          <Container className='why-tiniest-biome pb-8 pb-md-30 py-6 py-md-0'>
            <Row className='justify-content-center'>
              <Col className="text-center mt-3 mb-8 why-tiniest-biome-title">
                {why_tiniest_biome && <Typography className='pink-title-tiniest-biome font-Moranga' textType="heading2" text={documentToReactComponents(why_tiniest_biome?.text)} />}
              </Col>
            </Row>
            <Row className='justify-content-center gap-6'>
              <Card md={6} xs={12} className='px-6 py-2 py-md-6 rounded-1r'>
                <img src={product_step_1?.image?.fields?.file?.url} alt='step1' className='mx-auto mb-4 mb-md-10'/>
                <Typography textType="heading4" className='font-Moranga mx-0' text={documentToReactComponents(product_step_1?.text)} />
              </Card>
              <Card md={6} xs={12} className='px-6 py-2 py-md-6 rounded-1r'>
                <img src={product_step_2?.image?.fields?.file?.url} alt='step2' className='mx-auto mb-4 mb-md-10'/>
                <Typography textType="heading4" className='font-Moranga mx-0' text={documentToReactComponents(product_step_2?.text)} />
              </Card>
              <Card md={6} xs={12} className='px-6 py-2 py-md-6 rounded-1r'>
                <img src={product_step_3?.image?.fields?.file?.url} alt='step3' className='mx-auto mb-4 mb-md-10'/>
                <Typography textType="heading4" className='font-Moranga mx-0' text={documentToReactComponents(product_step_3?.text)} />
              </Card>
              <Card md={6} xs={12} className='px-6 py-2 py-md-6 rounded-1r'>
                <img src={product_step_4?.image?.fields?.file?.url} alt='step4' className='mx-auto mb-4 mb-md-10'/>
                <Typography textType="heading4" className='font-Moranga mx-0' text={documentToReactComponents(product_step_4?.text)} />
              </Card>
            </Row>
          </Container>
        </div>
      </section> 
    </div>
  )
}

ProductView.propTypes = {
  product: PropTypes.object,
  attributes: PropTypes.object,
  name: PropTypes.string,
  description: PropTypes.string,
  display_price: PropTypes.string,
  primaryVariant: PropTypes.object
}

export default ProductView
