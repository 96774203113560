const { findSingleRelationshipDocument } = window.SpreeSDK

const normalizeUser = (
  spreeSuccessResponse,
  spreeUser,
) => {
  let billingAddress;
  let shippingAddress;

  if (spreeUser.relationships.default_billing_address.data) {
    billingAddress = findSingleRelationshipDocument(
      spreeSuccessResponse,
      spreeUser,
      'default_billing_address',
    )
  }

  if (spreeUser.relationships.default_shipping_address.data) {
    shippingAddress = findSingleRelationshipDocument(
      spreeSuccessResponse,
      spreeUser,
      'default_shipping_address',
    )
  }

  return {
    ...spreeUser.attributes,
    shipping_address: {
      id: shippingAddress?.id,
      ...shippingAddress?.attributes,
    },
    billing_address: {
      id: billingAddress?.id,
      ...billingAddress?.attributes,
    },
  }
}

export default normalizeUser;
