import { useDispatch, useSelector } from 'react-redux'
import { addItem, cartSelector, createCart } from '../../store/slices/cart/cartSlice'
import SpreeService from '../../services/spreeService'
import ensureToken from '../../services/ensureToken'
import { useContentful } from '../useContentful'
import { useNavigate } from 'react-router-dom'
import { customToast } from '../../components/ui/notification/customToast'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'

const useAddItem = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { data: cart } = useSelector(cartSelector);

  const { data: errorMessages } = useContentful({
    content_type: 'randomParagraph',
    'fields.name[in]': 'error_message_product_out_of_stock',
    convertToObject: true,
  })

  const checkCartValidity = () => {
    if (!cart || ['complete'].includes(cart.state)) {
      return false
    }

    return true
  }

  const addItemToCart = async (data) => {
    let token = ensureToken()

    if (!token) {
      const response = await SpreeService.createCart()

      token = response.customerId
      localStorage.setItem('orderToken', token)

      await addItemToCart({
        ...data
      })
    } else {
      const isCartValid = checkCartValidity();

      if (!isCartValid) {
        await dispatch(createCart())
      }

      try {
        const result = await dispatch(addItem({
          ...data,
          ...token
        }))
          .unwrap()
        navigate('/cart', { replace: true })

        return result;
      } catch (error) {
        customToast({
          message: documentToPlainTextString(errorMessages?.error_message_product_out_of_stock.fields.content),
          type: 'error'
        });
        return;
      }
    }
  }

  return addItemToCart
}

export default useAddItem
