import IconPropTypes from './IconPropTypes';

function RecipeBookIcon({
  size = 54
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 70 70"
    >
      <path
        stroke="#44C8F5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="3.1"
        d="M16 52.938V15.65A5.652 5.652 0 0121.65 10h32.768v38.418h-3.39"
      ></path>
      <path
        stroke="#44C8F5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="3.1"
        d="M21.87 58.588h-.695A5.174 5.174 0 0116 53.412v.113c0-2.858 2.316-5.096 5.175-5.096h33.243V58.6H36.87"
      ></path>
      <path
        stroke="#44C8F5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="3.1"
        d="M36.87 54.068h13.028v-5.65M20.87 53.87h1M26.17 51.808v9.04l3.39-2.09 3.39 2.09v-9.04"
      ></path>
      <path
        stroke="#44C8F5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3.1"
        d="M39.729 15.65v4.52M43.119 15.65v4.52M46.508 15.65v7.91c0 1.615-.994 2.474-2 2.937-.836.384-1.322 1.266-1.265 2.181l1.006 12.96c0 .972-.283 2.26-2.825 2.26-2.543 0-2.825-1.288-2.825-2.26l1.006-12.96c.067-.915-.43-1.797-1.266-2.18-1.017-.464-2-1.312-2-2.939v-7.91M25.186 20.328c-.429 2.576-1.276 10.011-1.276 10.011l2.26 2.26v9.04c0 1.174.858 2.26 2.26 2.26 1.4 0 2.26-.95 2.26-2.125v-25.56s0-1.412-1.696-1.412c-1.695 0-3.356 2.825-3.808 5.526v0z"
      ></path>
    </svg>
  );
}

RecipeBookIcon.propTypes = IconPropTypes

export default RecipeBookIcon;
