const normalizeRecipe = (item) => {

  return {
    ...item.fields,
    id: item.sys.id,
    bacteria: item.fields.bacteria.map(bacteria => bacteria.fields.bacteria),
    foodTypeIcon: item.fields.foodTypeIcon.fields,
    foodTypeValue: item.fields.foodTypeValue.fields.foodType,
    ingredients: item.fields.ingredients.map(ingredient => ingredient.fields.ingredient),
    knowledgeLibraryCategory: item.fields.knowledgeLibraryCategory.fields.name,
    recipePicture: item.fields.recipePicture.fields,
    servingsIcon: item.fields.servingsIcon.fields,
    totalTimeIcon: item.fields.totalTimeIcon.fields,
    wellnessArea: {
      ...item.fields.wellnessArea.fields,
      id: item.fields.wellnessArea.sys.id,
    },
  }
}

export default normalizeRecipe
