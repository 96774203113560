import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useResolvedPath, useMatch, Link as RouterLink } from 'react-router-dom';

const Link = ({
  children,
  to,
  className,
  underline,
  external,
  ...props
}) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  const linkClassName = clsx('link', {
    'active': match,
    'underline': underline,
    [className]: className
  })

  if (external) {
    return (
      <a href={to} className={linkClassName} {...props}>
        {children}
      </a>
    )
  }

  return (
    <RouterLink to={to} className={linkClassName}>
      {children}
    </RouterLink>
  )
}

Link.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
  underline: PropTypes.bool,
  external: PropTypes.bool,
}

export default Link
