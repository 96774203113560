import React from 'react'
import HeaderNew from '../Header/HeaderNew'
import FooterNew from '../Footer/FooterNew'
import { Outlet } from 'react-router-dom';

export const Layout = () => {
  return (
    <>
      <HeaderNew />
      <main>
        <Outlet />
      </main>
      <FooterNew />
    </>
  )
}

export default Layout
