import { toast } from 'react-toastify';
import Typography from '../typography/Typography';
import SuccessIcon from '../icons/SuccessIcon';
import InfoIcon from '../icons/InfoIcon';
import WarningIcon from '../icons/WarningIcon';

export const CustomToast = ({
  title,
  message,
  type,
}) => {
  return (
    <div className="toast-custom-body">
      <div>
        {type === 'info' && <InfoIcon />}
        {type === 'error' && <WarningIcon />}
        {type === 'success' && <SuccessIcon />}
      </div>
      <div>
        <Typography textType="heading5" text={title} className="mb-1" block />
        <Typography textType="body1" text={message} block />
      </div>
    </div>
  )
}

export const customToast = ({ title, type = 'info', message }) => {
  toast(<CustomToast title={title} type={type} message={message} />, { type: type });
};
