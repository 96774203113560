import { useEffect, useState } from 'react'
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { selectCountries } from '../../../store/slices/global/countriesSlice'
import Checkbox from '../../ui/forms/Checkbox'

const AccountAddressForm = ({
  savedData,
  data,
  excludedStates,
}) => {
  const {
    register,
    watch,
    resetField,
    reset,
    formState: { errors }
  } = useFormContext()
  const { data: countries } = useSelector(selectCountries)
  const [useSaved, setUseSaved] = useState(false)
  const [states, setStates] = useState([])
  const formData = watch()

  useEffect(() => {
    if (data) {
      reset({
        firstname: data.firstname,
        lastname: data.lastname,
        address1: data.address1,
        city: data.city,
        country_iso: data.country_iso,
        state_name: data.state_code,
        zipcode: data.zipcode,
        phone: data.phone,
      })
    }
  }, [data, reset])

  useEffect(() => {
    if (useSaved) {
      reset({
        firstname: savedData.firstname,
        lastname: savedData.lastname,
        address1: savedData.address1,
        city: savedData.city,
        country_iso: savedData.country_iso,
        state_name: savedData.state_code,
        zipcode: savedData.zipcode,
        phone: savedData.phone,
        useShippingAddress: useSaved,
      })
    }
  }, [reset, useSaved, savedData])

  useEffect(() => {
    if (countries.length > 0 && formData.country_iso) {
      const countryStates = countries.find(country => country.iso === formData.country_iso)?.states || []

      if (excludedStates) {
        setStates(countryStates.filter(state => !excludedStates.includes(state.name)))
      } else {
        setStates(countryStates)
      }
    }

    resetField('state_name')
  }, [countries, resetField, formData.country_iso, excludedStates])

  const stateRequired = formData.country_iso &&
    countries.length > 0 &&
    countries.find(country => country.iso === formData.country_iso).states?.length > 0

  const handleUseSaved = () => {
    if (useSaved) {
      setUseSaved(false);
    } else {
      setUseSaved(true);
      reset({
        firstname: savedData.firstname,
        lastname: savedData.lastname,
        address1: savedData.address1,
        city: savedData.city,
        country_iso: savedData.country_iso,
        state_name: savedData.state_code,
        zipcode: savedData.zipcode,
        phone: savedData.phone,
        useShippingAddress: false,
      })
    }
  }

  return (
    <Row className="py-3 py-md-0">
      {savedData?.id && (
        <Col lg={12}>
          <Checkbox
            label="Billing same as shipping address"
            value={useSaved}
            onChange={handleUseSaved}
            wrapClassName="px-0 pb-4"
            labelClassName="checkout-checkbox"
          />
        </Col>
      )}
      <Col md={6}>
        <FloatingLabel controlId="firstname" label="First name" className="mb-3">
          <Form.Control
            type="text"
            placeholder="First name"
            {...register("firstname", {
              required: 'First name is required'
            })}
            disabled={useSaved}
            className="w-100"
          />
        </FloatingLabel>
        {errors.firstname && <Form.Text>{errors.firstname.message}</Form.Text>}
      </Col>
      <Col md={6}>
        <FloatingLabel controlId="lastname" label="Last name" className="mb-3">
          <Form.Control
            type="text"
            placeholder="Last name"
            {...register("lastname", {
              required: 'Last name is required'
            })}
            disabled={useSaved}
            className="w-100"
          />
          {errors.lastname && <Form.Text>{errors.lastname.message}</Form.Text>}
        </FloatingLabel>
      </Col>
      <Col lg={12}>
        <FloatingLabel label="Street Address" className="mb-4" controlId="checkoutStreetAddress">
          <Form.Control
            type="text"
            placeholder="Street Address"
            {...register("address1", {
              required: 'Address is required'
            })}
            disabled={useSaved}
            className="w-100"
          />
          {errors.address1 && <Form.Text>{errors.address1.message}</Form.Text>}
        </FloatingLabel>
      </Col>
      <Col lg={12}>
        <FloatingLabel label="City" className="mb-4" controlId="checkoutCity">
          <Form.Control
            type="text"
            placeholder="City"
            {...register("city", {
              required: 'City is required'
            })}
            disabled={useSaved}
            className="w-100"
          />
          {errors.city && <Form.Text>{errors.city.message}</Form.Text>}
        </FloatingLabel>
      </Col>
      <Col xs={12}>
        <FloatingLabel label="Country" className="mb-4" controlId="checkoutCountry">
          <Form.Select
            aria-label="Default select example"
            {...register("country_iso", {
              required: 'Country is required'
            })}
            className="w-100"
          >
            <option value="">Select Country</option>
            {countries.map(country => (
              <option
                key={country.id}
                value={country.iso}
                >{country.name}</option>
            ))}
          </Form.Select>
          {errors.country_iso && <Form.Text>{errors.country_iso.message}</Form.Text>}
        </FloatingLabel>
      </Col>
      {states.length > 0 ? (
        <Col md={6}>
          <FloatingLabel label="State" className="mb-3" controlId="checkoutState">
            <Form.Select
              type="text"
              {...register("state_name", {
                required: stateRequired && 'State is required'
              })}
              disabled={useSaved}
              className="w-100"
            >
              <option value="">Select State</option>
              {states.map(state => (
                <option key={state.id} value={state.abbr}>{state.name}</option>
              ))}
            </Form.Select>
            {errors.state_name && <Form.Text>{errors.state_name.message}</Form.Text>}
          </FloatingLabel>
        </Col>
      ) : (
        <Col md={6}>
          <FloatingLabel label="State" className="mb-4" controlId="checkoutState">
            <Form.Control
              type="text"
              placeholder="State"
              {...register("state_name", {
                required: stateRequired && 'State is required'
              })}
              disabled={useSaved}
              className="w-100"
            />
            {errors.state && <Form.Text>{errors.state.message}</Form.Text>}
          </FloatingLabel>
        </Col>
      )}
      <Col md={6}>
        <FloatingLabel label="Zip code" className="mb-4" controlId="checkoutZipCode">
          <Form.Control
            type="text"
            placeholder="Zip code"
            {...register("zipcode", {
              required: 'Zipcode is required'
            })}
            disabled={useSaved}
            className="w-100"
          />
          {errors.zipcode && <Form.Text>{errors.zipcode.message}</Form.Text>}
        </FloatingLabel>
      </Col>
      <Col lg={12}>
        <FloatingLabel label="Phone Number" className="mb-4" controlId="checkoutPhone">
          <Form.Control
            type="text"
            placeholder="Phone Number"
            {...register("phone", {
              required: "Phone number should be at least 11 digits starting with +, for example +12345678900",
              minLength: {
                value: 11,
                message: "Phone number should be at least 11 digits starting with +, for example +12345678900"
              },
              pattern: {
                value: /^\+[123456789]\d{7,14}$/,
                message: "Phone number should be at least 11 digits starting with +, for example +12345678900"
              }
            })}
            disabled={useSaved}
            className="w-100"
          />
          {errors.phone && <Form.Text>{errors.phone.message}</Form.Text>}
        </FloatingLabel>
      </Col>
    </Row>
  )
}

export default AccountAddressForm
