import getImageUrl from './get-image-url'

const createGetAbsoluteImageUrl =
  (useOriginalImageSize = true) =>
  (
    image,
    minWidth,
    minHeight
  ) => {
    let url

    if (useOriginalImageSize) {
      url = image.attributes.transformed_url || null
    } else {
      url = getImageUrl(image, minWidth, minHeight)
    }

    if (url === null) {
      return null
    }

    return url
  }

export default createGetAbsoluteImageUrl
