function BagIcon() {
  return (
    <svg width="36" height="30" viewBox="0 0 36 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.6372 28.0002C14.4405 28.0002 15.0917 27.4001 15.0917 26.6599C15.0917 25.9197 14.4405 25.3197 13.6372 25.3197C12.8338 25.3197 12.1826 25.9197 12.1826 26.6599C12.1826 27.4001 12.8338 28.0002 13.6372 28.0002Z" stroke="#444444" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M29.6372 28.0002C30.4405 28.0002 31.0917 27.4001 31.0917 26.6599C31.0917 25.9197 30.4405 25.3197 29.6372 25.3197C28.8338 25.3197 28.1826 25.9197 28.1826 26.6599C28.1826 27.4001 28.8338 28.0002 29.6372 28.0002Z" stroke="#444444" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2 2H7.81818L11.7164 19.9457C11.8494 20.5627 12.2137 21.117 12.7455 21.5115C13.2774 21.906 13.9428 22.1155 14.6255 22.1035H28.7636C29.4463 22.1155 30.1117 21.906 30.6436 21.5115C31.1754 21.117 31.5397 20.5627 31.6727 19.9457L34 8.70115" stroke="#444444" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default BagIcon;
